<template>
    <base-modal title="Feature Request" :footer="false" mode="wide">
        <div class="window" id="feature-request">
            <!-- Dashboard-->
            <div class="dashboardMain modal-form-body" id="insertFeedbackObject">
                <!-- left Side page-->
                <div class="leftSide">
                    <div class="action-section">
                        <div class="action-section-head">
                            <h2>Submit a Request
                                <VueCustomTooltip :multiline="true"
                                                  label="Suggest a new feature here. These ideas help us improve the terminal."
                                                  position="is-bottom">
                                    <font-awesome-icon :icon="['fas', 'question-circle']"/>
                                </VueCustomTooltip>
                            </h2>
                        </div>
                        <div>
                            <button class="action-section-button" @click="openRequestForm = !openRequestForm">Make a
                                Suggestion
                            </button>
                        </div>
                    </div>
                    <!--How it works section-->
                    <div class="action-section">
                        <div class="action-section-head">
                            <h2>How It Works
                            </h2>
                        </div>
                        <collapsible title="Learn More" buttonClass="action-section-button">
                            <div v-html="HOW_IT_WORKS_TIP" style="padding-right: 2px; font-weight: normal">
                            </div>
                        </collapsible>
                    </div>
                </div>
                <!-- Right side  page-->
                <div class="rightSide">
                    <div v-if="openRequestForm" class="request-form">
                      <h2 style="padding-left: 10px; ">
                                Please complete the form below. Remember to include as much detail as possible.</h2>
                      <div class="modal-form-body">
                        
                        <form class="filled-form" @submit.prevent >
                            
                            <fieldset>
                              <vue-form-generator  ref="form"
                                      :schema="schema"
                                      :model="featureModel"
                                      :options="formOptions"
                              ></vue-form-generator>
                              <base-button
                                      style="margin-left: 14px; margin-top: 5px; float: left"
                                      mode="green"
                                      :link="false"
                                      @click.native="submitNewFeatureRequest"
                              >Submit Request
                              </base-button>
                              <base-button
                                      style="margin-left: 14px; margin-top: 5px; float: right"
                                      mode="grey"
                                      :link="false"
                                      @click.native="closeFeatureRequestTab"
                              >Close
                              </base-button>
                            </fieldset>
                        </form>
                      </div>
                    </div>
                    <div class="otherSuggestions" v-else>
                        <div class="otherSuggestionsHead">
                            <div><h2 id="tabTitle">Vote for our next new feature</h2></div>
                        </div>
                        <h3 v-if="successfulVote" style="padding-left: 20px">
                            <label style="color: lightgreen">Your vote has been added. You can edit your vote at any
                                time.</label>
                        </h3>
                        <div class="suggestionTable">
                            <div class="suggestionTableHead">
                                <div><h3>Vote</h3></div>
                                <div><h3>Title</h3></div>
                                <div><h3>Details</h3></div>
                            </div>
                            <div class="suggestionTableData">
                                <div v-for="feature in featuresList" :key="feature.id" class="suggestionTableDataRow2">
                                    <div class="suggestionTableDataRow">
                                        <div>
                                            <div>
                                                <font-awesome-icon size="2x"
                                                @click="() => voteFeature(feature)"
                                                :icon="['fas', voteSettings[feature.voted].position]"
                                                class="voteIcon"
                                                :style="{ color: voteSettings[feature.voted].color, paddingRight: '10px', paddingLeft: '10px', cursor: 'pointer', fontSize: '16px'}"/>
                                            </div>
                                        </div>
                                        <div class="suggestionRowHeading">
                                            {{ feature.subject }}
                                        </div>
                                        <div class="detailsExpandSuggestionRow">
                                            <font-awesome-icon @click="feature.expanded =! feature.expanded" size="1x"
                                                               v-if="feature.expanded" :icon="['fas', 'minus-square']"
                                                               :style="{ color: 'orange' }"/>
                                            <font-awesome-icon @click="feature.expanded =! feature.expanded" v-else
                                                               size="1x"
                                                               :icon="['fas', 'plus-square']"
                                                               :style="{ color: 'orange' }"/>
                                        </div>
                                    </div>
                                    <div class="suggestionTableDataRowExpanded" v-if="feature.expanded">
                                        <div class="suggestionExpandedRowInsert">
                                            <div class="description">
                                                <span>What is it?</span>
                                                <br/>
                                                <p v-html="feature.description" style="margin-bottom: 2px; color: white; font-weight: normal">
                                                </p>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import Collapsible from "../../components/ui/forms/Collapsible";
import Constants from "@/utility/constants"
import FeatureRequestService from "@/services/featurerequest/feature.request.service"
import VueFormGenerator from "vue-form-generator";
import _ from "lodash";
import {createNamespacedHelpers} from "vuex";
const general = createNamespacedHelpers("general")
const VOTES = {
    UP: 1,
    DOWN: 2,
    NONE: 0
}

export default {
    name: "FeatureRequestList",
    components: {Collapsible},
    data: function () {
        return {
            HOW_IT_WORKS_TIP: Constants["HOW_IT_WORKS_TIP"],
            featuresList: [],
            successfulVote: false,
            openRequestForm: false,
            VOTES: VOTES,
            featureModel: {
                request: null,
                goal: null,
                workaround: null
            },
            voteSettings: {
                0: {color: 'grey', position: 'thumbs-up'},
                1: {color: 'green', position: 'thumbs-up'},
                2: {color: 'red', position: 'thumbs-down'}
            },
            schema: {
                fields: [
                    {
                        type: 'textArea',
                        label: "What is your problem or request?",
                        model: "request",
                        required: true,
                        validator: VueFormGenerator.validators.required
                    },
                    {
                        type: 'textArea',
                        label: "What are you trying to achieve?",
                        model: "goal",
                        required: true,
                        validator: VueFormGenerator.validators.required
                    },
                    {
                        type: 'textArea',
                        label: "What is your current work around?",
                        model: "workaround",
                        required: true,
                        validator: VueFormGenerator.validators.required
                    },
                ]
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            },
        }
    },
    computed: {},
    watch: {
        ...general.mapGetters(["user"]),
        openRequestForm: function () {
            this.clearFeatureRequestModel()
        }
    },
    methods: {
        clearSuccessMessage: function () {
            setTimeout(() => this.successfulVote = false, 3000)
        },
        clearFeatureRequestModel: function () {
            this.featureModel = {
                request: null,
                goal: null,
                workaround: null
            }
        },
        loadFeaturesList: function () {
            FeatureRequestService.getAllFeatureRequest().then(response => {
                if(response?.data?.data) {
                    this.featuresList = [];
                    let features  = _.orderBy((response?.data?.data?.features || []), "id");
                    let currentVotes  = _.groupBy((response?.data?.data?.user_votes?.filter(vote => vote.user_id === window.loggedIdUserId) || []), vote => vote.feature_id);
                    console.log(currentVotes);
                    features.forEach((feature) => {
                        let userVote = currentVotes[feature.id]?.[0]?.up_or_down;
                        let voteStatus = userVote === "UP" ? VOTES.UP : (userVote === "DOWN" ? VOTES.DOWN: VOTES.NONE);
                        this.featuresList.push({
                            ...feature,
                            voted: voteStatus,
                            expanded: false
                        });
                    })
                }
            })
        },
        voteFeature: function (feature) {
            this.successfulVote = false;
            let id = feature.id;
            let voteType = VOTES.UP;
            // if (voteType === VOTES.UP) {
            //     this.featuresList.forEach(feature => feature.voted = VOTES.DOWN);
            // }
            this.featuresList.filter(feature => feature.id === id).forEach(feature => feature.voted = voteType);
            this.submitFeatureVote(feature);
        },
        submitFeatureVote: function (feature) {
            FeatureRequestService.submitFeatureVote(feature).then(response => {
                this.successfulVote = response;
                this.clearSuccessMessage();
                this.loadFeaturesList();
            });
        },
        validate: function () {
            return this.$refs.form.validate();
        },
        submitNewFeatureRequest: function () {
            if(this.validate()) {
                FeatureRequestService.submitFeatureRequest(this.featureModel)
                    .then(() => {
                        this.showInfoMessage("Thank You!", "Your feature suggestion has been submitted. We'll use that to decide which idea to build next", this.closeFeatureRequestTab);
                    });
            }

        },
        closeFeatureRequestTab: function () {
            this.openRequestForm = false;
        }
    },
    created: function () {
        this.loadFeaturesList();
    }
}
</script>

<style scoped lang="scss">
#features {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#features td, #features th {
  border: 1px solid #ddd;
  padding: 8px;
}

#features tr:nth-child(even) {
  background-color: #f2f2f2;
}

#features th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

.voteIcon:hover {
  color: green !important;
}



#feature-request {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

a:link {
  text-decoration: none;
  color: #fefefe;
}

a:visited {
  text-decoration: none;
  color: #fefefe;
}

a:hover {
  text-decoration: none;
  color: #fefefe;
}

a:active {
  text-decoration: none;
  color: #fefefe;
}

p {
  font-size: 12px;

}

label {
  padding-top: 10px;
}


/* Feeback navigation */

#feedbackNav {
  max-width: auto;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 3fr 1.2fr 1.2fr 1.2fr 3fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;

  color: #fefefe;
  text-align: center;
  font-size: 14px;
}


/* non active button   */
.dashboadFeedbackButton.non-active, .comingUpFeedbackButton.non-active, .releasesFeedbackButton.non-active {
  min-width: 120px;
  background: rgb(27, 24, 24);
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fefefe;
  border: 0 solid #fefefe;
  cursor: pointer;
}

/* active button   */
.dashboadFeedbackButton.active, .comingUpFeedbackButton.active, .releasesFeedbackButton.active {
  min-width: 120px;
  margin-top: 10px;
  background: #253138;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: #d5ad35;
  border: 1px solid #fefefe;
  cursor: pointer;

}

.dashboadFeedbackButton a {

  padding-top: 20px;
  color: #d5ad35;
}


.dashboadFeedbackButton:hover {
  background: cornflowerblue;
  color: #fefefe;
}


.dashboadFeedbackButton:active {
  background-color: #253138;

}

/* Coming up button   */
.comingUpFeedbackButton:hover {
  background: cornflowerblue;
}

.comingUpFeedbackButton:active {
  background-color: #253138;
}


/* New releases button   */
.releasesFeedbackButton:hover {
  background: cornflowerblue;
}

.releasesFeedbackButton:active {
  background-color: #253138;
}


/* Main content                    */

.modal-form-body {
  height: calc(74vh + 10px);
  max-height: 560px;
}

.rightSide {
  overflow-y: hidden;
}

.rightSide .modal-form-body {
  height: calc(65vh + 10px);
  max-height: 345px;
  overflow-y: auto;

  .filled-form {
    background-color: unset;
    padding: 0;
  }

  
}

.request-form {
    height: inherit;
    max-height: inherit;
  }

fieldset {
  margin: 15px 15px 15px 15px;
  border: none;
  background-color: rgba($color-03, 0.6);

    //Poppins
    padding: 10px 20px;

    //Roboto
    // padding: 20px 25px;
}

.dashboardMain {

  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 3fr 8fr;
  grid-template-rows: auto;
  grid-gap: 30px;
  color: $color-20;
  // font-weight: bold;
  font-size: 12px;
  // height: 550px;
}

/* Left Column */
.leftSide {
  // max-width: 100%;
  // min-width: 170px;
  // margin-top: 20px;
  // margin-bottom: 0px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr;
  align-content: baseline;
  grid-gap: 5px;
  color: #fefefe;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  // height: 100%;

}

.rightSide,
.leftSide {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  padding: 20px 25px;
  background-color: rgba($color-03, 0.6);
  height: calc(48vh + 50px);
  max-height: 460px;
}


/* Submit a request */
.action-section {
  
  margin-bottom: 20px;
  max-height: 100px;
}

.action-section-head h2 {
  font-size: 14px;
  font-weight: 100;
}

/* suggestion button leads to suggestion form  */


.makeSuggestionBtn:hover {
  background: #f3ce60;

}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  padding-top: 40px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(24, 32, 37); /* Fallback color */
  background-color: rgba(24, 32, 37, 0.9); /* Black w/ opacity */
  overflow: auto;
}

/* Modal Content */
.modal-content {
  background-color: #253138;
  margin: auto;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  width: 60%;
}

/* The Close Button */
.close {
  color: rgba(213, 173, 53, 0.2);
  float: right;
  font-size: 40px;
  font-weight: bold;
  padding-right: 70px;
  padding-bottom: 30px;
}

.close:hover,
.close:focus {
  color: #d5ad35;
  text-decoration: none;
  cursor: pointer;
}

.feedbackFormSection, p {

  color: #98a6ad;
}


.submitBtnSection {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-gap: 5px;
  grid-template-rows: 1fr;
  padding-top: 15px;
}

#submitBtn {
  width: auto;
  padding: 1rem 2rem;
  background-color: #d5ad35;
  border: none;
  color: #fefefe;
  font-size: 1.5rem;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;

}


textarea {
  height: 9rem;
  width: 100%;
  background-color: #2f3f48;
  border: 2px solid;
  font-size: 14px;
  resize: none;
  text-align: left;

}

.modalHeadings {
  padding-top: 10px;
  font-size: 17px;
  color: #fefefe;
}

.modalSubHeadings {
  font-size: 12px;
  color: #98a6ad;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modalPara {
  color: #98a6ad;
}


/*Learn more button with dropdown section  */


/* The Close Button */
.closeThis {
  color: rgba(213, 173, 53, 0.2);
  float: right;
  font-size: 40px;
  font-weight: bold;
  padding-right: 70px;
  padding-bottom: 30px;
}

.closeThis:hover,
.closeThis:focus {
  color: #d5ad35;
  text-decoration: none;
  cursor: pointer;
}


/* Latest Updates */
.whatsNewSection {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  padding-left: 20px;
  padding-top: 5px;
  height: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

p .whatsNewSection {

  color: rgb(64, 216, 102);
}

.whatsNewHead {

  padding-left: 0px;
  height: auto

}


/* Right Column */

.rightSide {
  // max-width: 100%;
  // margin-top: 20px;
  // margin-bottom: 0px;
  margin-right: 20px;
  color: #fefefe;
  font-size: 13px;
  // height: auto;
}

/* Sugested by others section */
.otherSuggestions {
  // padding-bottom: 10px;
  height: inherit;
  max-height: inherit;
}

.feedbackVotedForCounter {
  color: rgb(88, 170, 20);
}

.feedbackVotedFor {

  text-align: right;
  padding-right: 3px;
}

.feedbackVotedForFeatureSuggestions {

  padding-left: 3px;
}

/*Filter button shows suggestions that have not been voted on by user yet   */
.suggestionsVotedForeFilterBtn {

  background: #d5ad35;
  color: #fefefe;
  margin-top: 10px;
  padding: 5px;
  text-align: right;
  border: none;

}


.suggestionsVotedForeFilterBtn:hover {

  background: rgb(88, 170, 20);

}

.suggestionTable {
  min-height: 30px;
  height: inherit;
  max-height: inherit;

}

.suggestionTableHead {
  background: rgb(32, 30, 30);
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;

}

.suggestionTableHead h3 {
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
}

.suggestionTableHead div:nth-child(2) h3 {
  padding-left: 0;
}

.suggestionTableData {
  &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $color-02;

    }

    &::-webkit-scrollbar-thumb {
      background: $color-03;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
}

.suggestionTableData, .suggestionTableData2 {
  // background: #253138;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: repeat;
  // grid-gap: 5px;
  // padding-left: 10px;
  // margin-left: 5px;
  // padding-right: 10px;
  // margin-right: 5px;
  // margin-bottom: 5px;
  // padding-top: 10px;
  height: calc(40vh + 30px);
  max-height: 320px;
  overflow-y: auto;
}

.suggestionTableData3, .suggestionTableData4 {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat;
  grid-gap: 5px;
  padding-left: 10px;
  margin-left: 5px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  min-height: 20px;

}

.hiddenData3 {
  display: none;
  height: auto;
  min-height: 20px;
}

.hiddenData4 {
  display: none;
  height: auto;
  min-height: 20px;
}

.suggestionTableDataRow {
  background: $color-02;
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  grid-template-rows: 1fr auto;
  max-height: 40px;
  padding-top: 10px;
  font-size: 14px;
}

.suggestionTableDataRow2 {
  display: grid;
  // grid-template-columns: 13.3fr;
  grid-template-rows: 1fr auto;
  background: $color-02;
  border-bottom: 1px solid $color-01;
  min-height: 35px;
}

.suggestionTableDataRowExpanded {
  background: $color-02;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 5px;
  text-align: center;
  padding: 20px;
}

.suggestionExpandedRowInsert {
  background: #253138;
  display: grid;
  grid-template-columns: 10fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  text-align: center;
  padding: 20px;
}

.hidden-content {
  display: none;
}


.detailsExpandSuggestionRow {
  cursor: pointer;
}

.suggestionAchive {
  text-align: left;
  padding-bottom: 20px;
}

.suggestionWorkAround {
  text-align: left;
}

.voteStatusColumn {
  padding-left: 5px;
}

.hiddenVoted {
  display: block;
}

.expandedRowHeading {
  font-weight: bold;
  padding-bottom: 5px;
  color: #d5ad35;
  font-size: 14px;
}


.voteSuggestion {

  background: darkgrey;
  margin-left: 5px;
  margin-top: 5px;

}

.voteSuggestion:hover {
  background: rgb(75, 74, 74);
  margin-left: 5px;
  margin-top: 5px;

}

.voteSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  text-align: center;
  padding-left: 15px;


}

.voteSectionText {
  text-align: center;
  padding-top: 10px;
  font-size: 15px;
}

.voteUpBtn {
  width: auto;
  padding: 10px;
  text-align: center;
  background-color: rgb(23, 148, 23);
  color: #fefefe;
  border: none;
  border-radius: 25px;
  max-width: 70px;
  font-weight: bold;
  cursor: pointer;
}

i {
  pointer-events: none;
}


i {
  pointer-events: none;
}

.description {
  font-size: 15px;
  text-align: left;
  color: #fefefe;
  width: auto;

}

.description, p {
  font-size: 13px;
  text-align: left;
  color: #fefefe;
  width: auto;

}


.description, b {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #d5ad35;
  width: auto;

}

.description, h6 {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #d5ad35;
  width: auto;
}


@media screen and (max-width: 1200px) {
  /* for screens that are less than or equal to 1200 pixels wide */
  /* suggestion button leads to suggestion form  */
  .makeSuggestionBtn {
    background: #d5ad35;
    color: #fefefe;
    padding: 10px;
    border: none;
    max-width: 150px;
    font-size: 12px;
  }


  /*Learn more button with dropdown section  */
  .howItWorksBtn {
    background: #d5ad35;
    color: #fefefe;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    border: none;
    max-width: 150px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1000px) {
  /* for screens that are less than or equal to 1000 pixels wide */
  .fullscreen {
    width: 100%;
    grid-row: 3;
    grid-column: 1/13;
  }


  .dashboardMain {

    max-width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 3px;
    color: #fefefe;
    font-weight: bold;
    font-size: 12px;
    height: auto;

  }


  /* Left Column */
  .leftSide {
    max-width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-right: 0px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-gap: 5px;
    color: #fefefe;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    height: 60px;

  }

  .suggestionTableHead {
    background: rgb(32, 30, 30);
    display: grid;
    grid-template-columns: 2.1fr 6fr 2fr 1.2fr;
    grid-template-rows: 1fr;

    padding-left: 5px;
    margin-left: 15px;;
    margin-right: 15px;
    padding-right: 10px;
    height: 35px;
    padding-top: 5px;
    font-size: 11px;

  }


  .suggestionTableDataRow {
    background: #182025;
    display: grid;
    grid-template-columns: 2.1fr 6fr 2fr 1.2fr;
    grid-template-rows: 1fr auto;
    max-height: 35px;
    padding-top: 10px;
    font-size: 11px;
  }

  .suggestionTableDataRow2 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    grid-gap: 5px;
    background: #182025;
  }
}


@media screen and (max-width: 400px) {
  /* for screens that are less than or equal to 400 pixels wide */
  .fullscreen {
    width: 100%;
    grid-row: 3;
    grid-column: 1/13;
  }

  #feedbackNav {
    max-width: 100%;
    height: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: 1% 30% 30% 31% 1%;
    grid-template-rows: 1fr;
    grid-gap: 5px;

    color: #fefefe;
    text-align: center;
    font-size: 10px;
  }

  /* non active button   */
  .dashboadFeedbackButton.non-active, .comingUpFeedbackButton.non-active, .releasesFeedbackButton.non-active {
    min-width: 70px;
    background: rgb(27, 24, 24);
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 1px;
    padding-right: 1px;
    color: #fefefe;
    cursor: pointer;
  }

  /* active buttons   */
  .dashboadFeedbackButton.active, .comingUpFeedbackButton.active, .releasesFeedbackButton.active {
    min-width: 70px;
    margin-top: 10px;
    background: #253138;
    padding-top: 10px;
    padding-left: 1px;
    padding-right: 1px;
    color: #d5ad35;
    border: 1px solid #fefefe;
    cursor: pointer;

  }


  /*submit request btn */
  

  /* How it works */
  


  .suggestionTableDataRow {
    background: #182025;
    display: grid;
    grid-template-columns: 1.8fr 6fr 2.2fr 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 5px;
    min-height: 40px;
    padding-top: 10px;
    padding-right: 5px;


  }

  .suggestionTableData, .suggestionTableData2 {
    background: #253138;
    display: grid;
    grid-template-columns: 1fr;
    /*grid-template-rows: repeat;*/
    grid-gap: 5px;
    padding-left: 3px;
    margin-left: 2px;
    padding-right: 3px;
    padding-bottom: 5px;
    margin-right: 2px;
    margin-bottom: 5px;
    padding-top: 10px;
  }


  .suggestionTableHead {
    background: rgb(32, 30, 30);
    display: grid;
    grid-template-columns: 1.8fr 7.5fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 2px;
    padding-left: 3px;
    margin-left: 5px;
    padding-right: 3px;
    padding-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;

  }


  .suggestionRowStatusText.voting, .suggestionRowStatusText.planned, .suggestionRowStatusText.development, .suggestionRowStatusText.live {
    text-align: center;

  }


  .fa-plus-square::before {
    font-size: 18px;


  }

  .fa-minus-square::before {
    font-size: 18px;


  }

  .fa-square::before {
    font-size: 16px;
    text-align: center;
    padding-right: 0px;
  }

  .fa-square {
    font-size: 16px;
    padding-right: 0px;
    text-align: center;
    padding-left: 10px;
  }

  i.fa {
    padding-right: 0px;
  }

  p {
    font-size: 10px;
  }

  b {
    font-size: 12px;
  }

  h6 {
    font-size: 12px;

  }

  .voteSectionText {
    font-size: 12px;
  }

  .otherSuggestionsHead {
    display: grid;
    grid-template-columns: 7fr 0fr 0fr 0fr 0fr;
    grid-template-rows: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    height: 30px;
  }

  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9; /* Sit on top */
    padding-top: 0px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    overflow: auto;
  }

  /* Modal Content */
  .modal-content {
    background-color: #253138;
    margin: auto;
    padding-left: 20px;
    padding-bottom: 120px;
    padding-right: 20px;
    border: 1px solid #888;
    width: 100%;
    height: 100%;
  }


  /* The Close Button */
  .close {
    color: #d5ad35;
    float: right;
    font-size: 20px;
    font-weight: bold;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  /* The Close Button */
  .closeThis {
    color: #d5ad35;
    float: right;
    font-size: 20px;
    font-weight: bold;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .priorityHeading {
    padding-left: 5px;
    padding-right: 5px;
    height: 30px;

  }

  #priorityData {
    padding-left: 5px;
    padding-right: 5px;
  }


}

#tabTitle{
  color: $color-20;
}


</style>

<style lang="scss">
  .action-section-button {
    background: $color-15;
    color: $color-01;
    padding: 10px;
    border: none;
    width: 100%;
    cursor: pointer;

}
</style>
