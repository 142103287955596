

export default {
    addBulkBoards(state, payload) {
        state.boards = payload;
    },
    addBoard(state, payload) {
        state.boards.push(payload);
    },
    deleteBoard(state, payload) {
        const index = state.boards.findIndex(board => board === payload);
        state.boards.splice(index, 1);
    },
    haveBoards(state) {
        state.haveBoards = true;
    },


    addBulkChannels(state, payload) {
        state.channels = payload;
    },
    addChannel(state, payload) {
        state.channels.push(payload);
    },
    deleteChannel(state, payload) {
        const index = state.channels.findIndex(channel => channel === payload);
        state.channels.splice(index, 1);
    },
    haveChannels(state) {
        state.haveChannels = true;
    },


    addBulkTweets(state, payload) {
        state.tweets = payload;
    },
    addTweet(state, payload) {
        state.tweets.push(payload);
    },
    deleteTweet(state, payload) {
        const index = state.tweets.findIndex(tweet => tweet === payload);
        state.tweets.splice(index, 1);
    },

    lastTweetUpdate(state, payload) {
        state.lastTweetUpdate = payload;
    },

    lastBoardUpdate(state, payload) {
        state.lastBoardUpdate = payload;
    },

    lastChannelUpdate(state, payload) {
        state.lastChannelUpdate = payload;
    },

    lastHandleUpdate(state, payload) {
        state.lastHandleUpdate = payload;
    },

    lastChannelHandleUpdate(state, payload) {
        state.lastChannelHandleUpdate = payload;
    },

    addupperMostParent(state, payload) {
        const index = state.upperMostParents.findIndex(element => element === payload);

        if(index < 0) {
            state.upperMostParents.push(payload);
        }
    },

    addFetchedBoard(state, payload) {
        const index = state.tweetsFetchedForBoards.indexOf(payload);

        if(index < 0)
            state.tweetsFetchedForBoards.push(payload);
    },
    clearFetchBoards(state) {
        state.tweetsFetchedForBoards.length = 0;
    }
}