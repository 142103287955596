export default {
    addBulkRecords(_, payload) {
        const processingObject = {
            objStore: `ecocalendar`, 
            data: payload
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(() => {});
        
    },

    lastEcoCalendarUpdate(context, payload) {
        context.commit("lastEcoCalendarUpdate", payload);
    },

    lastEcoCalendarDelete(context, payload) {
        context.commit("lastEcoCalendarDelete", payload);
    },

    setFetchDay(context, payload) {
        context.commit("setFetchDay", payload);
    }
}