import httpClient from "../../api/HttpClient";
import {createUrl} from "../../utility/helper-functions";

export default {
    setAnnouncementAsReadById: function (id) {
        return httpClient.patch(createUrl('/user/announcements/' +id + '/read'), {});
    },
    listAllAnnouncements: function () {
        return httpClient.get(createUrl("/user/announcements"), {generalError: true});
        // return Promise.resolve([
        //     {id: 0, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: false, image: '', date: '2021-07-03'},
        //     {id: 1, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: false, image: '', date: '2021-01-02'},
        //     {id: 2, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: true, image: '', date: '2021-02-01'},
        //     {id: 3, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: true, image: '', date: '2021-06-01'},
        //     {id: 4, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: true, image: '', date: '2021-01-01'},
        //     {id: 5, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: true, image: '', date: '2021-01-01'},
        //     {id: 6, title: 'Heyo', description: 'JAWS 15 announces that a long description is available and can be opened with Alt+Enter. Doing so opens the long description page in a new tab, reads the HTML title, then begins reading the page. JAWS does not read the aria-describedby text.\n' +
        //             'NVDA 2014.2 announces "graphic has long description", then reads the alt text. Users must know how to access the long description (NVDA + D). After executing the hot key, the long description opens in a new tab or window, depending on how the browser is configured (NOTE: IE blocks this under the default security settings, so you may have to grant permissions for this site then try again).\n' +
        //             'ChromeVox 1.31.4 announces "Image with long description", then reads the alt text. Like with NVDA, users must know how to access the long description, and I was unable to get it to work. It\'s probably broken, and I see that a ChromeVox bug has already been filed.\n' +
        //             'VoiceOver in Safari on MacOS X 10.9.3 (Maverick) does not announce availability of any of the long descriptions.', read: true, image: '', date: '2021-01-01'},
        // ])
    }
}
