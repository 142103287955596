<template>
    <div class="home-screen">
        <fullpage-section
        class="dashboard-row"
        name="dashboards"
        title="Open or create dashboard"
        :forceShowHeader="true"
        :moreLink="true"
        :slideDown="true"
        :scrollable="false"
        :elements="dashboardsForDesktopDisplay"
        ></fullpage-section>
        
        <fullpage-section
        v-if="renderedProducts.length"
        name="products"
        title="Products you might like"
        :moreLink="false"
        :slideDown="false"
        :scrollable="true"
        :elements="renderedProducts"
        ></fullpage-section>

        <fullpage-section
        name="widgets"
        :title="widgetsTitle"
        :moreLink="false"
        :slideDown="false"
        :scrollable="false"
        :elements="widgetsToShow"
        :showcaseMode="specialLink === '/showcaselogin'"
        ></fullpage-section>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../main';
import FullpageSection from './ui/FullpageSection.vue';
import _ from 'lodash';
import WidgetsByPreferredOrder from '../mixins/widgetsByPreferredOrder';
import TrackingService from '../services/tracking/tracking.service';
import LivestreamService from '../services/livestream/livestream.service';
import { addDays, usersTime } from '../utility/datetime';
import Constants from '../utility/constants';
import { getServerEnv } from '../utility/helper-functions';

const dashboards = createNamespacedHelpers('dashboard');
const payments = createNamespacedHelpers('payments');
const general = createNamespacedHelpers('general');
const livestreamNS = createNamespacedHelpers("webinars");

export default {
    props:["specialLink"],
    components: {
        FullpageSection
    }, 
    mixins: [WidgetsByPreferredOrder],  
    created() {

        eventBus.$on("remove-promo", obj => {
            if(obj.instanceID === this.$root.instanceID)
                this.removePromo(obj);
        });
        if(this.dbReady)
            this.handlePromoSection();
        console.log(this.specialLink);
        
    }, 
    mounted() {
        TrackingService.sendEvent("visit_home_page", {});
    },
    computed: {
        ...dashboards.mapGetters(['defaultDashboards', 'dashboards', 'layouts', 'customDashboards', 'systemDashboards', 'getDashboardById', 'isThereLayoutByInstanceID']),
        ...payments.mapGetters(['promotionalProducts', 'videoWidgetsForShop', 'mergedPlans', 'currentAddons']),
        ...general.mapGetters(['dbReady','lastTrainingCentreOrderUpdated', 'entityUpdateTimeout']),
        ...livestreamNS.mapGetters(['lastLiveStreamUpdate', 'lastLiveStreamDelete']),


        isThereLayout() {
            return this.isThereLayoutByInstanceID(this.$root.instanceID);
        },
        groupedDashboards: function () {
            return {
                defaultDashboards: this.defaultDashboards,
                customDashboards: this.customDashboards,
                systemDashboards: this.systemDashboards.filter(el => !el.mobile && !el.unique_id && !el.vidpage),
                showcaseDashboards: this.systemDashboards.filter(el => !el.mobile && el.unique_id && !el.vidpage),
                mobileDashboards: _.orderBy(this.systemDashboards.filter(el => el.mobile), dashboard => dashboard.orderBy, ["desc"]),
                vidpageDashboards: this.systemDashboards.filter(el => el.vidpage)

            }
        },
        dashboardsForDesktopDisplay() {
            let dashboards = [
                ...this.groupedDashboards.showcaseDashboards,
                ...this.groupedDashboards.defaultDashboards,
                ...this.groupedDashboards.systemDashboards,
            ];

            return dashboards;
        },
        vidpageDashboards() {
            return this.systemDashboards.filter(el => el.vidpage);
        },
        widgetsToShow() {

            if(this.specialLink === '/showcaselogin')
                return this.widgetsByPreferredOrder.filter(el => el.planDetails?.planWidgetDetails?.showcase);
            
            return this.widgetsByPreferredOrder;
        },
        widgetsTitle() {
            const items = this.specialLink === '/showcaselogin' ? 'Showcases' : 'Indicators';
            return  items + ' (' + this.widgetsToShow.length + ')'
        }
    },
    data() {
        return {
            renderedProducts: [],
        }
    },
    methods: {
        ...dashboards.mapActions(['updateDashboardById']),
        removePromo() {
            this.handlePromoSection();
        },
        handlePromoSection() {
            let renderedProducts = _.cloneDeep(this.promotionalProducts);

            const foundPlans = renderedProducts.filter(el => this.mergedPlans.find(e => e.id === el.id)).map(el => el.id);
            if(foundPlans.length) {
                foundPlans.forEach(el => {
                    const index = renderedProducts.findIndex(e => el === e.id);
                    if(index > -1)
                        renderedProducts.splice(index, 1, _.cloneDeep(this.mergedPlans.find(e => e.id === el)));
                });
            }

            //MTA
            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC");
            const env = getServerEnv();
            let mtas = this.currentAddons.filter(el => el.product.parent_id === Constants.MTA_PROMO[env]);
            
            if(mtas.length) {
                mtas = _.orderBy(mtas, el => el.ended_at, ['desc']);
                const end = mtas[0]?.ended_at?.replace('T', ' ').replace('.000000Z', '');
                const hideUntil = addDays(-7,end) + ' 00:00:00';
                // console.log(end, hideUntil, now)
                if(now < hideUntil) {
                    const index = renderedProducts.findIndex(el => el.id === Constants.MTA_PROMO[env]);
                    if(index > -1) {
                        renderedProducts.splice(index, 1);
                    }
                }

                // console.log("renderedProducts", renderedProducts, this.currentAddons, mta);
            }

            let renderedShowcases = _.cloneDeep(this.vidpageDashboards.filter(el => el.widget.planDetails?.planWidgetDetails.showcase)).map(el => el.widget);
            const Ids = renderedShowcases.map(el => el.implementation_id);
            
            LivestreamService.fetchNextUpcomingByImpIDs(Ids).then(r => {
                if(!_.isEmpty(r)) {
                    renderedShowcases.forEach(el => {
                        if(r[el.implementation_id]) {
                            el.next_upcoming = r[el.implementation_id];
                            el.forceShowcase = true;
                            el.id = el.implementation_id,
                            el.showcase_created_at = el.training_centre_order_at;
                        }
                    });
                }

                renderedShowcases.filter(el => el.code === 'TCVIDEO').forEach(el => {
                    el.id = el.implementation_id;
                    el.forceShowcase = true;
                    el.showcase_created_at = el.planDetails?.planWidgetDetails.showcase_created_at.replace('T', ' ').replace('.000000Z', '');
                });
                
                renderedShowcases = renderedShowcases.filter(el => el.forceShowcase);
                
                let mta = renderedProducts.find(el => el.id === Constants.MTA_PROMO[env])
                renderedProducts = renderedProducts.filter(el => el.id !== Constants.MTA_PROMO[env]);
                if(mta)
                    renderedProducts.unshift(mta);

                renderedShowcases = _.orderBy(renderedShowcases, el => el.showcase_created_at, "asc");


                this.renderedProducts = _.cloneDeep([...renderedShowcases, ...renderedProducts]);

            });

        }   
    },
    watch: {
        promotionalProducts: {
            deep: true,
            handler: function (val) {
                this.renderedProducts = _.cloneDeep(val);
            }
        },
        dbReady(val) {
            if(val) {
                this.handlePromoSection();
            }
                
        },
        videoWidgetsForShop: {
            deep: true,
            handler: function (nVal, oVal) {
                if(nVal &&
                oVal &&
                !_.isEqual(nVal, oVal)) {
                    this.handlePromoSection();
                }
            }
        },
        lastTrainingCentreOrderUpdated: {
            deep: true,
            handler: function(nVal,oVal) {
                if(!_.isEqual(nVal,oVal)) {
                    // console.log("on home", nVal,oVal, this.vidpageDashboards);
                    const layouts = _.cloneDeep(this.layouts);
                    const changed = layouts["vidpage_" + nVal.widget_implementation_id];

                    if(changed) {
                        changed.widget.training_centre_order_at = nVal.training_centre_order_at.replace("T",' ').substring(0,19);
                        
                        // console.log(changed);
                        this.updateDashboardById(changed);
                        this.handlePromoSection();
                    }
                }
            }
        },
        lastLiveStreamUpdate() {
            this.handlePromoSection();
        },
        lastLiveStreamDelete() {
            this.handlePromoSection();
        },
        // entityUpdateTimeout(val) {
        //     if(val)
        //         this.handlePromoSection();
        // }
    },
    
    
}
</script>

<style lang="scss" scoped>
    .home-screen {
        @include standardContainer;

        & .dashboard-row {
            display: none;

            @include respond(lg) {
                display: unset;
            }
        }
        
    }
</style>