
export default {
    getLeaderBoardByKey: function (state) {
        // console.log("leader board", state.leaderboard);
        return (key) => state.leaderboard[key];
    },
    getPairsByKey: function (state) {
        return (key) => state.pairs[key];
    },
    lastCurrencyUpdate: function (state) {
        return state.lastUpdate;
    },
    lastCurrencyPush: function (state) {
        return state.lastPush;
    },
}
