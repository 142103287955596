export default {
    setGroupCols(state, payload) {
        state.groupCols = payload;
    },
    setCountries(state, payload) {
        state.countries = payload;
    },
    setGroupedDataIDs(state, payload) {
        state.groupedDataIDs = payload;
    }
}