<template>
    <base-modal title="Take trial" :footer="false" mode="small">
        <div id="announcement-info" style="margin-top: 5px; ">
            <form class="filled-form" @submit.prevent>
                <vue-form-generator ref="form"
                                    :schema="schema"
                                    :model="model"
                                    :options="formOptions"
                ></vue-form-generator>
                <base-button
                        style="margin-left: 14px;margin-top: 15px; float: right; color: white"
                        mode="blue"
                        :link="false"
                        @click.native="buyTrial"
                >Buy trial
                </base-button>
            </form>
        </div>
    </base-modal>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import _ from "lodash";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";

export default {
    name: "TrialPeriodDetails",
    mixins: [SubscriptionMixin],
    props: {
        product: {
            type: Object
        }
    },

    data() {
        return {
            model: {
                period: '',
                price: ''
            },
            schema: {
                fields: [
                    {
                        type: "select",
                        label: "How many days would you like your trial to last",
                        model: "period",
                        required: true,
                        values: [],
                        styleClasses: "medium-input with-margin",
                        validator: VueFormGenerator.validators.required
                    },
                    {
                        type: "input",
                        inputType: "text",
                        label: "Price $",
                        model: "price",
                        styleClasses: "medium-input",
                        disabled: true
                    }
                ]
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            },
        }
    },
    computed: {
        productTrialIds: function () {
            return this.product.trials.map(trial => {
                return {
                    id: trial.id,
                    name: trial.subscription_length + " " + trial.subscription_length_type
                }
            });
        },
        groupedProductTrials: function () {
            return _.groupBy(this.product.trials, trial => trial.id || 0);
        },

        selectedTrial: function () {
            return this.groupedProductTrials[this.model.period]?.[0];
        }

    },
    watch: {
        selectedTrial: function () {
            this.model.price = this.formatPriceObject(this.selectedTrial.pricing);
        }
    },
    methods: {
        validate: function () {
            return this.$refs.form.validate();
        }
    },
    created() {
        this.schema.fields[0].values = this.productTrialIds;
    }
}
</script>

<style scoped lang="scss">

</style>
