import _ from "lodash";
export default {
    fetchInitialItems(obj) {
        const queryObject = {
            name: "initial-video-fetch",
            objStore: "video_library_videos",
            categories: obj.categories,
            query: "/widgets/videos",
            type: obj.type
        }

        // console.log("q",queryObject);

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            // console.log("these are the responses", response);
            if(obj.type === "course")
                return response;
            
                // console.log(response)
                const data = _.cloneDeep(response);
                const videos = response.videos.slice(0,12)
                data.videos = videos;
                
            return data;
        });
    }, 
    loadMoreItems(categories, offset, limit, isSearchMode, from, to, keywords, type) {
        const queryObject = {
            name: "videos-load-more",
            objStore: "video_library_videos",
            categories: categories,
            offset: offset,
            limit: limit,
            from: from,
            to: to,
            isSearchMode: isSearchMode,
            keywords: keywords,
            type: type
        }

        // console.log("service", queryObject);

        return window.bootUp.iDB.getMoreRecords(queryObject).then(response => {
            response = response.filter(video => !video.deleted_at);
            return response;
        });
    },
    fetchItemsFromServer() {
        const queryObject = {

            name: "videos-inital-fetch-server",
            objStore: "video_library_videos",
            query: "/widgets/videos",

        }

        return window.bootUp.iDB.getRecordsFromServer(queryObject).then(r => r);
    },
    getCatsUpwards(current, top) {
        return window.bootUp.iDB.getCatsUpwards(current, top).then(r => r);
    },
    getSubCategoriesForCourse(top) {
        const obj = {
            objStore: "lookupCategories", 
            prop: "parent_id",
            value: top
        }
        return window.bootUp.iDB.getRecordByProperty(obj).then(r => r);
    },
    getCourseCat(cat) {
     
        const obj = {
            objStore: "lookupCategories", 
            prop: "id",
            value: cat
        }
        return window.bootUp.iDB.getRecordByProperty(obj).then(r => r);
  
    },
    fetchResourcesForVideo(id) {
        const obj = {
            objStore: "video_library_videos",
            prop: "id",
            value: id
        }
        return window.bootUp.iDB.getRecordByProperty(obj).then(r => r);
    }
  
}