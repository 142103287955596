<template>
    <div class="news--item__feedback">
        <div
        class="wait-for-live-stream-note"
        v-if="isLiveStream && !this.currentLiveIDs.length"
        ><span style="display: inline-block; margin-right: 10px;"><font-awesome-icon :icon="['fas', 'hourglass-half']"/></span>Please wait for a live stream to begin to ask questions</div>
        <form @submit.prevent
        v-else
        >
            <base-input
            inputType="text" 
            forID="feedback-question"
            elementType="input"
            v-model="question"
            labelClass="video-question"
            max="200"
            :label="questionText"
            >
            >
            </base-input>
            <div class="video-question-button btn-mobile-center-desktop-left">
                <base-button 
                :mode="'search'"
                @click.native="submitQuestion"
                >Submit Question</base-button>
            </div>
        </form>
    </div>
</template>

<script>
import { talkToServer } from '../../../utility/helper-functions';
import Swal from "sweetalert2";
import { createNamespacedHelpers } from 'vuex';

const livestreamsNS = createNamespacedHelpers('webinars');

export default {
    props: ["forWidget", "vidcomID", "implementation_id", "widgetTitle"],
    computed: {
        ...livestreamsNS.mapGetters(['currentLiveIDs']),

        isLiveStream() {
            return this.forWidget === "live-stream";
        },
        isVidCom() {
            return this.forWidget === "vid-com";
        },
        questionText() {
            if(this.isVidCom)
                return "Type your question here. You'll receive a video reply via email within 48 hours.";
            else
                return "Type your question here. We'll answer it at the end.";
        }
    },
    data() {
        return {
            question: ''
        }
    },
    methods: {
        submitQuestion() {
            if(!this.question.length)
                return;

            let success, slug;
            let query = {};

            // if(this.isLiveStream) {
            //     query.question = this.question;
            //     slug = `/widgets/live-streams/${this.currentLiveIDs[0]}/question`; //incorrect but not used anymore
            //     success = 'Your question was successfully submitted';
            // }

            if(this.isVidCom) {
                query.content_id = this.vidcomID;
                query.question_text = this.question;
                query.module = this.implementation_id;
                query.source = this.widgetTitle;

                slug = `/widgets/questions`;
                success = 'Your question was successfully submitted. Please check your emails in the next 24-48 hours for our response.';
            }

            // console.log(query);
            talkToServer(slug, "POST", null, query, true).then(response => {
                if(response && response.data && (response.data.success || response.success)) {
                    this.question = '';

                    Swal.fire({
                        title: 'Success!',
                        type: 'info',
                        text: success,
                        showCancelButton: false
                    });

                    this.$emit("close-slide-down");
                }else{
                    Swal.fire({
                        title: 'Error!',
                        type: 'warning',
                        text: "Could not submit question. Please try again later.",
                        showCancelButton: false
                    });
                }
            }).catch(() => {
                
            })
            

        }
    }
}
</script>

<style lang="scss" scoped>
    .news--item__feedback {
        grid-column: 1 / -1;

    }

    form {
        padding: 15px;
        // background-color: $color-015;
    }

    .wait-for-live-stream-note {
        // background-color: $color-02;
        text-align: center;
        font-size: 16px;
        padding: 45px 0;
        color: $input-color-filter;
    }
</style>

<style lang="scss">
    .video-question-button button {
        height: 36px;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
        color: $input-color;
        background-color: $checkbox-active;
        outline-style: none;
        border-style: none;
        cursor: pointer;
        text-align: left;
        margin: 5px 15px;
        padding: 0 12px;
        border-radius: 10px;
        text-align: center;
    }
    
</style>