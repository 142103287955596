export default {
    addBulkRecords(_, payload) {
        
        const processingObject = {
            objStore: `headlines`, 
            data: payload
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(() => {});
        
    },

    // haveHeadlines(context, payload) {
    //     context.commit("haveHeadlines", payload);
    // },

    lastHeadlineUpdate(context, payload) {
        context.commit("lastHeadlineUpdate", payload);
    },

    setHeadlineValues(context, payload) {
        // context.dispatch("haveHeadlines", false);
        context.dispatch("availableCategories/haveAvailableCategories", payload, { root: true });
        context.dispatch("lookupCategories/haveLookupCategories", payload, { root: true });
        context.dispatch("lookupProperties/haveLookupProperties", payload, { root: true });

    },
    setRareHeadlinesFetched({commit}, payload) {
        commit("setRareHeadlinesFetched", payload);
    }

}