export default {
    setGroupCols({commit, getters}, payload) {
        const first = getters.originalGroupCols[0];
        let arr = payload; 
        // console.log(first, payload)
        if(payload[0]?.id !== first.id)
            arr = [first, ...payload]

        commit("setGroupCols", arr);
    },
    setCountries({commit}, payload) {
        commit("setCountries", payload);
    },
    setGroupedDataIDs({commit}, payload) {
        commit("setGroupedDataIDs", payload);
    }
}