<template>
    <!-- :style="fullscreenStyle"   -->
    <div
    class="widget-frame"  :class="[widgetSize, widgetLayoutID, { frame: isTerminalUser, videopage: isVideoPage }]" ref="widget" v-resize @resize="onResize">
        <div style="cursor: pointer" @dblclick="toggleFullscreen"
            v-if="isTerminalUser && !isVideoPage"
            id="widget-board-step"
            class="widget-frame-header vue-draggable-handle"
            :class="{'is-mobile': isMobileForDashboards}">
            <span class="widget-title">{{ widgetTitle }}</span>
            <div class="custom-menu-items">
                <div
                v-if="showRating"
                class="rating-button"
                >
                    <star-rating :implementation_id="implementation_id"
                    :showTextTile="isSmWidget"
                    :widgetTitle="widgetTitle"></star-rating>
                </div>
            </div>
            <button v-if="showHandlers && !isMobileForDashboards"
                    @click="toggleFullscreen" class="toggle-fullscreen" style="cursor: pointer">
                <font-awesome-icon :icon="['fas', fullscreenId > 0 ? 'compress-arrows-alt': 'expand-arrows-alt']"/>
            </button>
            <div class="widget-mini-menu-icon">
                <widget-mini-menu
                v-if="showHandlers && !isMobileForDashboards && fullscreenId === 0"
                :implementation_id="implementation_id"
                :layout_id="layout_id"></widget-mini-menu>
            </div>
            <button v-if="isCurrentCardExpiring && showHandlers"
                    @click="openPaymentSettings" style="right: 50px; cursor: pointer">
                <VueCustomTooltip :label="paymentReminder" :multiline="true"
                                  position="is-left">
                    <font-awesome-icon :icon="['fas', 'credit-card']" style="color: red"/>
                </VueCustomTooltip>
            </button>
        </div>
        <div id="tips-step"
        v-if="isThereMenu && !isVideoPage">
            <widget-menu>
                <li v-if="menu.isSearchEnabled">
                    <button @click="openSearch">
                            <span
                                    v-if="!isSearchOpen"
                            >
                                <font-awesome-icon
                                        :icon="['fas', 'search']"/>
                                <!-- <span class="icon-description">&nbsp;Search</span> -->
                            </span>

                        <span
                                class="red"
                                v-else
                        >
                                <font-awesome-icon
                                        :icon="['fas', 'times']"/>
                                <!-- <span class="icon-description">&nbsp;Exit</span> -->
                            </span>

                    </button>
                </li>
                <li v-if="menu.isGlossaryEnabled">
                    <button @click="openGlossary">
                        <font-awesome-icon :icon="['fas', 'book']"/>
                        <!-- <span class="icon-description">&nbsp;Glossary</span> -->
                    </button>
                </li>
                <li 
                class="li-video-question"
                v-if="menu.isVideoQuestionEnabled">
                    <button @click="openVideoQuestion">
                        <font-awesome-icon :icon="['fas', 'comments']"/>
                        <span class="icon-description">&nbsp;Ask Questions</span>
                    </button>
                </li>
                <li 
                class="li-drop-down"
                v-if="menu.isDropdownEnabled">
                        <span class="widget-menu-item dropdown">
                            <font-awesome-icon :icon="['fas', 'table']"/>
                            <select class="widget-dropdown"
                                    v-if="menu.options.boards.length > 1"
                                    @change="switchBoard"
                                    v-model="menu.options.current"
                            >
                                <option
                                        v-for="option in menu.options.boards"
                                        :key="option.id"
                                        :value="option.id"
                                >{{ option.board_name }}</option>
                            </select>
                            <span v-else-if="menu.options.boards.length === 1" style="font-size: 12px"
                                  class="icon-description select-replacement">&nbsp;{{ menu.options.boards[0].board_name
                                }}</span>
                            <span v-else style="font-size: 12px"
                                  class="icon-description select-replacement">&nbsp;No boards</span>
                        </span>

                </li>
                
                <!-- <li v-if="menu.isUpcomingAndArchiveSwitchEnabled && !isSmWidget">
                        <VueCustomTooltip label="Switch between upcoming and archived live streams" position="is-bottom">
                            <span class="widget-menu-item slider two-sided">

                                <switch-slider
                                :checked="details.upcomingActive"
                                inputClass="white-button grey small two-sided"
                                @change="setUpcomingOrArchive"
                                />
                                <span class="icon-description left">&nbsp;Archives</span>
                                <span class="icon-description right">&nbsp;Upcoming</span>

                            </span>
                        </VueCustomTooltip>

                </li> -->
                <li 
                class="li-datepicker"
                v-if="menu.isDatepickerEnabled">
                    <date-picker 
                    class="eco-cal-date-picker"
                    v-model="dateRange" range :shortcuts="dateRangeShortcuts" :format="'D MMM'"></date-picker>
                    <!-- <label @focus="openDatepicker"
					:for="'datepicker-'+layout_id">
						<font-awesome-icon :icon="['fas', 'calendar-alt']"/>
						<span class="icon-description">&nbsp;This Week: 17 Jan - 23 Jan</span>
					</label>
					<input type="hidden" :id="'datepicker-'+layout_id"> -->
                </li>
                <li 
                class="li-filter"
                v-if="menu.isFilterEnabled">
                    <button @click="openFilter">
                        <span
                        >
                            <font-awesome-icon
                                    :icon="['fas', 'filter']"/>
                            <span class="icon-description"></span>
                        </span>
                    </button>
                </li>
                <!-- <li 
                class="li-tutorial"
                v-if="isOnShowcase">
                    <button @click="openTutorial">
                        <span
                        >
                            <font-awesome-icon :icon="['fas', 'play-circle']" />&nbsp;Learn how to trade with this
                        </span>
                    </button>
                </li> -->
                <li 
                class="li-up-next"
                v-if="menu.isNextEventScrollerEnabled">
                    <button @click="scrollToNextEvent">
                        <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                        <span class="icon-description">&nbsp;Up Next</span>
                    </button>
                </li>
                <li 
                class="li-real-time li-align-right"
                v-if="menu.isStreamingEnabled"
                >
                    <div>
                        <span class="text-label">Real-time streaming</span>
                        <font-awesome-icon :icon="['fas', 'circle']"
                        style="margin: 0 3px;"
                        :style="{color: (isRealTime ? '#3B9C83': '#FC5A5A')}"/>
                        <span class="text-label"> {{isRealTime ? 'ON' : 'OFF'}}</span>
                    </div>
                </li>
                <li v-if="menu.isAutoScrollEnabled"
                class="li-autoscroll li-align-right"
                >
                    <VueCustomTooltip
                            :label="`Automatically display the latest ${this.scrollToolTip}, so you don't miss anything.`"
                            position="is-bottom">
                            <span class="widget-menu-item slider">

                                <switch-slider
                                        :checked="details.autoScrollOn"
                                        inputClass="grey-green white-button small"
                                        @change="setAutoScroll"
                                />
                                <span class="icon-description">&nbsp;Auto-scroll</span>

                            </span>
                    </VueCustomTooltip>

                    </li>
            </widget-menu>
            <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down">
                <component
                        :is="slideDownComponent"
                        @search-request="setSearchTerms"
                        @apply-filters="applyFilters"
                        @close-slide-down="closeSlideDown"
                        :forWidget="this.componentToBeRendered"
                        :vidcomID="this.vidComID"
                        :widgetTitle="widgetTitle"
                        :layout_id="this.layout_id"
                        :implementation_id="this.implementation_id"
                ></component>
            </vue-slide-up-down>
        </div>
        <div v-else :style="'height: ' + (isVideoPage ? '0' : ' 48px') + ';'"></div>
        <div class="widget-frame-body no-drag"
        :class="{'is-mobile': isMobileForDashboards}"
        >
            <transition
            name="fade"
            duration="700"
            >
                <component
                :class="{'fade-in-fade-out': animateFade}"
                v-if="show"
                :is="componentToBeRendered"
                :details="details"
                :fullscreen="fullscreenId > 0"
                @set-frame="setFrame"
                @auto-scroll="setAutoScroll"
                @close-search="closeSearch"
                @update-user-settings="updateUserSettings"
                @vidcom-id-change="id => this.vidComID = id"
                ></component>
            </transition>
        </div>
    </div>
</template>

<script>
import {eventBus} from '../../main';
import BreakingHeadlines from '../widgets/BreakingHeadlines';
import BlankWidget from '../widgets/BlankWidget';
import EcoCalendar from '../widgets/EcoCalendar';
import InterestRate from '../widgets/InterestRate';
import LiveStream from '../widgets/LiveStream';
import SocialScanner from '../widgets/SocialScanner';
import EconomicResearch from '../widgets/EconomicResearch.vue';
import StarRating from '../widgets/components/StarRating';
import VidCom from '../widgets/VidCom';
import VideoLegacy from '../widgets/VideoLegacy';
import VideoLibrary from '../widgets/VideoLibrary';
import VideoQuestion from '../widgets/components/VideoQuestion';
import VideoCourseWrapper from '../widgets/VideoCourseWrapper.vue';
import WelcomeWidget from '../widgets/WelcomeWidget';
import WidgetMenu from '../widgets/components/WidgetMenu';
import WidgetSearch from '../widgets/components/WidgetSearch';
import EcocalFilter from '../widgets/components/EcoCalendar/EcocalFilter.vue';
import WidgetMiniMenu from '../widgets/components/WidgetMiniMenu.vue';
import CurrencyStrengthMonitorWidget from '../widgets/components/CurrencyStrengthMonitor/CurrencyStrengthMonitorWidget';
import {createNamespacedHelpers} from 'vuex';
import _ from "lodash";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import PaymentCardModal from "./payments/PaymentCardModal";
import {addDays, addTime, createTimeStamp, dateTimedifference, formatAnnouncementDate, thisWeek, usersTime} from "../../utility/datetime";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {isMobileForDashboards, randomizeBetween} from '../../utility/helper-functions';
import findComponent from '../../mixins/componentToBeRendered';
import WidgetsByPreferredOrder from '../../mixins/widgetsByPreferredOrder';

const dashboards = createNamespacedHelpers('dashboard');
const payments = createNamespacedHelpers('payments');
const currency = createNamespacedHelpers('currency');

const REMINDER_TIMESTAMP = 259200000;

const xl = 1850;
const lg = 1272;
const md = 950;
const sm = 627;
const xs1 = 420;
const xs = 320;
const xxs = 160;


export default {
    props: ["layout_id", "implementation_id", "showHandlers", "panels", "pageType", "active"],
    mixins: [findComponent, WidgetsByPreferredOrder],
    components: {
        DatePicker,
        Loading,
        BlankWidget,
        BreakingHeadlines,
        EcoCalendar,
        EconomicResearch,
        EcocalFilter,
        InterestRate,
        LiveStream,
        SocialScanner,
        StarRating,
        VidCom,
        VideoLibrary,
        VideoLegacy,
        VideoQuestion,
        VideoCourseWrapper,
        WelcomeWidget,
        WidgetMenu,
        WidgetMiniMenu,
        WidgetSearch,
        CurrencyStrengthMonitorWidget
    },
    created() {
        this.getSettings(this.layout_id);
        eventBus.$on("update-settings", id => this.getSettings(id));
        eventBus.$on("apply-filters", obj => {
            if(obj.layout_id === this.layout_id) {
                this.applyFilters(obj);
                eventBus.$emit("close-modal");
            }
        });

        this.details.panels = this.panels;

        
    },
    mounted() {
        // this.$nextTick(() => {
        //     console.log(window.devicePixelRatio, window.outerHeight, window.innerHeight,this.$refs.widget.clientHeight);
        // })
        this.show = true;
    },
    computed: {
        ...dashboards.mapState(['fullscreenId']),
        ...dashboards.mapGetters(['activeDashboardByInstanceID', 'dashboards', 'layouts', 'activeDashboardInfoByInstanceID']),

        ...payments.mapGetters(['availableWidgets', 'availableWidgetsPriceInformation', 'isCurrentCardExpiring']),
        ...currency.mapGetters(['lastCurrencyUpdate']),
        activeDashboard() {
            return this.activeDashboardByInstanceID(this.$root.instanceID, this.pageType);
        },
        activeDashboardInfo() {   
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, this.pageType);
        },
        isVideoPage() {
            return this.pageType === 'videopage';
        },
        // activeDashboard() {
        //     return this.activeDashboardByInstanceID(this.$root.instanceID);
        // },
        // activeDashboardInfo() {
        //     return this.activeDashboardInfoByInstanceID(this.$root.instanceID);
        // },
        widgetTitle() {
            return this.details.userSettings.widgetname;
        },
        upcomingPaymentDate: function () {
            let widgetProducts = this.availableWidgetsPriceInformation[this.implementation_id + ""];
            return widgetProducts?.includedPlan[0]?.next_billing_at;
        },
        upcomingPayment: function () {
            if (this.upcomingPaymentDate) {
                let currentDate = new Date();
                let upcomingPaymentDate = new Date(this.upcomingPaymentDate);
                return upcomingPaymentDate.getTime() - currentDate.getTime() <= REMINDER_TIMESTAMP;
            }
            return false;
        },

        paymentReminder: function () {
            return "Next payment " + formatAnnouncementDate(this.upcomingPaymentDate) + "!";
        },
        componentToBeRendered() {
            return this.findComponentToBeRendered(this.implementation_id);
        },
        widgetSize() {
            // if (this.fullscreenId > 0) {
            //     return '';
            // }
            if (this.width > xl) {
                return 'size-xxs size-xs size-xs1 size-sm size-md size-lg size-xl';
            }
            if (this.width >= lg) {
                return 'size-xxs size-xs size-xs1 size-sm size-md size-lg';
            }
            if (this.width >= md) {
                return 'size-xxs size-xs size-xs1 size-sm size-md';
            }
            if (this.width >= sm) {
                return 'size-xxs size-xs size-xs1 size-sm';
            }
            if(this.width >= xs1) {
                return 'size-xxs size-xs size-xs1';
            }
            if (this.width >= xs) {
                return 'size-xxs size-xs';
            }

            if (this.width >= xxs) {
                return 'size-xxs';
            }

            return '';
        },
        widgetLayoutID() {
            return 'widget-'+this.layout_id;
        },
        isLgWidget() {
            return this.width >= lg;

        },
        isMdWidget() {
            return this.width >= md;

        },
        isSmWidget() {
            return this.width >= sm;
        },
        isXs1Widget() {
            return this.width >= xs1;
        },
        isXsWidget() {
            return this.width >= xs;
        },
        showRating() {
            const displayOptions = !(!this.isSmWidget && this.hideRatingIfNeeded);
            let longAgo = true;

            const past = addTime(-3, "months");

            const ratings = this.$store.getters["widget/ratings"];

            let refDate = ratings?.[this.implementation_id];
            if (refDate) {
                refDate = usersTime(ratings?.[this.implementation_id], true, true, true, {
                    dateFormat: "y-m-d",
                    timeFormat: "24hr"
                });

                if (refDate) {

                    if (past < refDate)
                        longAgo = false;
                }
            }


            return displayOptions && longAgo;
        },
        isSearchOpen() {
            return this.slideDownComponent === "widget-search" && this.isSlideDownOpen;
        },
        // isFilterOpen() {
        //     return this.slideDownComponent === "ecocal-filter" && this.isSlideDownOpen;
        // },
        scrollToolTip() {
            if (this.componentToBeRendered === 'eco-calendar')
                return 'event';
            else if (this.componentToBeRendered === 'social-scanner')
                return 'tweet';

            return '';
        },
        // fullscreenStyle() {
        //     if(this.fullscreenId <= 0)
        //         return '';
            
        //     return 'height: calc(100vh - 62px);'
        // },
        isThereMenu() {
            return Object.keys(this.menu).length;
        },
        isOnShowcase() {
            const found = this.widgetsByPreferredOrder.find(el => el.implementation_id === this.implementation_id);
            if(!found)
                return false;
              
            return found.planDetails.planWidgetDetails?.showcase && found.planDetails.includedDetails.indexOf("Showcase") > -1;
        },
    },
    data() {
        return {
            details: {
                implementation_id: this.implementation_id,
                layoutID: this.layout_id,
                userSettings: this.userSettings,
                isMdWidget: true,
                isSmWidget: true,
                isXs1Widget: true,
                isXsWidget: true,
                width: this.width,
                height: this.height,
                boardID: null,
                autoScrollOn: null,
                searchTerms: null,
                dateRange: '',
                selectedOption: '',
                forceUpdate: null,
                upcomingActive: true,
                pageType: this.pageType,
                widgetCode: this.$store.getters["widget/getCodeByImplementationId"](this.implementation_id)
            },
            isSlideDownOpen: false,
            width: null,
            height: null,
            timeout: null,
            menu: {},
            slideDownComponent: null,
            isTerminalUser: !window.bootUp.nonTerminalUser,
            hideRatingIfNeeded: false,
            testPlaying: false,
            loading: false,
            dateRange: '',
            dateRangeShortcuts: [
                {text: 'Today', onClick: () => [new Date(), new Date()]},
                {
                    text: 'Tomorrow',
                    onClick: () => [new Date(addDays(1)), new Date(addDays(1))]
                },
                {
                    text: 'Yesterday',
                    onClick: () => [new Date(addDays(-1)), new Date(addDays(-1))]
                },
                {
                    text: 'This Week',
                    onClick: () => [new Date(thisWeek(0)), new Date(thisWeek(6))]
                },
                {
                    text: 'Next Week',
                    onClick: () => [new Date(thisWeek(7)), new Date(thisWeek(13))]
                },
                {
                    text: 'Last Week',
                    onClick: () => [new Date(thisWeek(-7)), new Date(thisWeek(-1))]
                },

            ],
            // upcomingActive: true,
            isMobileForDashboards: isMobileForDashboards(),
            interval: '',
            isRealTime: true,
            show: false,
            vidComID: null,
            animateFade: false
        }

    },
    methods: {
        ...dashboards.mapActions(['setFullScreenId', 'updateDashboardById']),
    
        // closeWidget() {
        //     this.$emit('close-widget');
        // },
        toggleFullscreen: function () {
            if(this.isMobileForDashboards)
                return;
                
            // console.log("fsId=" + this.fullscreenId);
            // console.log("layout_id=" + this.layout_id);
            if (this.fullscreenId === this.layout_id) {
                this.animateFade = true;
                setTimeout(() => this.setFullScreenId(0), 400);
                
                setTimeout(() => this.animateFade = false, 1600);
                
            } else {
                this.animateFade = true;
                setTimeout(() => this.setFullScreenId(this.layout_id), 400);
                setTimeout(() => this.animateFade = false, 1600);
                // eventBus.$emit("scroll-into-widget", this.layout_id);
            }
        },
        updateCardDetails: function () {
            this.showModal(PaymentCardModal);
        },
        openPaymentSettings: function () {
            eventBus.$emit('open-modal', {
                title: "Account Settings",
                componentInModal: "account-settings",
                mode: "wide",
                panel: {
                    tab: 'billing'
                }
            });
        },
        openConfig() {
            // eventBus.$emit('open-modal', {
            //     instanceID: this.$root.instanceID,
            //     title: "Widget Config",
            //     header: false,
            //     componentInModal: "widget-shop",
            //     classes: "terminal-modal",
            //     panel: {
            //         view: "widget-config",
            //         id: this.implementation_id,
            //         layout_id: this.layout_id,
            //         showBackButton: true,
            //     },
            //     mode: "wide"
            // });
        },
        openWidgetSetup() {
            const instanceID = this.$root.instanceID;
            const mode = "edit";
            const panel = {
                view: 'widget-config',
                id: this.implementation_id,
                layout_id: this.layout_id,
                mode,
            };

            const modal = {
                instanceID
            };

            // modal.header = false;
            modal.componentInModal = "widget-shop";
            modal.classes = "terminal-modal simplified";
            modal.panel = panel;
            modal.mode = "wide";

            eventBus.$emit('open-modal', modal);

            // console.log(this.item);
            
    
        },
        onResize(e) {
            // if (this.fullscreenId <= 0) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.width = e.detail.width;
                    this.height = e.detail.height;
                    this.details.height = e.detail.height;
                    this.details.width = this.width;
                    this.details.isMdWidget = this.isMdWidget;
                    this.details.isSmWidget = this.isSmWidget;
                    this.details.isXs1Widget = this.isXs1Widget;
                    this.details.isXsWidget = this.isXsWidget;
                    this.isMobileForDashboards = isMobileForDashboards();
                    eventBus.$emit("widget-resize", {
                        id: this.layout_id,
                        height: e.detail.height,
                        width: e.detail.width
                    });
                }, 500);
            // } else {
                // this.width = 1850;
                // this.width = e.detail.width;
                // this.details.width = this.width;
                // eventBus.$emit("widget-resize", {
                //     id: this.layout_id,
                //     height: window.innerHeight - 120
                // });
            // }
            // console.log("resize event", e.detail.width, e.detail.height);


        },
        openSearch() {
            this.slideDownComponent = "widget-search";
            this.isSlideDownOpen = !this.isSlideDownOpen;
            this.details.searchTerms = null;
        },
        closeSearch() {
            this.closeSlideDown();
            this.details.searchTerms = null;
        },
        openFilter() {
            const instanceID = this.$root.instanceID;
            const panel = {
                id: this.implementation_id,
                layout_id: this.layout_id,
            };

            const modal = {
                instanceID
            };

            let componentInModal;
            if(this.details.widgetCode === 'CALENDAR')
                componentInModal = "ecocal-filter";
            else if(this.details.widgetCode === 'ECRESEARCH')
                componentInModal = "ecresearch-filter";

            // modal.header = false;
            modal.componentInModal = componentInModal;
            modal.classes = "terminal-modal simplified auto-height";
            modal.panel = panel;
            modal.mode = "wide";

            eventBus.$emit('open-modal', modal);
        },
        applyFilters(obj) {

            this.details.userSettings = {
                ...this.details.userSettings,
                impact: obj.impact,
                countries: obj.countries
            }

            this.closeSlideDown();

        },
        closeSlideDown() {
            this.isSlideDownOpen = false;

            if (this.slideDownComponent === "widget-search")
                this.details.searchTerms = null;
        },
        openGlossary() {
            eventBus.$emit('open-modal', {
                title: "Glossary",
                componentInModal: "glossary-app",

                mode: "wide"
            });
        },
        openVideoQuestion() {
            this.slideDownComponent = "video-question";
            this.isSlideDownOpen = !this.isSlideDownOpen;
        },
        setFrame(obj) {
            if (obj.menu) {
                this.menu = {
                    ...this.menu,
                    ...obj.menu
                },
                this.hideRatingIfNeeded = false;//Object.keys(obj.menu).length > 1;
                if (obj.menu.dateRange) {
                    this.dateRange = obj.menu.dateRange;
                }
                if(obj.menu.isStreamingEnabled) {
                    this.interval = setInterval(() => {
                        this.evaluateRealTime();
                    }, 5000);
                }
            }

        },
        getSettings(id) {
            if (this.layout_id === id) {

                let thisWidget;
                if (this.isTerminalUser || !this.isTerminalUser) {
                    thisWidget = this.layouts[this.activeDashboard].elements[id];
                    this.details.userSettings = thisWidget.userSettings;
                } else {
                    // console.log("this.implementation_id",this.implementation_id);
                    thisWidget = this.$store.getters['widget/getWidgetByImplementationId'](this.implementation_id);

                    let userSettings = {};

                    // if(thisWidget.settings)
                        for (const setting in thisWidget.settings) {
                            userSettings = {
                                ...userSettings,
                                [setting]: thisWidget.settings[setting].value
                            }
                        }

                    this.details.userSettings = userSettings;

                }

                // console.log(this.details.userSettings);

                this.details.autoScrollOn = this.details.userSettings.autoscroll;

            }

        },
        setAutoScroll() {
            this.details.autoScrollOn = !this.details.autoScrollOn;

            const active = _.cloneDeep(this.activeDashboardInfo);

            active.elements[this.layout_id].userSettings.autoscroll = this.details.autoScrollOn;

            this.updateDashboardById(active);


        },
        scrollToNextEvent() {
            eventBus.$emit("scroll-to-next-event", this.layout_id);
        },
        openDatepicker() {
            console.log("datepicker clicked");
        },
        switchBoard() {
            this.details.boardID = +this.menu.options.current;
            this.details.forceUpdate = randomizeBetween(1000, 9999);
            this.details.selectedOption = this.menu.options.current;

            const active = _.cloneDeep(this.activeDashboardInfo);

            let current = !isNaN(+this.menu.options.current) ? +this.menu.options.current : this.menu.options.current;

            active.elements[this.layout_id].userSettings.defaultboard = current;

            this.updateDashboardById(active);
        },
        setSearchTerms(obj) {
            if (!obj)
                this.openSearch();
            else {
                this.details.searchTerms = obj;
            }

        },
        // setUpcomingOrArchive() {
        //     this.details.upcomingActive = !this.details.upcomingActive;
        // },
        evaluateRealTime() {
            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC");

            let difference = 0;
            let isRealtime = true;

            if(this.lastCurrencyUpdate)
                difference = dateTimedifference(this.lastCurrencyUpdate, now, 'seconds');
            
            if(difference < 61)
                isRealtime = true;
            else
                isRealtime = false;

            this.isRealTime = isRealtime;


        },
        updateUserSettings({key, val}) {
            
            const active = _.cloneDeep(this.activeDashboardInfo);

            const userSettings = active.elements[this.layout_id].userSettings;
            userSettings[key] = val;

            this.updateDashboardById(active);


        },
        openTutorial() {
            const instanceID = this.$root.instanceID;
            const modal = {
                instanceID,
                componentInModal: "tutorial-modal",
                classes: "terminal-modal simplified auto-height",
                mode: "",
                panel: {
                    widget: this.$store.getters['widget/getWidgetByImplementationId'](this.implementation_id)
                }
            };

            eventBus.$emit('open-modal', modal);
        }
    },
    watch: {
        dateRange() {
            const [from, to] = this.dateRange;

            this.details.dateRange = {
                from: createTimeStamp(from),
                to: createTimeStamp(to)
            };
        }
    },
    beforeDestroy() {
        if(this.interval)
            clearInterval(this.interval);
    }
}
</script>

<style scoped lang="scss">
.widget-frame {
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto 1fr;
  border-radius: 3px;
  background-color: $onboard-survey-card-background;

  &.frame {
    grid-template-rows: 48px auto 1fr;
  }

  &.videopage {
      background-color: transparent;
  }

  &.videopage.frame {
      grid-template-rows: 0 auto 1fr;

      & .widget-frame-body  {
          padding: 0;
      }
  }


  &-header {
    background-color: $widget-faded-background;
    // position: relative;
    display: grid;
    grid-template-columns: 120px 1fr 20px 20px;
    align-items: center;
    height: 48px;
    padding: 0 20px;

    .widget-title {
    //   position: absolute;
      top: 14px;
      left: 20px;
      @include H7;
      font-weight: bold;
    
      color: $input-color;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;

    }

    & button {
    //   position: absolute;
      top: 15px;
      font-size: 13px;
      background-color: transparent;
      border: none;
      color: $input-color;
      cursor: pointer;
      text-align: right;
      width: 20px;
    }

    .custom-menu-items {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        height: 22px;
        margin-right: 5px;

        & > div {
            border-right: 1px solid $input-color-filter;
            padding: 0 5px;
        }
    }
  }

  &-body {
    overflow-y: hidden;
    padding: 0 20px 20px 20px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $color-02;

    }

    &::-webkit-scrollbar-thumb {
      background: $color-03;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
  }

}

.select-replacement {
  position: absolute;
  height: 21px;
  width: 75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  //Poppins
  top: -1px;
  left: 36px;
  
  //Roboto
//   top: 2px;
//   left: 43px;
}

.widget-mini-menu-icon {
    display: flex; 
    justify-content: flex-end; 
}

.size-sm .widget-title {
  width: unset;
}

.size-xs .widget-frame-header .widget-title {
  max-width: none;
  
}

.size-xxs .widget-frame-header {
  max-width: none;
  grid-template-columns: 205px 1fr 20px 20px;
}

.size-xs .widget-frame-header {
  max-width: none;
  grid-template-columns: 225px 1fr 20px 20px;
}

.size-sm .widget-frame-header {
  max-width: none;
  grid-template-columns: 260px 1fr 20px 20px;
}

.is-mobile {
    &.widget-frame-header {
        grid-template-columns: 210px 1fr;

        & .rating-button {
            border: none;
        }

        & .widget-mini-menu-icon {
            display: none;
        }

        & .toggle-fullscreen {
            display: none;
        }
    }
}

.size-xs .widget-frame-header.is-mobile {
    grid-template-columns: 240px 1fr;
}

.is-mobile {
    &.widget-frame-body {
        padding-left: 0;
        padding-right: 0;
    }
}

.size-sm .widget-frame-body.is-mobile {
    padding-left: 20px;
    padding-right: 20px;
}

.fullscreen-wrapper {
  width: 100%;
  height: 100%;
  background: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .button {
    margin-bottom: 20px;
  }
}
.widget-dropdown {
  height: 100%;
}

.eco-cal-date-picker.mx-datepicker.mx-datepicker-range {

    width: 220px;
    
    & input {
        
        border-radius: 8px;
    }
}

.fade-in-fade-out {
    animation: renderFadeIn 1.6s;
    animation-timing-function: ease;
}


</style>
