<template>
    <div>
        <div id="profileTab">
            <div>
                <profile></profile>
            </div>
        </div>
        <br/>
        <div id="terminalTab">
            <div>
                <terminal></terminal>
            </div>
        </div>
        <br/>
        <div id="subscriptionTab">
            <div>
                <subscription-details></subscription-details>
            </div>
        </div>
        <br/>
        <div id="billingTab">
            <div>
                <billing-details></billing-details>
            </div>
        </div>
        <br/>
        <div id="invoicesTab">
            <div>
                <invoice-history></invoice-history>
            </div>
        </div>
    </div>
</template>

<script>
import Profile from "./Profile";
import Terminal from "./Terminal";
import SubscriptionDetails from "./SubscriptionDetails";
import BillingDetails from "./BillingDetails";
import InvoiceHistory from "./InvoiceHistory";

export default {
    name: "AccountWrapper",
    components: {InvoiceHistory, BillingDetails, SubscriptionDetails, Terminal, Profile}
}
</script>

<style>

</style>
