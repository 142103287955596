<template>
    <div>
        <div class="product-name" style="margin: 10px 0;">
            <div>
                <div class="product-icon" v-if="!plan.ended_at" style="line-height:56px;">
                  <font-awesome-icon class="check"  :icon="['fa', 'check']" size="sm"/>
                </div>
                <div class="product-icon"  v-else>
                  <font-awesome-icon class="pause" :icon="['fa', 'pause']" size="sm"/>
                </div>
            </div>
            <div style="margin-left: 10px; display: flex; flex-direction: column; align-self: center; padding-right:5px;">
                <div>
                    <span>{{ plan.product.name }}</span>
                </div>
                <div>
                    <span class="plan-description" v-html="description"></span>
                </div>
                <div>
                <span class="plan-description" v-if="isUpcoming && !isCurrentPlanTrial">
                    Access starts on {{ startsAt }}
                </span>
                <VueCustomTooltip :label="trialInfoMessage" position="is-right" :multiline="true"
                                  v-if="isUpcoming && isCurrentPlanTrial">
										<span class="plan-description">
											Resumes on {{ toSystemDate(trialEndDate) }}
										</span>
                </VueCustomTooltip>
                <VueCustomTooltip :label="trialInfoMessage" position="is-right" :multiline="true"
                                  v-else-if="isCurrentPlanTrial">
                <span class="plan-description red">
                    Access ends on {{ toSystemDate(trialEndDate) }}
                </span>
                </VueCustomTooltip>
                <VueCustomTooltip :label="infoMessage" position="is-right" :multiline="true" v-else-if="plan.ended_at">
                    <span class="plan-description red">
                        Access ends on {{ toSystemDate(plan.ended_at) }}
                        <!-- <b-button @click="reactivatePlan" style="background-color: #2B3941"
                                  v-if="plan.product.is_visible_front_end">Cancel Downgrade </b-button> -->
                    </span>
                </VueCustomTooltip>
                <div v-else-if="formattedPrice !== 'Free'"
                class="plan-description">
                    <label
                    >
                      {{ 'Next payment will be ' + formattedPrice + ' on ' + toSystemDate(plan.next_billing_at) + ' ' + vatData}}
                    </label>
                  </div>
            </div>
            </div>
            <div 
            v-if="!upcoming"
            style="display: flex; align-self: start;" >
                <div>
                    <VueCustomTooltip 
                    v-if="plan.ended_at && !isTrial && plan.is_subscription && parseInt(this.plan.price)"
                    position="is-left"
                    label="Cancel Downgrade" :multiline="true">
                        <a 
                        class="transparent-link"
                        href="javascript:void(0);"
                        @click="reactivatePlan(plan)">
                        <font-awesome-icon :icon="['fa', 'redo']" />
                    </a>
                    </VueCustomTooltip>
                    <VueCustomTooltip 
                    v-else-if="showTrash"
                    position="is-left"
                    label="Downgrade" :multiline="true">
                    <a 
                    class="transparent-link"
                    href="javascript:void(0);"
                    @click="downgradePlanHandler()">
                        <font-awesome-icon :icon="['fas', 'trash-alt']"/>
                    </a>
                    </VueCustomTooltip>
                    <VueCustomTooltip
                    style="margin-left: 8px;"
                    v-if="showUpgrade"
                    position="is-left"
                    label="Upgrade" :multiline="true">
                    <a 
                    class="transparent-link"
                    href="javascript:void(0);"
                    @click="upgradeHandler()">
                        <font-awesome-icon :icon="['fas', 'arrow-alt-circle-up']"/>
                    </a>
                    </VueCustomTooltip>
              </div>
            </div>
        </div>
        <div>
            <div class="slide-down-link-style">
                <span v-if="isCurrentPlanTrial && !isUpcoming">
                    {{ formattedTrialLength }}
                </span>
                <span v-else>
                    {{ formattedPrice }} {{ isFree(formattedPrice) ? '' : ' per ' + plan.product.subscription_length_type + ' ' + vatData }}
                </span>
            </div>
        </div>
        <div
                v-if="plan.product.commitments && plan.product.commitments.length > 0">
            <select v-model="selectedCommitment">
                <option v-for="commitment in plan.product.commitments" :value="commitment"
                        :key="commitment.slug">{{ commitment.name }}
                </option>
            </select>
        </div>
        <div v-if="widgetsToShow && widgetsToShow.length"
        style="margin-top: 25px;"  
        >
            <a href="javascript:void(0);"
            class="slide-down-link-style"
            @click="isSlideDownOpen = !isSlideDownOpen"
            >{{ slideDownProducts }} 
              <font-awesome-icon v-if="!isSlideDownOpen" :icon="['fa', 'chevron-down']"/>
              <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
            </a>
            <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down"
            >
                <div style="margin-top:10px;">
                    <div
                    v-if="categorisedWidgetsToShow.widgets.length"
                    >
                        <span class="included-widgets-label">Indicator{{ categorisedWidgetsToShow.widgets.length > 1 ? 's' : '' }}:</span>
                        <ul class="included-widgets">
                        <li
                        v-for="widget in categorisedWidgetsToShow.widgets" :key="widget.name"
                        >
                        <VueCustomTooltip
                        v-if="widget.showcase"
                        :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                        >
                            <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                        </VueCustomTooltip>
                        <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                        <span class="product-widget-name">{{ widget.name }}</span>
                        </li>
                        </ul>
                    </div>
                    <div class="">
                        <div v-if="categorisedWidgetsToShow.livestreams.length">
                            <span class="included-widgets-label">Live stream series:</span>
                            <ul class="included-widgets">
                                <li
                                v-for="widget in categorisedWidgetsToShow.livestreams" :key="widget.name"
                                >
                                <VueCustomTooltip
                                v-if="widget.showcase"
                                :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                                >
                                    <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                                </VueCustomTooltip>
                                <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                                <span class="product-widget-name">{{ widget.name }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="categorisedWidgetsToShow.videos.length">
                            <span class="included-widgets-label">Video course{{ categorisedWidgetsToShow.videos.length > 1 ? 's' : '' }}:</span>
                            <ul class="included-widgets">
                                <li
                                v-for="widget in categorisedWidgetsToShow.videos" :key="widget.name"
                                >
                                <VueCustomTooltip
                                v-if="widget.showcase"
                                :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                                >
                                    <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                                </VueCustomTooltip>
                                <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                                <span class="product-widget-name">{{ widget.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </vue-slide-up-down>
        </div>
    </div>

</template>

<script>
import dayjs from "dayjs";
import {createNamespacedHelpers} from "vuex";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import { toSystemDate } from '../../../utility/datetime';
import { formatPrice, getServerEnv } from '../../../utility/helper-functions';
import { eventBus } from '../../../main';
import Constants from '../../../utility/constants';


const payments = createNamespacedHelpers('payments');
const widgets = createNamespacedHelpers('widget');
export default {
    name: "PlanDetails",
    // created() {
    //   console.log("data", parseInt(this.plan.price), !this.plan.ended_at && this.plan.is_subscription && parseInt(this.plan.price) > 0);
    // },
    mixins: [SubscriptionMixin],
    props: {
        upcoming: Boolean,
        plan: {
            type: Object,
            default: () => {
            }
        }
    },
    data: function () {
        return {
            selectedCommitment: {},
            isSlideDownOpen: false,
        }
    },
    computed: {
        ...payments.mapGetters(["isCurrentCardValid"]),
        ...widgets.mapGetters(['getCodeByImplementationId']),
        formattedPrice: function () {
            return formatPrice(this.plan);
        },
        vatData() {
            if(this.includesVAT)
                return "(includes UK VAT)";
            else
                return "";
        },
        period: function () {
            let fromDate = "From: " + this.toSystemDate(this.plan.started_at);
            let toDate = ", To: " + this.toSystemDate(this.plan.ended_at);
            return fromDate + (this.plan.ended_at ? toDate : "");
        },
        nextPayment: function () {
            let price = "Next payment: " + this.formattedPrice + ' ' + this.vatData;
            let date = ", On: " + this.toSystemDate(this.plan.next_billing_at);
            return price + dayjs((this.plan.next_billing_at) ? date : "");
        },
        infoMessage: function () {
            return "You’ve downgraded your plan. Access ends on " + this.toSystemDate(this.plan.ended_at);
        },
        isUpcoming: function () {
            return new Date(this.plan.started_at).getTime() > new Date().getTime() || this.upcoming;
        },
        startsAt: function () {
            return this.toSystemDate(this.plan.started_at);
        },
        formattedTrialLength: function () {
            return this.plan.product.subscription_length + ' ' + this.plan.product.subscription_length_type + ' Trial';
        },
        trialEndDate: function () {
            return this.activePlan.ended_at;
        },
        trialInfoMessage: function () {
            return "Your Trial of " + this.activePlan.product.name + " ends on " + this.toSystemDate(this.trialEndDate);
        },
        widgetsToShow() {
          if(+this.plan.price === 0) {
            return this.plan.product.widgets.filter(el => el.pivot);
          }
          let widgets = this.plan.product.widgets;

          if(this.plan.product?.parent_id) 
            widgets = this.mergedPlans.find(el => el.id === this.plan.product.parent_id).widgets;
        
          return widgets ? widgets.filter(wid => !wid.showcase) : [];
        },
        slideDownProducts() {
            const prodArr = [];
            let plural = '';
            const widgets = this.categorisedWidgetsToShow.widgets;
            const livestreams = this.categorisedWidgetsToShow.livestreams;
            const videos = this.categorisedWidgetsToShow.videos;

            if(widgets.length) {
                plural = widgets.length > 1 ? 's' : '';
                prodArr.push(`${widgets.length} indicator${plural}`);
            }
            if(livestreams.length) {
                prodArr.push(`${livestreams.length} live stream series`);
            }
            if(videos.length) {
                plural = videos.length > 1 ? 's' : '';
                prodArr.push(`${videos.length} video course${plural}`);
            }
            

            let string = '';
            
            prodArr.forEach((el, i) => {
                if(i < prodArr.length - 1) {
                    string+= el + ', '
                }
                else{
                    string+= el + ' ' 
                }
            });
                return string + 'included';
        },
        categorisedWidgetsToShow() {
            if(!this.widgetsToShow.length)
                return {};

            const arrW = [];
            const arrLs = [];
            const arrVi = [];
            
            this.widgetsToShow.forEach(wid => {
                let code = wid.code ? wid.code : this.getCodeByImplementationId(wid.pivot?.widget_implementation_id || wid.implementation_id);

                    if(code === 'TCSTREAM') {
                        arrLs.push(wid);
                    }
                    else if(code === 'TCVIDEO') {
                        const env = getServerEnv();
                        const mtaID = Constants.MTA_PROMO[env];
                        
                        if((wid?.pivot?.widget_implementation_id === Constants.MTA_COURSE[env] || wid.implementation_id === Constants.MTA_COURSE[env]) &&
                        this.plan.id !== mtaID && this.plan.parent_id !== mtaID &&
                        wid.name.indexOf('(taster)') === -1) {
                            wid.name = wid.name + ' (taster)';
                        }
                        arrVi.push(wid);
                    }
                    else {
                        arrW.push(wid);
                    }
            });

            const obj = { 
                widgets: arrW, 
                livestreams: arrLs, 
                videos: arrVi
            };

            return obj;
        },
        description() {
            let desc = this.plan.product.description;
            const regex = new RegExp("<[^>]*>?",'gm')
            desc = desc.replace(regex,'');

            return desc;
        },
        showUpgrade() {
            
            //legacy plans, free plan
            const prod = this.activePlan.product;
            if(!prod.is_visible_front_end || parseFloat(this.activePlan.price) === 0)
                return true;

            //if there are more than 1 prem plan
            const upgradeables = this.mergedPlans.filter(el => parseFloat(el.pricing.USD) > 0);

            if(upgradeables.length > 1)
                return true;

            //if there is just one premium and user is on this premium
            const upgradeableComms = [];
            const currentCommValue = prod.subscription_length * Constants.PLAN_CONSTANTS.length_values[prod.subscription_length_type];

            upgradeables.forEach(el => {
                const trialsExcluded = el.commitments.filter(e => !e.trial_of_product_id);
                
                trialsExcluded.forEach(el => {
                    const upgradeCommValue = el.subscription_length * Constants.PLAN_CONSTANTS.length_values[el.subscription_length_type];
                    if(currentCommValue < upgradeCommValue)
                        upgradeableComms.push(el);
                });
            });

            return !!upgradeableComms.length;
        },
        showTrash() {
            
            if(!this.plan.ended_at && this.plan.is_subscription && parseInt(this.plan.price) > 0)
                return true;

            return false;
            // if(!this.isLegacy) {
            //     return !this.isExplorer  && !this.isProTrial;
            // }
            // if(this.plan.ended_at)
            //     return false;
            // return true;
        }
    },
    methods: {
      downgradePlanHandler() {
        const explorer = this.mergedPlans.find(el => el.slug.indexOf("explorer") > -1);
        if(explorer) {
            this.downgradePlan(explorer, this.plan);
        }
      },
      upgradeHandler() {
        const instanceID = this.$root.instanceID;

        const modal = {
            instanceID,
            componentInModal: "terminal-plan-selector",
            classes: "terminal-modal simplified auto-height",
            mode: "auto",
            panel: {
                isUpgrading: true,
                isDowngrading: false
            }
        };
            

        eventBus.$emit('open-modal', modal);
      },
      widgetInfoLabel: function (widget) {
       
            if(widget.planDetails?.planWidgetDetails?.showcase && widget.planDetails?.purchased?.length === 0) {
                return widget.planDetails?.includedDetails;
            }

            const plans = this.mergedPlans.filter(plan => plan.product_type === 'plan');
            let found;
            let showcaseEnds;
            plans.forEach(plan => {
                const premium = plan.widgets.find(el => el.showcase === false && el.implementation_id === widget.pivot?.widget_implementation_id);

                const showcase = plan.widgets.find(el => el.showcase && el.implementation_id === widget.pivot?.widget_implementation_id);
                
                if(showcase)
                    showcaseEnds = toSystemDate(showcase.showcase_ends_at, false, 'DD MMM YYYY');

                if(premium) {
                    found = plan.name;
                }
            
            });
            if(found)
                return "Normally on " + found + " showcased here for free until " + showcaseEnds + ".";
            else 
                return "Showcased here for free until " + showcaseEnds + "."
            
        },
    }
}
</script>


<style lang="scss" scoped>
.product-name {
  display: grid;
  grid-template-columns: 55px 1fr 55px;
}

.product-icon {
  @include productIcon;
}

.slide-down-link-style {
  color: $input-color;
  text-decoration: none;

  font-weight: bold;

  & svg {
    color: $input-color-filter;
  }
}




</style>
