<template>
    <div id="announcement-info" style="margin-top: 5px">
        <div style="color: white">
            <table>
                <tbody>
                <tr>
                    <td>
                        <div>
                            <label>Card:</label>
                            <span>{{ paymentMethod.description }}</span>

                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div>
                            <label>Expires on:</label>
                            <span>{{ cardExpirationDate }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <label>Name on Card:</label>
                            <span>{{ paymentMethod.holder }}</span>

                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div>
                            <label>Billing Address:</label>
                            <span>{{ paymentMethod.address }}</span>

                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="float: right; margin-bottom: 10px; margin-top: -4rem;">
            <div>
                <base-button
                        mode="red"
                        :link="false"
                        style="margin-right: 15px; width:50px;"
                        @click.native="deleteCard"
                >
                    <font-awesome-icon :icon="['fas', 'trash-alt']"/>
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const payments = createNamespacedHelpers('payments');
export default {
    name: "CreditCardDetails",
    computed: {
        ...payments.mapState(["paymentMethod"]),
        ...payments.mapGetters(["userHasActiveSubscriptions", "nextPaymentInformation"]),
        cardExpirationDate: function () {
            return dayjs(this.paymentMethod?.valid_until).format("M / YYYY");
        },
    },
    methods: {
        ...payments.mapActions(['loadPaymentMethod', 'removePaymentMethod']),
        deleteCard() {
            if (this.userHasActiveSubscriptions && this.nextPaymentInformation.active) {
                Swal.fire({
                    title: 'Action Required',
                    type: 'info',
                    text: 'Please cancel your products before removing your payment details',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                    title: 'Are you sure?',
                    type: 'warning',
                    text: 'You will need to add a new card before you purchase new products',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.removePaymentMethod().then(() => {
                            Swal.fire({
                                title: 'Success',
                                type: 'info',
                                text: 'Your payment method was successfully removed!',
                                confirmButtonColor: '##1d9deb',
                                confirmButtonText: 'OK'
                            })
                            this.$modal.hide(this.$parent.name);
                        });
                    }
                });
            }

        }
    }
}
</script>

<style scoped>

</style>
