<template>
    <div class="checkout-screen" ref="checkout">
        <div>
            <div class="checkout-overview">
                <div class="checkout-overview-left">
                    <next-steps :products="products" :noCommitmentSelected="noCommitmentSelected"></next-steps>
                </div>
                <div class="checkout-overview-right">
                    <order-summary 
                    :products="products"
                    :model="model" 
                    :removeHandler="removeProduct"
                    :close="closeCheckout"
                    :isUpgrading="details.isUpgrading"
                    :makePayment="makePayment"
                    @commitment-changed-at-checkout="commitmentChanged"
                    ></order-summary>
                </div>
                <div>

                </div>
            </div>
        </div>
        <div>
            <div>
                <div>
                    <div>
                        <div>
                            <!-- <div>
                                <b-form-checkbox
                                        v-model="model.termsAndConditions"
                                >
                                    I agree to the <a
                                        style="color: white; "
                                        href="https://financialsource.co/terms-conditions/"
                                        target="_blank">terms and conditions</a>
                                </b-form-checkbox> 
                            </div>
                            <div><span>${{ model.totalWithVat }}</span></div>
                            <div>
                                <button block style="padding: 5px" @click="makePayment">Make Payment</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
</template>

<script>
import OrderSummary from "./ui/checkout/OrderSummary.vue";
import NextSteps from "./ui/checkout/NextSteps";
import SubscriptionMixin from "../mixins/SubscriptionMixin";
import _ from 'lodash';
import {getPromoID, talkToServer, toSystemPrice} from "../utility/helper-functions";
import { eventBus } from '../main';
import { createNamespacedHelpers } from 'vuex';

const payments = createNamespacedHelpers("payments");

export default {
    name: "CheckoutOverview",
    components: {NextSteps, OrderSummary},
    mixins: [SubscriptionMixin],
    props: {
        details: {
            type: Object,
            default: () => {
            }
        }
    },
    created() {
        this.selectedItem = this.details.selectedItem;


        const slug = this.selectedItem.details ? this.selectedItem.details.slug : this.selectedItem.slug;
        if(slug)
            talkToServer('/products/interest/' + slug, "POST", null, {}, true);
    },
    computed: {
        ...payments.mapGetters(["activePlan"]),
        type() {
            return this.details.type;
        },
        baseProdType() {
            if(this.details.selectedItem.product_type === 'plan' || this.mergedPlans.find(el => el.id === this.details.selectedItem.parent_id))
                return 'plan';

            return 'option';
        }
    },
    data: function () {
        return {
            products: [],
            model: {
                total: 0,
                vat: 0,
                totalWithVat: 0,
                termsAndConditions: false
            },
            selectedItem:null,
            noCommitmentSelected: false,
            toggling: true,
        }
    },
    watch: {
        products: {
            deep: true,
            handler: function (val) {
                if(getPromoID() && (val?.[0]?.product_type === 'plan' || val?.[0]?.product_sub_type === 'bundle') && !this.noCommitmentSelected && this.toggling) {
                    this.noCommitmentSelected = true;
                    this.toggling = false;
                }else{
                    this.noCommitmentSelected = false;
                }
                let total = 0;
                this.products.forEach(product => {
                    total += product.updatedPrice;
                })
                this.model.total = toSystemPrice(total);
                this.model.vat = toSystemPrice(this.calculateVat(total));
                this.model.totalWithVat = toSystemPrice(this.calculatePriceWithVat(total));
            }
        }
    },
    methods: {
        addProduct: function (product) {
            this.products.push(product);
        },
        removeProduct: function (product) {
            this.products.splice(this.products.findIndex(item => item.slug === product.slug), 1);
        },
        makePayment: function () {
            console.log("make payment");
            if (!this.hasActiveCard) {
                this.setMakingPayment(false);
                this.$toast.warning(
                    '<div><span style="color: #393636">There is no payment method</span>' +
                    '<label style="color: #393636">Please add Payment Method in Billing Details</label></div>', {
                        position: 'bottom'
                    })
                return;
            }
            this.products.forEach(product => {
                if (product.product_type === "addon") {
                    this.upgradePromotion(product.planDetails.addonProducts[0]);
                } else {
                    this.upgradePromotion(product);
                }
            });
        },
        closeCheckout() {
            localStorage.removeItem('promo');
            window.bootUp.promo = null;
            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                comp: this.$store.getters["general/componentAfterRefresh"] || "home"
            });
        },
        commitmentChanged(obj) {
            if(this.baseProdType === 'plan' ) {
                this.selectedItem = (this.mergedPlans.find(el => el.id === obj.parent)).commitments.find(el => el.id === obj.comm);
            }else {
                this.selectedItem = (this.promotionalProducts.find(el => el.id === obj.parent)).commitments.find(el => el.id === obj.comm);
            }

            this.updateProducts();
        },
        updateProducts() {
            this.products = [];
            let initialProduct = {
                ..._.cloneDeep(this.selectedItem),
                product_type: this.type,
                updatedPrice: this.type === "addon" ? this.selectedItem.planDetails.priceNumeric : parseFloat(this.selectedItem.pricing["USD"]),
                currentPrice: this.type === "addon" ? this.selectedItem.planDetails.priceNumeric : parseFloat(this.selectedItem.pricing["USD"])
            }
            this.products.push(initialProduct);
        }
    },
    mounted() {

        this.updateProducts();
        this.$nextTick(() => {
            const top = this.$refs.checkout;
            top.scrollIntoView();   
        })
    }
}
</script>

<style lang="scss">
    .checkout-screen {
        @include standardContainer;
        color: $input-color;
        margin-top: 20px;
    }
    .checkout-overview {
        display: flex;
        flex-direction: column-reverse;

        @include respond(lg) {
            display: grid;
            gap: 30px;
            grid-template-columns: 300px 1fr;
            margin-top: -45px;
        }

        @include respond(xl) {
            grid-template-columns: 400px 1fr 200px;
        }

        &-left {
            margin-top: 25px;

            @include respond(lg) {
                margin-top: 0;
            }
        }
    }


    // .close-container {
    //     height: 35px;
    //     position: relative;

    //     & button {
    //         position: absolute;
    //         right: 15px;
    //         border: none;
    //         background-color: transparent;
    //         color: $input-color-filter;
    //         cursor: pointer;
    //     }
    // }
</style>
