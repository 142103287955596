<template>
    <div 
    class="video-screen-particular"
    style="padding-bottom: 30px;"
    >
        <!-- <div> -->
            <div class="form-container">

                <form @submit.prevent
                class="video-search-box">
                    <base-input
                    elementType="input"
                
                    inputType="text"
                    v-model="searchTerms.keywords"
                    :placeholder="'Search for keywords...'"
                    forID="name"
                    inputClass=""
                    formControlClass=""
                    
                    ></base-input>

                    <base-button
                    mode="o-blue"
                    :link="false"
                    @click.native="search"
                    >Search
                    </base-button>
                    <base-button
                    v-if="searchMode"
                    mode="o-grey"
                    :link="false"
                    @click.native="back"
                    >Back
                    </base-button>
                </form>

                <!-- <form @submit.prevent
                class="video-search-box">
                    <base-input
                    elementType="input"
                    :placeholder="'Ask a question...'"
                    inputType="text"
                    v-model="question"
                    forID="name"
                    inputClass=""
                    formControlClass=""
                    
                    ></base-input>
                    
                    <base-button
                    mode="o-blue"
                    :link="false"
                    @click.native="submitQuestion"
                    >Submit
                    </base-button>
                </form> -->

            </div>
        <!-- </div> -->
        
        <fullpage-section
        v-for="item in Object.entries(sortedItems)"
        :key="item[0]"
        :name="'videotiles'"
        :title="''"
        :tiles="true"
        :moreLink="false"
        :slideDown="false"
        :scrollable="true"
        :elements="item[1]"
        :handler="loadMore"
        :height="height"
        :noMoreItems="noMoreItems"
        :details="details"
        ></fullpage-section>
    </div>
</template>

<script>
import { eventBus } from '../../main';
import VideoCourseMixin from '../../mixins/videoCourseMixin';
// import { isMobileForDashboards } from '../../utility/helper-functions';
import FullpageSection from '../ui/FullpageSection.vue';
import Swal from 'sweetalert2';
import { talkToServer } from '../../utility/helper-functions';

export default {
    mixins: [VideoCourseMixin],
    props: ["details"],
    components: {
        FullpageSection
    },
    created() {
        eventBus.$on("window-resize", h => {
            this.timeout = setTimeout(() => {
                clearTimeout(this.timeout);
                this.calcHeight(h);

                eventBus.$emit("scroller-calc-vertical", {
                    instanceID: this.$root.instanceID
                });
            }, 750);
        });

        console.log(this.details);
    },
    // mounted() {
    //     this.$nextTick(() => {
    //         this.calcHeight();
    //     });
    // }, 
    data() {
        return {
            height: 0,
            timeout: null,
            searchTerms: {
                keywords: ''
            },
            question: ''
        }
    },
    methods: {
        calcHeight(h) {
            this.height = h * 2 - 75;
            // console.log("calci", document.querySelector('.widget-wrapper').clientHeight - adj);
            // let adj = 30;
            // if(isMobileForDashboards())
            //     adj = 55;

            // const num = document.querySelector('.widget-wrapper').clientHeight - adj;
            // if(num)
            //     this.height = num;
        },
        search() {
            this.bulkItems = [];
            this.sortedItems = {};
            this.noMoreItems = false;
            this.loadMore();
        },
        back() {
            this.bulkItems = [];
            this.sortedItems = {};
            this.noMoreItems = false;
            this.searchMode = false;
            this.searchTerms.keywords = ''; 
            this.fetchInitialItems();
        },

        submitQuestion() {
            if(!this.question.length)
                return;

            let success, slug;
            let query = {};
           
            query.content_id = this.vidcomID ///?;
            query.question_text = this.question;
            query.module = this.details.implementation_id;
            query.source = this.details.userSettings.widgetname;

            slug = `/widgets/questions`;
            success = 'Your question was successfully submitted. Please check your emails in the next 24-48 hours for our response.';
        
            // console.log(query);
            talkToServer(slug, "POST", null, query, true).then(response => {
                if(response && response.data && (response.data.success || response.success)) {
                    this.question = '';

                    Swal.fire({
                        title: 'Success!',
                        type: 'info',
                        text: success,
                        showCancelButton: false
                    });

                    this.$emit("close-slide-down");
                }else{
                    Swal.fire({
                        title: 'Error!',
                        type: 'warning',
                        text: "Could not submit question. Please try again later.",
                        showCancelButton: false
                    });
                }
            }).catch(() => {
                
            })
            

        }
    }
}
</script>

<style lang="scss">
    .video-screen-particular {
        & .form-container {
            width: 100%;

            & form {

                margin: 20px 0;
                
                &:first-child {
                    margin-bottom: 40px;
                }

                &:first-child {

                    @include respond(md) {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                }
                &:nth-child(2) {
                    @include respond(md) {
                        margin-bottom: 0;
                        margin-left: 10px;
                    }
                }

                & .form-control {
                    flex: 1 1 auto;
                }

                & input {
                    font-size: 16px;

                    @include respond(md) {
                        min-width: 235px;
                    }
                    @include respond(lg) {
                        min-width: 335px;
                    }
                }

            }

            & button {
                height: 48px;
            }

            @include respond(md) {
                width: unset;
                display: flex;
                justify-content: space-around;
            }
            
        }
    }
    .video-search-box {
        display: flex;
        height: 48px;
        align-items: center;

        & label {
            display: none;
        }

        & input {
            background-color: rgba(black, 0.3);
        }

        & button {
            margin-left: 10px;
        }


    }
</style>
