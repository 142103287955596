import { talkToServer } from "../../utility/helper-functions";
import {store} from "../../store/store";
import { usersTime } from "../../utility/datetime";
// import testData from './test.json';
import _ from 'lodash';

export default {
    getLastCurrenyData() {
        talkToServer("/widgets/currency-strength/closed", "GET", null, null, true).then(response => {
            // const response = testData;
            let lastUpdate = null;
            Object.entries(response.data).forEach(([key,value]) => {
                if(!lastUpdate)
                    lastUpdate = value.created_at;
                // console.log(value)
                const json = value.json;
                store.dispatch("currency/setPairs", (_.cloneDeep({key: key, value: json[5]})));
                store.dispatch("currency/setLeaderBoard", (_.cloneDeep({key: key, value: json[3]})));
                
            });
            store.dispatch("currency/setLastPush", usersTime(lastUpdate, true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, false, "Europe/London"));
        });
    }
}