export default {
    addBulkRecords(_, payload) {
        
        const processingObject = {
            objStore: `glossary`, 
            data: payload
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(() => {});
        
    },

    // setLastGlossaryUpdate({commit}, payload) {
    //     commit("setLastGlossaryUpdate", payload);
    // }


}