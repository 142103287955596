<template>
    <div>
        <div class="top-row">
            <billing-card-details></billing-card-details>
        </div>
        <payment-history :selectedInvoices="selectedInvoices"></payment-history>
    </div>
</template>

<script>
import PaymentHistory from "./PaymentHistory";
import BillingCardDetails from "./BillingCardDetails";

export default {
    name: "BillingSettings",
    props: {
        selectedInvoices: Array
    },
    components: {BillingCardDetails, PaymentHistory},
    data: function () {
        return {
            isSlideDownOpen: false
        }
    },
    methods: {
        toggleChangePlan: function (isSlideDownOpen) {
            this.isSlideDownOpen = isSlideDownOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
.settings-tabs .tabs-body-content {

  @include respond(lg) {
    height: 535px;
  }

}

.top-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include respond(md) {
    flex-direction: row;
  }
}

.top-row > div {
  width: 100%;
}

.inside-row {
  margin: 5px 0;
  padding: 6px 0;

  @include respond(md) {
    text-align: right;
  }
}

</style>
