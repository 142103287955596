<template>
    <div :id="instanceID" class="vld-parent">
        <loading v-model="showSpinner" :active="showSpinner"
                 :can-cancel="true"
                 :is-full-page="false">
            Please wait, while the action is being performed...
        </loading>
        <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                duration="200">
            <base-modal v-if="modalIsOpen"
                        :header="modal.header"
                        :mode="modal.mode"
                        :title="modal.title"
                        :classes="modal.classes"
                        :footer="modal.footer"
                        :countdown="modal.countdown"
                        :close="true"
                        @close-modal="closeModal">
                <template #default>
                    <component :is="modal.componentInModal" :panel="modal.panel" :modal="modal"></component>
                </template>
            </base-modal>
        </transition>


        <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut">


            <onboard-funnel
            v-if="loggedOut"
            :promoID="promoID"
            :specialLink="specialLink"
            @register-login-successful="processLogin"
            :continueOnboardingState="continueOnboardingState"
            @onboard-skipped="onboardSkipped"
            ></onboard-funnel>

        </transition>
        <login-component v-if="autoLogin && loggedOut"></login-component>
        <div v-if="!loggedOut && readyForWidgets" class="platform-background">
            <div 
            v-if="remoteAccess"
            style="position: absolute; width: 100%; padding: 2px 0; background-color: #B33C22; color: #FFFFFF; font-size: 10px; text-align: center; z-index: 3;">ADMIN MODE or NOT LOGGED OUT since last admin mode. Please ensure to log out when finished the session.</div>
            <the-header
                v-if="!nonTerminalUser"
                :currentComp="componentToBeRendered"
            ></the-header>
            <top-ribbon
            v-if="!nonTerminalUser"
            :ribbonType="ribbonType"></top-ribbon>
            <component v-if="!loggedOut && readyForWidgets" :is="componentToBeRendered" :details="componentDetails" :specialLink="specialLink"></component>
            <div class="footer" v-if="fullscreenId > 0">
                <p>
                    <font-awesome-icon
                            :icon="['fa', 'copyright']"/>
                    {{ currentYear }} Financial Source v3.0{{displayVersionID}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import Echo from "laravel-echo";
import Pusher from './mixins/pusher';
import Squawk from './mixins/squawk';
import Intercom from './mixins/intercomMixin';
import findComponent from './mixins/componentToBeRendered';
import Constants from "@/utility/constants"
const dashboards = createNamespacedHelpers('dashboard');
const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
const headlines = createNamespacedHelpers('headlines');
const widgets = createNamespacedHelpers('widget');
const squawk = createNamespacedHelpers('squawk');
const webinars = createNamespacedHelpers('webinars');
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import AudioSquawk from './components/widgets/AudioSquawk';
// import BreakingHeadlines from './components/widgets/BreakingHeadlines';
import * as workerTimers from 'worker-timers';
import AccountSettings from './components/ui/AccountSettings.vue';
import AccountOverview from './components/ui/account/AccountOverview.vue';
import BugReport from './components/ui/forms/BugReport';
import ChangePassword from './components/ui/ChangePassword.vue'
import EcocalModal from './components/widgets/components/EcoCalendar/EcocalModal';
import EditDashboard from './components/ui/forms/EditDashboard';
import EntityComponent from './components/EntityComponent';
import GlossaryApp from './components/widgets/GlossaryApp';
import LoginComponent from "./components/ui/onboard/LoginComponent.vue";
import ImageViewer from './components/widgets/components/ImageViewer';
import InterestrateModal from './components/widgets/components/InterestRate/InterestrateModal.vue';
import PdfViewer from './components/widgets/components/PdfViewer';
// import PlatformLogin from './components/ui/forms/PlatformLogin';
import TheHeader from './components/ui/TheHeader.vue';
import DashboardComponent from './components/DashboardComponent.vue';
import VideoComponent from './components/VideoComponent.vue';
import HomeComponent from './components/HomeComponent.vue';
import ResearchComponent from './components/ResearchComponent.vue';
import CheckoutComponent from './components/CheckoutComponent.vue';
import StreamReminder from './components/widgets/components/StreamReminder';
import SubmitRating from './components/widgets/components/SubmitRating';
import TerminalSupport from './components/ui/forms/TerminalSupport';
import WidgetShop from './components/ui/shop/WidgetShop';
import TerminalPlanSelector from './components/ui/TerminalPlanSelector.vue';
import WelcomePopup from './components/ui/WelcomePopup.vue';
import TutorialModal from './components/widgets/components/TutorialModal.vue';
import CardDetailsModal from './components/ui/cardmodal/CardDetailsModal.vue';
import SunsetPopup from './components/widgets/components/VideoPlayers/SunsetPopup.vue';
import { createDashboardElements, diffArray, getParameterByName, getPromoID, getServerEnv, isMobileForDashboards, talkToServer } from './utility/helper-functions';
import { rightNowUTCWithFormatting } from './utility/datetime';
import { eventBus, tabCommReceiver, tabCommSender } from './main';
import AppService from './services/app.service';
import { setupCacheCallBlocker } from './data/localStorage';
// import VidCom from './components/widgets/VidCom';
import TrackingService from './services/tracking/tracking.service';
import _ from "lodash";
import BootUpMixin from "./mixins/bootUpMixin";
import OnboardFunnel from './components/ui/OnboardFunnel.vue';
import TermsConditions from './components/ui/onboard/components/TermsConditions.vue'
import TopRibbon from './components/ui/TopRibbon.vue';
import EcocalFilter from './components/widgets/components/EcoCalendar/EcocalFilter.vue';
import EcresearchFilter from './components/widgets/components/EconomicResearch/EcresearchFilter.vue';

import Swal from "sweetalert2";
import PaymentCardModal from "./components/ui/payments/PaymentCardModal";
import dayjs from 'dayjs';
import accountService from './services/account/account.service';

const PAYMENT_LIMIT = 86400000;
export default {
    components: {
        Loading,
        AccountOverview,
        AccountSettings,
        BugReport,
        ChangePassword,
        CheckoutComponent,
        EcocalModal,
        EcresearchFilter,
        EditDashboard,
        EcocalFilter,
        EntityComponent,
        CardDetailsModal,
        GlossaryApp,
        ImageViewer,
        InterestrateModal,
        PdfViewer,
        LoginComponent,
        DashboardComponent,
        HomeComponent,
        VideoComponent,
        ResearchComponent,
        TerminalSupport,
        WidgetShop,
        StreamReminder,
        SubmitRating,
        SunsetPopup,
        TerminalPlanSelector,
        WelcomePopup,
        OnboardFunnel,
        TermsConditions,
        TheHeader,
        TopRibbon,
        TutorialModal
    },
    mixins: [Intercom,Pusher, Squawk, BootUpMixin, findComponent],
    data() {
        return {
            nonTerminalUser: window.bootUp.nonTerminalUser,
            autoLogin: window.bootUp.nonTerminalUser || window.bootUp.remoteAccess,
            loggedOut: false,
            componentToBeRendered: null,
            componentDetails: null,
            modalIsOpen: false,
            versionChanged: false,
            formObj: {
                id: null,
                mode: ''
            },
            modal: {},
            currentYear: new Date().getFullYear(),
            readyForWidgets: true,
            isPopOut: getParameterByName('popout'),
            watchToRemoveShowcaseAnalChat: false,
            continueOnboardingState: {},
            ribbonType: '',
            allSettings: {},
            remoteAccess: window.bootUp.remoteAccess || window.localStorage.getItem("remoteAccess"),
            promoID: false,
            specialLink: ''
        }
    },
    provide() {
        return {
            formObj: this.formObj
        }
    },
    computed: {
        ...general.mapState(['showSpinner', 'versionId']),
        ...general.mapGetters(['user', 'hasRenewFails', 'componentAfterRefresh']),
        ...dashboards.mapState(['poppedoutDashboards', 'fullscreenId']),
        ...payments.mapState(['paymentMethod', 'paymentHistory', 'activeSubscriptions', 'currentProducts', 'availableProducts']),
        ...dashboards.mapGetters(['activeDashboardByInstanceID', 'activeDashboardInfoByInstanceID', 'layouts']),
        ...payments.mapGetters(['userHasPurchasedProducts', 'isCurrentCardValid', 'isCurrentCardExpiring', 'allWidgets','availableWidgets', 'activePlan', 'promotionalProducts', 'mergedPlans', 'currentAddons']),
        ...headlines.mapGetters(['readyForCategoryProcessing']),
        ...widgets.mapGetters(['pusherChannels', 'getCodeByImplementationId', 'hasTCWidgets']),
        ...squawk.mapGetters(['subscribedToSquawk']),
        ...webinars.mapGetters(['isCurrentlyLive']),
        instanceID() {
            return this.$root.instanceID
        },
        activeDashboard() {
            return this.activeDashboardByInstanceID(this.instanceID, "dashboard");
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.instanceID, "dashboard");
        },
        activeVideoPage() {
            return this.activeDashboardByInstanceID(this.instanceID, "videopage");
        },
        activeVideoPageInfo() {
            return this.activeDashboardInfoByInstanceID(this.instanceID, "videopage");
        },
        loginFormTitle() {
            let formMode = this.$store.getters['auth/getLoginFormMode'];
            return formMode === 'register' ? 'Create your free account': formMode;
        },
        displayVersionID() {
            if(window.bootUp.domain === "v3server-dev.finsowidgets.com")
                return '.0.'+this.versionId;

            return '';
        },
        displayTC() {
            return this.hasTCWidgets;
        }
    },
    created() {
        const track = window.bootUp.domain === "v3server-live.finsowidgets.com";
        TrackingService.initializeTracking(track);
        TrackingService.trackUser();
        // console.log(window.dataLayer);
        const promoID = getPromoID();
        if(promoID)
            this.promoID = +promoID;

        const specialLink = localStorage.getItem("specialLink");
            if(specialLink)
                this.specialLink = specialLink;

        this.registerCloseWindowEvent();
        this.registerAnalchatCheckEvent();
        this.$store.dispatch('widget/setWidgets', _.cloneDeep(window.bootUp.widgets));

        eventBus.$on("nav-change", obj => {
            if(obj.instanceID !== this.$root.instanceID)
                return;

            this.componentDetails = obj.details;
            if(obj.comp !== 'checkout') {
                this.setActiveNavItem(obj.comp);
                this.setComponentAfterRefresh(obj.comp);
            }
                

            this.componentToBeRendered = Constants.COMPONENTS[obj.comp];
            
        });
        // console.log('get widgets', this.$store.getters['widget/widgets']);

        // if(!localStorage.getItem('token') &&
        // this.$router.currentRoute.path !== '/pwr-rst' &&
        // this.$router.currentRoute.path !== '/pwr',
        // this.$router.currentRoute.path !== '/start-here') {
        //     // let query;
        //     if(!this.$router.currentRoute.query) {
        //         this.$router.push('/login');
        //     }
        //         // query = this.$router.currentRoute.query;

        //     // console.log("pushing to login", this.$router.currentRoute);
        
        

        eventBus.$on('login-successful', data => {
            this.launchApp(data, true);
        });
        eventBus.$on('register-login-successful', data => {
            if(data.instanceID !== this.$root.instanceID)
                return;

            this.launchApp(data.data, true);
            // this.openTourGuide();
        });
        eventBus.$on('logout-successful', () => {
            this.logout();
        });
        eventBus.$on('open-modal', modal => {
            // if (eventBus.$root.isReady) {
            //     eventBus.$root.isReady = false;
            // if(modal.instanceID === this.$root.instanceID) {
                this.formObj.id = modal.id;
                this.formObj.mode = 'new';
                if (modal.id) {
                    this.formObj.mode = 'edit';
                }

                this.modal = modal;
                this.modalIsOpen = true;
            // }

            // }
            // setTimeout(() => {
            //     eventBus.$root.isReady = true;
            // }, 100);


        });
        eventBus.$on('close-modal', () => {
            this.closeModal();
            // const body = document.getElementsByTagName("body");
            // for (const b of body) {
            //     // console.log(b);
            //     b.style.overflow = "auto";
            // }
        });

        window.onbeforeunload = () => {
            localStorage.removeItem('adfx');
            localStorage.removeItem('adt');
        }

        const token = localStorage.getItem('token');

        // console.log(rightNowUTCWithFormatting(false));

        if (!token) {
            this.loggedOut = true;
        } else {
            const now = rightNowUTCWithFormatting(false);
            const cacheBlocker = +localStorage.getItem('cacheCallBlocker');
            let params = '';
            let clearStores = false;

            // console.log(now - cacheBlocker, window.bootUp.cacheBlockerIntervall)

            if (now - cacheBlocker > window.bootUp.cacheBlockerIntervall) {
                this.$store.dispatch("tweetdeck/clearFetchBoards");
                params = window.bootUp.cacheParams;
                clearStores = true;
            }


            if(!window.bootUp.firstCallMade) {
                talkToServer(`/user/refresh${params}`, "GET", null, null, true).then(response => {
                    if (response && response.success) {
                        // console.log("resp", response);
                        this.saveUserData(response);
                        this.launchApp(response.data, clearStores);
                        if(!cacheBlocker) {
                            tabCommSender.postMessage({ wakeUpPopout: true });
                        }
                    } else {
                        this.logout();
                    }

                }).catch(() => this.logout());
            }else{
                this.launchApp({}, false);
            }

            this.initializeVersionId();
        }
        
        tabCommReceiver.onmessage = event => { 
        

            // console.log(event);
            if(event?.wakeUpMain && !getParameterByName("popout") || 
            event?.wakeUpPopout && getParameterByName("popout")) {
                location.reload();
            }
            
        }
        tabCommSender.onmessage = event => { 
            
            if(event?.dashboardUpdate) {
                this.communicateDashboardUpdateToOtherTabs(event.dashboard);
            }
            
        }
        
        

    },
    methods: {
        ...dashboards.mapActions(['addDashboard','resetDashboards', 'updateDashboardById', 'setActiveDashboard', 'closeAllPopouts', 'setAnalChatActiveOn', 'setSystemDashboards', 'setSystemDashboardsFromLayout', 'setActiveDashboardAndTab', 'processSystemLayouts','addUserGuideDashboard', 'updateDashboardById', 'communicateDashboardUpdateToOtherTabs']),
        ...payments.mapActions(['setCurrentShop', 'loadPaymentMethod', 'loadAvailableProducts', 'setWidgetProducts', 'removePaymentMethod', 'loadPaymentHistoryPromise','setFailedPayment']),
        ...general.mapActions(['setCountries', 'setCurrentUser', 'destroyUserStoreData', 'setVersionId', 'fetchShopSettings', 'processShopSettings', 'setActiveNavItem', 'setComponentAfterRefresh', 'setDbReady', 'setLastTrainingCentreOrderUpdated', 'setWatchHistory']),
        ...widgets.mapActions(['addPusherChannel', 'removePusherChannel', 'clearPusherChannels']),
        ...squawk.mapActions(['setSubscribedToSquawk']),
        registerCloseWindowEvent: function () {
            let context = this;
            window.addEventListener('beforeunload', function () {
                context.closeWindowPopouts();

            }, false)
        },

        processLogin(data) {
            // console.log("processLogin");
            this.launchApp(data.data, true, data.isOnboard);
        },

        closeModal() {

            if(this.modal.componentInModal === "bug-report")
                eventBus.$emit('bug-report-closed');

            this.modal = {};
            this.modalIsOpen = false;
        },

        closeWindowPopouts: function () {
            let context = this;
            let dashboard = _.cloneDeep(context.activeDashboardInfo);
            if (dashboard.popout) {
                dashboard.tab = true;
                dashboard.popout = false;
                context.updateDashboardById(dashboard);
            } else {
                context.poppedoutDashboards.forEach(popout => popout.window.close());
                context.closeAllPopouts();
            }
        },
        registerAnalchatCheckEvent: function () {
            eventBus.$on("ANALCHAT_CHECK", () => {
                if(this.componentToBeRendered !== 'home-component' && this.componentToBeRendered !== 'video-component') {
                    this.shutDownIntercom();
                    return;
                }
                if(!this.loggedOut && !this.isPopOut &&
                (this.availableWidgets.length || this.watchToRemoveShowcaseAnalChat)) {

                    let allowRemoval = !this.availableWidgets?.includes("57");
                    if(allowRemoval) {

                        this.watchToRemoveShowcaseAnalChat = false;
                        this.removeAnalChatWidget();
                    } else {

                        this.setAnalChatActiveOn(null);
                        const found = this.allWidgets.find(el => el.code === "ANALCHAT");

                        if(found && found.planDetails.showcasePlanSlug) {
                            this.watchToRemoveShowcaseAnalChat = true;
                            const dashboardID = 999999;
                            this.setAnalChatActiveOn(dashboardID);
                            this.startIntercom();
                        }else{
                            talkToServer("/setting/user/analyst-chat", "GET", null, null, true).then(resp => {

                                if(resp && resp.data && resp.data.slug && resp.data.slug === "analyst-chat") {

                                    let dashboardID = (JSON.parse(resp.data.json)).dashboardID;

                                    this.setAnalChatActiveOn(dashboardID);
                                    if(dashboardID)
                                        this.startIntercom();
                                }

                            });
                        }
                        // const obj = {
                        //     dashboardID: Math.random()
                        // };

                        // talkToServer("/setting/user/analyst-chat", "POST", null, obj, true).then(() => {
                        //     this.setAnalChatActiveOn(obj.dashboardID);
                        //     this.startIntercom();
                        // });
                    }
                }
            })

        },
        async launchApp(data, clearStores, isOnboard=false) {
            // this.componentToBeRendered = "home-component";
            if(!this.isPopOut)
                this.$router.replace({'query': null});
                
            this.setActiveNavItem("home");
            let context = this;
            this.loggedOut = false;
			if(window.bootUp.isExploreMode)
				return;

            this.allSettings = await talkToServer('/setting/user', "GET", null, null, true);
            const allSettings = this.allSettings;
            // console.log('All settings', allSettings);

            if(!window.bootUp.firstCallMade) {
                //start cacheBlocker only on main window and once for non-terminal users
                if(!getParameterByName("popout") && window.bootUp.cacheBlocker == null) {



                    if(clearStores) {
                        this.clearStoreValues();
                        this.resetDashboards();
                    }
                    AppService.setupDB(clearStores).then(response => {
                        if(response) {
                            // console.log("clearStores", clearStores, "cachecall blocker null", window.bootUp.iDB);

                            this.$store.dispatch('storeCacheCallData', data);
                            this.$store.dispatch("widget/addLookupCategories");
                            this.$store.dispatch("headlines/setHeadlineValues", true);
                            this.readyForWidgets = true;

                        }

                    });
                    setupCacheCallBlocker();
                }else {
                    AppService.setupDB().then(() => {

                        this.$store.dispatch("widget/addLookupCategories");
                        this.$store.dispatch("headlines/setHeadlineValues", true);
                        this.$store.dispatch("headlines/setRareHeadlinesFetched", true);
                        this.readyForWidgets = true;

                        // console.log("ready for widgets", this.readyForWidgets);
                    }
                    );
                }
            }else{
                this.readyForWidgets = true;
            }


            if(!window.bootUp.firstCallMade) {

                const shop = allSettings?.data.find(el => el.slug === 'shopsettings');
                if(shop)
                    this.processShopSettings(shop.json);

                if(!window.bootUp.isExploreMode) {
                    const layouts = allSettings?.data.find(el => el.slug === 'layouts');
                    if(layouts)
                        await this.processSystemLayouts(layouts.json);

                }

                // await this.getUserSettings();
                await this.loadAvailableProducts();
                // if(!window.bootUp.nonTerminalUser)
                this.setCurrentShop([data.user.associated_data.product_access, data.user.associated_data.scheduled_product_access]);
                this.setWidgetProducts(window.bootUp.entity?.widget_products || {});
                this.setCountries(window.bootUp?.countries || []);
                this.setCurrentUser(data.user.user);
                this.setDbReady(true);


                if(!data.user.user.first_logged_in_at) {
                    this.setTimezoneOnFirstLogin();

                    if(isMobileForDashboards()) {
                        const html = `Please note, functionality on mobile devices are limited.<br /><br /><strong>To take full advantage of the terminal, please log in on a desktop or laptop computer.</strong>`
                        Swal.fire({
                            title: 'Mobile Version',
                            html: html,
                            type: 'info',
                            confirmButtonColor: '##1d9deb',
                            confirmButtonText: 'OK'
                            // timer: 1500
                        });
                    }

                }


                if(!this.nonTerminalUser)
                    this.loadPaymentMethod().then(() => {
                        context.checkCurrentUserPaymentSettings();
                    });


                // start cacheBlocker only on main window and once for non-terminal users
                // console.log(clearStores,"clearStores");
                // if(!getParameterByName("popout") && window.bootUp.cacheBlocker == null) {
                // 	AppService.setupDB(clearStores).then(response => {
                // 		if(response) {
                //             this.readyForWidgets = true;
                //             this.$store.dispatch('storeCacheCallData', data);
                //         }

                // 	});
                // 	setupCacheCallBlocker();
                // }else {
                //     AppService.setupDB().then(() => this.readyForWidgets = true);
                // }

                //Squawk

                if (this.subscribedToSquawk) {

                    this.setOrGetAudioTokens();

                }

                //headline fetch job
                
                if ((this.$store.getters["widget/getWidgetByCode"]("HEADLINES") || this.$store.getters["widget/getWidgetByCode"]("VIDCOM")) &&
                !window.bootUp.headlineFetchJob) {
                    const number = 5;
                    window.bootUp.headlineFetchJob = setInterval(() => {

                        const lastCheck = dayjs().utc().add(-number, 'minutes').format("YYYY-MM-DD[T]HH:mm:ss");
                        // console.log("last check",lastCheck);
                        talkToServer("/widgets/headlines/en/since/"+lastCheck, "GET", null, null, true).then(res => {
                            // console.log("resp headline", res);
                            if(res && res.data?.length) {

                                this.processNews(res.data);

                            }
                        });

                        setTimeout(() => {
                            talkToServer("/widgets/vidcom/en/since/"+lastCheck, "GET", null, null, true).then(res => {
                                // console.log("resp vidcom", res);
                                if(res && res.data?.length) {

                                    this.processNews(res.data);

                                }
                            });
                        }, 5000);
                    }, number * 60 * 1000);
                }


                const jwt = 'Bearer ' + localStorage.getItem('token');

                window.Pusher = require('pusher-js');
                const pusherDetails = window.bootUp.entity.pusher;
                window.Echo = new Echo({
                    broadcaster: 'pusher',
                    key: pusherDetails.app_key,
                    wsHost: "pusher.finsowidgets.com",
                    cluster: pusherDetails.cluster,
                    wsPort: 6001,
                    wssPort: 6001,
                    forceTLS: false,
                    disableStats: true,
                    auth: {
                        headers: {
                            'Authorization': jwt
                        }
                    },
                    authEndpoint: window.bootUp.entity.mapped_domain + "/broadcasting/auth"
                });

                //TEMP
                window.user.date_format = "M d";
                window.user.time_format = "HH:mm";

                this.setupDefaultPusherChannels(this.displayPaymentNotification);
                await this.checkFailedPayments();
            }

            this.getLayouts();

            if(!this.displayTC && this.componentAfterRefresh === 'videos') {
                this.setComponentAfterRefresh('');
            }

            let nextComp = this.componentAfterRefresh ? Constants.COMPONENTS[this.componentAfterRefresh] : 'home-component';

           
            if(isMobileForDashboards() && nextComp === 'home-component') {
                this.setComponentAfterRefresh('dashboard');
                nextComp = 'dashboard-component';
            }
            
            if(!this.nonTerminalUser && !this.isPopOut && !window.bootUp.remoteAccess) {
                let onBoardingPropFound = false;
                allSettings?.data.find(el => {
                    if(el.slug === "welcome-dismissed")
                        this.activateWelcomePopup(el);
                    else if(el.slug === "onboard-steps") {
                        onBoardingPropFound = true;
                        const state = JSON.parse(el.json);
                        const step = parseInt(state?.step);


                        if(step && step > 0) {
                            // state.step = "3";
                            state.skippable = true;
                            this.loggedOut = true;
                            this.continueOnboardingState = state;
                            if(this.$router.currentRoute.path !== '/start-here')
                                this.$router.push('/start-here');

                        }else{
                            // if(clearStores) {
                                this.$router.replace("/");
                            // }
                            
                        }
                    }

                    if(!onBoardingPropFound /*&& clearStores*/) {
                        this.$router.replace("/");
                    }
                        
                });

                if(isOnboard) { 
                    if(this.promoID && this.promoID !== Constants.MTA_FREE_ROUTE) {
                        this.loggedOut = false;
                        this.redirectToCheckout();

                    }else{
                        this.continueOnboardingState = { step: 3 };
                        this.loggedOut = true;
                    }
                    
                    
                    if(this.$router.currentRoute.path !== '/start-here')
                        this.$router.push('/start-here');
                }
                // else if(clearStores) {
                //     nextComp = "home-component";

                //     if(isMobileForDashboards())
                //         nextComp = 'dashboard-component';
                // }
  
            }
            // else if(this.isPopOut || this.nonTerminalUser) {
            //     nextComp = "dashboard-component";
            // }
            // else {
            //     console.log("fall back");
            //     this.componentToBeRendered = "home-component";
            // }

            if(!window.bootUp.nonTerminalUser && isMobileForDashboards()) {
                const mobiles = _.orderBy(Object.values(this.layouts).filter(el => `${el.id}`.indexOf("mobile_finso") > -1 ), dashboard => dashboard.orderBy, ['desc']);
                const key = mobiles.length ? mobiles[0].id : -1;
                if(key && key !== -1) {
                    this.setActiveDashboard({ 
                        target: "dashboard",
                        instanceID: this.$root.instanceID,
                        id: key
                    });
                    // this.setActiveDashboard({ 
                    //     target: "video",
                    //     instanceID: this.$root.instanceID,
                    //     id: key
                    // });
                }
                    
            }

            // if(this.isPopOut)
            //     this.componentToBeRendered = 'dashboard-component';

            if(this.specialLink === '/showcaselogin') {
                 this.setComponentAfterRefresh('home');
                nextComp = "home-component";
                // localStorage.removeItem("specialLink");
            }

            this.componentToBeRendered = nextComp;

            const startProcessing = new CustomEvent('start-processing');
            document.dispatchEvent(startProcessing);

            if(this.promoID && this.promoID !== Constants.MTA_FREE_ROUTE) {
                this.redirectToCheckout();
            }


            this.handleRibbon();


        },


        verifyPaymentSettingsValidity: function () {
            // console.log("this.isCurrentCardValid, this.activeSubscriptions", this.isCurrentCardValid, this.activeSubscriptions);
            if (!this.isCurrentCardValid) {
                return this.userHasPurchasedProducts;
            }
            return false;
        },
        checkCurrentUserPaymentSettings: function () {
            let cardUpdateRequired = this.verifyPaymentSettingsValidity();
            if (cardUpdateRequired) {
                this.displayCardUpdateNotification();
            } else if (this.isCurrentCardExpiring) {
                this.displayCardExpirationNotification();
            }
        },

        redirectToCheckout() {
            const promo = Object.values(this.availableProducts).find(el => el.id === this.promoID);
            let notFound = true;
            
            if(promo.product_type === 'plan') {
                const activeID = this.activePlan.product.parent_id ? this.activePlan.product.parent_id : this.activePlan.product.id;
                if(activeID === promo.id)
                    notFound = false;

            }
            else
                notFound = promo ? this.promotionalProducts.find(el => el.id === promo.id) : true;

            if(!notFound) {
                Swal.fire({
                    title: 'Notice',
                    type: 'info',
                    text: 'You have already purchased this promotion.',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3B9C83',
                    confirmButtonText: 'OK'
                }).then(() => {});
            }
            if(promo && notFound) {
                if(promo.product_type === 'plan') {
                        eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: "checkout",
                        details: {
                            type: "plan",
                            selectedItem: this.mergedPlans.find(el => el.id === this.promoID),
                            isUpgrading: this.isUpgrading
                        }
                    });
                }
                else {
                    
                    TrackingService.sendTransaction(this.activePlan, "purchased_");
                    
                    eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: "checkout",
                        details: {
                            type: "promotion",
                            selectedItem: promo
                        }
                    });
                }
            }
        },

        displayCardUpdateNotification: function () {
            if(isMobileForDashboards())
                return;

            Swal.fire({
                title: 'No payment method!',
                type: 'warning',
                text: 'Please add a card now to ensure your product access continues uninterrupted.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Update card details!'
            }).then((result) => {
                if (result.value) {
                    this.showModal(PaymentCardModal);
                }
            });
        },
        displayPaymentNotification: function (product) {
            if(isMobileForDashboards())
                return;

            Swal.fire({
                title: 'Action Required!',
                type: 'warning',
                text: 'Your recent payment failed. To continue using this product, please update your payment details now.',
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Update!'
            }).then((result) => {
                if (result.value) {
                    if(!this.userHasPurchasedProducts) {
                        this.removePaymentMethod().then(() => {});
                    }
                    this.openPaymentSettings(product.invoice_id);
                }
            });
        },
        checkFailedPayments: async function () {
            this.loadPaymentHistoryPromise().then(() => {
                // console.log("checkFailedPayments")
                let currentDate = new Date().getTime();
                let selectedInvoices = [];
                // console.log(this.paymentHistory)
                this.paymentHistory.forEach((payment) => {
                    if(payment.paid_status !== 'full' &&  currentDate - new Date(payment.created_at) <= PAYMENT_LIMIT) {
                        selectedInvoices.push(payment.invoice_id);
                    }
                    // console.log(currentDate - new Date(payment.created_at) <= PAYMENT_LIMIT)
                })

                if(isMobileForDashboards())
                    return;

                // console.log("selectedInvoices.length > 0 && !this.isCurrentCardValid", selectedInvoices.length > 0 && !this.isCurrentCardValid)

                if(selectedInvoices.length > 0 && !this.isCurrentCardValid) {
                    Swal.fire({
                        title: 'Action Required!',
                        type: 'warning',
                        text: 'To access your product you need to add a card and make payment.?',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCloseButton: false,
                        cancelButtonColor: '#1d9deb',
                        confirmButtonColor: '#ff5656',
                        confirmButtonText: 'Make payment'
                    }).then((result) => {
                        if (result.value) {
                            eventBus.$emit('close-modal');
                            this.$modal.hideAll();
                            setTimeout(() => {
                                eventBus.$emit('open-modal', {
                                    title: "Account Settings",
                                    componentInModal: "account-settings",
                                    mode: "wide",
                                    panel: {
                                        tab: 'extra',
                                        selectedInvoices: selectedInvoices
                                    }
                                });
                            }, 300)

                        }
                    });
                }
            });

        },
        displayCardExpirationNotification: function () {
            if(isMobileForDashboards())
                return;

            Swal.fire({
                title: 'Your Card is about to expire!',
                type: 'warning',
                text: 'Please update your card before ' + this.toSystemDate(this.paymentMethod.valid_until) + ' to ensure your product access continues uninterrupted.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Update card details!'
            }).then((result) => {
                if (result.value) {
                    this.showModal(PaymentCardModal);
                }
            });
        },



        openPaymentSettings: function (invoiceId) {
            eventBus.$emit('close-modal');
            this.$modal.hideAll();
            setTimeout(() => {
                eventBus.$emit('open-modal', {
                    title: "Account Settings",
                    componentInModal: "account-settings",
                    mode: "wide",
                    panel: {
                        tab: 'extra',
                        selectedInvoices: [invoiceId]
                    }
                });
            }, 300)

        },
        handlePayment: function (product) {
            let action;
            if (product.product_type === "plan") {
                action = this.purchasePlan(product);
            } else {
                action = this.purchaseAddon(product);
            }
            action.then(() => {
                this.hideModal("PaymentCardModal");
            }).catch(() => {
                Swal.fire({
                    title: 'Payment Failed!',
                    type: 'warning',
                    text: 'Please add a new payment card or contact your bank to process this payment',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Ok!'
                })
            })
        },
        logout() {
            if(window.bootUp.remoteAccess) {
                window.bootUp.remoteAccess = false;
                this.autoLogin = false;
            }
            localStorage.removeItem("remoteAccess");
            this.remoteAccess = false;

            this.closeWindowPopouts();

            this.continueOnboardingState = {};

            if(window.Echo)
                window.Echo.disconnect();

            this.clearPusherChannels();
            window.bootUp.isExploreMode = false;

            localStorage.removeItem('token');
            localStorage.removeItem('adfx');
            localStorage.removeItem('adt');
            this.resetDashboards();
            this.destroyUserStoreData();
            this.setActiveDashboard({});
            this.setWatchHistory({});
            this.setDbReady(false);
            this.setLastTrainingCentreOrderUpdated({});
            this.setComponentAfterRefresh('');
            workerTimers.clearInterval(window.bootUp.cacheBlocker);
            clearInterval(window.bootUp.headlineFetchJob);
            window.bootUp.headlineFetchJob = null;
            window.bootUp.cacheBlocker = null;
            this.shutDownIntercom();
            this.clearStoreValues();
            this.$store.dispatch("headlines/setRareHeadlinesFetched", false);

            localStorage.removeItem("promo");
            this.promoID = null;
            localStorage.removeItem("specialLink");
            this.specialLink = '';

            if(window.webrtc)
                window.webrtc.stop_audio();

            window.bootUp.iDB.deleteDB();
            delete window.user;
            this.loggedOut = true;
            this.$router.push("/login");
        },
        clearStoreValues() {
            this.$store.dispatch("general/clearStoreValues");
        },
        // getUserSettings() {
        //     // console.log('getting now user settings...');
        //     this.setAnalChatActiveOn(null);
        //     return talkToServer('/setting/user', 'GET', null, null, true).then(response => {
        //         console.log("All user settings", response);
        //         const analChat = response.data.find(item => item.slug === "analyst-chat");

        //         if(analChat) {
        //             this.setAnalChatActiveOn((JSON.parse(analChat.json)).dashboardID);
        //         }

        //         return;

        //     });
        // },
        async setTimezoneOnFirstLogin() {
            const userObj = _.cloneDeep(this.user);
            userObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // console.log(userObj);

            await accountService.saveUserAccountSettings(userObj).then(result => {
                    if(result.data.success) {
                        return this.loadCurrentUserSettings().then(() => {});

                    }
            })
            .catch(() => {
                // const errMessages = [];
                // Object.values(error?.response?.data?.data || {}).forEach(message => errMessages.push(message[0]));
                Swal.fire({
                    title: 'Error',
                    type: 'danger',
                    text: 'Could not set default timezone automatically, please set it manually in your Account Settings.',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
            });

            // console.log("after", this.user);
        },
        initializeVersionId: function () {
            AppService.getVersionId().then(result => {
                this.setVersionId(result.data);
                this.versionChanged = false;
                this.checkVersion();
            })
        },
        checkVersion: function () {
            let handler = () => AppService.getVersionId().then(result => {
                if(result.data !== this.versionId && this.versionChanged !== true) {
                    this.versionChanged = true;
                    let message = 'There is a new version of the application (#' + result.data + '). </br>Click here to load the new version!';
                    this.$toast.info(message, {
                        duration: 0,
                        onClick: () => {
                            window.location.reload();
                        }
                    });
                }
            })
            setInterval(handler, 300000);
        },
        getLayouts() {
            // console.log("get layouts");
            // let context = this;
            const instanceID = this.$root.instanceID;
            // if(!window.bootUp.isExploreMode) {

            // console.log('layouts', instanceID);
            if(!this.layouts[instanceID] && window.bootUp.nonTerminalUser) {
                this.autoCreateBrokerDashboards();
            }else{
                const layouts = _.cloneDeep(this.layouts);
                // console.log("layout", this.layouts);
                let currentActiveDashboard = layouts[this.activeDashboard];
                let currentActiveVideoPage = layouts[this.activeVideoPage];

                let dId;
                let vId;
                if(currentActiveDashboard)
                    dId = currentActiveDashboard.id;
                if(currentActiveVideoPage)
                    vId = currentActiveVideoPage.id

                if(this.activeDashboard < 0 || !currentActiveDashboard || !this.activeVideoPage || !currentActiveVideoPage) {

                    const dashboards = Object.keys(layouts).map(key => layouts[key]);
                    // let firstKey = dashboards.find(el => !isNaN(el.id));

                    let activeDashboard, activeVideoPage;

                    if(isMobileForDashboards() && !window.bootUp.nonTerminalUser) {
                        activeDashboard = null; //dashboards.find(dashboard => dashboard.mobile);
                        activeVideoPage = null;
                    }
                    else {
                        if(this.activeDashboard < 0 || !currentActiveDashboard) {
                            activeDashboard = dashboards.filter(el => !el.vidpage).find(dashboard => {
                                if(dashboard.default)
                                    return dashboard
                                else if(dashboard.broker && instanceID === dashboard.id)
                                    return dashboard
                                // else
                                //     return firstKey;
                            });

                            dId = activeDashboard ? activeDashboard.id : -1;
                        }
                        if(!this.activeVideoPage || !currentActiveVideoPage) {
                            activeVideoPage = dashboards.filter(el => el.vidpage).find(dashboard => {
                                if(dashboard.default)
                                    return dashboard
                                else if(dashboard.broker && instanceID === dashboard.id)
                                    return dashboard
                                // else
                                //     return firstKey;
                            });

                            vId = activeVideoPage ? activeVideoPage.id : -1;

                        }
                    }
                }



                if(this.activeDashboard >= 0 && !this.poppedoutDashboards.includes(this.activeDashboard)) {
                        if(layouts[this.activeDashboard])
                            layouts[this.activeDashboard].tab = true;

                        // console.log("poppedout", Object.values(layouts));
                        this.setSystemDashboards(Object.values(layouts));
                }
                this.setActiveDashboardAndTab({id: dId, instanceID: instanceID});
                this.setActiveDashboard({target: "videopage", id: vId, instanceID: instanceID});
                //     context.layoutFetchDone = true;
            }
        },
        autoCreateBrokerDashboards() {
            // console.log("autocreating...");
            // const targetDiv = this.$root.targetDiv;
            let impID = this.$root.widgetId;

            const nextID = this.$root.instanceID;

            const widget = this.$store.getters["widget/getWidgetByImplementationId"](impID);
            const elements = createDashboardElements(widget, "broker");

            let code = {
                id: nextID,
                name: nextID,
                broker: true,
                elements: elements
            };

            // console.log("code",code);

            this.updateDashboardById(code);
            this.setActiveDashboardAndTab({
                id: code.id,
                instanceID: this.$root.instanceID
            });

            this.layoutFetchDone = true;

        },
        activateWelcomePopup(welcome) {

            const panelObj = {
                "welcome-dismissed": welcome ? JSON.parse(welcome.json) : {}
            }

            // console.log(welcome);

            if(!welcome?.["v3-onboard"]) {
                panelObj.active = this.activePlan;
                panelObj.type = 'v3-onboard';

                 if(panelObj.active !== 'Legacy Plan' || panelObj.active !== 'Pro Plan')
                    return;

            }

            eventBus.$emit('open-modal', {
                title: "Welcome to your new terminal",
                componentInModal: "welcome-popup",
                mode: "",
                panel: {
                    panelObj: panelObj
                }
            });

        },
        onboardSkipped() {
            if(localStorage.getItem('token') && !this.isPopOut) {

                // console.log("rendering comp.")
                const env = getServerEnv();

                this.componentToBeRendered = "home-component";
                
                if(isMobileForDashboards())
                    this.componentToBeRendered = "dashboard-component";

                if(this.promoID === Constants.MTA_FREE_ROUTE) {
                    const mtas = this.currentAddons.filter(el => el.product.parent_id === Constants.MTA_PROMO[env]);
                    if(!mtas.length)
                        this.componentToBeRendered = "video-component";

                    localStorage.removeItem("promo");
                }
                

                if(this.$router.currentRoute.path !== '/') {
                    this.$router.replace("/");
                }
                

                talkToServer('/setting/user/onboard-steps', "POST", null, { step: "-1" }, true)

                this.loggedOut = false;
            }

        },
        handleRibbon() {
            const comp = this.componentToBeRendered;

            const isThereFailedProduct = this.hasRenewFails;
            // console.log("this.hasRenewFails",this.hasRenewFails);

            if(comp === 'home-component') {
                // if((!this.isCurrentCardValid || this.isCurrentCardExpiring) && this.activeSubscriptions)
                if(isThereFailedProduct)
                    this.ribbonType = 'updateCard';
                else if(!this.ribbonType !== 'updateCard')
                    this.ribbonType = 'planUpgrade';

            }else{
                this.ribbonType = '';
            }

        },
        liveStreamStarted(stream) {
            if(!this.isCurrentlyLive(stream.id))
                    this.$toast.error("The " + stream.name + " is live now.<br/>If you have access, you can join via the Training Centre.", {
                    duration: 300000,
                    position: "bottom-left"
                });
        }
    },
    watch: {
        componentToBeRendered () {
            this.handleRibbon();
            eventBus.$emit("ANALCHAT_CHECK");


        },
        hasRenewFails() {
            this.handleRibbon();
        },
        readyForCategoryProcessing(val) {
            // console.log("headline val changed.....");
            if(val) {
                this.$store.dispatch("widget/addLookupCategories");
            }
        },
        availableWidgets: function (nVal, oVal) {
            // console.log("avail", nVal, oVal);
            const changedWidgets = diffArray(nVal, oVal);
            if(changedWidgets.length) {
                changedWidgets.forEach(el => {
                    const impID = +el;
                    const code = this.getCodeByImplementationId(impID);
                    const isHeadlines = code === "HEADLINES" ? true : false;
                    const channel = Constants.PUSHER_CHANNELS[code];

                    if(channel) {
                        if(nVal.includes(el)) {

                            if(isHeadlines) {
                                this.setOrGetAudioTokens();
                                // console.log("headlines");
                                setTimeout(() => this.setSubscribedToSquawk(true));
                            }

                            if(!this.pusherChannels.includes(channel) &&
                            channel !== 'livestream' &&
                            channel !== 'videolibrary'
                            ) {
                                // console.log("added", channel);
                                this.addPusherChannel(channel);
                                this.setupPusherChannels(channel);

                        }



                        }
                        else if(oVal.includes(el)) {
                            // console.log("removed", channel);
                            this.removePusherChannel(channel);
                            this.leavePusherChannels(channel);

                                if(isHeadlines) {
                                    window.webrtc.stop_audio();
                                    this.setSubscribedToSquawk(false);
                                    if(localStorage.getItem("token"))
                                        localStorage.removeItem("playingSquawk");
                                }

                        }
                    }


                });

                eventBus.$emit("ANALCHAT_CHECK");
            }

        },
        activePlan: {
            deep: true,
            handler: function (nVal,oVal) {
                
                if(!oVal && nVal?.product?.is_visible_front_end && !this.isPopOut) {
                    const found = this.allSettings?.data?.find(el => el.slug === "welcome-dismissed");
                    if(!found) {
                        console.log(this.allSettings);
                        this.onboardSkipped();
                    }
                        
                }
                    
            }
        }
    },
    // beforeDestroy() {
        
    // }
}
</script>

<style lang="scss" scoped>
    .platform-background {
        background-color: $onboard-survey-background;
        min-height: 200px;
    }

</style>
