import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
// import { getServerEnv } from '../utility/helper-functions';

const payments = createNamespacedHelpers('payments');

export default {
    computed: {
        ...payments.mapGetters(['sortedWidgetsByCreationDate', 'currentProducts']),

        widgetsByPreferredOrder() {
            const filters = el => {
                // const env = getServerEnv();

                const notTC = el.code !== 'TCSTREAM' &&  el.code !== 'TCVIDEO';
                
                // if(env === 'live')
                //     return notTC;

                let access = true;
                if(!el.planDetails.favorableProduct && !el.planDetails.included)    
                    access = false;
                
                return notTC && access;
                
            }
            return  _.orderBy(this.sortedWidgetsByCreationDate.filter(el => filters(el)), w => w.planDetails.ranking, ['desc']);
        },

    }
}