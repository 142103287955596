export default {
    // haveHeadlines(state) {
    //     return state.haveHeadlines;
    // },
    readyForHeadlineProcessing(_, getters, _2, rootGetters) {

        return getters.readyForCategoryProcessing &&
        rootGetters["lookupProperties/haveLookupProperties"];

    },

    readyForCategoryProcessing(_, _2, _3, rootGetters) {

        return rootGetters["availableCategories/haveAvailableCategories"] &&
        rootGetters["lookupCategories/haveLookupCategories"];
       

    },

    lastHeadlineUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastHeadlineUpdate));
    },

    rareHeadlinesFetched(state) {
        return state.rareHeadlinesFetched;
    }

}