import httpClient from "../../api/HttpClient";
import {createUrl} from "../../utility/helper-functions";
export default {
    getLayouts: function () {
        return httpClient.get(createUrl('/setting/user/layouts'))
    },
    saveLayouts: function (payload) {
        return httpClient.post(createUrl('/setting/user/layouts'), payload);
    }

}
