<template>
    <base-modal title="Purchase Addon" :footer="false" mode="wide"  >
        <div>
            <tabs :cache-lifetime="0" :options="{ useUrlFragment: false}" ref="tabs" >
                <tab name="Video" id="settings">
                    <div class="video-optimizer-div">
                        <div class="responsive-16-9-frame" v-if="widget.video_id">
                            <iframe class="terminal-embed-video" :src="'https://player.vimeo.com/video/' + widget.video_id" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen="">
                            </iframe>
                        </div>
                    </div>
                </tab>
                <tab name="Purchase" id="purchase">
                    <widget-purchase :widget="widget" @widget-purchased="closeModal"></widget-purchase>
                </tab>
            </tabs>
        </div>
    </base-modal>
</template>

<script>
import WidgetPurchase from "./WidgetPurchase";
export default {
    name: "WidgetPurchaseModal",
    components: {WidgetPurchase},
    props: {
        addonProduct: Object,
        widget: Object
    },
    methods: {
        closeModal: function () {
            this.$modal.hide(this.$parent.name);
        }
    }
}
</script>

<style scoped>

</style>
