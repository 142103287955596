export default {
    lastLiveStreamUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastLiveStreamUpdate));
    },

    lastLiveStreamDelete(state) {
        return state.lastLiveStreamDelete;
    },

    currentLiveIDs(state) {
        return state.currentLiveIDs;
    },

    isCurrentlyLive: state => ID => {
        return state.currentLiveIDs.findIndex(el => el === ID) > -1;
    },

    activeReminders(state) {
        return state.activeReminders;
    }
}