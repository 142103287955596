<template>
    <div class="add-widget">
        <div>
            <a href="javascript:void(0)"
            @click="addWidget"
            ><font-awesome-icon :icon="['fas', 'plus']"/></a>
        </div>
        <div>
            <base-button 
            mode="o-dark-grey platform-action"
            @click.native="addWidget"
            >Add indicator</base-button>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main';
export default {
    props: ["item"],
    methods: {
        addWidget() {
            console.log(this.item);
            const modal = {
                instanceID: this.$root.instanceID
            };

           
            modal.componentInModal = "widget-shop";
            modal.classes = "terminal-modal simplified";
            modal.panel = {
                view: "widget-list",
                addingMode: true,
                slot: this.item.slot,
                widgetDirectAddition: true

            };
            modal.mode = "wide";

            eventBus.$emit('open-modal', modal);

        }
    }
}
</script>

<style lang="scss" scoped>
.add-widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $onboard-survey-card-background;
    height: 100%;
    color: $input-color;
    border-radius: 10px;

    & > div {
        margin: 4px 0;
    }

    & a {
        color: inherit;
        font-size: 22px;
    }
}
</style>