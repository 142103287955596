<template>
    <div class="calevent-modal">
        <div>
            <h2>{{ panel.eventName }}</h2>
        </div>
        <div class="data-table economic-calendar desktop">
        <div class="data-table subtable">
            <div class="data-table-title">Info</div>
            <div class="data-table-header">

                <div class="data-table-row">
                    <div class="data-table-row-main" style="padding-right: 5px;">
                        <div class="data-table--cell">Specs</div>
                        <div class="data-table--cell">Details</div>
                    </div>
                </div>

            </div>
            <div class="data-table-body">
                <div class="data-table-row">

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Date</div>
                        <div class="data-table--cell">{{ formattedDate }}</div>
                    </div>
                
                </div>

                <div class="data-table-row">

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Time</div>
                        <div class="data-table--cell">{{ formattedTime }}</div>
                    </div>
                
                </div>

                <div class="data-table-row">

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Currency</div>
                        <div class="data-table--cell">{{ currency }}</div>
                    </div>
                
                </div>

                <div class="data-table-row">

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Impact</div>
                        <div class="data-table--cell"
                        :class="`u-color-${impactClassSpecifier}-importance`"
                        ><font-awesome-icon :icon="['fas', 'circle']" class="u-color-low-importance"/></div>
                    </div>
                
                </div>

                <div class="data-table-row">

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Actual</div>
                        <div class="data-table--cell"
                        :class="actualSemantics"
                        >
                        <!-- <font-awesome-icon :icon="['fas', 'bolt']" size="xs"/>  -->
                        <span v-if="isNext && !actualValue && countdown">{{ countdown }}</span>
                        <span v-if="isNext && !actualValue && countdownFinished">
                            <VueCustomTooltip label="Data delayed at source" position="is-left" class="eco-tooltip-revision">
                                <font-awesome-icon :icon="['fas', 'hourglass-half']" />
                            </VueCustomTooltip>
                        </span>
                        <span v-else>
                            <span v-if="isActualExtreme"
                            class="tooltip"
                            :class="actualSemantics"
                            >
                                <VueCustomTooltip :label="actualExtremeTooltipText" position="is-top" class="eco-tooltip-revision">
                                    <font-awesome-icon :icon="['fas', 'bolt']"/>&nbsp;
                                    <font-awesome-icon :icon="['fa', actualArrow]" /> {{ actualValue }}
                                </VueCustomTooltip>
                            </span>
                            <span v-else-if="actualHigherOrLower"
                            class="tooltip"
                            :class="actualSemantics"
                            >
                                <VueCustomTooltip v-if="actualArrow" :label="actualTooltipText" position="is-top" class="eco-tooltip-revision">
                                    <!-- <font-awesome-icon :icon="['fa', actualArrow]" />  -->
                                    {{ actualValue }}
                                </VueCustomTooltip>
                            </span>
                            <span v-else>
                                {{ actualValue }}
                            </span>
                            
                        </span>
                        </div>
                    </div>
                
                </div>

                <div class="data-table-row"
                v-if="!isHoliday || !isSpeech"
                >

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Forecast</div>
                        <div class="data-table--cell u-color-revision">
                            <span v-if="!forecastArrow">{{ forecastValue }}</span>
                            <span v-else
                            class="tooltip u-color-revision"
                            >
                                <VueCustomTooltip  :label="forecastTooltipText" position="is-left" class="eco-tooltip-revision">
                                    <font-awesome-icon :icon="['fa', forecastArrow]" /> {{ forecastValue }}
                                </VueCustomTooltip>
                            </span>
                        </div>
                    </div>
                
                </div>

                <div class="data-table-row"
                v-if="!isHoliday || !isSpeech"
                >

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Min</div>
                        <div class="data-table--cell u-color-revision">{{ minValue }}</div>
                    </div>
                
                </div>

                <div class="data-table-row"
                v-if="!isHoliday || !isSpeech"
                >

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Max</div>
                        <div class="data-table--cell u-color-revision">{{ maxValue }}</div>
                    </div>
                
                </div>

                <div class="data-table-row"
                v-if="!isHoliday || !isSpeech"
                >

                    <div class="data-table-row-main">
                        <div class="data-table--cell">Previous</div>
                        <div class="data-table--cell">
                            <span v-if="revised">{{ previousValue }}</span>
                            <span v-else
                            class="tooltip"
                            :class="revisedSemantics">
                                <VueCustomTooltip :label="revisedTooltipText" position="is-top" class="eco-tooltip-revision">
                                    <font-awesome-icon :icon="['fa', revisedArrow]" /> {{ previousValue }}
                                </VueCustomTooltip>
                            </span>
                        </div>
                    </div>
                
                </div>

                <!-- <div class="data-table-row">

                    <div class="data-table-row-main">
                        <div class="data-table--cell">FS Notes</div>
                        <div class="data-table--cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ornare massa eget egestas purus viverra. Leo a diam sollicitudin tempor id eu.</div>
                    </div>
                
                </div> -->


            </div>

        <!--  -->
        </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import calEventMixin from '../../../../mixins/calendareventMixin';

const ecocalNS = createNamespacedHelpers('calendar');

export default {
    props: ["panel"],
    mixins: [calEventMixin],
    computed: {
        ...ecocalNS.mapGetters(['lastEcoCalendarUpdate']),

        calEvent() {
            return this.panel.calEvent;
        },
    },
    mounted() {
        this.actualFigure = this.actualValue;
    },
    data() {
        return {
            isNext: this.panel.isNextUpcoming,
            actualFigure: null,
        }
    },
    watch: {
        lastEcoCalendarUpdate(nVal) {
            if(nVal.previous_id === this.calEvent.id &&
            nVal.actual) {
                this.isNext = false;
                this.actualFigure = nVal.actual;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .data-table,
    .data-table .data-table-row-main > .data-table--cell:first-child {
        color: $input-color-filter;
    }
    .data-table .data-table-row-main > .data-table--cell {
   
        overflow: visible;
    }
    .calevent-modal {
        

        padding: 0 20px 20px;

        & h2 {
            @include H2;
        }
    }
    .data-table.economic-calendar.desktop {
        padding: 0;

    }
    .data-table.subtable {
        padding-top: 0;

    }
    .data-table-title {
        display: none;
    }
    .data-table-header {
        background-color: transparent;

        & .data-table-row .data-table--cell:first-child {
            color: $input-color-filter;
            border-right: 2px solid $separator-line-color;
        }

        .data-table-row {
            background-color: transparent;
        }

        & .data-table--cell {
            padding-left: 5px;
        }
    }
    .data-table-row-main {
        border-bottom: none;

        & > .data-table--cell:first-child {
            color: $input-color;
        }
    }
    .data-table--cell:last-child {
        border-right: none;
    }

    .tooltip {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: white;

        &.u-better-than {
            border-bottom-color: $core-lightgreen;
        }
        &.u-worse-than {
            border-bottom-color: $core-lightred;
        }
        &.u-color-revision {
            border-bottom-color: $color-16;
        }

    }

    
</style>