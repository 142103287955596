<template>
    <div class="stream-list" ref="scrollable">
        <div>
            <videos-perday
            v-for="(arr, objKey, index) in sortedItems.videos"
            :key="objKey"
            :index="index"
            :objKey="objKey"
            :videosPerday="arr"
            :noReminder="true"
            :widgetCode="'TCSTREAM'"
            :impID="impID"
            ></videos-perday>
            <div v-if="noMoreItems" class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No more items</div>
        </div>
    </div>
</template>

<script>
import ScrollMixins from '../../../mixins/scrollMixins';
import VideoMixin from '../../../mixins/video';
import VideosPerday from '../../widgets/components/VideoPlayers/VideosPerday.vue'

export default {
    props: ["item", "currentCat"],
    components: {
        VideosPerday
    },
    mixins: [VideoMixin, ScrollMixins],
    created() {
        this.fetchInitialStreams();
    },
    provide() {
        return {
            $activeVideo: () => {}
        }
    },
    mounted() { 
        this.$nextTick(() => {
            this.setupScrollListener();
        });
        this.fetchMode = false;
        
    },
    beforeDestroy() {
        this.removeScrollListener();
    },
    computed: {
        impID() {   
            return this.item.implementation_id;
        },
        list() {
            if(this.currentCat === 'upcomingActive')
                return 'upcomingVideos';

            return 'archiveVideos';
        },
        noMoreItems() {
            return this[this.list].noMoreItems;
        }
    },
    data() {
        return {
            categoriesSent: false,
        }
    },
    methods: {
        onScroll() {
            // if(this.widgetCode === 'TCVIDEO')
            //     return;
                
            const scrollTop = this.$refs.scrollable.scrollTop;
            const height = this.$refs.scrollable.getBoundingClientRect().height;
            const scrollHeight = this.$refs.scrollable.scrollHeight;
            
            if ((scrollTop + height >= scrollHeight - 200) &&
            this[this.list].resultsReady && 
            !this[this.list].noMoreItems) {                 
                // console.log("scroll-down-enough");
                this.loadMore(this.list);
                
            }                
            if(scrollTop > 100) {
                // console.log("scrolled down");
                this.isScrolledDown = true;
            }else{
                this.isScrolledDown = false;
                // console.log("scrolled up");
                // this.$emit("new-video-viewed");
            }
        },
    },
    watch: {
        categories(val) {
            if(val.length && !this.categoriesSent) {
                this.categoriesSent = true;
                this.$emit("cats-ready", this.categories);
                if(!this.categories.find(el => el.id === 'upcomingActive')) {
                    this.upcomingActive = false;
                    this.archiveActive = true;
                }
            }
                
        },
        currentCat(nVal, oVal) {
            this[nVal] = true;
            this[oVal] = false;
        }
    }
}
</script>

<style lang="scss">
    .stream-list {
        overflow: auto;
        @include scrollbarDark;

        & .video-button {
            display: none;
        }
    }
</style>