export default {
    setCurrentProducts(state, payload) {
        state.currentProducts = payload;
    },
    setScheduledProducts(state, payload) {
        state.scheduledProducts = payload;
    },
    setPaymentMethod(state, payload) {
        state.paymentMethod = payload;
    },
    setPaymentHistory(state, payload) {
        state.paymentHistory = payload;
    },
    setAvailableProducts(state, payload) {
        state.availableProducts = payload;
    },
    setWidgetProducts(state, payload) {
        state.widgetProducts = payload;
    },
    destroyUserStoreData(state) {
        state.currentProducts = [];
        state.scheduledProducts = [];
        state.availableProducts = [];
        state.widgetProducts = [];
        state.paymentMethod = {};
    },
    setFailedPayment(state, payload) {
        state.failedPayment = payload;
    },
    setMakingPayment(state, payload) {
        state.makingPayment = payload;
    },
    setHistoricProductAccess(state, payload) {
        state.historicProductAccess = payload;
    }
}
