import Vue from 'vue';
import App from './App.vue';
import { store } from './store/store.js';

import VueFormGenerator from 'vue-form-generator';
import Resize from "vue-element-resize-detector";
import VueSlideUpDown from 'vue-slide-up-down';

import { BroadcastChannel } from 'broadcast-channel';

import Cleave from 'cleave.js';

import "@fontsource/roboto";
import "@fontsource/roboto/500.css"
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins";

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';

import VueSimpleAlert from "vue-simple-alert";

import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

// import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import 'animate.css';
import router from './router/router.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faPlus,
	faThLarge,
	faCog,
	faTimes,
	faWindowRestore,
	faPencilAlt,
	faTrashAlt,
	faChevronLeft,
	faChevronRight,
	faExclamationTriangle,
	faAngleDoubleUp,
	faLock,
	faLockOpen,
	faArrowsAlt,
	faFile,
	faSquare,
	faComment,
	faMinusCircle,
	faSearch,
	faTimesCircle,
	faChevronDown,
	faBook,
	faComments,
	faBug,
	faExpandAlt,
	faStar,
	faCopy,
	faVolumeUp,
	faVolumeMute,
	faVolumeDown,
	faMusic,
	faQuestionCircle,
	faPlusCircle,
	faDotCircle,
	faArrowAltCircleUp,
	faTable,
	faCircle,
	faLongArrowAltDown,
	faLongArrowAltUp,
	faBolt,
	faArrowCircleRight,
	faCalendarAlt,
	faFilePdf,
	faCreditCard,
	faThumbsDown,
	faThumbsUp,
	faBox,
	faPlusSquare,
	faMinusSquare,
	faWindowMaximize,
	faExpandArrowsAlt,
	faCompressArrowsAlt,
	faCopyright,
	faBell,
	faEnvelopeOpen,
	faEnvelope,
	faCertificate,
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
	faArrowCircleUp,
	faChevronCircleUp,
	faSpinner,
	faShoppingCart,
	faEye,
	faCheck,
	faRedo,
	faEdit,
	faWindowClose,
	faHeadphones,
	faFileImage,
	faHourglassHalf,
	faInfoCircle,
	faInfo,
	faCheckCircle,
	faFilter,
	faUser,
	faCircleNotch,
	faHome,
	faTachometerAlt,
	faFlag,
	faUserCircle,
	faEllipsisV,
	faArrowRight,
	faArrowLeft,
	faExchangeAlt,
	faClock,
	faChevronUp,
	faPlayCircle,
	faSortAmountDownAlt,
	faSortAmountUp,

	faWallet,
	faFastForward,
	faDivide,
	faStore,
	faFolderOpen,
	faFolder,
	faChartArea,
	faChartLine, faBullhorn, faFileUpload, faImage, faArrowUp, faArrowDown, faPaperPlane, faPeopleCarry, faTv, faPause, faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import {
	faCcAmex,
	faCcDinersClub,
	faCcDiscover,
	faCcJcb,
	faCcMastercard, faCcPaypal, faCcStripe,
	faCcVisa,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import BaseModal from './components/ui/BaseModal';
import BaseInput from './components/ui/forms/BaseInput';
import BaseButton from './components/ui/forms/BaseButton';
import SwitchSlider from './components/ui/forms/SwitchSlider';
import {findComponentToBeRenderedByCode, getParameterByName, getServerEnv, isValidPromo, talkToServer} from './utility/helper-functions';
import VModal from 'vue-js-modal';
import VueSlimScroll from 'vue-slimscroll';
import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(VueSlimScroll)



// Vue.use(VueSlimScroll);
import VueTour from 'vue-tour'

// Vue.component('vue-phone-number-input', VuePhoneNumberInput);

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
import SharedMixins from "./mixins/SharedMixins";
import AplicationTourMixin from "./mixins/application.tour";
import indexedDB from './data/indexedDB';

import vueVimeoPlayer from 'vue-vimeo-player';
Vue.use(vueVimeoPlayer);


// import "@coreui/coreui/scss/coreui.scss";
// import CoreuiVue from '@coreui/vue';
// Vue.use(CoreuiVue);
const VueScrollTo = require('vue-scrollto');

library.add([faPlus, faThLarge, faCog, faChevronLeft, faChevronRight, faTimes, faWindowRestore, faPencilAlt, faTrashAlt, faExclamationTriangle, faAngleDoubleUp, faLock, faLockOpen, faArrowsAlt, faFile, faSquare, faComment, faMinusCircle, faSearch, faTimesCircle, faChevronDown, faChevronUp, faBook, faComments, faBug, faExpandAlt, faStar, faCopy, faVolumeUp, faVolumeMute, faVolumeDown, faMusic, faQuestionCircle, faPlusCircle, faMinusCircle, faDotCircle, faArrowAltCircleUp, faTable, faCircle, faLongArrowAltDown, faLongArrowAltUp, faBolt, faArrowCircleRight, faCalendarAlt, faFilePdf, faCreditCard, faThumbsDown, faThumbsUp, faBox, faPlusSquare, faMinusSquare, faWindowMaximize, faExpandArrowsAlt, faCompressArrowsAlt, faCopyright, faBell, faEnvelopeOpen, faEnvelope, faCertificate, faArrowAltCircleLeft, faArrowAltCircleRight, faArrowCircleUp, faChevronCircleUp, faSpinner, faTwitter, faShoppingCart, faEye, faCheck, faRedo, faEdit, faWindowClose, faHeadphones, faFileImage, faHourglassHalf, faInfoCircle, faInfo, faCheckCircle, faFilter, faUser, faCircleNotch, faHome, faTachometerAlt, faFlag, faUserCircle, faEllipsisV, faArrowRight, faArrowLeft, faArrowUp,faArrowDown, faExchangeAlt, faClock, faPlayCircle, faWallet, faFastForward, faDivide, faStore, faSortAmountDownAlt, faSortAmountUp, faFolderOpen, faFolder, faChartArea, faChartLine, faBullhorn, faCcVisa, faCcAmex, faCcDinersClub, faCcDiscover, faCcJcb, faCcMastercard, faCcPaypal, faCcStripe, faFileUpload, faImage, faPaperPlane, faPeopleCarry, faTv, faPause, faEye, faEyeSlash, faArrowsAlt]);

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
});

Vue.directive('cleave', {
    inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
    },
    update: (el) => {
        const event = new Event('input', {bubbles: true});
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100);
    }
});
import {Tabs, Tab} from 'vue-tabs-component';

Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);

// Vue.component('vue-form-generator', VueFormGenerator);
Vue.component('vue-slide-up-down', VueSlideUpDown);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('base-modal', BaseModal);
Vue.component('base-input', BaseInput);
Vue.component('base-button', BaseButton);
Vue.component('switch-slider', SwitchSlider);
Vue.component("vue-pdf-app", VuePdfApp);
Vue.use(VModal, {dialog: true, dynamic: true, injectModalsContainer: true});
Vue.use(VueScrollTo);
Vue.mixin(SharedMixins);
Vue.mixin(AplicationTourMixin);
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(VueToast);
Vue.use(VueFormGenerator, {
	validators: {
		strongPassword: (value) => {
			let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/;

			if (!regex.test(value)) {
				return [ 'Password should be a minimum five characters, at least one uppercase letter, ' +
				'one lowercase letter and one number'];
			} else {
				return [];
			}
		},
		confirmPasswordValidator: (value, model) => {
			let password = model.password;
			let confirmPassword = value;
			if (password !== confirmPassword) {
				return ["Passwords don't match."];
			}else{
				return [];
			}
		},
		atLeastOneValidator: (value) => {
			if(!value.length)
				return ["At least one option must be selected."];
			else
				return [];
		}
	}
});
import { Row, Column, Hidden } from 'vue-grid-responsive';

Vue.component('row', Row);
Vue.component('column', Column);
Vue.component('hidden', Hidden);
import VuePlaceAutocomplete from 'vue-place-autocomplete';
import _ from "lodash";

Vue.use(VuePlaceAutocomplete);
Vue.use(VueCustomTooltip, {
	color: '#000',
    background: '#fff',
	multiline: true,
	size: "is-small"

});

Vue.use(Resize);
Vue.use(VueSimpleAlert);

import Constants from './utility/constants';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

Vue.use(StripePlugin, {
	pk: Constants["STRIPE_API_KEY"]
});
Vue.config.productionTip = false;

window.bootUp = {
	//do not change domain, build process switches between dev and live 
	domain: 'v3server-live.finsowidgets.com', 
	nonTerminalUser: false,
	firstCallMade: false,
	componentToBeRendered: null,
	refno: null,
	cacheParams: '?cache=y&language=en',
	cacheBlockerIntervall: 15,
	cacheBlocker: null,
	grid: 128,
	widgets: null,
	isExploreMode: false,
	iDB: indexedDB,
	entity : null,
	bID: null,
	headlineFetchJob: null,
	remoteAccess: false,
	promo: null,
	products: null,
	// specialCheckoutProductIDs: {
	// 	dev: [106],
	// 	live: [80]
	// },
	specialPaths: ["/showcaselogin"]
};
if(getParameterByName("setdomain") && window.bootUp.domain.indexOf('dev') > -1)
	window.bootUp.domain = getParameterByName("setdomain");

const environment = getServerEnv();

window.bootUp.products = {
	dev: [19, 74, 106, Constants.MTA_PROMO[environment], Constants.MTA_FREE_ROUTE],
	live: [19, 74, 80, Constants.MTA_PROMO[environment], Constants.MTA_FREE_ROUTE] //
};

export const tabCommSender = new BroadcastChannel('multi_tab');
export const tabCommReceiver = new BroadcastChannel('multi_tab');

export const eventBus = new Vue({
	data: {
		isReady: true
	}
});

export const widgetQueueTimeout = null;
export const widgetQueue = [];
let widgetQueueCheck = null;

document.addEventListener('start-processing', () => {
	const index = widgetQueue.findIndex(wdg => !wdg.started);

	if(index > 0)
		window.bootUp.firstCallMade = true;

	if(index > -1) {
		widgetQueue[index].started = true;
		processQueue(widgetQueue[index]);
	}

});

const initApp = widget => {
	const { wdgt, div, refno } = widget;

	let widgetComponentKey = "";
	if(window.bootUp.widgets) {
		let selectedWidget = (window.bootUp.widgets.filter(entry => entry.implementation_id === wdgt)?.[0]);
		if(selectedWidget) {
			widgetComponentKey = findComponentToBeRenderedByCode(selectedWidget.code);
			window.bootUp.componentToBeRendered = widgetComponentKey;
		}
	}

	new Vue({
		data: {
			refno: refno,
			targetDiv: div,
			instanceID: div.replace("#",'') + '_' + wdgt,
			widget: widgetComponentKey,
			widgetId: wdgt,
			height: document.querySelector(div).getBoundingClientRect().height
		},
		router,
		store,
		render: h => h(App),
	}).$mount(div);
}

const processQueue = (widget) => {
	const { div, refno, data } = widget;

	if(!window.bootUp.firstCallMade) {
		talkToServer(`/entity/${refno}`, 'GET', null, null).then(response => {
			console.log('entity level', response);
			if(response && response.success && response.data) {
				window.bootUp.entity = response.data.entity;
				window.bootUp.countries = response.data.countries;
				const settings = response.data.entity.settings;
				window.bootUp.refno = refno;
				window.bootUp.canRegisterUsersViaApi = settings['can-register-users-via-api'] ? settings['can-register-users-via-api'].value : false;
				window.bootUp.nonTerminalUser = settings['can-allow-non-users-access-via-api'] ? settings['can-allow-non-users-access-via-api'].value : false;

				if(getParameterByName('ara') && getParameterByName('t')) {
					localStorage.removeItem("token");
					window.bootUp.remoteAccess = true;
				}

				localStorage.removeItem("specialLink");
				const index = window.bootUp.specialPaths.findIndex(el => el === location.pathname);
				if(index > -1)
					localStorage.setItem("specialLink", window.bootUp.specialPaths[index]);

				const promo = +getParameterByName('promo') || +localStorage.getItem("promo");
				
				// SPECIAL PROMO
				if(isValidPromo(promo)) {
					localStorage.setItem('promo', promo);
					if(+promo === Constants.MTA_FREE_ROUTE) {
						window.bootUp.promo = {
							name: "Macro Trading Accelerator",
							description: "Access our free indicators & training inside the terminal immediately",
							product_type: "option"
						}
					}
					else {

						const found = Object.values(response.data.shop).find(el => el.id === +promo);
						window.bootUp.promo = found;
						
						if(found && found.promotion_ends_at) {
							
							const now = dayjs.utc().format('YYYY-MM-DD HH:mm:ss');
							let deadline = dayjs.utc(found.promotion_ends_at.replace('T', ' ').replace('.000000Z','')).format('YYYY-MM-DD HH:mm:ss');
							if(now > deadline) {
								localStorage.removeItem('promo', promo);
								Swal.fire({
									title: 'Expired!',
									type: 'warning',
									text: 'This promotion has expired.',
									allowOutsideClick: false,
									showCancelButton: false,
									confirmButtonColor: '#ff5656',
									confirmButtonText: 'OK'
								}).then(() => {});
							}
						}else if(found && found.product_type !== 'plan'){
							localStorage.removeItem('promo', promo);
							window.bootUp.promo = null;
						}
					}	

				}
				// else{
				// 	localStorage.removeItem('promo', promo);
				// 	window.bootUp.promo = null;
				// }
				// SPECIAL PROMO ENDS

				//TEMP
				let appState = localStorage.getItem("appState");

				if(!appState && window.bootUp.nonTerminalUser || appState === "terminalUser" && window.bootUp.nonTerminalUser) {
					localStorage.setItem("appState", "nonTerminalUser");
					localStorage.removeItem("token");
				}else if(!appState &&!window.bootUp.nonTerminalUser || appState === "nonTerminalUser" && !window.bootUp.nonTerminalUser) {
					localStorage.setItem("appState", "terminalUser");
					localStorage.removeItem("token");
				}
				//TEMP ENDS

				if(window.bootUp.nonTerminalUser) {
					window.bootUp.user = data.user;
				}

				// window.bootUp.componentToBeRendered = wdgt;

				window.bootUp.widgets = _.cloneDeep(response.data.widgets);

				initApp(widget);
			}else{
				console.log('not auth',response);
				document.querySelector(div).innerHTML = response.error;
			}

		});
	}else{
		initApp(widget);
	}
};


export const startWidget = (wdgt, div, refno, data) => {

	clearTimeout(widgetQueueCheck);

	widgetQueue.push({
		wdgt,
		div,
		refno,
		data,
		started: false
	});

	widgetQueueCheck = setTimeout(() => {
		const startProcessing = new CustomEvent('start-processing');
        document.dispatchEvent(startProcessing);
	}, 100);


};



(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tplvkcfy';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();


//can register: c529d2b8-c784-4179-984f-7e92dabee6e1
startWidget(3, "#app", "c529d2b8-c784-4179-984f-7e92dabee6e1", {});

// cannot register: 211f12a8-eafb-4e92-a9e5-e799c7f30e7e
// startWidget(62, "#app", "059a92b6-0ee2-4e14-8057-97c07025e77e", {
// 	user: {
// 		userref: '57687565364645',
// 		name: 'Peter Bln',
// 		email: 'peter@petercoding.xyz',
// 		timezone: 'Europe/London'
// 	}
// });

// startWidget(59, "#app2", "059a92b6-0ee2-4e14-8057-97c07025e77e", {
// 	user: {
// 		userref: '57687565364645',
// 		name: 'Peter Bln',
// 		email: 'peter@petercoding.xyz',
// 		timezone: 'Europe/London'
// 	}
// });

// startWidget(62, "#app3", "059a92b6-0ee2-4e14-8057-97c07025e77e", {
// 	user: {
// 		userref: '57687565364645',
// 		name: 'Peter Bln',
// 		email: 'peter@petercoding.xyz',
// 		timezone: 'Europe/London'
// 	}
// });




// const ch = 21;

// startWidget(59, "#app3", "059a92b6-0ee2-4e14-8057-97c07025e77e", {
// 	user: {
// 		userref: '5271274645100' + ch,
// 		name: 'Peter Bln',
// 		email: 'ppias@petercoding' + ch + '.xyz',
// 		timezone: 'Europe/London'
// 	}
// });


// startWidget(62, "#app", "059a92b6-0ee2-4e14-8057-97c07025e77e", {
// 	user: {
// 		userref: '5271274645100' + ch,
// 		name: 'Peter Bln',
// 		email: 'ppias@petercoding' + ch + '.xyz',
// 		timezone: 'Europe/London'
// 	}
// });

// startWidget(63, "#app2", "059a92b6-0ee2-4e14-8057-97c07025e77e", {
// 	user: {
// 		userref: '5271274645100' + ch,
// 		name: 'Peter Bln',
// 		email: 'ppias@petercoding' + ch + '.xyz',
// 		timezone: 'Europe/London'
// 	}
// });








