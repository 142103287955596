import {eventBus, tabCommSender, widgetQueue, widgetQueueTimeout} from '../../main';
import {arrayIntersect, createDashboardElements, getParameterByName, getServerEnv, isMobileForDashboards} from '../../utility/helper-functions';
import _ from "lodash";
import DashboardService from "../../services/dashboard/dashboard.service.js"
import TrackingService from '../../services/tracking/tracking.service';
import { usersTime } from '../../utility/datetime';
import Constants from '../../utility/constants';

export default {
    setActiveDashboard(context, payload) {
        let activeObj = {}
        
        if(!_.isEmpty(payload)) {
            const { id, instanceID, target } = payload;

            if(_.isObject(context.state.activeDashboard))
                activeObj = _.cloneDeep(context.state.activeDashboard);
            
            // console.log("activeObj 1", JSON.parse(JSON.stringify(activeObj)));
            ///Legacy
            if(typeof(activeObj[instanceID]) !== 'object') 
                activeObj[instanceID] = {};
            ///ENDS Legacy
            // console.log("activeObj 2", JSON.parse(JSON.stringify(activeObj)));

            if(!id) {
                activeObj[instanceID][target] = -1;
                
            }else{
                activeObj[instanceID][target] = id;
            }

            
        }
        // console.log("activeObj 3", JSON.parse(JSON.stringify(activeObj)));

        // console.log("action", activeObj);

        context.commit('setActiveDashboard', activeObj);

        if(context.rootGetters["general/componentAfterRefresh"] === 'dashboard' ) {
            const found = context.getters["dashboards"].find(el => el.id === payload.id);
            const obj = {};
            if(found)
                obj.panel_layout = found.panelLayoutID;

            TrackingService.sendEvent("opened_dashboard", obj);
        }
            
    },
    setFullScreenId({commit}, payload) {
        commit('setFullScreenId', payload);
    },
    setActiveDashboardAndTab({dispatch}, payload) {
        payload.target = "dashboard";
        dispatch('setActiveDashboard', payload);
        if(!window.bootUp.nonTerminalUser && !isMobileForDashboards())
            dispatch('setDashboardAsTab', payload.id);
    },
    setDashboardAsTab({state, dispatch, getters}, payload) {
        const dashboard = _.cloneDeep(state.layouts.layouts[payload]);
        if (dashboard) {
            if (!dashboard.tab) {
                dashboard.precedence = getters.tabbedDashboards?.[0]?.precedence - 1 || 100;
            }
            dashboard.tab = true;
            dispatch('updateDashboardById', dashboard);
        }
    },
    setDashboards(context, payload) {
        let dashboards = payload;
        dashboards = JSON.parse(dashboards);
        // dashboards = dashboards.filter(element => !element.window.closed);
        //open these windows
        // context.commit('setDashboards', dashboards);
        // "/?popout=y&dashboard=" + this.dashboardId, `dashboard-${this.dashboardId}`,"target=_blank,toolbar=yes,scrollbars=no,resizable=yes,top=100,left=100,width=920,height=768"

        dashboards.forEach(element => {
            const id = parseInt(element.replace('dashboard-', ''));
            let newWindow = window.open("", element, "target=_blank,toolbar=yes,scrollbars=no,resizable=yes,top=100,left=100,width=920,height=768");
            if (newWindow.location.href === "about:blank") {
                newWindow = window.open("/?popout=y&dashboard=" + id, element, "target=_blank,toolbar=yes,scrollbars=no,resizable=yes,top=100,left=100,width=920,height=768");

                const popout = {};

                popout.id = id;
                popout.name = element;
                popout.window = newWindow;

                // console.log(popout);

                context.dispatch('addDashboard', popout);
            } else {
                // We've already obtained the reference.
                // However, IE and FireFox won't put focus on an already opened window.
                // So we have to do that explicitly:
                newWindow.focus();
                const popout = {};

                popout.id = id;
                popout.name = element;
                popout.window = newWindow;

                context.dispatch('addDashboard', popout);
            }


        });

        if (dashboards.length > 0 && !getParameterByName('popout')) {
            eventBus.$emit('remove-any-active-board');
            // setInterval(() => {
            //     const poppedOut = context.getters.poppedoutDashboards;
            //     const popouts = poppedOut.map(element => element.name);
            //     localStorage.setItem('popouts', JSON.stringify(popouts));
            // }, 1000);
        }
    },
    addDashboard(context, payload) {
        const dashboards = context.state.poppedoutDashboards.filter(element => element.id !== payload.id);
        dashboards.push(payload);
        context.commit('addDashboard', dashboards);

        const names = dashboards.map(element => element.name);
        localStorage.setItem('popouts', JSON.stringify(names));
    },
    removeDashboard(context, payload) {
        const dashboards = context.state.poppedoutDashboards.filter(element => element.id !== payload);
        context.commit('removeDashboard', dashboards);
        const names = dashboards.map(element => element.name);
        localStorage.setItem('popouts', JSON.stringify(names));
    },
    deleteDashboardById(context, payload) {
        context.commit("deleteDashboardById", payload.id);

        if (payload.id === context.state.activeDashboard[payload.instanceID]) {
            context.dispatch("setNextActiveDashboardId", payload);
        }
        context.dispatch("persistSystemLayouts");
    },
    setNextActiveDashboardId(context, payload) {
        if (context.getters.tabbedDashboards.length > 0) {
            console.log("here in next active db id");
            context.dispatch("setActiveDashboardAndTab", {
                id: context.getters.tabbedDashboards[0].id,
                instanceID: payload.instanceID
            });
        }
    },
    updateDashboardById(context, payload) {
        // console.log("updateDashboardById", payload)
        context.commit("updateDashboardById", payload);
        const index = widgetQueue.findIndex(wdg => !wdg.started);
        if(index < 0) {
            context.dispatch("persistSystemLayouts");
        }
        
        tabCommSender.postMessage({ dashboardUpdate: true, dashboard: payload });

    },
    communicateDashboardUpdateToOtherTabs(context, payload) {
        context.commit("updateDashboardById", payload);
    },
    updateWidgetLayout(context, [elements, dashboardId]) {
        // console.log("updateWidgetLayout", elements, dashboardId);
        context.commit("updateWidgetLayout", {elements, dashboardId});
    },
    resetDashboards(context) {
        context.commit('setSystemDashboards', []);
    },
    setSystemDashboards(context, payload) {
        context.commit('setSystemDashboards', payload);
        context.dispatch("persistSystemLayouts");
    },
    setSystemDashboardsFromLayout(context, payload) {
        context.commit('setSystemDashboardsFromLayout', payload);
    },
    addUserGuideDashboard(context) {
        context.commit('addUserGuideDashboard');
    },
    closeAllPopouts(context) {
        context.commit('closeAllPopouts');
    },
    fetchSystemLayouts(context) {
        return new Promise((resolve => {
            // console.log("token:" + localStorage.getItem('token'));
            DashboardService.getLayouts().then(result => {
                // console.log("from service", result);
                if (result.data && result.data.data?.json) {
                    context.dispatch("setSystemDashboardsFromLayout", {
                        layout: JSON.parse(result.data.data.json),
                    }).then(() => resolve(result));
                } else {
                    resolve(null);
                }
            })
        }))
    },
    processSystemLayouts(context, payload) {
        context.dispatch("setSystemDashboardsFromLayout", {
            layout: JSON.parse(payload),
        });
    },

    persistSystemLayouts(context) {
        clearTimeout(widgetQueueTimeout);
        setTimeout(() => DashboardService.saveLayouts(context.state.layouts).then(() => {}), 500);

    },

    setAnalChatActiveOn(context, payload) {
        context.commit("setAnalChatActiveOn", payload);
    },
    generateReadonlyDashboards(context) {
        if(window.bootUp.nonTerminalUser)
            return;

        //Showcase removed
        // let widgets = _.cloneDeep(context.rootState.widget.widgets);
        // let activePlan = _.cloneDeep(context.rootGetters["payments/activePlan"]);
        // let products = _.cloneDeep(context.rootState.payments.availableProducts);

        let layouts = _.cloneDeep(context.getters.layouts);

        
        let nonReadOnly = Object.values(layouts).filter(layout => layout.readonly !== true);
        layouts = {};
        
        nonReadOnly = nonReadOnly.filter(el => !isNaN(el.id));
        // console.log(nonReadOnly);
        nonReadOnly.forEach(el => {
            layouts[el.id] = el 
        });
        context.commit("generateReadonlyDashboards", layouts);


        //mobile
        let availableWidgetIDs = (context.rootGetters["payments/availableWidgets"]).map(el => +el);
        let availableWidgets = context.rootGetters["payments/allWidgets"].filter(el => availableWidgetIDs.includes(el.implementation_id) && el.code !== 'TCSTREAM' && el.code !== 'TCVIDEO').map(el => +el.implementation_id);
        const mobileLayouts = {};
        const vidpageLayouts = {};

        const oldAvailableIDs = Object.values(layouts).filter(el => el.mobile).map(el => el.id);
        oldAvailableIDs.forEach(id => delete layouts[id]);

        // const oldAvailableImpIDs = oldAvailableIDs.map(el => +(el.replace('mobile_finso_','')));
        // const diffID =  'mobile_finso_' + (diffArray(availableWidgets, oldAvailableImpIDs))[0];
        // const activeInfo = Object.entries(context.getters.activeDashboard);
        // console.log(diffID, activeInfo);
        
        availableWidgets.forEach(impID => {

            if(impID === 57)
                return;

            // console.log(impID);

            const widget = context.rootGetters["widget/getWidgetByImplementationId"](impID);
            let orderBy = 0;
            if(widget.code === "HEADLINES")
                orderBy = 1;

            let code = {
                id: 'mobile_finso_' + impID,
                name: '',
                mobile: true,
                elements: {},
                panelLayoutID: 1,
                orderBy: orderBy
            };

            const elements = createDashboardElements(widget, "mobile");

            code.name = widget.name.replace('Terminal: ','');
            code.elements = elements;

            mobileLayouts[code.id] = code;

        });

        //mobile ends

        //video dashboards
        
        let videoWidgets = (context.rootGetters["payments/videoWidgetsForShop"]);
        videoWidgets.forEach(el => {
            
            let hideIfNeeded = false;
            if(el.training_centre_widget_style === 'LEGACY')
                hideIfNeeded = true;

            let foundExpiry = el.planDetails?.addonProducts?.find(el => el.only_visible_if_ordered_before);
            
            if(foundExpiry) {
                const now = usersTime(new Date(), true, true, true, {
                    dateFormat: "y-m-d",
                    timeFormat: "24hr"
                }, "UTC");
                const expiry = foundExpiry.only_visible_if_ordered_before.replace('T', ' ').replace('.000000Z', '');

                if(now > expiry)
                    hideIfNeeded = true;

                
            }

            if(!(hideIfNeeded && !context.rootGetters["payments/availableWidgetsPriceInformation"][el.implementation_id].includedPlan.length)) {
                

                let code = {
                    id: 'vidpage_' + el.implementation_id,
                    impID: el.implementation_id,
                    name: '',
                    vidpage: true,
                    elements: {},
                    panelLayoutID: 1,
                    code: el.code,
                    widget: el
                };

                const elements = createDashboardElements(el, "vidpage");

                code.name = el.name.replace('Terminal: ','');
                
                //MTA course name fix
                const env = getServerEnv();
                const prodAccessIDs = [context.rootGetters["payments/activePlan"].product.id, ...context.rootGetters["payments/currentAddons"].map(el => el.product.parent_id ? el.product.parent_id : el.product.id)];
                const fullAccessProds = Constants.TC_FREE_VIDEOS[env][code.impID]?.full_access_prod_ids;
                
                if(Constants.MTA_COURSE[env] === code.impID && !(arrayIntersect(prodAccessIDs, fullAccessProds))) {
                    code.name = code.name + " (taster)";
                    code.widget.name = code.name;
                }
                //MTA course name fix ENDS

                code.elements = elements;

                vidpageLayouts[code.id] = code;
            
            }
            
        });
        
        //video dashboards ENDS

        layouts = {
            ...layouts,
            ...mobileLayouts,
            ...vidpageLayouts
        }

        
        //Showcase removed
        // let readonlyDashboards = [];//Object.values(layouts).filter(layout => layout.readonly === true).map(layout => layout.unique_id);
        // // console.log(readonlyDashboards);
        // let readonlyWidgets = [];
        // if(activePlan.product?.widgets) {
        //     readonlyWidgets = _.cloneDeep(payload.flatMap(product => {
        //         return product.product.widgets.filter(widget => widget.showcase === true)
        //     }));
        // }
        // let widgetProductNames = Object.values(products).filter(product => product?.product_type === constants.ADDON_TYPE && product?.trial_of_product_id === null && product.is_promotion === false).filter(product => product.widgets.length === 1).map(product => {
        //     return {
        //         ...product.widgets[0],
        //         layoutName: product.widgets.name
        //     };
        // })
        // if(activePlan.product.is_visible_front_end && activePlan.product.slug === "legacy-plan") {
        //     activePlan.product.widgets.forEach(entry => {
        //         readonlyWidgets.push(entry);
        //     })
        // }
        // //Don't showcase if included in trial
        // const showCaseImplIDs = readonlyWidgets.filter(el => el.implementation_id).map(el => el.implementation_id);
        // let shouldNotBeShowcased = [];
        
        // (context.rootGetters["payments/currentPlans"]).forEach(element => {
        //     if(element.product.mainTrialProduct) {
        //         shouldNotBeShowcased = element.product.mainTrialProduct.widgets.filter(el => showCaseImplIDs.includes(el.implementation_id));
        //     }
        // });

        // shouldNotBeShowcased.forEach(el => {
            
        //     Object.values(layouts).forEach(e => {
                
        //         Object.values(e.elements).forEach(w => {
                   
        //             if(w.layout.implementation === el.implementation_id) {
        //                 e.no_showcase_on_trial = true;
        //             }
                        
        //         });
               
        //     });
        // });
        // //Don't showcase if included in trial --ENDS

        // readonlyWidgets.forEach(widget => {
        //     if(widget?.pivot?.widget_implementation_id && widget.code !== "ANALCHAT" && widget.name !== "Analyst Desk Chat") {
        //         let selectedWidget = _.cloneDeep(widgets.filter(entry => entry.implementation_id === widget.pivot.widget_implementation_id)?.[0] || {});
        //         let added = Object.values(layouts).map(entry => entry.unique_id);
        //         let selectedWidgetProduct = widgetProductNames.filter(entry => entry.implementation_id === widget?.pivot?.widget_implementation_id)?.[0] || {};
        //         // console.log("selectedWidgetProduct", selectedWidgetProduct);


        //         const settObj = {}
        //         for(const setting in selectedWidget.settings) {
        //             // console.log(selectedWidget.settings);
        //             if(selectedWidget.settings[setting].formJSON)
        //                 settObj[setting] = selectedWidget.settings[setting].formJSON.model[setting];
        //         }

        //         // console.log("settObj", settObj);

        //         if(!readonlyDashboards.includes(widget.pivot.widget_implementation_id) && !added.includes(widget.pivot.widget_implementation_id)) {
        //             // if(payload) {
        //             let nextDashboardId = findNextNumber(layouts);
        //             let layoutId = randomizeBetween(1000000, 9999999);
        //             layouts[nextDashboardId] = {
        //                 "default": false,
        //                 "readonly": true,
        //                 "unique_id": widget.pivot.widget_implementation_id,
        //                 "id": nextDashboardId,
        //                 "elements": {
        //                     [widget.pivot.widget_implementation_id]: {
        //                         "widgetCode": widget.code,
        //                         "layoutId": layoutId,
        //                         "userSettings": {
        //                             ...settObj,
        //                             "widgetname": selectedWidgetProduct.layoutName || widget.name,
        //                             // "fontsize": "medium-font",
        //                             // "timezone": "Europe/London"
        //                         },
        //                         "layout": {
        //                             "x": 0,
        //                             "y": 0,
        //                             "w": window.bootUp.grid,
        //                             "h": window.bootUp.grid/3,
        //                             "i": widget.pivot.widget_implementation_id,
        //                             "static": true,
        //                             "implementation": widget.pivot.widget_implementation_id,
        //                             "moved": false
        //                         }
        //                     }
        //                 },
        //                 "name": selectedWidgetProduct.layoutName || widget.name,
        //                 "tab": false
        //             }
        //         }
        //     }
        // })
        // let readonlyWidgetsIds = readonlyWidgets.map(entry => entry.pivot?.widget_implementation_id);
        // readonlyDashboards.forEach(implementationId => {
        //     if(!readonlyWidgetsIds.includes(implementationId)) {
        //         let layout = Object.values(layouts).find(entry => entry.unique_id === implementationId);
        //         delete layouts[layout.id];

        //         // let instanceID,active;
        //         const activeInfo = Object.entries(context.getters.activeDashboard);
        //         if(activeInfo.length === 1 && layout.id === activeInfo[0][1]) {
        //             context.dispatch("setActiveDashboard", { 
        //                 instanceID: activeInfo[0][0],
        //                 id: +(Object.keys(layouts).map(el => el))[0]
        //             });
        //         }
                


        //     }
        // });

        context.commit("generateReadonlyDashboards", layouts);
    }

}
