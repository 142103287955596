<template>
    <div class="dashboard-layouts"
    :class="{'disabled' : disableSelection}">
        <div class="dashboard-layouts-panel"
        v-for="option in panelOptions"
        :key="option.id"
        >
            <label :for="option.name"
            class="panel-checkbox-label"
            >
                <div>
                    <div v-html="panelLayoutID === option.id ? option.logo_highlighted: option.logo" style="line-height: 0;"></div>
                    <div class="dashboard-layouts-panel-name">{{ option.name }}</div>
                </div>
            </label>
            <input 
            type="radio" 
            class="panel-checkbox-input"
            :id="option.name" 
            :name="option.name" 
            :value="option.id" 
            v-model="panelLayoutID"
            @change="emitSelectionChange"
            />
        </div>

    </div>
</template>

<script>
export default {
    props: ["initialLayout"],
    // created() {
    //    console.log(this.initialLayout);
    //     if(this.initialLayout) {
    //         this.panelLayoutID = this.initialLayout;
    //         // document.querySelector('.panel-checkbox-input').disabled = true;
    //     }
            

    //     this.$emit('layout-selection-change', this.panelLayoutID);
    // },
    mounted() {
        if(this.initialLayout) {
            this.panelLayoutID = this.initialLayout;
            const inputs = document.querySelectorAll('.panel-checkbox-input') //.disabled = true;
            for(const i of inputs) {
                i.disabled = true;
            }
            this.disableSelection = true;
        }

        this.$emit('layout-selection-change', this.panelLayoutID);
    },
    data() {
        return {
            panelOptions: [
                {
                    id: 1,
                    name: "1 Panel",
                    logo: `<svg width="100%" height="100%" viewBox="0 0 140 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#383A42"/>
                    <path d="M10 17.1794H130V23.1794H10V17.1794Z" fill="#383A42"/>
                    <path d="M11 24.1794H129V84.1794C129 86.3886 127.209 88.1794 125 88.1794H15C12.7909 88.1794 11 86.3886 11 84.1794V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#383A42" stroke-width="3"/>
                    </svg>`,
                    logo_highlighted: `<svg width="100%" height="100%" viewBox="0 0 140 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#727B9A"/>
                    <path d="M10 17.1794H130V23.1794H10V17.1794Z" fill="#727B9A"/>
                    <path d="M11 24.1794H129V84.1794C129 86.3886 127.209 88.1794 125 88.1794H15C12.7909 88.1794 11 86.3886 11 84.1794V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#727B9A" stroke-width="3"/>
                    </svg>`
                },
                {
                    id: 2,
                    name: "2 Panels",
                    logo: `<svg width="100%" height="100%" viewBox="0 0 140 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#383A42"/>
                    <path d="M10 17.1794H67.5V23.1794H10V17.1794Z" fill="#383A42"/>
                    <path d="M11 24.1794H66.5V85.1794C66.5 87.3886 64.7091 89.1794 62.5 89.1794H15C12.7909 89.1794 11 87.3886 11 85.1794V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M72.5 17.1794H130V23.1794H72.5V17.1794Z" fill="#383A42"/>
                    <path d="M73.5 24.1794H129V85.1794C129 87.3886 127.209 89.1794 125 89.1794H77.5C75.2909 89.1794 73.5 87.3886 73.5 85.1794V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#383A42" stroke-width="3"/>
                    </svg>`,
                    logo_highlighted: `<svg width="100%" height="100%" viewBox="0 0 140 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#727B9A"/>
                    <path d="M10 17.1794H67.5V23.1794H10V17.1794Z" fill="#727B9A"/>
                    <path d="M11 24.1794H66.5V85.1794C66.5 87.3886 64.7091 89.1794 62.5 89.1794H15C12.7909 89.1794 11 87.3886 11 85.1794V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M72.5 17.1794H130V23.1794H72.5V17.1794Z" fill="#727B9A"/>
                    <path d="M73.5 24.1794H129V85.1794C129 87.3886 127.209 89.1794 125 89.1794H77.5C75.2909 89.1794 73.5 87.3886 73.5 85.1794V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#727B9A" stroke-width="3"/>
                    </svg>`
                },
                {
                    id: 3,
                    name: "3 Panels",
                    logo: `<svg width="100%" height="100%" viewBox="0 0 140 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#383A42"/>
                    <path d="M10 17.1794H46.6667V23.1794H10V17.1794Z" fill="#383A42"/>
                    <path d="M11 24.1794H45.6667V85.1794C45.6667 87.3886 43.8758 89.1794 41.6667 89.1794H15C12.7909 89.1794 11 87.3886 11 85.1794V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M51.6666 17.1794H88.3333V23.1794H51.6666V17.1794Z" fill="#383A42"/>
                    <path d="M52.6666 24.1794H87.3333V85.1794C87.3333 87.3886 85.5424 89.1794 83.3333 89.1794H56.6666C54.4575 89.1794 52.6666 87.3886 52.6666 85.1794V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M93.3334 17.1794H130V23.1794H93.3334V17.1794Z" fill="#383A42"/>
                    <path d="M94.3334 24.1794H129V85.1794C129 87.3886 127.209 89.1794 125 89.1794H98.3334C96.1242 89.1794 94.3334 87.3886 94.3334 85.1794V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#383A42" stroke-width="3"/>
                    </svg>`,
                    logo_highlighted: `<svg width="100%" height="100%" viewBox="0 0 140 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#727B9A"/>
                    <path d="M10 17.1794H46.6667V23.1794H10V17.1794Z" fill="#727B9A"/>
                    <path d="M11 24.1794H45.6667V85.1794C45.6667 87.3886 43.8758 89.1794 41.6667 89.1794H15C12.7909 89.1794 11 87.3886 11 85.1794V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M51.6666 17.1794H88.3333V23.1794H51.6666V17.1794Z" fill="#727B9A"/>
                    <path d="M52.6666 24.1794H87.3333V85.1794C87.3333 87.3886 85.5424 89.1794 83.3333 89.1794H56.6666C54.4575 89.1794 52.6666 87.3886 52.6666 85.1794V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M93.3334 17.1794H130V23.1794H93.3334V17.1794Z" fill="#727B9A"/>
                    <path d="M94.3334 24.1794H129V85.1794C129 87.3886 127.209 89.1794 125 89.1794H98.3334C96.1242 89.1794 94.3334 87.3886 94.3334 85.1794V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#727B9A" stroke-width="3"/>
                    </svg>`
                },
                {
                    id: 4,
                    name: "4 Panels",
                    logo: `<svg width="100%" height="100%" viewBox="0 0 140 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#383A42"/>
                    <path d="M10 17.1794H67V23.1794H10V17.1794Z" fill="#383A42"/>
                    <path d="M11 24.1794H66.5V45.6282C66.5 47.8373 64.7091 49.6282 62.5 49.6282H15C12.7909 49.6282 11 47.8373 11 45.6282V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M72.5 17.1794H130V23.1794H72.5V17.1794Z" fill="#383A42"/>
                    <path d="M73.5 24.1794H129V45.6282C129 47.8373 127.209 49.6282 125 49.6282H77.5C75.2909 49.6282 73.5 47.8373 73.5 45.6282V24.1794Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M10 55.6282H67V61.6282H10V55.6282Z" fill="#383A42"/>
                    <path d="M11 62.6282H66.5V84.0769C66.5 86.286 64.7091 88.0769 62.5 88.0769H15C12.7909 88.0769 11 86.286 11 84.0769V62.6282Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M72.5 55.6282H130V61.6282H72.5V55.6282Z" fill="#383A42"/>
                    <path d="M73.5 62.6282H129V84.0769C129 86.286 127.209 88.0769 125 88.0769H77.5C75.2909 88.0769 73.5 86.286 73.5 84.0769V62.6282Z" stroke="#383A42" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#383A42" stroke-width="3"/>
                    </svg>`,
                    logo_highlighted: `<svg width="100%" height="100%" viewBox="0 0 140 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H135C137.761 0 140 2.23858 140 5V7.17949H0V5Z" fill="#727B9A"/>
                    <path d="M10 17.1794H67V23.1794H10V17.1794Z" fill="#727B9A"/>
                    <path d="M11 24.1794H66.5V45.6282C66.5 47.8373 64.7091 49.6282 62.5 49.6282H15C12.7909 49.6282 11 47.8373 11 45.6282V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M72.5 17.1794H130V23.1794H72.5V17.1794Z" fill="#727B9A"/>
                    <path d="M73.5 24.1794H129V45.6282C129 47.8373 127.209 49.6282 125 49.6282H77.5C75.2909 49.6282 73.5 47.8373 73.5 45.6282V24.1794Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M10 55.6282H67V61.6282H10V55.6282Z" fill="#727B9A"/>
                    <path d="M11 62.6282H66.5V84.0769C66.5 86.286 64.7091 88.0769 62.5 88.0769H15C12.7909 88.0769 11 86.286 11 84.0769V62.6282Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M72.5 55.6282H130V61.6282H72.5V55.6282Z" fill="#727B9A"/>
                    <path d="M73.5 62.6282H129V84.0769C129 86.286 127.209 88.0769 125 88.0769H77.5C75.2909 88.0769 73.5 86.286 73.5 84.0769V62.6282Z" stroke="#727B9A" stroke-width="2"/>
                    <path d="M1.5 8.67944H138.5V94.0769C138.5 96.0099 136.933 97.5769 135 97.5769H5C3.067 97.5769 1.5 96.0099 1.5 94.0769V8.67944Z" stroke="#727B9A" stroke-width="3"/>
                    </svg>`
                }
            ],
            panelLayoutID: 1,
            disableSelection: false
        }
    },
    methods: {
        emitSelectionChange() {
            this.$emit('layout-selection-change', this.panelLayoutID);
        }
    }
}
</script>

<style lang="scss">
    .panel-checkbox-input {
        display: none;
    }
    .dashboard-layouts {
        display: flex;
        justify-content: space-between;

        & .panel-checkbox-label {
            cursor: pointer;
        }

        &.disabled {
            cursor: not-allowed;

            & .panel-checkbox-label {
                cursor: not-allowed;
            }
        }
    }
    .dashboard-layouts-panel {
        width: 20%;
        height: 20%;

        &.highlighted path {
            fill: $input-placeholder-color!important;
        }
    }
    .dashboard-layouts-panel-name {
        text-align: center;
        margin: 10px;
        color: $input-color;
    }
</style>