import Swal from "sweetalert2";
import {addTime, calculateDay, toSystemDate } from "../utility/datetime";
import {createNamespacedHelpers} from "vuex";
import PaymentCardModal from "../components/ui/payments/PaymentCardModal";
import {toMessageWithVariables} from "../utility/helper-functions";
import PaymentService from "../services/payments/payment.service"
import {eventBus} from "../main";
import ProductPrices from "./product.prices";
import dayjs from "dayjs";
import TrackingService from "../services/tracking/tracking.service";

const payments = createNamespacedHelpers('payments');
const general = createNamespacedHelpers('general');

const BUY_PROMOTIONS_WARNING_MESSAGE = 'When you click below, your card {$cardNumber} will be charged {$price} today. Your access to this product will expire on {$expirationDate}. You will not be charged again.'
const BUY_SUBSCRIPTION_PROMOTIONS_WARNING_MESSAGE = "When you click below, your card {$cardNumber} will be charged {$price} today and then {$nextBillingAmount} per {$subscription} for {$subscriptionLength}, until you cancel.";

export default {
    mixins: [ProductPrices],
    computed: {
        ...payments.mapState(['availableProducts', 'paymentMethod', 'currentProducts']),
        ...general.mapState(['user']),
        ...payments.mapGetters([
            'activePlan',
            'isCurrentPlanTrial',
            'availablePlansForComparison',
            'currentPlans',
            'currentAddons',
            'activeSubscriptions',
            'isCurrentCardValid',
            'addonSubscriptions',
            'addonPromos',
            'upcomingPlans',
            'nextPaymentInformation',
            'availableWidgets',
            'promotionalProducts',
            'plansForUpgrade',
            'currentAddonsSlugs',
            'upcomingPlansSlugs',
            'currentProductsSlugs',
            'availablePlans',
            'sortedWidgetsByCreationDate',
            'mergedPlans',
            'makingPayment'
        ]),
        ccData: function () {
            return this.paymentMethod?.description || "";
        },
        ccDetails: function () {
            return this.ccData.split("*");
        },
        cardExpirationDate: function () {
            return this.paymentMethod ? dayjs(this.paymentMethod?.valid_until).format("MM/YY") : '';
        },
        hasActiveCard: function () {
            return this.paymentMethod?.valid_until !== null && this.paymentMethod?.valid_until !== undefined;
        },
        currentVisibleAddons: function () {
            return this.currentAddons.filter(product => product.product.is_subscription);
        },
        currentVisiblePromotions: function () {
            return this.currentAddons.filter(product => !product.product.is_subscription);
        },
        showNextPaymentInformation: function () {
            return this.nextPaymentInformation.active;
        },
        cardNumber: function () {
            return this.paymentMethod?.description?.split("*")?.[1] || "";
        },
        friendlyCardNumber: function () {
            return "**** **** **** " + this.cardNumber;
        },
        isHiddenPlan() {
            return !this.activePlan?.product.is_visible_front_end;
        },
        isTrial() {
            return this.activePlan?.product.trial_of_product_id;
        },


        activeSlug() {
            return this.activePlan?.product.slug;
        },
        isLegacy() {
            return this.activePlan.is_visible_front_end;
        },
        isExplorer() {
            return this.activeSlug.indexOf('explorer') > -1;
        },
        isProTrial() {
            return this.activeSlug.indexOf('pro') > -1 && this.activeSlug.indexOf('trial') > -1;
        },
        isPro() {
            return this.activeSlug.indexOf('pro') > -1 && this.activeSlug.indexOf('trial') === -1;
        },



        includesVAT() {
            return this.user.sales_tax_percent > 0;
        }
    },
    methods: {
        ...payments.mapActions(['purchasePlan', 'purchaseAddon', 'cancelAddon', 'setMakingPayment']),
        calculateUpgradeStatus: function (product) {
            if (!this.activePlan.product || (this.activePlan.price / 100 < product.pricing['USD'] / 100)) {
                return 2;
            }
            return 3;
        },
        displayMessage: function (generalMessage, product, currentPrice, generalPrice) {
            let isTrial = product.trial_of_product_id > 0;
            return '<div>\n' +
                '        <label>' + generalMessage + '</label>\n' +
                '        <table style="text-align: right;width:100%">\n' +
                '            <thead>\n' +
                '            <tr>\n' +
                '                <th style="text-align: left;">Payments:</th>\n' +
                '                <th>Price</th>\n' +
                (this.displayVatInformation ? ' <th>Vat</th>\n' : '') +
                (this.displayVatInformation ? '<th>Vat Price</th>\n' : '') +
                '            </tr>\n' +
                '            </thead>\n' +
                '            <tbody>\n' +
                '            <tr>\n' +
                '                <td style="text-align: left;">Now</td>\n' +
                '                <td>$ ' + currentPrice + '</td>\n' +
                (this.displayVatInformation ? '<td>$ ' + this.calculateVat(currentPrice) + '</td>\n' : '') +
                (this.displayVatInformation ? ' <td>$ ' + this.calculatePriceWithVat(currentPrice) + '</td>\n' : '') +
                '            </tr>\n' +
                (!isTrial && product.is_subscription && product.subscription_length_type !== null && product.subscription_length_type !== "none" ? '<tr>\n' +
                    '                <td style="text-align: left;">Every ' + product.subscription_length_type + '</td>\n' +
                    '                <td>$ ' + generalPrice + '</td>\n' +
                    (this.displayVatInformation ? '<td>$ ' + this.calculateVat(generalPrice) + '</td>\n' : '') +
                    (this.displayVatInformation ? ' <td>$ ' + this.calculatePriceWithVat(generalPrice) + '</td>\n' : '') +
                    '            </tr>\n' : '') +
                '            </tbody>\n' +
                '        </table>\n' +
                '    </div>';

        },
        calculateProductPrice: function (product) {
            return new Promise(resolve => {
                PaymentService.calculateProductPrice(product).then(result => {
                    console.log(result);
                    resolve(result.data?.data?.amount || 0);
                });
            });
        },
        calculateNextBillingDate: function (product) {
             return toSystemDate(addTime(product.subscription_length, product.subscription_length_type, Date.now()).substring(0, 10));
        },
        changePlan(product, activePlan) {
            this.calculateProductPrice(product).then(price => {
                this.successfulUpgrade = false;
                let isFree = this.isFree(this.formatPriceObject(product.pricing));
                let messageText = isFree ? 'This product is free.' : this.displayMessage('When you click below, your card will be charged $' + this.calculatePriceWithVat(price / 100) + ' today and then $'
                    + this.calculatePriceWithVat(product.pricing['USD'] / 100) + ' every ' + product.subscription_length_type + ' until you cancel.', product, price / 100, product.pricing['USD'] / 100);
                Swal.fire({
                    title: "Are you sure?",
                    type: 'warning',
                    html: messageText,
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Upgrade!'
                }).then((result) => {
                    if (result.value) {
                        if (this.isCurrentCardValid || isFree) {
                            this.modifyPlan(product, "UPGRADE", activePlan);
                        } else {
                            this.showModal(PaymentCardModal,
                                {
                                    showMakePayment: true, closeSpinner: false, handlePayment: (changed) => {
                                        if (changed) {
                                            this.changePlan(product, activePlan);
                                        } else {
                                            this.modifyPlan(product, "UPGRADE", activePlan)
                                        }
                                    }
                                })
                        }
                    }
                });
            });
        },
        downgradePlan(product, activePlan) {
            let isFree = this.isFree(this.formatPriceObject(product.pricing));
            Swal.fire({
                title: 'Are you sure you want to downgrade?',
                type: 'warning',
                text: "When you click below, your plan will be downgraded on " + toSystemDate(activePlan.next_billing_at) + '.',
                showCancelButton: true,
                cancelButtonColor: '#383A42',
                cancelButtonText: 'Back',
                confirmButtonColor: '#FC5A5A',
                confirmButtonText: 'Downgrade'
            }).then((result) => {
                eventBus.$emit("close-modal");
                if (result.value) {
                    if (this.isCurrentCardValid || isFree) {
                        this.modifyPlan(product, "DOWNGRADE", activePlan);
                    } else {
                        this.showModal(PaymentCardModal, {
                            showMakePayment: true,
                            closeSpinner: false,
                            handlePayment: (changed) => {
                                if (changed) {
                                    this.downgradePlan(product, activePlan);
                                } else {
                                    this.modifyPlan(product, "DOWNGRADE", activePlan)
                                }
                            }
                        })
                    }
                }
            });
        },
        modifyPlan: async function (product, type, activePlan) {
            if (product.product_type === "plan") {
                this.purchasePlan(product).then(() => {
                    if (type === "UPGRADE") {
                        if (this.$parent.$vnode.componentOptions.tag === "view-products") {
                            Swal.fire({
                                title: 'Success',
                                type: 'info',
                                text: 'Your account has been upgraded.',
                                showCancelButton: true,
                                cancelButtonColor: '#1d9deb',
                                confirmButtonColor: '#ff5656',
                                confirmButtonText: 'Open Subscription Settings'
                            }).then((result) => {
                                if (result.value) {
                                    // this.goToSubscriptionSettings();
                                    this.setMakingPayment(false);
                                }
                            });
                        } else {
                            this.showInfoMessage("Success", "Your account has been upgraded.");
                            // this.goToSubscriptionSettings();
                            this.setMakingPayment(false);
                        }

                        this.successfulUpgrade = true;
                        this.clearSuccessMessage();
                    } else {
                        
                        TrackingService.sendTransaction(activePlan, "cancelled");

                        Swal.fire({
                            title: 'Downgrade successful!',
                            type: 'info',
                            text: 'Your new downgraded plan will begin on ' + toSystemDate(activePlan.next_billing_at) + '.',
                            allowOutsideClick: false,
                            showCancelButton: false,
                            confirmButtonColor: '#3B9C83',
                            confirmButtonText: 'OK',
                        }).then(() => {
                            console.log("downgrade success");
                        });

                        let message = "Your account has been downgraded; you won’t be charged again.";
                        let isCancellationActive = activePlan.status === 'renewfail';
                        if(!isCancellationActive) {
                            message += "You’ll lose access to your current settings on " + toSystemDate(activePlan.next_billing_at);
                        }
                        this.showInfoMessage("Success", message);
                    }
                    this.$emit("plan-upgraded")
                    // this.scrollToTop();
                });

            } else {
                this.purchaseAddon(product).then(() => {
                    // this.scrollToTop();
                    // this.goToSubscriptionSettings();
                    this.setMakingPayment(false);
                });
            }
        },
        // goToSubscriptionSettings: function () {
        //     eventBus.$emit('open-modal', {
        //         panel: {
        //             tab: 'subscriptions',
        //             zIndex: 20000
        //         },
        //         title: "Account Settings",
        //         componentInModal: "account-settings",
        //         mode: "wide",
        //     });
        //     this.$modal.hideAll();
        // },
        cancelProduct: function (addon) {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'Your product will be cancelled.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.cancelAddon(addon.product).then(() => {
                        let message = addon.product.name + " has been cancelled; you won’t be charged again.";
                        if (addon.ended_at) {
                            message += "Access ends on " + this.toSystemDate(addon.ended_at);
                        }
                        
                        TrackingService.sendTransaction(addon, "cancelled");

                        this.showInfoMessage("Success", message);
                        // this.scrollToTop()
                    });

                }
            });
        },
        reactivateProduct: function (addon) {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: ' When you click below your scheduled downgrade will be cancelled and you’ll continue to be charged for ' + addon.product.name,
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Cancel Downgrade'
            }).then((result) => {
                if (result.value) {
                    this.purchaseProduct(addon);
                }
            });
        },
        purchaseProduct: function (addon) {
            this.purchaseAddon(addon.product).then(() => {
                this.showInfoMessage("Success", addon.product.name + " has been reactivated");

                TrackingService.sendTransaction(addon, "reactivated");
                // this.scrollToTop()

            });
        },
        submitPurchase: function () {
            this.purchasePlan(this.plan.product).then(() => {
                
                TrackingService.sendTransaction(this.plan, "reactivated");
                this.showInfoMessage("Success", "Your plan has been reactivated");
                // this.scrollToTop()
            });
        },
        reactivatePlan: function (plan) {
            let isFree = this.isFree(this.formatPrice(plan));
            let messageText = isFree ? 'When you click below your scheduled downgrade will be cancelled. This product is free.' :
                ' You\'ll stay on your current plan and continue being charged. You can downgrade at any time. ';
            Swal.fire({
                title: 'Are you sure you want to cancel this downgrade?',
                type: 'warning',
                text: messageText,
                showCancelButton: true,
                cancelButtonColor: '#383A42',
                cancelButtonText: 'Back',
                confirmButtonColor: '#FC5A5A',
                confirmButtonText: 'Cancel Downgrade'
            }).then((result) => {
                if (result.value) {
                    if (this.isCurrentCardValid || isFree) {
                        this.purchasePlan(plan.product).then(() => {

                            TrackingService.sendTransaction(plan, "reactivated");
                            // this.scrollToTop()
                            this.showInfoMessage("Your downgrade has been cancelled!", "Your " + plan.product.name + " subscription will remain active until you downgrade. Your next billing date is " + toSystemDate(plan.next_billing_at) + ".");
                        });
                    } else {
                        this.showModal(PaymentCardModal, {
                            showMakePayment: true,
                            closeSpinner: false,
                            handlePayment: (changed) => {
                                if (changed) {
                                    this.reactivatePlan();
                                } else {
                                    this.submitPurchase()
                                }
                            }
                        })
                    }
                }
            });
        },
        addSubscription: function () {
            Swal.fire({
                title: 'Are You Sure?',
                type: 'warning',
                html: this.displayMessage('When you click below, your card ' + (this.paymentMethod.description || '') + ' will be charged $' + this.calculatePriceWithVat(this.subscriptionProduct.pricing['USD'] / 100) + ' today and then every ' + this.subscriptionProduct.subscription_length_type + ' until you cancel.', this.subscriptionProduct, this.subscriptionProduct.pricing['USD'] / 100, this.subscriptionProduct.pricing['USD'] / 100),
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Buy'
            }).then((result) => {
                if (result.value) {
                    this.checkCardDetailsToPurchaseAddon();
                }
            });
        },
        checkCardDetailsToPurchaseAddon: function () {
            if (this.isCurrentCardValid) {
                this.purchaseProductHandler();
            } else {
                this.showModal(PaymentCardModal, {
                    showMakePayment: true,
                    closeSpinner: false,
                    handlePayment: (changed) => {
                        if (changed) {
                            this.addSubscription();
                        } else {
                            this.checkCardDetailsToPurchaseAddon()
                        }
                    }
                });
            }
        },
        purchaseProductHandler: function () {
            this.purchaseAddon(this.subscriptionProduct)
                .then(() => {
                    Swal.fire({
                        title: 'Payment Success!',
                        type: 'info',
                        text: 'Your payment was successful, the ' + this.subscriptionProduct.name + ' is now active!',
                        showCancelButton: true,
                        cancelButtonColor: '#1d9deb'
                    });
                    TrackingService.sendTransaction(this.subscriptionProduct, "purchased");

                    this.$emit("widget-purchased");
                    // this.scrollToTop()
                    // this.goToSubscriptionSettings();
                    this.setMakingPayment(false);
                }).catch(() => {
                    this.setMakingPayment(false);
                Swal.fire({
                    title: 'Payment Failed!',
                    type: 'info',
                    text: 'The ' + this.subscriptionProduct.name + ' could not be activated. Please upload a new card or contact your bank to authorise this payment and try again.',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
            })
        },
        purchasePromotion: function (product) {
            let messageText = "";
            if (product.is_subscription) {
                let variables = {
                    cardNumber: this.cardNumber,
                    price: this.calculatePriceWithVat(product.pricing['USD'] / 100),
                    nextBillingAmount: this.calculatePriceWithVat(product.pricing['USD'] / 100),
                    subscription: product.subscription_length_type,
                    subscriptionLength: calculateDay(1, product.subscription_length_type)
                }
                messageText = toMessageWithVariables(BUY_SUBSCRIPTION_PROMOTIONS_WARNING_MESSAGE, variables);
            } else {
                let variables = {
                    cardNumber: this.cardNumber,
                    price: this.calculatePriceWithVat(product.pricing['USD'] / 100),
                    expirationDate: calculateDay(product.subscription_length, product.subscription_length_type)
                }
                messageText = toMessageWithVariables(BUY_PROMOTIONS_WARNING_MESSAGE, variables);
            }
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                html: this.displayMessage(messageText, product, product.pricing['USD'] / 100, product.pricing['USD'] / 100),
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Buy!'
            }).then((result) => {
                if (result.value) {
                    if (this.isCurrentCardValid) {
                        // console.log("card valid, upgrading promo");
                        this.upgradePromotion(product);
                    } else {
                        this.showModal(PaymentCardModal, {
                            showMakePayment: true,
                            closeSpinner: false,
                            handlePayment: (changed) => {
                                if (changed) {
                                    this.purchasePromotion(product);
                                } else {
                                    this.upgradePromotion(product)
                                }
                            }
                        })
                    }
                }
            });
        },
        upgradePromotion: async function (product) {
            let action;
            if (product.product_type === "plan") {
                action = this.purchasePlan(product);
            } else {
                action = this.purchaseAddon(product);
            }
            // console.log("action");
            action.then(() => {
                // console.log("action");
                Swal.fire({
                    title: 'Payment Success!',
                    type: 'info',
                    text: 'Your payment was successful, the ' + product.name + ' is now active!',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
                TrackingService.sendTransaction(product, "purchased", true);

                this.$emit("plan-purchased")
                // this.scrollToTop()
                // this.goToSubscriptionSettings();
                this.setMakingPayment(false);
                localStorage.removeItem("promo");
                
                setTimeout(() => {
                    eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: this.$store.getters["general/componentAfterRefresh"] || "home"
                    });
                },500)
                
            })
            .catch(e => console.log("e", e));
            console.log("action done");
        },
        buyTrial() {
            let isValid = this.validate();
            if (isValid) {
                Swal.fire({
                    title: 'Are you sure?',
                    type: 'warning',
                    html: this.displayMessage('When you click below, your card will be charged $' + this.calculatePriceWithVat(this.selectedTrial.pricing['USD'] / 100) + ' and your account will immediately be upgraded. Your trial will automatically end on ' + calculateDay(this.selectedTrial.subscription_length, this.selectedTrial.subscription_length_type) + ' and you will not be charged again', this.selectedTrial, this.selectedTrial.pricing['USD'] / 100, this.selectedTrial.pricing['USD'] / 100),
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Upgrade!'
                }).then((result) => {
                    if (result.value) {
                        if (this.isCurrentCardValid) {
                            this.purchaseProductTrial();
                        } else {
                            this.showModal(PaymentCardModal, {
                                showMakePayment: true,
                                closeSpinner: false,
                                handlePayment: (changed) => {
                                    if (changed) {
                                        this.buyTrial();
                                    } else {
                                        this.purchaseProductTrial()
                                    }
                                }
                            })
                        }
                    }
                });
            }
        },
        purchaseProductTrial: async function () {
            this.purchasePlan(this.selectedTrial).then(() => {
                Swal.fire({
                    title: 'Trial Activated!',
                    html: "Your trial of " + this.product.name + " is now activated. Enjoy it!",
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                });
            });
            this.setMakingPayment(false);
            // this.goToSubscriptionSettings();
            // this.scrollToTop()
        }

    }
}
