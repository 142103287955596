export default {
    majorCountries(state) {
        return state.majorCountries;
    },
    getCountryAdjective: (state) => (code) => {
        const country = state.countryData.find(country => country.cca3 === code);
        if(country)
            return country.demonyms.eng.m;
        
            return '';
    },

    lastEcoCalendarUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastEcoCalendarUpdate));
    },

    lastEcoCalendarDelete(state) {
        return state.lastEcoCalendarDelete;
    },

    fetchDay(state) {
        return state.fetchDay;
    }
}