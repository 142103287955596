export default {
    getLastShopVisitById: function (state) {
        return (key) => state.lastShopVisitDate[key];
    },
    user(state) {
        return state.user;
    },
    renewFails(state) {
        return state.renewFails;
    },
    hasRenewFails(state) {
        return !!state.renewFails.length;
    },
    paymentBoundToFail(state) {
        return state.paymentBoundToFail;
    },
    activeNavItem(state) {
        return state.activeNavItem;
    },
    componentAfterRefresh(state) {
        return state.componentAfterRefresh;
    },
    dbReady(state) {
        return state.dbReady;
    },
    lastTrainingCentreOrderUpdated(state) {
        return state.lastTrainingCentreOrderUpdated;
    },
    watchHistory(state) {
        return state.watchHistory;
    },
    entityUpdateTimeout(state) {
        return state.entityUpdateTimeout;
    }
}
