<template>
    <div class="change-plan">
        <h3 v-if="successfulUpgrade">
            <label style="color: lightgreen">Your plan has been upgraded.</label>
        </h3>
        <div class="plan-container">
            <product-details v-if="activePlan.product"
                             :plan="activePlan.product"
                             :change-plan-handler="() => changePlan(activePlan.product.mainTrialProduct, activePlan)"
                             :price="formatPrice(activePlan, false)"
            />
            <product-details v-for="product in upcomingPlans" :key="product.slug"
                             :plan="product.product" :upgrade-status="0" :start-date="product.started_at"
                             :price="formatPrice(product, false)"
            />
            <product-details v-for="product in plansForUpgrade" :key="product.slug"
                             :plan="product"
                             :has-trials="product.trials.length > 0 "
                             :price="formatPriceObject(product.pricing, false)"
                             :change-plan-handler="() => changePlan(product, activePlan)"
                             :downgrade-plan-handler="() => downgradePlan(product, activePlan)"
                             :open-trial-options-handler="openTrialOptions"
                             :upgrade-status="calculateUpgradeStatus(product)"
            >
                <span v-if="newPlansList.includes(product.slug)" class='badge not-round'>New</span>
            </product-details>
        </div>
        <div>
            <plan-widgets-box></plan-widgets-box>
        </div>
    </div>
</template>

<script>
import ProductDetails from "../payments/ProductDetails";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";

import EventBus from "../../../store/bus";
import {createNamespacedHelpers} from "vuex";
import _ from "lodash";
import PlanWidgetsBox from "../shop/PlanWidgetsBox";
import TrialPeriodDetails from "../payments/TrialPeriodDetails";
const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
export default {
    name: "ChangePlan",
    components: {PlanWidgetsBox, ProductDetails},
    mixins: [SubscriptionMixin],
    data: function () {
        return {
            successfulUpgrade: false,
            newPlansList: []
        };
    },
    computed: {
        ...general.mapGetters(['getLastShopVisitById']),
        ...payments.mapGetters(['newPlans']),
        lastVisitDate: function () {
            return this.getLastShopVisitById("PLAN");
        }
    },
    methods: {
        ...general.mapActions(['setLastShopVisitDate']),
        clearSuccessMessage: function () {
            setTimeout(() => this.successfulUpgrade = false, 3000)
        },
        openTrialOptions: function (product) {
            this.showModal(TrialPeriodDetails, {product: product});
        }
    },
    created() {
        this.newPlansList = _.cloneDeep(this.newPlans);
        EventBus.$on("scroll-to-plan", () => {
            setTimeout(() => this.$el.scrollIntoView(), 300);
        });
        this.$nextTick(() => {
            this.setLastShopVisitDate(['PLAN', new Date()]);
        });
    }
}
</script>

<style lang="scss" scoped>
.change-plan {
  margin-top: 20px;
  padding: 15px;
  // background-color: rgba($color-03, 0.6);

  @include respond(md) {
    margin-right: 5px;
  }
}

.plan-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    // justify-items: center;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-around;
//   flex-direction: column;
//   align-items: center;

    @include respond(md) {
        // flex-direction: row;
        grid-template-columns: repeat(2, 1fr);
    }

  @include respond(lg) {
    // flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
  }
}

</style>
