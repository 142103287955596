export default {
    groupCols(state) {
        return state.groupCols;
    },
    originalGroupCols(state) {
        return state.originalGroupCols;
    },
    countries(state) {
        return state.countries;
    },
    groupedDataIDs(state) {
        return state. groupedDataIDs;
    }
}