import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return{
            boards: [],
            channels: [],
            tweets: [],
            lastTweetUpdate: null,
            lastBoardUpdate: null,
            lastChannelUpdate: null,
            lastHandleUpdate: null,
            lastChannelHandleUpdate: null,
            haveBoards: false,
            haveChannels: false,
            upperMostParents: [],
            tweetsFetchedForBoards: []

        }
    },
    mutations,
    actions,
    getters
}