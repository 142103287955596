<template>
    <label class="switch" :class="inputClass">
        <input 
        type="checkbox" 
        v-bind="$attrs"
        v-on="$listeners"
        
        @input="$emit('update', $event.target.value)">
        <span class="slider" :class="inputClass"></span>
    </label>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ["inputClass"],
    model: {
        prop: "value",
        event: "update"
    },
}
</script>

<style lang="scss" scoped>
    .switch {

        position: relative;
        display: inline-block;
		
		& input { 
			opacity: 0;
			width: 0;
			height: 0;
	
			&:checked + .slider:before {
		
                -webkit-transform: translateX(1.2rem);
                -ms-transform: translateX(1.2rem);
                transform: translateX(1.2rem);
				
			}
		}

        &.red-green input,
        &.grey-green input {
            &:checked + .slider {
				background-color: $onboard-green !important;
			}
	
			&:focus + .slider {
				box-shadow: 0 0 1px $onboard-green !important;
			}
        }

	}

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: .4s;
        transition: .4s;
        z-index: 1;
        height: 30px;
        width: 50px;

        &.small {
            height: 18px;
            width: 37px;

            &:before {
            height: 13px;
            width: 13px;


        }


        }
        
        &:before {
            position: absolute;
            content: "";
            left: 2px;
            bottom: 3px;
            -webkit-transition: .4s;
            transition: .4s;

            height: 26px;
            width: 26px;


        }

    }

    .grey .slider {
        background-color: $color-04;
    }

    .two-sided .slider {
        left: 47px;


    }

    .red-green .slider {
        background-color: #b10404  !important;
    }

    .grey-green .slider {
        background-color: $input-placeholder-color  !important;
    }

    .white-button .slider:before {
        background-color: white !important;
    }




    .slider {
        border-radius: 34px;
        
        &:before {
            border-radius: 50%;
        }
    }
</style>