<template>
    <div>
        <div v-html="retweet.text">
        </div>
        <div v-if="retweet.images.length" style="text-align: center; margin-top: 1rem;">
            <social-image
            v-for="image in retweet.images"
            :key="retweet.tweet_id + image.id"
            :image="image"
            ></social-image>
    </div>
    </div>
    
</template>

<script>
import SocialImage from './SocialImage';

export default {
    props: ["retweet"],
    components: {
        SocialImage
    }
}
</script>