<template>
    <div class="retweet-frame">
        <re-tweet
        v-for="retweet in retweets"
        :key="retweet.tweet_id"
        :retweet="retweet">
        </re-tweet>
    </div>
</template>

<script>
import ReTweet from './ReTweet'

export default {
    props: ["retweets"],
    components: {
        ReTweet
    }
}
</script>