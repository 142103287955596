<template>
    <base-modal class="terminal-modal simplified auto-height" :footer="false" mode="wide">
        <div id="announcement-info" style="margin-top: 35px">
            <pdf-viewer :panel="{url: pdfContent}"></pdf-viewer>
        </div>
    </base-modal>
</template>

<script>
import PdfViewer from "../../widgets/components/PdfViewer";
function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}
export default {
    name: "PaymentInvoiceModal",
    components: {PdfViewer},
    props: {
        content: String,
    },
    computed: {
        pdfContent: function () {
            return base64ToArrayBuffer(this.content);
        }
    }
}
</script>

<style scoped lang="scss">

</style>
