<template>
    <div :id="targetDiv" style="overflow:hidden;">
        <router-view/>
    </div>
</template>

<script>
export default {
  data() {
    return {
      targetDiv: (this.$root.targetDiv).replace('#','')
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  height: 100%;
  background-color: $onboard-survey-background;
  
}

.window {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.responsive-16-9-frame {
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  position: relative;


  & iframe, img {
    border: 0;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;

  }
}

.li-align-right {
  margin-left: auto;
}

.icon-description {
  font-size: 9px;
}

.instruction-row {
  margin-bottom: 35px;
}

.footer {
  height: 50px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  background-color: $onboard-survey-background;
  border-top: 1px solid $separator-line-color;
  letter-spacing: 0.3px;
  color: $input-color-filter;
}
</style>
