export default {
    "HOW_IT_WORKS_TIP": "You can up-vote one feature. " +
        "<br/><br/>Change your vote at any time. Submit your own requests using the option above. " +
        "<br/><br/>We’ll build the most popular feature.",
    "PLAN_TYPE": "plan",
    "ADDON_TYPE": "option",
    "STRIPE_API_KEY": "pk_live_jOihLBVtT7asbyx36SbpXdSK",
    "WIDGETS": {
        "HEADLINES": "breaking-headlines",
        "CURRMETER": "currency-strength-monitor-widget",
        "TWEETSCAN": "social-scanner",
        "WELCOME": "welcome-widget",
        "CALENDAR": "eco-calendar",
        "VIDLIB": "video-legacy",
        "IRPT": "interest-rate",
        "VIDCOM": "vid-com",
        "SQUAWK": "audio-squawk",
        "WEBINARS": "video-legacy",
        "BLANK": "blank-widget",
        "TCSTREAM": "live-stream",
        "TCVIDEO": "video-course-wrapper",
        "ECRESEARCH": "economic-research"

    },
    "PERMANENT_OFFER_DEADLINE": 157680000, //5 yrs
    "PUSHER_CHANNELS": {
        "HEADLINES": "headlines",
        "CURRMETER": "curr-strength",
        "TWEETSCAN": "tweetdeck",
        "CALENDAR": "ecocalendar",
        "VIDLIB": "videolibrary",
        "IRPT": "irpt",
        "VIDCOM": "headlines",
        "SQUAWK": "",
        "WEBINARS": "livestream",
        "ANALCHAT": "",
        "TCSTREAM": "livestream",
        "TCVIDEO": "videolibrary"
    },
    "COUNTRIES": [
        {country: 'United Kingdom', code: 'GB'},
        {country: 'United States', code: 'US'},
        {country: 'Canada', code: 'CA'},
        {country: 'Australia', code: 'AU'},
        {country: 'New Zealand', code: 'NZ'},
        {country: 'Austria', code: 'AT'},
        {country: 'Belgium', code: 'BE'},
        {country: 'Bulgaria', code: 'BG'},
        {country: 'Brazil ', code: 'BR'},
        {country: 'Cyprus', code: 'CY'},
        {country: 'Czech Republic', code: 'CZ'},
        {country: 'Denmark', code: 'DK'},
        {country: 'Estonia', code: 'EE'},
        {country: 'Finland', code: 'FI'},
        {country: 'France', code: 'FR'},
        {country: 'Germany', code: 'DE'},
        {country: 'Greece', code: 'GR'},
        {country: 'Hong Kong', code: 'HK'},
        {country: 'Hungary', code: 'HU'},
        {country: 'India', code: 'IN'},
        {country: 'Ireland', code: 'IE'},
        {country: 'Italy', code: 'IT'},
        {country: 'Japan', code: 'JP'},
        {country: 'Latvia', code: 'LV'},
        {country: 'Lithuania', code: 'LT'},
        {country: 'Luxembourg', code: 'LU'},
        {country: 'Malaysia', code: 'MY'},
        {country: 'Malta', code: 'MT'},
        {country: 'Mexico ', code: 'MX'},
        {country: 'Netherlands', code: 'NL'},
        {country: 'Norway', code: 'NO'},
        {country: 'Poland', code: 'PL'},
        {country: 'Portugal', code: 'PT'},
        {country: 'Romania', code: 'RO'},
        {country: 'Singapore', code: 'SG'},
        {country: 'Slovakia', code: 'SK'},
        {country: 'Slovenia', code: 'SI'},
        {country: 'Spain', code: 'ES'},
        {country: 'Sweden', code: 'SE'},
        {country: 'Switzerland', code: 'CH'}
    ],
    "USER_COUNTRIES": [
        {
            "name": "United Kingdom of Great Britain and Northern Ireland",
            "alpha2Code": "GB",
            "alpha3Code": "GBR",
            "group": "main"
        },
        {
            "name": "United States of America",
            "alpha2Code": "US",
            "alpha3Code": "USA",
            "group": "main"
        },
        {
            "name": "Canada",
            "alpha2Code": "CA",
            "alpha3Code": "CAN",
            "group": "main"
        },
        {
            "name": "Australia",
            "alpha2Code": "AU",
            "alpha3Code": "AUS",
            "group": "main"
        },
        {
            "name": "New Zealand",
            "alpha2Code": "NZ",
            "alpha3Code": "NZL",
            "group": "main"
        },
        {
            "name": "Afghanistan",
            "alpha2Code": "AF",
            "alpha3Code": "AFG"
        },
        {
            "name": "Åland Islands",
            "alpha2Code": "AX",
            "alpha3Code": "ALA"
        },
        {
            "name": "Albania",
            "alpha2Code": "AL",
            "alpha3Code": "ALB"
        },
        {
            "name": "Algeria",
            "alpha2Code": "DZ",
            "alpha3Code": "DZA"
        },
        {
            "name": "American Samoa",
            "alpha2Code": "AS",
            "alpha3Code": "ASM"
        },
        {
            "name": "Andorra",
            "alpha2Code": "AD",
            "alpha3Code": "AND"
        },
        {
            "name": "Angola",
            "alpha2Code": "AO",
            "alpha3Code": "AGO"
        },
        {
            "name": "Anguilla",
            "alpha2Code": "AI",
            "alpha3Code": "AIA"
        },
        {
            "name": "Antarctica",
            "alpha2Code": "AQ",
            "alpha3Code": "ATA"
        },
        {
            "name": "Antigua and Barbuda",
            "alpha2Code": "AG",
            "alpha3Code": "ATG"
        },
        {
            "name": "Argentina",
            "alpha2Code": "AR",
            "alpha3Code": "ARG"
        },
        {
            "name": "Armenia",
            "alpha2Code": "AM",
            "alpha3Code": "ARM"
        },
        {
            "name": "Aruba",
            "alpha2Code": "AW",
            "alpha3Code": "ABW"
        },
        {
            "name": "Austria",
            "alpha2Code": "AT",
            "alpha3Code": "AUT"
        },
        {
            "name": "Azerbaijan",
            "alpha2Code": "AZ",
            "alpha3Code": "AZE"
        },
        {
            "name": "Bahamas",
            "alpha2Code": "BS",
            "alpha3Code": "BHS"
        },
        {
            "name": "Bahrain",
            "alpha2Code": "BH",
            "alpha3Code": "BHR"
        },
        {
            "name": "Bangladesh",
            "alpha2Code": "BD",
            "alpha3Code": "BGD"
        },
        {
            "name": "Barbados",
            "alpha2Code": "BB",
            "alpha3Code": "BRB"
        },
        {
            "name": "Belarus",
            "alpha2Code": "BY",
            "alpha3Code": "BLR"
        },
        {
            "name": "Belgium",
            "alpha2Code": "BE",
            "alpha3Code": "BEL"
        },
        {
            "name": "Belize",
            "alpha2Code": "BZ",
            "alpha3Code": "BLZ"
        },
        {
            "name": "Benin",
            "alpha2Code": "BJ",
            "alpha3Code": "BEN"
        },
        {
            "name": "Bermuda",
            "alpha2Code": "BM",
            "alpha3Code": "BMU"
        },
        {
            "name": "Bhutan",
            "alpha2Code": "BT",
            "alpha3Code": "BTN"
        },
        {
            "name": "Bolivia (Plurinational State of)",
            "alpha2Code": "BO",
            "alpha3Code": "BOL"
        },
        {
            "name": "Bonaire, Sint Eustatius and Saba",
            "alpha2Code": "BQ",
            "alpha3Code": "BES"
        },
        {
            "name": "Bosnia and Herzegovina",
            "alpha2Code": "BA",
            "alpha3Code": "BIH"
        },
        {
            "name": "Botswana",
            "alpha2Code": "BW",
            "alpha3Code": "BWA"
        },
        {
            "name": "Bouvet Island",
            "alpha2Code": "BV",
            "alpha3Code": "BVT"
        },
        {
            "name": "Brazil",
            "alpha2Code": "BR",
            "alpha3Code": "BRA"
        },
        {
            "name": "British Indian Ocean Territory",
            "alpha2Code": "IO",
            "alpha3Code": "IOT"
        },
        {
            "name": "United States Minor Outlying Islands",
            "alpha2Code": "UM",
            "alpha3Code": "UMI"
        },
        {
            "name": "Virgin Islands (British)",
            "alpha2Code": "VG",
            "alpha3Code": "VGB"
        },
        {
            "name": "Virgin Islands (U.S.)",
            "alpha2Code": "VI",
            "alpha3Code": "VIR"
        },
        {
            "name": "Brunei Darussalam",
            "alpha2Code": "BN",
            "alpha3Code": "BRN"
        },
        {
            "name": "Bulgaria",
            "alpha2Code": "BG",
            "alpha3Code": "BGR"
        },
        {
            "name": "Burkina Faso",
            "alpha2Code": "BF",
            "alpha3Code": "BFA"
        },
        {
            "name": "Burundi",
            "alpha2Code": "BI",
            "alpha3Code": "BDI"
        },
        {
            "name": "Cambodia",
            "alpha2Code": "KH",
            "alpha3Code": "KHM"
        },
        {
            "name": "Cameroon",
            "alpha2Code": "CM",
            "alpha3Code": "CMR"
        },
        {
            "name": "Cabo Verde",
            "alpha2Code": "CV",
            "alpha3Code": "CPV"
        },
        {
            "name": "Cayman Islands",
            "alpha2Code": "KY",
            "alpha3Code": "CYM"
        },
        {
            "name": "Central African Republic",
            "alpha2Code": "CF",
            "alpha3Code": "CAF"
        },
        {
            "name": "Chad",
            "alpha2Code": "TD",
            "alpha3Code": "TCD"
        },
        {
            "name": "Chile",
            "alpha2Code": "CL",
            "alpha3Code": "CHL"
        },
        {
            "name": "China",
            "alpha2Code": "CN",
            "alpha3Code": "CHN"
        },
        {
            "name": "Christmas Island",
            "alpha2Code": "CX",
            "alpha3Code": "CXR"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "alpha2Code": "CC",
            "alpha3Code": "CCK"
        },
        {
            "name": "Colombia",
            "alpha2Code": "CO",
            "alpha3Code": "COL"
        },
        {
            "name": "Comoros",
            "alpha2Code": "KM",
            "alpha3Code": "COM"
        },
        {
            "name": "Congo",
            "alpha2Code": "CG",
            "alpha3Code": "COG"
        },
        {
            "name": "Congo (Democratic Republic of the)",
            "alpha2Code": "CD",
            "alpha3Code": "COD"
        },
        {
            "name": "Cook Islands",
            "alpha2Code": "CK",
            "alpha3Code": "COK"
        },
        {
            "name": "Costa Rica",
            "alpha2Code": "CR",
            "alpha3Code": "CRI"
        },
        {
            "name": "Croatia",
            "alpha2Code": "HR",
            "alpha3Code": "HRV"
        },
        {
            "name": "Cuba",
            "alpha2Code": "CU",
            "alpha3Code": "CUB"
        },
        {
            "name": "Curaçao",
            "alpha2Code": "CW",
            "alpha3Code": "CUW"
        },
        {
            "name": "Cyprus",
            "alpha2Code": "CY",
            "alpha3Code": "CYP"
        },
        {
            "name": "Czech Republic",
            "alpha2Code": "CZ",
            "alpha3Code": "CZE"
        },
        {
            "name": "Denmark",
            "alpha2Code": "DK",
            "alpha3Code": "DNK"
        },
        {
            "name": "Djibouti",
            "alpha2Code": "DJ",
            "alpha3Code": "DJI"
        },
        {
            "name": "Dominica",
            "alpha2Code": "DM",
            "alpha3Code": "DMA"
        },
        {
            "name": "Dominican Republic",
            "alpha2Code": "DO",
            "alpha3Code": "DOM"
        },
        {
            "name": "Ecuador",
            "alpha2Code": "EC",
            "alpha3Code": "ECU"
        },
        {
            "name": "Egypt",
            "alpha2Code": "EG",
            "alpha3Code": "EGY"
        },
        {
            "name": "El Salvador",
            "alpha2Code": "SV",
            "alpha3Code": "SLV"
        },
        {
            "name": "Equatorial Guinea",
            "alpha2Code": "GQ",
            "alpha3Code": "GNQ"
        },
        {
            "name": "Eritrea",
            "alpha2Code": "ER",
            "alpha3Code": "ERI"
        },
        {
            "name": "Estonia",
            "alpha2Code": "EE",
            "alpha3Code": "EST"
        },
        {
            "name": "Ethiopia",
            "alpha2Code": "ET",
            "alpha3Code": "ETH"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "alpha2Code": "FK",
            "alpha3Code": "FLK"
        },
        {
            "name": "Faroe Islands",
            "alpha2Code": "FO",
            "alpha3Code": "FRO"
        },
        {
            "name": "Fiji",
            "alpha2Code": "FJ",
            "alpha3Code": "FJI"
        },
        {
            "name": "Finland",
            "alpha2Code": "FI",
            "alpha3Code": "FIN"
        },
        {
            "name": "France",
            "alpha2Code": "FR",
            "alpha3Code": "FRA"
        },
        {
            "name": "French Guiana",
            "alpha2Code": "GF",
            "alpha3Code": "GUF"
        },
        {
            "name": "French Polynesia",
            "alpha2Code": "PF",
            "alpha3Code": "PYF"
        },
        {
            "name": "French Southern Territories",
            "alpha2Code": "TF",
            "alpha3Code": "ATF"
        },
        {
            "name": "Gabon",
            "alpha2Code": "GA",
            "alpha3Code": "GAB"
        },
        {
            "name": "Gambia",
            "alpha2Code": "GM",
            "alpha3Code": "GMB"
        },
        {
            "name": "Georgia",
            "alpha2Code": "GE",
            "alpha3Code": "GEO"
        },
        {
            "name": "Germany",
            "alpha2Code": "DE",
            "alpha3Code": "DEU"
        },
        {
            "name": "Ghana",
            "alpha2Code": "GH",
            "alpha3Code": "GHA"
        },
        {
            "name": "Gibraltar",
            "alpha2Code": "GI",
            "alpha3Code": "GIB"
        },
        {
            "name": "Greece",
            "alpha2Code": "GR",
            "alpha3Code": "GRC"
        },
        {
            "name": "Greenland",
            "alpha2Code": "GL",
            "alpha3Code": "GRL"
        },
        {
            "name": "Grenada",
            "alpha2Code": "GD",
            "alpha3Code": "GRD"
        },
        {
            "name": "Guadeloupe",
            "alpha2Code": "GP",
            "alpha3Code": "GLP"
        },
        {
            "name": "Guam",
            "alpha2Code": "GU",
            "alpha3Code": "GUM"
        },
        {
            "name": "Guatemala",
            "alpha2Code": "GT",
            "alpha3Code": "GTM"
        },
        {
            "name": "Guernsey",
            "alpha2Code": "GG",
            "alpha3Code": "GGY"
        },
        {
            "name": "Guinea",
            "alpha2Code": "GN",
            "alpha3Code": "GIN"
        },
        {
            "name": "Guinea-Bissau",
            "alpha2Code": "GW",
            "alpha3Code": "GNB"
        },
        {
            "name": "Guyana",
            "alpha2Code": "GY",
            "alpha3Code": "GUY"
        },
        {
            "name": "Haiti",
            "alpha2Code": "HT",
            "alpha3Code": "HTI"
        },
        {
            "name": "Heard Island and McDonald Islands",
            "alpha2Code": "HM",
            "alpha3Code": "HMD"
        },
        {
            "name": "Holy See",
            "alpha2Code": "VA",
            "alpha3Code": "VAT"
        },
        {
            "name": "Honduras",
            "alpha2Code": "HN",
            "alpha3Code": "HND"
        },
        {
            "name": "Hong Kong",
            "alpha2Code": "HK",
            "alpha3Code": "HKG"
        },
        {
            "name": "Hungary",
            "alpha2Code": "HU",
            "alpha3Code": "HUN"
        },
        {
            "name": "Iceland",
            "alpha2Code": "IS",
            "alpha3Code": "ISL"
        },
        {
            "name": "India",
            "alpha2Code": "IN",
            "alpha3Code": "IND"
        },
        {
            "name": "Indonesia",
            "alpha2Code": "ID",
            "alpha3Code": "IDN"
        },
        {
            "name": "Côte d'Ivoire",
            "alpha2Code": "CI",
            "alpha3Code": "CIV"
        },
        {
            "name": "Iran (Islamic Republic of)",
            "alpha2Code": "IR",
            "alpha3Code": "IRN"
        },
        {
            "name": "Iraq",
            "alpha2Code": "IQ",
            "alpha3Code": "IRQ"
        },
        {
            "name": "Ireland",
            "alpha2Code": "IE",
            "alpha3Code": "IRL"
        },
        {
            "name": "Isle of Man",
            "alpha2Code": "IM",
            "alpha3Code": "IMN"
        },
        {
            "name": "Israel",
            "alpha2Code": "IL",
            "alpha3Code": "ISR"
        },
        {
            "name": "Italy",
            "alpha2Code": "IT",
            "alpha3Code": "ITA"
        },
        {
            "name": "Jamaica",
            "alpha2Code": "JM",
            "alpha3Code": "JAM"
        },
        {
            "name": "Japan",
            "alpha2Code": "JP",
            "alpha3Code": "JPN"
        },
        {
            "name": "Jersey",
            "alpha2Code": "JE",
            "alpha3Code": "JEY"
        },
        {
            "name": "Jordan",
            "alpha2Code": "JO",
            "alpha3Code": "JOR"
        },
        {
            "name": "Kazakhstan",
            "alpha2Code": "KZ",
            "alpha3Code": "KAZ"
        },
        {
            "name": "Kenya",
            "alpha2Code": "KE",
            "alpha3Code": "KEN"
        },
        {
            "name": "Kiribati",
            "alpha2Code": "KI",
            "alpha3Code": "KIR"
        },
        {
            "name": "Kuwait",
            "alpha2Code": "KW",
            "alpha3Code": "KWT"
        },
        {
            "name": "Kyrgyzstan",
            "alpha2Code": "KG",
            "alpha3Code": "KGZ"
        },
        {
            "name": "Lao People's Democratic Republic",
            "alpha2Code": "LA",
            "alpha3Code": "LAO"
        },
        {
            "name": "Latvia",
            "alpha2Code": "LV",
            "alpha3Code": "LVA"
        },
        {
            "name": "Lebanon",
            "alpha2Code": "LB",
            "alpha3Code": "LBN"
        },
        {
            "name": "Lesotho",
            "alpha2Code": "LS",
            "alpha3Code": "LSO"
        },
        {
            "name": "Liberia",
            "alpha2Code": "LR",
            "alpha3Code": "LBR"
        },
        {
            "name": "Libya",
            "alpha2Code": "LY",
            "alpha3Code": "LBY"
        },
        {
            "name": "Liechtenstein",
            "alpha2Code": "LI",
            "alpha3Code": "LIE"
        },
        {
            "name": "Lithuania",
            "alpha2Code": "LT",
            "alpha3Code": "LTU"
        },
        {
            "name": "Luxembourg",
            "alpha2Code": "LU",
            "alpha3Code": "LUX"
        },
        {
            "name": "Macao",
            "alpha2Code": "MO",
            "alpha3Code": "MAC"
        },
        {
            "name": "Macedonia (the former Yugoslav Republic of)",
            "alpha2Code": "MK",
            "alpha3Code": "MKD"
        },
        {
            "name": "Madagascar",
            "alpha2Code": "MG",
            "alpha3Code": "MDG"
        },
        {
            "name": "Malawi",
            "alpha2Code": "MW",
            "alpha3Code": "MWI"
        },
        {
            "name": "Malaysia",
            "alpha2Code": "MY",
            "alpha3Code": "MYS"
        },
        {
            "name": "Maldives",
            "alpha2Code": "MV",
            "alpha3Code": "MDV"
        },
        {
            "name": "Mali",
            "alpha2Code": "ML",
            "alpha3Code": "MLI"
        },
        {
            "name": "Malta",
            "alpha2Code": "MT",
            "alpha3Code": "MLT"
        },
        {
            "name": "Marshall Islands",
            "alpha2Code": "MH",
            "alpha3Code": "MHL"
        },
        {
            "name": "Martinique",
            "alpha2Code": "MQ",
            "alpha3Code": "MTQ"
        },
        {
            "name": "Mauritania",
            "alpha2Code": "MR",
            "alpha3Code": "MRT"
        },
        {
            "name": "Mauritius",
            "alpha2Code": "MU",
            "alpha3Code": "MUS"
        },
        {
            "name": "Mayotte",
            "alpha2Code": "YT",
            "alpha3Code": "MYT"
        },
        {
            "name": "Mexico",
            "alpha2Code": "MX",
            "alpha3Code": "MEX"
        },
        {
            "name": "Micronesia (Federated States of)",
            "alpha2Code": "FM",
            "alpha3Code": "FSM"
        },
        {
            "name": "Moldova (Republic of)",
            "alpha2Code": "MD",
            "alpha3Code": "MDA"
        },
        {
            "name": "Monaco",
            "alpha2Code": "MC",
            "alpha3Code": "MCO"
        },
        {
            "name": "Mongolia",
            "alpha2Code": "MN",
            "alpha3Code": "MNG"
        },
        {
            "name": "Montenegro",
            "alpha2Code": "ME",
            "alpha3Code": "MNE"
        },
        {
            "name": "Montserrat",
            "alpha2Code": "MS",
            "alpha3Code": "MSR"
        },
        {
            "name": "Morocco",
            "alpha2Code": "MA",
            "alpha3Code": "MAR"
        },
        {
            "name": "Mozambique",
            "alpha2Code": "MZ",
            "alpha3Code": "MOZ"
        },
        {
            "name": "Myanmar",
            "alpha2Code": "MM",
            "alpha3Code": "MMR"
        },
        {
            "name": "Namibia",
            "alpha2Code": "NA",
            "alpha3Code": "NAM"
        },
        {
            "name": "Nauru",
            "alpha2Code": "NR",
            "alpha3Code": "NRU"
        },
        {
            "name": "Nepal",
            "alpha2Code": "NP",
            "alpha3Code": "NPL"
        },
        {
            "name": "Netherlands",
            "alpha2Code": "NL",
            "alpha3Code": "NLD"
        },
        {
            "name": "New Caledonia",
            "alpha2Code": "NC",
            "alpha3Code": "NCL"
        },
        {
            "name": "Nicaragua",
            "alpha2Code": "NI",
            "alpha3Code": "NIC"
        },
        {
            "name": "Niger",
            "alpha2Code": "NE",
            "alpha3Code": "NER"
        },
        {
            "name": "Nigeria",
            "alpha2Code": "NG",
            "alpha3Code": "NGA"
        },
        {
            "name": "Niue",
            "alpha2Code": "NU",
            "alpha3Code": "NIU"
        },
        {
            "name": "Norfolk Island",
            "alpha2Code": "NF",
            "alpha3Code": "NFK"
        },
        {
            "name": "Korea (Democratic People's Republic of)",
            "alpha2Code": "KP",
            "alpha3Code": "PRK"
        },
        {
            "name": "Northern Mariana Islands",
            "alpha2Code": "MP",
            "alpha3Code": "MNP"
        },
        {
            "name": "Norway",
            "alpha2Code": "NO",
            "alpha3Code": "NOR"
        },
        {
            "name": "Oman",
            "alpha2Code": "OM",
            "alpha3Code": "OMN"
        },
        {
            "name": "Pakistan",
            "alpha2Code": "PK",
            "alpha3Code": "PAK"
        },
        {
            "name": "Palau",
            "alpha2Code": "PW",
            "alpha3Code": "PLW"
        },
        {
            "name": "Palestine, State of",
            "alpha2Code": "PS",
            "alpha3Code": "PSE"
        },
        {
            "name": "Panama",
            "alpha2Code": "PA",
            "alpha3Code": "PAN"
        },
        {
            "name": "Papua New Guinea",
            "alpha2Code": "PG",
            "alpha3Code": "PNG"
        },
        {
            "name": "Paraguay",
            "alpha2Code": "PY",
            "alpha3Code": "PRY"
        },
        {
            "name": "Peru",
            "alpha2Code": "PE",
            "alpha3Code": "PER"
        },
        {
            "name": "Philippines",
            "alpha2Code": "PH",
            "alpha3Code": "PHL"
        },
        {
            "name": "Pitcairn",
            "alpha2Code": "PN",
            "alpha3Code": "PCN"
        },
        {
            "name": "Poland",
            "alpha2Code": "PL",
            "alpha3Code": "POL"
        },
        {
            "name": "Portugal",
            "alpha2Code": "PT",
            "alpha3Code": "PRT"
        },
        {
            "name": "Puerto Rico",
            "alpha2Code": "PR",
            "alpha3Code": "PRI"
        },
        {
            "name": "Qatar",
            "alpha2Code": "QA",
            "alpha3Code": "QAT"
        },
        {
            "name": "Republic of Kosovo",
            "alpha2Code": "XK",
            "alpha3Code": "KOS"
        },
        {
            "name": "Réunion",
            "alpha2Code": "RE",
            "alpha3Code": "REU"
        },
        {
            "name": "Romania",
            "alpha2Code": "RO",
            "alpha3Code": "ROU"
        },
        {
            "name": "Russian Federation",
            "alpha2Code": "RU",
            "alpha3Code": "RUS"
        },
        {
            "name": "Rwanda",
            "alpha2Code": "RW",
            "alpha3Code": "RWA"
        },
        {
            "name": "Saint Barthélemy",
            "alpha2Code": "BL",
            "alpha3Code": "BLM"
        },
        {
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "alpha2Code": "SH",
            "alpha3Code": "SHN"
        },
        {
            "name": "Saint Kitts and Nevis",
            "alpha2Code": "KN",
            "alpha3Code": "KNA"
        },
        {
            "name": "Saint Lucia",
            "alpha2Code": "LC",
            "alpha3Code": "LCA"
        },
        {
            "name": "Saint Martin (French part)",
            "alpha2Code": "MF",
            "alpha3Code": "MAF"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "alpha2Code": "PM",
            "alpha3Code": "SPM"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "alpha2Code": "VC",
            "alpha3Code": "VCT"
        },
        {
            "name": "Samoa",
            "alpha2Code": "WS",
            "alpha3Code": "WSM"
        },
        {
            "name": "San Marino",
            "alpha2Code": "SM",
            "alpha3Code": "SMR"
        },
        {
            "name": "Sao Tome and Principe",
            "alpha2Code": "ST",
            "alpha3Code": "STP"
        },
        {
            "name": "Saudi Arabia",
            "alpha2Code": "SA",
            "alpha3Code": "SAU"
        },
        {
            "name": "Senegal",
            "alpha2Code": "SN",
            "alpha3Code": "SEN"
        },
        {
            "name": "Serbia",
            "alpha2Code": "RS",
            "alpha3Code": "SRB"
        },
        {
            "name": "Seychelles",
            "alpha2Code": "SC",
            "alpha3Code": "SYC"
        },
        {
            "name": "Sierra Leone",
            "alpha2Code": "SL",
            "alpha3Code": "SLE"
        },
        {
            "name": "Singapore",
            "alpha2Code": "SG",
            "alpha3Code": "SGP"
        },
        {
            "name": "Sint Maarten (Dutch part)",
            "alpha2Code": "SX",
            "alpha3Code": "SXM"
        },
        {
            "name": "Slovakia",
            "alpha2Code": "SK",
            "alpha3Code": "SVK"
        },
        {
            "name": "Slovenia",
            "alpha2Code": "SI",
            "alpha3Code": "SVN"
        },
        {
            "name": "Solomon Islands",
            "alpha2Code": "SB",
            "alpha3Code": "SLB"
        },
        {
            "name": "Somalia",
            "alpha2Code": "SO",
            "alpha3Code": "SOM"
        },
        {
            "name": "South Africa",
            "alpha2Code": "ZA",
            "alpha3Code": "ZAF"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "alpha2Code": "GS",
            "alpha3Code": "SGS"
        },
        {
            "name": "Korea (Republic of)",
            "alpha2Code": "KR",
            "alpha3Code": "KOR"
        },
        {
            "name": "South Sudan",
            "alpha2Code": "SS",
            "alpha3Code": "SSD"
        },
        {
            "name": "Spain",
            "alpha2Code": "ES",
            "alpha3Code": "ESP"
        },
        {
            "name": "Sri Lanka",
            "alpha2Code": "LK",
            "alpha3Code": "LKA"
        },
        {
            "name": "Sudan",
            "alpha2Code": "SD",
            "alpha3Code": "SDN"
        },
        {
            "name": "Suriname",
            "alpha2Code": "SR",
            "alpha3Code": "SUR"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "alpha2Code": "SJ",
            "alpha3Code": "SJM"
        },
        {
            "name": "Swaziland",
            "alpha2Code": "SZ",
            "alpha3Code": "SWZ"
        },
        {
            "name": "Sweden",
            "alpha2Code": "SE",
            "alpha3Code": "SWE"
        },
        {
            "name": "Switzerland",
            "alpha2Code": "CH",
            "alpha3Code": "CHE"
        },
        {
            "name": "Syrian Arab Republic",
            "alpha2Code": "SY",
            "alpha3Code": "SYR"
        },
        {
            "name": "Taiwan",
            "alpha2Code": "TW",
            "alpha3Code": "TWN"
        },
        {
            "name": "Tajikistan",
            "alpha2Code": "TJ",
            "alpha3Code": "TJK"
        },
        {
            "name": "Tanzania, United Republic of",
            "alpha2Code": "TZ",
            "alpha3Code": "TZA"
        },
        {
            "name": "Thailand",
            "alpha2Code": "TH",
            "alpha3Code": "THA"
        },
        {
            "name": "Timor-Leste",
            "alpha2Code": "TL",
            "alpha3Code": "TLS"
        },
        {
            "name": "Togo",
            "alpha2Code": "TG",
            "alpha3Code": "TGO"
        },
        {
            "name": "Tokelau",
            "alpha2Code": "TK",
            "alpha3Code": "TKL"
        },
        {
            "name": "Tonga",
            "alpha2Code": "TO",
            "alpha3Code": "TON"
        },
        {
            "name": "Trinidad and Tobago",
            "alpha2Code": "TT",
            "alpha3Code": "TTO"
        },
        {
            "name": "Tunisia",
            "alpha2Code": "TN",
            "alpha3Code": "TUN"
        },
        {
            "name": "Turkey",
            "alpha2Code": "TR",
            "alpha3Code": "TUR"
        },
        {
            "name": "Turkmenistan",
            "alpha2Code": "TM",
            "alpha3Code": "TKM"
        },
        {
            "name": "Turks and Caicos Islands",
            "alpha2Code": "TC",
            "alpha3Code": "TCA"
        },
        {
            "name": "Tuvalu",
            "alpha2Code": "TV",
            "alpha3Code": "TUV"
        },
        {
            "name": "Uganda",
            "alpha2Code": "UG",
            "alpha3Code": "UGA"
        },
        {
            "name": "Ukraine",
            "alpha2Code": "UA",
            "alpha3Code": "UKR"
        },
        {
            "name": "United Arab Emirates",
            "alpha2Code": "AE",
            "alpha3Code": "ARE"
        },
        {
            "name": "Uruguay",
            "alpha2Code": "UY",
            "alpha3Code": "URY"
        },
        {
            "name": "Uzbekistan",
            "alpha2Code": "UZ",
            "alpha3Code": "UZB"
        },
        {
            "name": "Vanuatu",
            "alpha2Code": "VU",
            "alpha3Code": "VUT"
        },
        {
            "name": "Venezuela (Bolivarian Republic of)",
            "alpha2Code": "VE",
            "alpha3Code": "VEN"
        },
        {
            "name": "Viet Nam",
            "alpha2Code": "VN",
            "alpha3Code": "VNM"
        },
        {
            "name": "Wallis and Futuna",
            "alpha2Code": "WF",
            "alpha3Code": "WLF"
        },
        {
            "name": "Western Sahara",
            "alpha2Code": "EH",
            "alpha3Code": "ESH"
        },
        {
            "name": "Yemen",
            "alpha2Code": "YE",
            "alpha3Code": "YEM"
        },
        {
            "name": "Zambia",
            "alpha2Code": "ZM",
            "alpha3Code": "ZMB"
        },
        {
            "name": "Zimbabwe",
            "alpha2Code": "ZW",
            "alpha3Code": "ZWE"
        }
    ],
    "ONBOARDING_STEPS": {
        0: "register-component",
        1: "finish-creating",
        2: "create-password",
        3: "choose-plan",
        4: "plan-selector",
        5: "enter-payment",
        6: "payment-success",
        7: "payment-failure",
        8: "free-success",
        9: "survey-welcome",
        10: "survey-section",
        100: "building-terminal"
    },
    "PANELLAYOUTIDS": {
        1: {
            cols: 1,
            rows: 1
        },
        2: {
            cols: 2,
            rows: 1
        },
        3: {
            cols: 3,
            rows: 1
        },
        4: {
            cols: 2,
            rows: 2
        },
        "availableRows": 2
    },
    "COMPONENTS": {
        "home": "home-component",
        "dashboard": "dashboard-component",
        "account": "account-overview",
        "checkout": "checkout-component",
        "videos": "video-component",
        "research": "research-component"
    },
    "PLAN_CONSTANTS": {
        "commitment_texts": {
            "day": "Daily Plan.",
            "week": "Weekly Plan.",
            "month": "Monthly Plan.",
            "year": "Annual Plan.",
        },
        "length_values": {
            "day": 1,
            "week": 7,
            "month": 30,
            "year": 360,
        }
    },
    "ERB_PROMO": {
        "dev": 106,
        "live": 80
    },
    "MTA_PROMO": {
        "dev": 139,
        "live": 84
    },
    "MTA_COURSE": { // implementation_id !
        "dev": 94,
        "live": 81
    },
    "SHOW_ONLY_IF_PURCHASED_TCS": {
        "dev": [], // 70, 94, 91, 95
        "live": [] // 73, 79, 80, 81, 82
    },
    "MTA_FREE_ROUTE": 726431,
    "TC_FREE_VIDEOS": {
        "dev": {
            94: {
                "full_access_prod_ids": [139],
                "free_videos": ["761482395", "760937768"]
            }
        },
        "live": {
            81: {
                "full_access_prod_ids": [84],
                "free_videos": ["762752971", "760937805", "760940253", "760946756", "760954155", "761002004", "761127105", "761482514", "761482898", "762173399"]
            }
        }
    }
}
