<template>
    <div class="change-plan">
        <div class="modal-info-box">
            <span class="modal-info-box-icon">
                <font-awesome-icon :icon="['fas', 'info-circle']" size="2x"/>
            </span>
            <span class="modal-info-box-text">
                <p>
                    <template v-if="promotionalProducts.length > 0">Our promotional products allow you to access exclusive
                        content and special bundles for reduced rates. <br/> Every promo is time limited and never repeated.
                    </template>
                    <template v-else>We have no promotional products available right now. <br/> We'll notify you when we
                        launch our next one and display it here.
                    </template>
                </p>
            </span>
        </div>
        <div class="plan-container">
            <promotion-product-details v-for="product in promotionalProducts" :key="product.slug"
                                       :plan="product"
                                       :price="formatPriceObject(product.pricing, false)"
                                       :change-plan-handler="purchasePromotion"
            >
                <span v-if="newPromosList.includes(product.slug)" class='badge not-round'>New</span>
            </promotion-product-details>
        </div>
    </div>
</template>

<script>
import PromotionProductDetails from "../payments/PromotionProductDetails";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";

import {createNamespacedHelpers} from "vuex";
import _ from "lodash";

const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
export default {
    name: "PromotionalProducts",
    mixins: [SubscriptionMixin],
    components: {PromotionProductDetails},
    data: function () {
        return {
            newPromosList: []
        }
    },
    computed: {
        ...payments.mapGetters(['newPromos']),
    },
    methods: {
        ...general.mapActions(['setLastShopVisitDate']),
    },

    created() {
        this.newPromosList = _.cloneDeep(this.newPromos);
        this.$nextTick(() => {
            this.setLastShopVisitDate(['PROMO', new Date()]);
        });
    }
}
</script>

<style lang="scss" scoped>
.change-plan {
  margin-top: 20px;
  padding: 15px;
  // background-color: rgba($color-03, 0.6);

  @include respond(md) {
    margin-right: 5px;
  }
}

.plan-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    // justify-items: center;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-around;
//   flex-direction: column;
//   align-items: center;

    @include respond(md) {
        // flex-direction: row;
        grid-template-columns: repeat(2, 1fr);
    }

  @include respond(lg) {
    // flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
  }
}

</style>
