<template>
    <div class="widget-config" style="padding-top: 20px; height: 100%;">
        <div class="shop">
            <div class="shop-tabs">
                <div class="tabs-body">
                    <div>
                        <item-instruction v-if="activeTab ==='instructions'"
                        :item="product"
                        mode="purchase"
                        @show-activation="activeTab = 'howactivation'"
                        ></item-instruction>
                    </div>
                    <div v-if="activeTab === 'howactivation'">
                        <how-activation-works
                        :isCourse="details.isCourse"
                        :isLiveStream="details.isLiveStream"
                        :product="product"
                        @back-to-instructions="activeTab = 'instructions'"
                        ></how-activation-works>
                    </div>
                </div>
            </div>
            <div class="shop-bottom">
                <!-- SPECIAL PROMO -->
                <div 
                v-if="isPromoProduct"
                class="col-lg-2 flex-center-lg"
                style="height:100%"
                >
                    
                    <base-button
                    mode="o-blue fullwidth"
                    :link="false"
                    @click.native="learnMore"
                    >Learn More
                    </base-button>
                    
                    <base-button
                    mode="green fullwidth"
                    :link="false"
                    @click.native="purchaseClicked"
                    >Purchase now
                    </base-button>
                </div>
                <!-- SPECIAL PROMO ENDS -->
                <div 
                v-else
                class="col-lg-2 flex-center-lg"
                style="height:100%; gap: 0;"
                >       
                    <div></div>         
                    <base-button
                    mode="green fullwidth"
                    :link="false"
                    @click.native="purchaseClicked"
                    >Purchase now
                    </base-button>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemInstruction from './ItemInstruction.vue';
import HowActivationWorks from './HowActivationWorks.vue';
import { eventBus } from '../../../main';
import { createNamespacedHelpers } from 'vuex';
import { getServerEnv, isValidPromo } from '../../../utility/helper-functions';
import Constants from '../../../utility/constants';

const payments = createNamespacedHelpers('payments');

export default {
    props: ["details"],
    components: {
        HowActivationWorks,
        ItemInstruction
    },
    // created() {
    //     console.log("product config", this.details);
    // },
    computed: {
        ...payments.mapState(['availableProducts']),

        product() {
            return Object.values(this.availableProducts).find(el => el.id === this.details.id);
        },
        isPromoProduct() {
            const env = getServerEnv();
            const promoID = this.product?.id
            return window.bootUp.products[env].includes(promoID);
           
        },
    },
    data() {
        return {
            activeTab: 'instructions'
        }
    },
    methods: {
        purchaseClicked() {
            //SPECIAL PROMO
            if(isValidPromo(this.product.id))
                localStorage.setItem("promo", this.product.id);
            //SPECIAL PROMO ENDS
            
            const type = this.product.product_type === 'plan' ? 'plan' : 'promotion';
            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                    comp: "checkout",
                    details: {
                        type: type,
                        selectedItem: this.product
                    }
            });
            // this.showModal(CheckoutOverview, {
            //     type: "promotion",
            //     selectedItem: this.details.product
            // }, {
            //     style: "width: 95% !important; height: 95% !important",
            //     resizable: true
            // })
            eventBus.$emit("close-modal");
            // console.log("purchase", this.details.product.id);
        },
        learnMore() {
            const env = getServerEnv();
            const promoID = this.product?.id;
            const erbPromo = env === 'live' && promoID === 80 || env !== 'live' && promoID === 106;
            const mtaPromo = env === 'live' && Constants.MTA_PROMO[env] === promoID || env !== 'live' && Constants.MTA_PROMO[env] === promoID;

            if(erbPromo)
                window.open("https://go.financialsource.co/research-bundle/", "_blank").focus();
            else if(mtaPromo)
                window.open("https://go.financialsource.co/accelerator", "_blank").focus();
        }
    }
}
</script>
