<template>
    <div id="container">
        <div class="top-row">
            <current-subscription-info @toggleChangePlan="toggleChangePlan"></current-subscription-info>
        </div>
        <div id="change-plan">
            <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down" >
                <change-plan
                        ref="slideDown"
                ></change-plan>
            </vue-slide-up-down>
        </div>
    </div>
</template>

<script>
import ChangePlan from "../forms/ChangePlan";
import CurrentSubscriptionInfo from "./CurrentSubscriptionInfo";
import EventBus from "../../../store/bus";
export default {
    name: "SubscriptionSettings",
    props: {
        selectedInvoices: Array
    },
    components: {CurrentSubscriptionInfo, ChangePlan},
    data: function () {
        return {
            isSlideDownOpen: false
        }
    },
    methods: {
        toggleChangePlan: function (isSlideDownOpen) {
            if(isSlideDownOpen) {
                this.$nextTick(() => EventBus.$emit("scroll-to-plan"));
            }
            this.isSlideDownOpen = isSlideDownOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
.settings-tabs .tabs-body-content {

  @include respond(lg) {
    height: 535px;
  }

}

.top-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include respond(md) {
    flex-direction: row;
  }
}

.top-row > div {
  width: 100%;
}

.inside-row {
  margin: 5px 0;
  padding: 6px 0;

  @include respond(md) {
    text-align: right;
  }
}

</style>
