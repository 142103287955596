import Constants from "@/utility/constants";
import _ from "lodash";
import {collectIncludingProds, formatPriceObject, getServerEnv, isThisProductMtaPromo, precise} from "../../utility/helper-functions";
import dayjs from "dayjs";
import {addDays, toSystemDate, usersTime } from "../../utility/datetime";
export default {
    currentPlans: function (state) {
        return state.currentProducts
            ?.filter(product => product.product?.product_type === Constants.PLAN_TYPE || product.product?.trial_of_product_id > 0) || [];
    },
    currentAddons: function (state) {
        return state.currentProducts?.filter(product => product.product?.product_type === Constants.ADDON_TYPE && product.product?.trial_of_product_id === null) || [];
    },
    currentShowcaseWidgets: function (state, getters) {
        console.log(getters.currentPlans);
        return Object.values(state.availableProducts || {})?.map(plan => plan.widgets?.filter(widget => widget.showcase === true) || [])?.flat(1);
    },
    scheduledPlans: function (state) {
        return state.scheduledProducts?.filter(product => product.product?.product_type === Constants.PLAN_TYPE) || [];
    },
    scheduledAddons: function (state) {
        return state.scheduledProducts?.filter(product => product.product?.product_type === Constants.ADDON_TYPE) || [];
    },
    userHasActiveSubscriptions: function (state) {
        let activeProducts = state.currentProducts?.filter(plan => plan.product?.is_subscription === true && plan.ended_at === null) || [];
        let scheduledProducts = state.scheduledProducts?.filter(plan => plan.product?.is_subscription === true && plan.ended_at === null) || [];
        return activeProducts.length > 0 || scheduledProducts.length > 0;
    },
    userHasPurchasedProducts: function (state) {
        let activeProducts = state.currentProducts?.filter(plan => plan.product?.is_subscription === true && plan.ended_at === null)
            ?.filter(plan => plan.price > 0)|| [];
        let scheduledProducts = state.scheduledProducts?.filter(plan => plan.product?.is_subscription === true && plan.ended_at === null) || [];
        return activeProducts.length > 0 || scheduledProducts.length > 0;
    },
    addonSubscriptions: function (state, getters) {
        return getters.currentAddons.filter(addon => addon.product.is_subscription);
    },
    addonPromos: function (state, getters) {
        return getters.currentAddons.filter(addon => addon.product.is_promotional === true);
    },
    activeSubscriptions: function (state, getters) {
        return getters.addonSubscriptions.length > 0 || getters.currentPlans.length > 0;
    },
    activePlan: function (state, getters) {
        let currentDate = new Date().getTime();
        let activePlan = _.orderBy(getters.currentPlans
            .filter(plan => new Date(plan.started_at).getTime() <= currentDate && (plan.status === 'renewfail ' || plan.status === 'active') || plan.ended_at === null), product => product.product.trial_of_product_id)?.[0] || {};
        if(activePlan?.product?.trial_of_product_id > 0) {
            activePlan.product.mainTrialProduct = Object.values(state.availableProducts)
                .find(product => product.id === activePlan.product.trial_of_product_id)
        }
        // if(activePlan) {
        //     let currentPlanWidgetIds = activePlan.product?.widgets?.map(widget => widget.implementation_id) || [];
        //     getters.currentShowcaseWidgets.forEach(widget => {
        //         // console.log(currentPlanWidgetIds,widget.implementation_id)
        //         if(!currentPlanWidgetIds.includes(widget.implementation_id)) {
        //             let clonedWidget = _.cloneDeep(widget);
        //             clonedWidget.generated = true;
        //             activePlan.product?.widgets?.push(clonedWidget);
        //         }
        //     })

        //     console.log("activePlan",activePlan);
            
        // }

        if(activePlan) {
            let currentPlanWidgetIds = activePlan.product?.widgets?.map(widget => widget.implementation_id) || [];
            const id = activePlan.product?.parent_id ? activePlan.product.parent_id : activePlan.product?.id;
            const showcases = Object.values(state.availableProducts).length ? Object.values(state.availableProducts).filter(product => product.product_type === Constants.PLAN_TYPE)?.find(el => el.id === id)?.widgets.filter(el => el.showcase) : [];
            
            showcases?.forEach(widget => {
                // console.log(currentPlanWidgetIds,widget)
                if(!currentPlanWidgetIds.includes(widget.implementation_id)) {
                    let clonedWidget = _.cloneDeep(widget);
                    clonedWidget.generated = true;
                    activePlan.product?.widgets?.push(clonedWidget);
                }
            })

            // console.log("activePlan",activePlan);
            
        }
        return activePlan;
    },
    isCurrentPlanTrial: function (state, getters) {
        return getters.activePlan.product?.trial_of_product_id > 0;
    },
    isCurrentPlanChild: function (state, getters) {
        return getters.activePlan.product?.parent_id > 0;
    },
    upcomingPlans: function (state, getters) {
        let currentDate = new Date().getTime();
        let isCurrentPlanTrial = getters.activePlan.product?.trial_of_product_id > 0;
        let upcomingPlans = state.scheduledProducts.filter(plan => new Date(plan.started_at).getTime() > currentDate) || [];
        if(isCurrentPlanTrial) {
            let currentPlan = getters.currentPlans
                .find(plan => (new Date(plan.started_at).getTime() <= currentDate && ((plan.status === 'renewfail ' || plan.status === 'active')) && plan.product.id !== getters.activePlan.product?.id) || plan.ended_at === null && plan.product.id !== getters.activePlan.product?.id);
            if(currentPlan) {
                upcomingPlans.unshift(currentPlan);
            }
        }
        return upcomingPlans;
    },
    currentAddonsSlugs: function (state, getters) {
        return getters.currentAddons?.map(addon => addon.product.slug) || [];
    },
    upcomingPlansSlugs: function (state, getters) {
        return getters.upcomingPlans?.map(plan => plan?.product?.slug) || [];
    },
    currentProducts(state) {
        return state.currentProducts;
    },
    currentProductsSlugs: function (state) {
        return state.currentProducts?.map(addon => addon.product.slug) || [];
    },
    plansForUpgrade: function (state, getters) {
        let context = getters;
        let productTrials = _.groupBy(Object.values(state.availableProducts), product => product.trial_of_product_id);
        let plans = _.orderBy(Object.values(state.availableProducts)
            .filter(product => product.slug !== getters.activePlan?.product?.slug)
            .filter(product => product.is_promotion === false)
            .filter(product => product.product_type === Constants.PLAN_TYPE)
            .filter(product => product.is_visible_front_end === true)
            .filter(product => product.trial_of_product_id === null)
            .filter(product => product.parent_id === null)
            .filter(product => product.id !== context.activePlan?.product?.trial_of_product_id)
            .filter(product => product.id !== context.activePlan?.product?.parent_id)
            .filter(product => !context.upcomingPlansSlugs.includes(product.slug))
            .filter(product => !context.currentAddonsSlugs.includes(product.slug)), product => product.pricing['USD']);
        plans.forEach(plan => plan.trials = productTrials[plan.id] || []);
        return plans;
    },
    availablePlans: function (state, getters) {
        let productTrials = _.groupBy(Object.values(state.availableProducts), product => product.trial_of_product_id);

        let productCommitments = _.groupBy(Object.values(state.availableProducts)
            .filter(item => item.trial_of_product_id === null), product => product.parent_id);

        const now = dayjs.utc().format().replace('T',' ').replace('Z', '');
        const env = getServerEnv();
        const wasMTAAndStillEligible = getters.historicProductAccess.find(el => { 

            return el.product.parent_id === Constants.MTA_PROMO[env] && el.status === 'cancelled' && addDays(7,el.ended_at.replace('T',' ').replace('.000000Z', '')) > now
        });
        const isMTAMember = getters.currentAddons.filter(el => el.product.parent_id === Constants.MTA_PROMO[env]).length;
        const hadThisNotVisiblePlan = getters.historicProductAccess.find(el => !el.product.is_visible_front_end);
        const activePlanVisibleFrontEnd = getters.activePlan?.product?.is_visible_front_end;

        let plans =_.orderBy(Object.values(state.availableProducts)
            .filter(product => product.is_promotion === false)
            .filter(product => product.product_type === Constants.PLAN_TYPE)
            .filter(product => {
                if(wasMTAAndStillEligible && !isMTAMember && hadThisNotVisiblePlan?.product?.id === product.id && activePlanVisibleFrontEnd) {
                    return true;
                }

                return product.is_visible_front_end === true;
            })
            .filter(product => product.parent_id === null)
            .filter(product => product.trial_of_product_id === null), product => product.pricing['USD']);
        plans.forEach(plan => {
            plan.trials = productTrials[plan.id] || [];
            plan.commitments = productCommitments[plan.id] || [];
        });
        return plans;
    },
    availablePlansForComparison: function (state, getters) {
        let productTrials = _.groupBy(Object.values(state.availableProducts), product => product.trial_of_product_id);
        let productCommitments = _.groupBy(Object.values(state.availableProducts)
            .filter(item => item.trial_of_product_id === null), product => product.parent_id);
        let plans =_.orderBy(Object.values(state.availableProducts)
            .filter(product => product.is_promotion === false)
            .filter(product => product.product_type === Constants.PLAN_TYPE)
            .filter(product => product.is_visible_front_end === true || product.id === getters.activePlan?.product?.id)
            .filter(product => product.parent_id === null)
            .filter(product => product.trial_of_product_id === null), product => product.pricing['USD']);
        plans.forEach(plan => {
            plan.trials = productTrials[plan.id] || [];
            plan.commitments = productCommitments[plan.id] || [];
        });
        return plans;
    },
    nextPaymentInformations: function (state, getters, root) {
        let nextPayments = {};
        let addProductBillingDetails = product => {
            if(product.next_billing_at && product.ended_at === null) {
                let nextBillingAt = product.next_billing_at.substr(0, 10);
                if(product.status === 'renewfail') {
                    nextBillingAt = product.last_billing_at.substr(0, 10);
                }
                if(!Object.prototype.hasOwnProperty.call(nextPayments, nextBillingAt)) {
                    nextPayments[nextBillingAt] = [];
                }
                nextPayments[nextBillingAt].push(product.price);
            }
        };
        state.currentProducts.forEach(addProductBillingDetails);
        state.scheduledProducts.forEach(addProductBillingDetails);
        let nextPaymentDate = Object.keys(nextPayments)
            .sort((date1, date2) => {
                return dayjs(date1).diff(dayjs(date2)) > 0 ? 1: 0;
        })?.[0];
        let nextPaymentPrice = nextPayments[nextPaymentDate]?.reduce((a, b) => parseInt(a)+parseInt(b), 0);
        let displayVatInformation = root.general.user.sales_tax_percent > 0;
        if(displayVatInformation) {
            nextPaymentPrice = precise(nextPaymentPrice * (root.general.user.sales_tax_percent/100 + 1));
        }
        if(nextPaymentDate && nextPaymentPrice > 0) {
           return  "$" + nextPaymentPrice/100 + " on " + dayjs(nextPaymentDate).format("DD-MMMM-YY");
        } else {
            return 0;
        }
    },
    nextPaymentInformation: function (state, getters, root) {
        let nextPayments = {};
        let addProductBillingDetails = product => {
            if(product.next_billing_at && product.ended_at === null) {
                let nextBillingAt = product.next_billing_at.substr(0, 10);
                if(product.status === 'renewfail') {
                    nextBillingAt = product.last_billing_at.substr(0, 10);
                }

                if(product.price > 0) {
                    if(!Object.prototype.hasOwnProperty.call(nextPayments, nextBillingAt)) {
                        nextPayments[nextBillingAt] = [];
                    }
                    nextPayments[nextBillingAt].push({
                        date: nextBillingAt,
                        product: product
                    });
                }


            }
        };
        state.currentProducts.forEach(addProductBillingDetails);
        state.scheduledProducts.forEach(addProductBillingDetails);
        let displayVatInformation = root.general.user.sales_tax_percent > 0;

        let payments = [];
        Object.entries(nextPayments).forEach(([date, products]) => {
            let nextPaymentPrice = products?.map(product => product.product.price)?.reduce((a, b) => parseInt(a)+parseInt(b), 0);
            if(displayVatInformation) {
                nextPaymentPrice = precise(nextPaymentPrice * (root.general.user.sales_tax_percent/100 + 1));
            }
            let productsNames = products.map(product => product.product.product.name).join(" + ");
            if(nextPaymentPrice > 0) {
                let paymentPriceDetails = productsNames +  " $" + nextPaymentPrice/100 + " on " + dayjs(date).format("DD-MMMM-YY");
                payments.push({
                   products: products,
                   paymentPriceDetails: paymentPriceDetails,
                });
            }

        });
       return {
           payments: payments,
           active: payments.length > 0
       }

    },
    isCurrentCardValid: function (state) {
        let validUntil = new Date(state.paymentMethod.valid_until).getTime();
        let currentDate = new Date().getTime();
        // console.log("validUntil", state.paymentMethod.valid_until);
        return !isNaN(validUntil) ? validUntil > currentDate : true;
    },
    isCurrentCardExpiring: function (state) {
        if(state.paymentMethod?.valid_until) {
            let validUntil = dayjs(state.paymentMethod.valid_until);
            let currentDate = dayjs();
            let difference = validUntil.diff(currentDate, 'days');
            return difference <= 7;
        }
        return false;
    },
    widgetProductSlugs: function (state) {
        let widgetProducts = {};
        Object.entries(state.availableProducts).forEach(([key, product]) => {
            product.widgets.forEach(widget => {
                if(!widgetProducts[widget.implementation_id]) {
                    widgetProducts[widget.implementation_id] = [];
                }
                widgetProducts[widget.implementation_id].push(key);
            });
        })
        return widgetProducts;
    },
    aggregatedWidgetProducts: function (state, getters) {
        let widgetProducts = _.cloneDeep(getters.widgetProductSlugs);
        if(getters.isCurrentPlanChild) {
        let childPlanSlug = getters.activePlan.product.slug;
            let childPlanParentID = getters.activePlan.product.parent_id;
            let parentSlug = getters.mergedPlans.find(el => el.id === childPlanParentID)?.slug;
            // console.log(childPlanSlug, parentSlug);
            Object.values(widgetProducts).forEach(products => {
                if(products.includes(parentSlug)) {
                    products.push(childPlanSlug);
                }
            })
        }
        // if(getters.isCurrentPlanTrial) {
        //     let trialPlanSlug = getters.activePlan.product.slug;
        //     let mainTrialProductSlug = getters.activePlan.product?.mainTrialProduct?.slug;
        //     Object.values(widgetProducts).forEach(products => {
        //         if(products.includes(mainTrialProductSlug)) {
        //             products.push(trialPlanSlug);
        //         }
        //     })
        // }
        return widgetProducts;
    },
    widgetProductDetails: function (state, getters) {
        let currentProductsBySlugs = _.groupBy(state.availableProducts, (product) => product.slug);
        let widgetProductDetails = {};
        Object.entries(getters.aggregatedWidgetProducts).forEach(([id, products]) => {
            widgetProductDetails[id] = [];
            products.forEach(product => {
                let widgetProduct = currentProductsBySlugs[product]?.[0];
                if(widgetProduct) {
                    widgetProductDetails[id].push(widgetProduct);
                }
            })
        });
        return widgetProductDetails;
    },

    availableWidgets: function (state, getters) {
        /* eslint-disable */
        let currentAvailableProductSlugs = state.currentProducts.map(entry => {
            if(entry.product.parent_id)
                return getters.promotionalProducts.find(el => el.id === entry.product.parent_id)?.slug  || getters.mergedPlans.find(el => el.id === entry.product.parent_id)?.slug;
            return entry.product.slug;
        });
        return Object.entries(getters.aggregatedWidgetProducts).filter(([_, products]) => {
            // console.log("products",products);
            return products.filter(productSlug => currentAvailableProductSlugs.includes(productSlug)).length > 0;
        }).map(([id, _]) => {
            // console.log(id);
            return id;
        })
        /* eslint-enable */
    },
    hasNewWidgets: function (state, getters) {
        return (product) => {
            let productWidgets = product.widgets.map(widget => widget.implementation_id);
            return productWidgets.some(r=> !getters.availableWidgets.includes(r+""));
        }
    },

    promotionalProducts: function (state, getters) {
        let productTrials = _.groupBy(Object.values(state.availableProducts), product => product.trial_of_product_id);
        let productCommitments = _.groupBy(Object.values(state.availableProducts).filter(item => item.trial_of_product_id === null), product => product.parent_id);
        let products = _.orderBy(Object.values(state.availableProducts)
            .filter(product => product.slug !== getters.activePlan?.product?.slug)
            .filter(product => product.is_promotion === true || product.is_featured)
            .filter(product => product.is_visible_front_end === true)
            .filter(product => !getters.currentProductsSlugs.includes(product.slug))
            .filter(product => product.trial_of_product_id === null)
            .filter(product => product.parent_id === null)
            .filter(product => new Date(product.promotion_ends_at).getTime() >= new Date().getTime() && new Date(product.promotion_starts_at).getTime() <= new Date().getTime()));
            // .filter(product => getters.hasNewWidgets(product)), product => new Date(product.promotion_ends_at).getTime());
        products.forEach(product => {
            product.trials = productTrials[product.id] || [];
            product.commitments = productCommitments[product.id] || [];
            const baseProd = _.cloneDeep(product);
            baseProd.commitments = [];
            if(isThisProductMtaPromo(product.id)) {
                let boughtInPast = false;
                let currentlyMember = false;
                const env = getServerEnv();

                currentlyMember = getters.currentAddons.find(el => el.product.id === Constants.MTA_PROMO[env] || el.product.parent_id === Constants.MTA_PROMO[env]);
                boughtInPast = getters.historicProductAccess.find(el => el.product.id === Constants.MTA_PROMO[env] || el.product.parent_id === Constants.MTA_PROMO[env]);
                
                if(!boughtInPast && !currentlyMember) {
                    product.commitments = product.commitments.filter(el => el.subscription_length_type !== 'year');
                }
                // else{
                //     product.commitments = [...product.commitments];
                // }
            }
            else
                product.commitments = [...product.commitments, baseProd];
        });
        return products;
    },
    availableWidgetsPriceInformation: function (state, getters) {
        const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");
        let availableWidgetsPriceInformation = {};
        Object.entries(getters.widgetProductDetails)
            .forEach(([id, products]) => {
                let addonProducts = products.filter(product => product.product_type === Constants.ADDON_TYPE && product.is_promotion === false && product.trial_of_product_id === null && product.parent_id === null);
                let planProducts = products.filter(product => product.product_type === Constants.PLAN_TYPE)
                                            .filter(product => product.widgets?.filter(widget => widget.implementation_id+"" === id && widget.showcase === false)?.length > 0);
                // let includedPlan = _.orderBy(products
                //     .map(product => state.currentProducts.filter(currentProduct => product.slug === currentProduct.product.slug))?.flat(1),
                //     (product) => product.price, 'desc');
                let includedPlan = _.orderBy(products
                    .map(product => {
                        return state.currentProducts.filter(currentProduct => { 
                            // console.log(product.slug, currentProduct.product.slug)
                            return product.slug === currentProduct.product.slug || currentProduct.product.slug.indexOf(product.slug) > -1;
                        })
                    })?.flat(1),
                    (product) => product.price, 'desc');
                
                // let includedPlanSlugs = includedPlan.map(product => product.product.slug);
                let included = !!includedPlan && includedPlan.length > 0;
                let purchased = products
                    .map(product =>
                        state.currentProducts.filter(currentProduct => {
                            return product.slug === currentProduct.product.slug || product.slug === currentProduct.product?.mainTrialProduct?.slug || currentProduct.product.slug.indexOf(product.slug) > -1
                        })
                        .filter(currentProduct => currentProduct.product.pricing?.["USD"] !== undefined || currentProduct.product.parent_id)
                    )?.flat(1)

                let includedDetails = "";
                let startDate = Number.MAX_VALUE;
                let planWidgetDetails = {};
                let showcasePlanSlug = "";
                let rank = 0;
                if (included) {
                    planWidgetDetails = getters.activePlan?.product?.widgets?.find(widget => widget.id+"" === id || widget.implementation_id+"" === id) || {};
                    let isAddon = getters.currentAddons.find(el => el.product.product_sub_type === 'addon' && el.product.widgets && el.product.widgets.length && el.product.widgets[0].id === +id)
                    // let isAddon = includedPlan.filter(entry => entry.product.product_type === Constants.ADDON_TYPE && entry.product.is_promotion === false && entry.product.trial_of_product_id === null && entry.parent_id === null)?.length > 0;
                    let isPromotion = getters.currentAddons.find(el => {
                        if(el.product.product_sub_type === 'bundle' && el.product.parent_id) {
                            return getters.promotionalProducts.find(prod => prod.id === el.product.parent_id)?.widgets.find(w => w.implementation_id === +id);
                        }

                        return el.product.product_sub_type === 'bundle' && el.product.widgets && el.product.widgets.length && el.product.widgets.find(w => w.id === +id);
                    });
                    // let isPromotion = includedPlan.filter(entry => entry.product.product_type === Constants.ADDON_TYPE && entry.product.is_promotion === true && entry.product.trial_of_product_id === null && entry.parent_id === null)?.length > 0;
                    let currMasterPlan = getters.activePlan;
                    if(currMasterPlan.product?.parent_id)
                        currMasterPlan = getters.mergedPlans.find(el => el.id === currMasterPlan.product.parent_id);
                    
                    let includedInMasterPlan = "";
                    let forceShowcase = false;

                    const includedInPlan = collectIncludingProds([currMasterPlan], +id);

                    // console.log("includedInPlan",+id, includedInPlan, getters.activePlan);
                    
                        if(planWidgetDetails.showcase) {
                            forceShowcase = true;
                        }
                        else if(includedInPlan.length)
                            includedInMasterPlan = "Included in your " + getters.activePlan.product.name;
                        else if(getters.upcomingPlans[0] && !getters.upcomingPlans[0]?.is_visible_front_end) {
                            includedInMasterPlan = "Included in your " + getters.upcomingPlans[0]?.product.name;
                        }
                    
                    // planWidgetDetails = getters.activePlan?.product?.widgets?.find(widget => widget.id+"" === id || widget.implementation_id+"" === id) || {};
                    // console.log(includedInMasterPlan);
                    if((isAddon || isPromotion)) {
                        if(isAddon) {
                            includedDetails = "Subscribed to indicator";
                            rank = 9;   
                            planWidgetDetails.showcase = false;
                        } 
                        else {
                            includedDetails = "Included in your " + (includedPlan[0].product.product_sub_type === 'bundle' && includedPlan[0].product.parent_id && getters.promotionalProducts.find(prod => prod.id === includedPlan[0].product.parent_id) ? getters.promotionalProducts.find(prod => prod.id === includedPlan[0].product.parent_id).name : includedPlan[0].product.name);
                            rank = 8;
                        }
                    } else {
                        includedDetails = includedInMasterPlan;
                        rank = 7;
                        if(planWidgetDetails?.showcase && !includedInMasterPlan || forceShowcase) {
                            if(planProducts.filter(el => el.is_visible_front_end).length > 0) {
                                includedDetails = "Normally on " + planProducts[0].name + ". Showcased here for free until " + toSystemDate(planWidgetDetails.showcase_ends_at, false, 'DD MMM YYYY');
                            } else {
                                includedDetails = "Normally a premium add-on. Showcased here for free until " + toSystemDate(planWidgetDetails.showcase_ends_at, false, 'DD MMM YYYY');
                                if(getters.activePlan?.product?.trial_of_product_id) {
                                    showcasePlanSlug = getters.activePlan?.product?.mainTrialProduct?.slug;
                                } else {
                                    showcasePlanSlug = getters.activePlan?.product?.slug;
                                }

                            }

                            rank = 10;

                        }
                    }
                    startDate = new Date(includedPlan[0].started_at).getTime();
                }
                if (!included && planProducts.filter(el => el.is_visible_front_end).length > 0) {
                    rank = 6;
                    // console.log(id)
                    includedDetails = "Upgrade to " + planProducts[0]?.name;
                }

                let priceNumeric = "";
                let price = 0;
                if(addonProducts.length > 0) {
                    price = "Addon cost: " + formatPriceObject(addonProducts[0].pricing) + " per " + addonProducts[0].subscription_length_type;
                    priceNumeric = addonProducts[0].pricing["USD"];
                }

                // if(!includedPlan.length && products[0]?.widgets[0]?.training_centre_widget_style && products[0]?.widgets[0]?.training_centre_widget_style === 'LEGACY')
                //     return;

                availableWidgetsPriceInformation[id] = {
                    addonProducts: addonProducts,
                    planProducts: planProducts,
                    priceNumeric: priceNumeric,
                    purchased: purchased,
                    price: price,
                    ranking: rank,
                    showcasePlanSlug: showcasePlanSlug,
                    included: included,
                    startDate: startDate,
                    includedDetails: includedDetails,
                    includedPlan: includedPlan,
                    products: products,
                    planWidgetDetails: planWidgetDetails,
                    isOnShowcase: planWidgetDetails.showcase && includedDetails.indexOf("Showcase") > -1,
                    notOnAnyPlan: !planProducts?.filter(el => el.is_visible_front_end).length,
                    availableOnPlan: planProducts?.filter(el => el.is_visible_front_end)[0]?.name,
                    availableAsAddon: addonProducts[0]?.slug,
                    includedAsAProd: includedPlan.length,
                    favorableProduct: null,
                    isOnProPlan: false
                }
            
                availableWidgetsPriceInformation[id].onlyOnOtherPlan = !included && !availableWidgetsPriceInformation[id].notOnAnyPlan;
                const favorableProduct = products.filter(el => {
                    let valid = true;
                    const ends = el.promotion_ends_at?.replace('T', ' ').replace('.000000Z','');

                    if(ends) 
                        valid = now > ends ? false : true;

                    return el.is_visible_front_end &&
                    (el.product_sub_type === 'bundle' || el.product_type === 'plan') &&
                    valid;
                });
                if(favorableProduct.length) {
                    availableWidgetsPriceInformation[id].favorableProduct = _.orderBy(favorableProduct, el => el.pricing.USD, "asc")[0];

                    availableWidgetsPriceInformation[id].isOnProPlan = !!favorableProduct.find(el => el.slug === 'pro-plan');
                    // if(!availableWidgetsPriceInformation[id].isOnProPlan)
                    //     availableWidgetsPriceInformation[id].ranking = 5;
                }

            });
        return availableWidgetsPriceInformation;
    },
    allWidgets: function (state, getters, root) {
        let availableWidgetsPriceInformation = getters.availableWidgetsPriceInformation;
        let availableAddonsIds = Object.keys(availableWidgetsPriceInformation)
            .filter(implementationId => availableWidgetsPriceInformation[implementationId]?.addonProducts?.filter(product => (product.is_visible_front_end === true || product.is_visible_front_end === false) && product.product_type === Constants.ADDON_TYPE && product.trial_of_product_id === null && product.parent_id === null)?.length > 0);
        let sortedWidgetsByPurchaseDate = _.cloneDeep(root.widget.widgets.filter(widget => availableAddonsIds.includes(widget.implementation_id + "")));
        sortedWidgetsByPurchaseDate.forEach(widget => {
            widget.planDetails = availableWidgetsPriceInformation[widget.implementation_id];
            widget.details = availableWidgetsPriceInformation[widget.implementation_id].addonProducts?.[0];
        });
        return sortedWidgetsByPurchaseDate;
    },
    sortedWidgetsByPurchaseDate: function (state, getters) {
        let allWidgets = getters.allWidgets;
        return _.orderBy(allWidgets, ["planDetails.startDate", "planDetails.priceNumeric"], ['asc', 'desc'])
    },
    sortedWidgetsByCreationDate: function (state, getters) {
        let allWidgets = getters.allWidgets;
        return _.orderBy(allWidgets, ["planDetails.startDate", "planDetails.priceNumeric"],  ['desc', 'desc'])
    },
    videoWidgetsForShop(_, getters) {
        // console.log(getters.availableWidgetsPriceInformation);
        return  getters.sortedWidgetsByCreationDate.filter(el => (el.code === 'TCSTREAM' ||  el.code === 'TCVIDEO')); //!el.training_centre_widget_style
    },
    qnaStyleWidgets(_, getters) {
        return  getters.sortedWidgetsByCreationDate.filter(el => el.implementation_id === 73); //el.training_centre_widget_style === 'Q&A'
    },
    getWidgetDetailsByID: function (state, getters) {
        return (id) => getters.sortedWidgetsByPurchaseDate.find(entry => entry.implementation_id === id);
    },
    newAddons: function (state, getters, root) {
        let lastVisitDate = root.general.lastShopVisitDate['ADDON'];
        if(lastVisitDate) {
            lastVisitDate = new Date(lastVisitDate).getTime();
            return getters.allWidgets.filter(widget => widget.planDetails.products.some(product => new Date(product.created_at).getTime() > lastVisitDate)).map(widget => widget.implementation_id);
        }
        return getters.allWidgets.map(widget => widget.implementation_id);
    },
    newPlans: function (state, getters, root) {
        let lastVisitDate = root.general.lastShopVisitDate['PLAN'];
        if(lastVisitDate) {
            lastVisitDate = new Date(lastVisitDate).getTime();
            return getters.plansForUpgrade.filter(product => new Date(product.created_at) > lastVisitDate).map(product => product.slug);
        }
        return getters.plansForUpgrade.map(product => product.slug);
    },
    newPromos: function (state, getters, root) {
        let lastVisitDate = root.general.lastShopVisitDate['PROMO'];
        if(lastVisitDate) {
            lastVisitDate = new Date(lastVisitDate).getTime();
            return getters.promotionalProducts.filter(product => new Date(product.created_at) > lastVisitDate).map(product => product.slug);
        }
        return getters.promotionalProducts.map(product => product.slug);
    },
    newProducts: function (state, getters) {
        return getters.newAddons.length + getters.newPlans.length + getters.newPromos.length;
    },
    mergedPlans(_1, getters) {
        let plans = [];
        getters.availablePlans.forEach(plan => {
            let clonedPlan = _.cloneDeep(plan);
            let clonedMainPlan = _.cloneDeep(plan);
            // console.log(getters.historicProductAccess, clonedPlan)
            // if(!clonedPlan.is_visible_front_end)
            //     return;

            //Merge plan and its commitment, trials
            
            // if(!_.isArray(clonedPlan.commitments))
            //     clonedPlan.commitments = [];

            //exclude trals on promo:
            // let commitments =  [...clonedPlan.commitments, clonedPlan];
            // if(getPromoID() !== clonedPlan.id)
            //     commitments =  [...commitments, ...clonedPlan.trials];

            clonedMainPlan.commitments = [];
            let commitments =  [...clonedPlan.commitments, ...clonedPlan.trials, clonedMainPlan];

            clonedPlan.commitments = _.orderBy(commitments, comm => comm.pricing.USD, ['asc']);

        

            plans.push(clonedPlan);
        });
        // console.log(plans)

        return plans;
    },

    failedPayment(state) {
        return state.failedPayment;
    },
    makingPayment(state) {
        return state.makingPayment;
    },
    historicProductAccess(state) {
        return state.historicProductAccess;
    }
}
