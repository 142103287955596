import { tabCommSender } from "../main";
import { rightNowUTCWithFormatting } from "../utility/datetime";
import * as workerTimers from 'worker-timers';

export const setupCacheCallBlocker = () => {
    localStorage.setItem('cacheCallBlocker', rightNowUTCWithFormatting(false));

    window.bootUp.cacheBlocker = workerTimers.setInterval(() => {
        const now = rightNowUTCWithFormatting(false);
        const cacheBlocker = +localStorage.getItem('cacheCallBlocker');
        // console.log(now - cacheBlocker);
        if(now - cacheBlocker > window.bootUp.cacheBlockerIntervall + 5) {
            
            tabCommSender.postMessage({ wakeUpMain: true });
            localStorage.setItem('cacheCallBlocker', 0);
            
        }else{
            localStorage.setItem('cacheCallBlocker', rightNowUTCWithFormatting(false));
        }
        
    }, window.bootUp.cacheBlockerIntervall * 1000);
}





