import { usersTime } from "../../utility/datetime";

export default {
    setWidgets(state, payload) {
        state.widgets = payload;
    },
    setStoreStartIndex(state, payload) {
        state.storeStartIndex = payload;
    },
    setStoreSearchMode(state, payload) {
        state.storeSearchMode = payload;
    },
    setSearchStartIndex(state, payload) {
        state.searchStartIndex = payload;
    },
    setStoreEndIndex(state, payload) {
        state.storeEndIndex = payload;
    },
    setSearchEndIndex(state, payload) {
        state.searchEndIndex = payload;
    },

    addPusherChannel(state, payload) {
        state.pusherChannels.push(payload);
    },
    removePusherChannel(state, payload) {
        const index = state.pusherChannels.findIndex(el => el === payload);
        if(index > -1)
            state.pusherChannels.splice(index, 1);
    },
    clearPusherChannels(state) {
        state.pusherChannels = [];
    },

    addLookupCategories(state, payload) {
        const currentWidget = state.widgets.find(wdg => wdg.implementation_id === payload.implementation_id);
        if(currentWidget && currentWidget.settings)
            currentWidget.settings.categories = {
                area: "Options",
                editableByUser: true,
                formJSON: payload.obj
            };
    },

    setRatings(state, payload) {
        state.ratings = payload;
    },

    setSingleRating(state, payload) {
        state.ratings[payload] = usersTime(new Date(), true, true, true, {dateFormat: "y-m-d", timeFormat: "24hr"}, "UTC");
    }
};