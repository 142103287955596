export default {
    setSubscribedToSquawk({commit}, payload) {
        commit('setSubscribedToSquawk', payload);
    },
    setSquawkAvailable({commit}, payload) {
        commit('setSquawkAvailable', payload);
    },
    setSwitchedToFullScreen({commit}, payload) {
        commit('setSwitchedToFullScreen', payload);
    }
}