<template>
    <div class="terminal-plan-selector">
        <div class="terminal-plan-selector--header">
            <h2>You are on {{ product }}</h2>
            <p v-if="mergedPlansForChoice.length > 1">Select one of these options to change your plan</p>
            <p v-else-if="isUpgrading">
                <span>Change your plan below</span><br />
                <span class="plan-notice">Your new plan will begin immediately and charge you every month until cancelled.</span></p>
            <p v-else-if="mergedPlansForChoice.length === 1">Change your plan below</p>
        </div>
        <form @submit.prevent>
            <div class="plan-container">
                <div class="plan-card1 plan terminal"
                v-for="plan in mergedPlansForChoice"
                :key="plan.id"
                :class="{'highlighted': selectedPlanID === plan.id}"
                >
                    <label :for="plan.name"
                    >
                        <plan-card
                        :plan="plan"
                        :selected="selectedPlanID"
                        :widgetsByProduct="widgetsByProduct"
                        :noCommitmentError="noCommitmentError"
                        @commitment-changed="commitmentChanged"
                        @plan-changed="(e) => selectedPlanID = e"
                        >
                        </plan-card>
                    </label>
                    <input
                    type="radio"
                    :id="plan.name"
                    :name="plan.name"
                    :value="plan.id"
                    v-model="selectedPlanID"
                    />
                </div>
            </div>
            <div class="container">
                <div class="button-div mt-10 align-center">
                    <base-button
                    :mode="'extra-h-padding ' + buttonColor"
                    :link="false"
                    @click.native="submitForm"
                    >{{ cta }} {{ selectedPlanObj.name }}</base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
// import OnBoardMixin from '../../../mixins/onBoardMixin';
import SubscriptionMixin from '../../mixins/SubscriptionMixin';
import PlanCard from './onboard/components/PlanCard.vue';
import Constants from '../../utility/constants';
import _ from 'lodash';
// import { getCurrentCountry, talkToServer } from '../../../utility/helper-functions';

// import Swal from 'sweetalert2';
// import { eventBus } from '../../main';
import { usersTime } from '../../utility/datetime';
import { eventBus } from '../../main';


export default {
    props: ["panel", "modal"],
    mixins: [SubscriptionMixin],
    components: {
        PlanCard
    },
    created() {
        
        this.selectedPlanID = this.mergedPlansForChoice[0].id;
        // console.log(this.panel);
    },
    computed: {
        mergedPlansForChoice() {
            if(this.modal?.checkout) {
                return this.mergedPlans.filter(el =>
                    this.modal.selectedItem.planDetails.planProducts.filter(plan => plan.slug === el.slug).length > 0
                )
            }

            const prod = this.activePlan.product;

            if(!prod.is_visible_front_end && this.isDowngrading)
                return this.mergedPlans;

            let arr = [];
    
            if(parseFloat(this.activePlan.price) === 0)
                return _.cloneDeep(this.mergedPlans).filter(el => parseFloat(el.pricing.USD) > 0);


             if(this.isDowngrading) {
                const downgradeables = _.cloneDeep(this.mergedPlans).filter(el => parseFloat(el.pricing.USD) === 0);

                arr = [...arr, ...downgradeables]
        
            }

            if(this.isUpgrading) {
                
                let upgradeables = _.cloneDeep(this.mergedPlans).filter(el => parseFloat(el.pricing.USD) > 0);

                let currentCommValue = prod.subscription_length * Constants.PLAN_CONSTANTS.length_values[prod.subscription_length_type];

                upgradeables.forEach(el => {
                    if(prod.id === el.id || prod.parent_id === el.id) {
                        
                        const upgradeableComms = [];
                        const trialsExcluded = el.commitments.filter(e => !e.trial_of_product_id);
                        
                        trialsExcluded.forEach(el => {
                            const upgradeCommValue = el.subscription_length * Constants.PLAN_CONSTANTS.length_values[el.subscription_length_type];
                            if(currentCommValue < upgradeCommValue)
                                upgradeableComms.push(el);
                        });

                        el.commitments = upgradeableComms;
                    }
                   
                    
                });
                upgradeables = upgradeables.filter(el => el.commitments.length);

                arr = [...arr, ...upgradeables];
  
            }

           
            return arr;
                
        },
        widgetsByProduct: function () {
            let widgets = this.sortedWidgetsByCreationDate.map(widget => {
                let newWidget = _.cloneDeep(widget);
                newWidget.availablePlans = widget?.planDetails?.products
                    ?.filter(product => product.is_promotion === false)
                    ?.filter(product => product.product_type === Constants.PLAN_TYPE)
                    ?.filter(product => product.is_visible_front_end === true || product.id === this.activePlan?.product?.id)
                    ?.filter(product => product.trial_of_product_id === null)
                    ?.map(product => product.slug) || [];
                return newWidget;
            });
            return _.orderBy(widgets, widget => widget.availablePlans.length, ['desc']);
        },
        isDownGrade() {
            if(this.selectedPlanObj.pricing.USD === 0)
                return true;
            else
                return false
        },
        buttonColor() {
            if(this.isDownGrade)
                return "red";

            return "o-blue";
        },
        selectedPlanObj() {
            return this.mergedPlans.find(el => el.id === this.selectedPlanID);
        },
        // isLegacySelected() {
        //     return !this.selectedPlanObj.is_visible_front_end;
        // },
        // isExplorerSelected() {
        //     return this.selectedPlanObj.slug.indexOf('explorer') > -1;
        // },
        // isProTrialSelected() {
        //     return this.selectedPlanObj.slug.indexOf('pro') > -1 && this.selectedPlanObj.slug.indexOf('trial') > -1;
        // },
        // isProSelected() {
        //     return this.selectedPlanObj.slug === 'fs-pro-plan';
        // },
        product() {
            const trial = this.activePlan.product.mainTrialProduct
            let name = this.activePlan.product.name;
            if(trial)
                name = this.activePlan.product.mainTrialProduct?.name;

            const period = this.activePlan.product.subscription_length_type;
            const length = this.activePlan.product.subscription_length;
            const details = trial ?  `a ${length}-${period} ${name} trial` : name;

            return details;

        },
        newSubStartDate() {
            const current = this.currentPlans.find(el => el.product.slug === this.activeSlug)
            const output = current.next_billing_at ? 'on ' + usersTime(current.next_billing_at, true, false, false, { dateFormat : "d M Y" }, false, "UTC") : " when your current one finishes"

            return output;
        },
        cta() {
            if(this.isDownGrade)
                return "Downgrade to";

            return "Select";
        },
        isUpgrading() {
            return this.panel?.isUpgrading;
        },
        isDowngrading() {
            return this.panel?.isDowngrading;
        }
    },
    data() {
        return {
            selectedPlanID: 0,
            commitment: 0,
            noCommitmentError: false
        }
    },
    methods: {
        submitForm() {
            if(!this.commitment) {
                // console.log(this.commitment);
                this.noCommitmentError = true;
                return;
            }

            if(this.isDownGrade) {
                const explorer = this.mergedPlans.find(el => el.slug.indexOf('explorer') > -1);
                if(explorer)
                    this.downgradePlan(explorer, this.activePlan);

            }
            else {
                eventBus.$emit("nav-change", {
                    instanceID: this.$root.instanceID,
                    comp: "checkout",
                    details: {
                        type: "plan",
                        selectedItem: this.commitment,
                        isUpgrading: this.isUpgrading
                    }
                });
                
          
                // this.showModal(CheckoutOverview, {
                //     selectedItem: this.selectedPlanObj,
                //     type: 'plan'
                // }, {
                //     style: "width: 95% !important; height: 95% !important",
                //     resizable: true
                // });
                ///UPGRADE
                // console.log("upgrade");
                //TEMP
                // if(!this.commitment && this.selectedPlanObj.slug.indexOf('explorer') === -1 {
                //     this.noCommitmentError = true;
                //     this.commitment = 0;
                //     return;
                // }
            }
            eventBus.$emit("close-modal");
        },
        commitmentChanged(val) {
            // console.log("comm value", val)
            this.commitment = val;
            this.noCommitmentError = false;
        }
    },
    watch: {
        selectedPlanID(nVal) {
            // console.log("nval",nVal);
            this.commitment = 0;
            const selectedPlan = this.mergedPlansForChoice.find(el => el.id === nVal);
            // console.log("selectedPlan",this.mergedPlansForChoice);
            if(selectedPlan.commitments.length === 1) {
                this.commitment = selectedPlan.commitments[0];
                // console.log("found the single", this.commitment);
            }
                

            this.noCommitmentError = false;
        }
    }
}
</script>

<style lang="scss" scoped>

    .terminal-plan-selector {
        padding: 0 35px 35px 35px;

        &--header {

            text-align: center;

            & h2 {
                @include H2;

                margin-top: 7px;
                margin-bottom: 5px;
            }

            & p {
                @include SubHead1;
                color: $input-color-filter;
                margin: 0;
                margin-bottom: 25px;

                & .plan-notice {
                    color: $input-color-filter;
                    font-size: 12px;
                    width: 250px;
                    margin-top: 20px;
                    display: inline-block;

                }
            }
        }
    }

</style>

<style lang="scss">
    .plan-card1.plan.terminal {
        background-color: $onboard-survey-background;

        &.highlighted {
            background-color: $onboard-survey-card-background;
        }
    }
</style>
