<template>
    <div class="widget-shop">

        <!-- <div class="shop-buttons">
            <span v-if="showBackButton">
                <button @click="backToShop">Back</button>
                <span class="button-separator">|</span>
            </span>
            <button @click="closeModal">Close</button>
        </div> -->

        <component :tab = "panel.tab" :is="componentToBeRendered" :details="details" @shop-navigate-to="switchView"></component>
    </div>
</template>

<script>
import WidgetList from './WidgetList.vue';
import WidgetConfig from './WidgetConfig.vue';
import ProductConfig from './ProductConfig.vue';
import { eventBus } from '../../../main';
import _ from "lodash";
import {createNamespacedHelpers} from "vuex";
// import { getParameterByName } from '../../../utility/helper-functions';
const payments = createNamespacedHelpers('payments');
export default {
    props: ["panel"],
    components: { ProductConfig, WidgetList, WidgetConfig },
    computed: {
        ...payments.mapGetters(['getWidgetDetailsByID']),
        showBackButton() {
            return this.panel.mode === "create" && this.componentToBeRendered !== "widget-list";
        },
        details() {
            if(this.componentToBeRendered === "widget-config") {

                return {
                    id: this.itemID,
                    layout_id: this.layoutID,
                    widget: this.widget || _.cloneDeep(this.getWidgetDetailsByID(this.panel.id)),
                    readonly: this.readonlyWidget,
                    slot: this.panel.slot,
                    mode: this.mode ? this.mode : this.panel.mode,
                    widgetDirectAddition: this.panel.widgetDirectAddition,
                    isCourse: this.panel.isCourse,
                    isLiveStream: this.panel.isLiveStream,
                    product: this.panel.product
                }

            }
            else if(this.componentToBeRendered === "product-config") {
                return {
                    id: this.itemID,
                    product: this.panel.product

                }
                
            }
            else if(this.componentToBeRendered === "widget-list") {
                return {
                    mode: this.panel.mode,
                    element: this.panel.element

                }
            }
           

            return {}


            // return {
            //     widgets: this.$store.getters['widget/widgets'],
            //     storeStartIndex: this.$store.getters['widget/getStoreStartIndex']
            // }

        }
    },
    data() {
        return {
            componentToBeRendered: this.panel.view,
            itemID: this.panel.id,
            layoutID: this.panel.layout_id,
            widget: null,
            readonlyWidget: false,
            mode: '',
            sourceView: '',
        }

    },
    methods: {
        switchView(obj) {
            let view = obj.view;
            if(view === "back") {
                view = this.sourceView;
            }
            if(view !== "back" && view !== 'how-activation-works')
                this.sourceView = view;
            
            this.componentToBeRendered = view;
           
            if(obj.id)
                this.itemID = obj.id;
            if(obj.widget)
                this.widget = obj.widget;
            if(obj.readonly)
                this.readonlyWidget = obj.readonly;
            if(obj.mode)
                this.mode = obj.mode;
            
        },
        closeModal() {
            eventBus.$emit("close-modal");
        },
        backToShop() {
            this.componentToBeRendered = "widget-list";
        }
    },
    created() {
        if(this.widget === null) {
            this.widget = _.cloneDeep(this.getWidgetDetailsByID(this.panel.id));

            // if(!getParameterByName("popout"))
            //     this.$tours['myTour'].nextStep();
        }

        eventBus.$on("shop-navigate-to", obj => {
            
            if(obj.instanceID === this.$root.instanceID) 
                this.switchView(obj);
        });

        this.sourceView = this.panel.view;
    },
    beforeDestroy() {
        eventBus.$off('shop-navigate-to');
    }

}
</script>

<style lang="scss">
    .widget-shop {
        position: relative;
        height: 100%;

        & .shop {
            display: grid;
            grid-template-rows: 1fr 110px;
            height: 100%;

            & > div {
                padding: 15px 30px;

                &:first-child {
                    padding-top: 0;
                }
                

                &:nth-child(2) {
                    background-color: $modal-footer;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    @include respond(lg) {
                        padding-top: unset;
                        padding-bottom: unset;
                    }

                }
            }
        }

        & .shop-buttons {
            position: absolute;
            top: -5px;
            right: 12px;

            & button {
                background-color: transparent;
                border: none;
                color: $color-01;
                cursor: pointer;
                text-transform: uppercase;
                font-size: 13px;
                color: $color-20;

                &:hover {
                    text-decoration: underline;
                }

            }


        }



        .widget-shop-header {
            color: $color-20;
            margin: 5px 15px;
            
            & h2 {
                font-size: 18px;
                font-weight: 600;
            }

             & p {
                color: $color-06;
            }

        }

        .shop-container,
        .filters {
            @include col-lg-2;
        }

        .shop-container {
            align-content: baseline;
            @include respond(lg) {
                height: calc(32vh + 20px + 30px + 15px + 20px);
                max-height: 320px;
            }

        }
        .shop .tabs {

            @include respond(lg) {
                height: 499px;
            }
        }
        .shop-item {
            border-left: 5px solid $color-067;

            padding: 15px;
            display: grid;
            grid-template-columns: 38fr 62fr;
            grid-template-rows: 5fr;
            cursor: pointer;
            // height: 170px;

            @include respond(lg) {
                height: 16vh;
                max-height: 120px;
            }

            // &:hover {
            //     background-color: rgba($color-01, 0.05);
            // }

            // & > div {

            // }
        }
        .shop-item {
            // box-shadow: 0 0 10px rgba($color-00, 1);
            // border-radius: 2px;
            // background-color: rgba($color-01, 0.20);
            background-color: rgba($color-03, 0.6);
            // padding: 15px;
            // display: grid;
            // grid-template-columns: 38fr 62fr;
            // grid-template-rows: 5fr;
            // cursor: pointer;
            height: 170px;

            &:hover {
            background-color: rgba($color-03, 0.85);
            }

            // & > div {

            // }
        }
          .shop-item-price {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        .shop-item--thumbnail {

            & img {
                width: 100%;
            }
        }
        .shop-item--description {

            margin-left: 15px;

            &__header {
                display: flex;
                justify-content: space-between;

                & h3 {
                    margin-top: 0;
                    text-transform: capitalize;
                    letter-spacing: 0.5px;

                    //Poppins
                    font-size: 15px;
                    margin-bottom: 5px;

                    //Roboto
                    // font-size: 16px;
                    //

                }

                &-price {

                    margin-left: 3px;

                    & .price {
                        color: $color-17;
                        font-weight: bold;
                        font-size: 22px;
                    }

                    & .period {
                        font-size: 9px;
                        color: $color-06;
                    }
                }


            }

            & p {
                margin: 0;
                font-size: 12px;
                color: $color-06;

            }

            &__text {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                //Poppins
                & p div {
                    line-height: 16px;
                }
                

                //Roboto
                //
            }
        }

        & .vue-form-generator {

            & fieldset {

                & .form-group {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 200px 300px 1fr;
                    align-items: baseline;

                    & select {
                        font-size: 20px;

                        & option:first-child {
                            display: none;
                        }
                    }

                    & input[type=checkbox] {
                        height: auto;
                    }

                    & .dropList {

                        & label {

                            margin: 5px 0;

                            & input {
                                height: auto;
                            }
                        }
                        
                    }
                }
            }

        }

    }

    .button-separator {
        color: $color-06
    }

    // @include settings;

</style>
