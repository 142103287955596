<script>
// import Chart from "chart.js";
import { Line, mixins } from "vue-chartjs";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";

const { reactiveProp } = mixins;

export default {
  name: "ChartLine",
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: { type: Object, required: true, default: () => null },
    options: { type: Object, required: false, default: () => null },
    dataIteration: { type: Number }
  },
  computed: {
    
  },
  mounted() {
    this.addPlugin([chartjsPluginAnnotation]);
    this.renderChart(this.chartData, this.options);
    // console.log(Chart.defaults);
  },
  watch: {
    dataIteration() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>

<style scoped></style>