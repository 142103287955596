<template>
    <div class="survey-option"
    :class="{ 'column' : isColumn }"
    >
        <div class="survey-option-header">
            <div class="survey-option-header-logo">
                <div v-html="option.logo" style="line-height: 0;"></div>
            </div>
        </div>
        <div class="survey-option-body">

            <div v-if="isInput"
            class="survey-option-body-title"
            >
                <input type="text" maxlength="100" :placeholder="option.title1" v-model="inputText" @keyup="$emit('input-keyup', inputText)" />
            </div>

            <div 
            v-else
            class="survey-option-body-title">
                <div class="survey-option-body-title-line-1">{{ option.title1 }}</div>
                <div v-if="option.title2" class="survey-option-body-title-line-2">{{ option.title2 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["option", "isColumn", "selected", "isInput"],
    // created() {
    //     console.log("created", this.inputText);
    // },
    data(){
        return {
            inputText: ''
        }
    },
    watch: {
        option: {
            deep: true,
            handler: function () {
                // console.log(nVal, oVal)
                this.inputText = '';
                this.$emit('input-keyup', this.inputText);
                // console.log("value chnaged", this.inputText);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .survey-option {
        // 
        display: flex;
        font-weight: normal;
        color: $input-color;
        align-items: center;
        flex-grow: 1;

        & .survey-option-body {
            margin-left: 25px;
            width: 100%;

            & input {
                display: block;
                border: none;
                background-color: transparent;
                width: 100%;
                color: $input-color;
                font-family: Poppins;
                font-size: 16px;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        &.column {
            padding: 25px;
            flex-direction: column;
            padding-bottom: 35px;
            align-items: normal;

            & .survey-option-header {
                margin-bottom: 25px;
            }

            .survey-option-body-title-line-1,
            .survey-option-body-title-line-2 {
                text-align: center;
            }

            & .survey-option-body {
            margin-left: 0;
        }
        }

    }
    .survey-option-header-logo {
        text-align: center;
    }
</style>

<style lang="scss">
    .survey-option-header-logo {
        text-align: center;
    }
</style>