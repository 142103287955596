<template>
    <div class="account-card">
        <div>
            <h2>Terminal</h2>
            <form>
                <div class="profile-main">
                    <div>
                         <base-input
                            elementType="select"
                            :class="{invalid: error.country_code}"
                            v-model="model.timezone"
                            forID="timezone"
                            inputClass=""
                            formControlClass=""
                            label="Time zone"
                            @clear-validity="clearValidity"
                            >
                                <option value="" disabled selected hidden class="select-prompt">Country *</option>
                                    <optgroup label="Popular time zones">
                                        <option
                                            v-for="timezone in popularOptions"
                                            :key="timezone.value"
                                            :value="timezone.value"
                                            :selected="timezone.value === model.timezone"
                                        >{{ timezone.text }}
                                        </option>
                                    </optgroup>
                                    <optgroup label="All time zones">
                                        <option
                                            v-for="timezone in timezoneOptions"
                                            :key="timezone.value"
                                            :value="timezone.value"
                                            :selected="timezone.value === model.timezone"
                                        >{{ timezone.text }}
                                        </option>
                                    </optgroup>
                            </base-input>
                        <!-- <b-form-select v-model="model.terminal" :options="timezoneOptions"/> -->
                    </div>
                    <div>

                    </div>
                </div>
            </form>
            <div class="account-error">
                <div v-for="error in errorMessages" :key="error">
                    <span>{{ error }}<br/></span>
                </div>
            </div>
            <base-button
            :disabled="updating"
            mode="o-blue"
            :link="false"
            @click.native="saveTerminalSettings"
            >Save Changes
            </base-button>
        </div>
    </div>
</template>

<script>
import timezones from "../../../assets/timezones.json";
import {createNamespacedHelpers} from "vuex";

const general = createNamespacedHelpers('general');
import _ from "lodash";
import AccountService from "../../../services/account/account.service";
import Swal from "sweetalert2";

export default {
    name: "Terminal",
    data: function () {
        return {
            timezoneOptions: [],
            popularOptions: [],
            model: {
                timezone: "Europe/London"
            },
            errorMessages: [],
            error: {
                timezone: false
            },
            updating: false
        }
    },
    computed: {
        ...general.mapState(["user"])
    },
    methods: {
        ...general.mapActions(['loadCurrentUserSettings']),

        saveTerminalSettings: function () {
             this.updating = true;
            this.errorMessages = [];
            if(this.error.timezone) {
                this.errorMessages = ["Please select a timezone."];
                this.updating = false;
            }

            let userObj = _.cloneDeep(this.user);
            Object.assign(userObj, this.model);
            AccountService.saveUserAccountSettings(userObj).then(result => {
                if(result.data.success) {
                    Swal.fire({
                        title: 'Success!',
                        type: 'info',
                        text: 'The timezone information was successfully updated!',
                        showCancelButton: true,
                        cancelButtonColor: '#1d9deb'
                    });
                    this.loadCurrentUserSettings().then(() => {});
                    this.updating = false;
                }
            })
        },
        clearValidity(field) {
            this.error[field] = false;
            this.errorMessages = [];
        },
        loadTerminalSettings: function () {
            const tzObj = timezones;
            let otherOptions = [];
            Object.entries(tzObj).forEach(([key, value]) => {
                otherOptions.push({value: `${key}`, text: value});
            });

            this.popularOptions = [
                    {value: "America/Los_Angeles", text: "America / Los Angeles"},
                    {value: "America/New_York", text: "America / New York"},
                    {value: "Europe/London", text: "Europe / London"},
                    {value: "Europe/Berlin", text: "Europe / Berlin"},
                    {value: "Australia/Sydney", text: "Australia / Sydney"}
            ];

            this.timezoneOptions = otherOptions;

            this.model.timezone = _.cloneDeep(this.user.timezone);
        }
    },
    created() {
        this.loadTerminalSettings();
    }
}
</script>

<style scoped>

</style>
