
import { usersTime } from '../utility/datetime';

export default {
    computed: {
        formattedRate() {
            const rate = this.bank.current_rate;
            return rate || rate === 0 ? parseFloat(rate).toFixed(3) +'%' : 'Unset';
        },
        formattedProbability() {
            if(this.checkProbAndChangeBy()) {
                const id = this.showBiggerBasedOnProbability(this.bank.sc1_probability, this.bank.sc2_probability);
                const prop = 'sc' + id + '_probability';
                return this.bank[prop].toFixed(0) + '%';
            }
                
            return !isNaN(this.bank.probability) && this.bank.probability ? (+this.bank.probability).toFixed(0) + '%' : 'Unset';
        },
        formattedChangeBy() {
            if(this.checkProbAndChangeBy()) {
                const id = this.showBiggerBasedOnProbability(this.bank.sc1_probability, this.bank.sc2_probability);
                const prop = 'sc' + id + '_change_by';
                return this.bank[prop].toFixed(3) + '%';
            }
            
            return !isNaN(this.bank.change_by) && this.bank.change_by ? parseFloat(this.bank.change_by).toFixed(3) + '%' : '-';
        },
        redOrGreen() {
            // console.log(this.bank.next_expected_move)
            const action = this.bank.next_expected_move;
            if(action === "Hike")
                return "u-color-13";
            else if(action === "Cut")
                return "u-color-12";
            else
                return "";
        },
        nextMeetingDay() {
            const date = usersTime(this.bank.next_meeting_date, true, false, false, {
                dateFormat: "d M Y",
            }, "UTC");
            return {
                dm: date.substring(0,6),
                y: date.substring(7,11)
            }
        },
        action() {
            if(this.bank.next_expected_move.toLowerCase() === 'no change')
                return "Hold";

            return this.bank.next_expected_move;
        }
    },
    methods: {
        isValidValue(val) {
            if(val)
                return true;

            return false;
        },
        checkProbAndChangeBy() {
            if(this.isValidValue(this.bank.sc1_change_by) &&
            this.isValidValue(this.bank.sc1_probability) &&
            this.isValidValue(this.bank.sc2_change_by) &&
            this.isValidValue(this.bank.sc2_probability))
                return true;

            return false;
        },
        showBiggerBasedOnProbability(sc1_probability, sc2_probability) {
            if(sc1_probability >= sc2_probability)
                return 1;

            return 2;
        }
    }
}