export default {

    lastEcoCalendarUpdate(state, payload) {
        state.lastEcoCalendarUpdate = payload;
    },

    lastEcoCalendarDelete(state, payload) {
        state.lastEcoCalendarDelete = payload;
    },

    setFetchDay(state, payload) {
        state.fetchDay = payload;
    }
}