export default {
    addBulkRecords(context, payload) {
        
        const processingObject = {
            objStore: `video_library_videos`, 
            data: payload
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(response => {
            if(response) {
                context.dispatch("haveVideos", true);
            }
            
        });
        
    },
    haveVideos(context, payload) {
        context.commit("haveVideos", payload);
    },

    lastVideoUpdate(context, payload) {
        context.commit("lastVideoUpdate", payload);
    },

    lastVideoDelete(context, payload) {
        context.commit("lastVideoDelete", payload);
    },

    setVideoValues(context, payload) {
        context.dispatch("haveVideos", payload);
    }

}