<template>
    <div 
    v-if="!disabled"
    class="tabs-menu--item" 
    :class="{'active': makeActive }" 
    >
        <a @click="clickMenu(tab.id)" class="buttontext">

        <div 
        :style="{width: itemWidth}"
        class="tab-text"
        >
            <font-awesome-icon v-if="type === 'dashboards'" :icon="['fas', 'window-restore']"/>
            {{ tab.name }} {{ renderedValue }}
        </div>
        </a>
        <dashboard-mini-menu
        v-if="miniMenu && makeActive"
        mode="dashboard-screen"
        :item="tab"
        :inactive="!makeActive"
        ></dashboard-mini-menu>
        <span class="border"></span>

    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
// import { eventBus } from '../../main';
import DashboardMiniMenu from './DashboardMiniMenu.vue';

const dashboards = createNamespacedHelpers('dashboard');

export default {
    props: ["tab", "miniMenu", "type", "active", "tabMenuWidth"],
    components: {
        DashboardMiniMenu
    },
    // created() {
    //     eventBus.$on("open-modal", modal => {
    //         if(modal.instanceID === this.$root.instanceID && 
    //         modal.componentInModal === "edit-dashboard") {
    //             this.hovered = false;
    //         }
    //     });

        
    // },
    // mounted() {
        // console.log(this.tabMenuWidth);
    // },
    computed: {
        ...dashboards.mapGetters(['tabbedDashboards', 'getPoppedoutDashboardByID']),

        makeActive() {
            return this.active === this.tab.id; //|| this.hovered;
        },
        itemWidth() {
            if(this.type === 'widget-config') {
                return '136px';
            }
            else if(this.type === 'playlist')
                return 'auto';

            const tabbedDashboardsCount = this.tabbedDashboards.length;

            const width = (this.tabMenuWidth - (37.5 * (tabbedDashboardsCount - 1)) - 61) / tabbedDashboardsCount;
                  
            return (width < 170 ? width : 170) + 'px';
        },
        renderedValue() {
            if(this.tab.count)
                return '(' + this.tab.count + ')';
            
            return '';
        },
        disabled() {
            return this.tab.canDisable && this.tab.disabled;
        }
    },
    // data() {
    //     return {
    //         hovered: false
    //     }
    // },
    methods: {
        ...dashboards.mapActions(['setActiveDashboardAndTab']),

        clickMenu(id) {
            if(this.disabled)
                return;

            this.$emit("menu-item-clicked", {
                id: id,
            });        
        },
    }
}
</script>