export default {
    props: ["calHeight", "details"],
    computed: {
        thisHeight() {
            return this.calHeight || this.height;
        },
        smallerThanMdWidget() {
            return !this.details.isMdWidget;
        },
        smallerThanXs1Widget() {
            return !this.details.isXs1Widget;
        },
        vertPadding() {
            if(this.thisHeight > 500 && !this.smallerThanMdWidget) {
                return 7.5;
            }else if(this.thisHeight > 390){
                return 3;
            }else {
                return 1;
            }
        },
        fontSize() {
            if(this.thisHeight > 500) {
                if(this.smallerThanMdWidget)
                    return 11;
                
                return 15;

            }else if(this.thisHeight > 390){

                if(this.smallerThanMdWidget)
                    return 11;

                return 12;

            }else {
                return 10;
            }
        },
        headerFontSize() {
            if(this.thisHeight > 500) {
                return 10;
            }else if(this.thisHeight > 390){
                return 9;
            }else {
                return 8;
            }
        },
        cellStyles() {
            return {
                paddingTop: this.vertPadding + 'px',
                paddingBottom: this.vertPadding + 'px',
                fontSize: this.fontSize + 'px',
            
            }
        },
        headerCellStyles() {
            return {
                paddingTop: this.vertPadding + 'px',
                paddingBottom: this.vertPadding + 'px',
                fontSize: this.headerFontSize + 'px',
       
            }
        }

        
    }
}