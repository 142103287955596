// import { sortDataBy } from "../../utility/helper-functions";

import { sortDataBy } from "../../utility/helper-functions";

export default {
    fetchInitialEcoCalendarEvents(fromTo) {

        const queryObject = {
            name: "initial-ecocalendar-fetch",
            objStore: "ecocalendar",
            fromTo: fromTo,
        }

        // console.log(queryObject);

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            sortDataBy(response, "timestamp", "timeASC");
            return response;
        });
    },
    // loadMoreLiveStreams(videolist, limit, offset) {

    //     const status = videolist === "archiveVideos" ? "finished" : "";

    //     const queryObject = {
    //         name: "livestreams-load-more",
    //         objStore: "livestreams",
    //         status: status,
    //         offset: offset,
    //         limit: limit,
    //     }

    //     console.log(queryObject);

    //     return window.bootUp.iDB.getMoreRecords(queryObject).then(response => {

    //         return response;
    //     });
    // }
}