export default {
    haveVideos(state, payload) {
        state.haveVideos = payload;
    },

    lastVideoUpdate(state, payload) {
        state.lastVideoUpdate = payload;
    },

    lastVideoDelete(state, payload) {
        state.lastVideoDelete = payload;
    },
}