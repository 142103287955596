export default {
    lastLiveStreamUpdate(state, payload) {
        state.lastLiveStreamUpdate = payload;
    },

    lastLiveStreamDelete(state, payload) {
        state.lastLiveStreamDelete = payload;
    },

    addCurrentLiveID(state, payload) {
        const index = state.currentLiveIDs.findIndex(el => el === payload);

        if(index === -1)
            state.currentLiveIDs.push(payload);

    },
    removeCurrentLiveID(state, payload) {
        const index = state.currentLiveIDs.findIndex(el => el === payload);

        if(index > -1)
            state.currentLiveIDs.splice(index,1);
    },
    setCurrentLiveIDs(state, payload) {
        state.currentLiveIDs = payload;
    },

    setActiveReminders(state, payload) {
        state.activeReminders = payload;
    },
    addActiveReminder(state, payload) {
        state.activeReminders.push(payload);
    },
    removeActiveReminder(state, payload) {
        const index = state.activeReminders.findIndex(el => el === payload);

        if(index > -1)
            state.activeReminders.splice(index, 1);

    },

}