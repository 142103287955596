<template>
    <div class="embedded-component">
        <div class="align-center">
            <svg id="profile-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5385 15.6471C15.5385 10.3191 19.7745 6 25 6C30.2255 6 34.4615 10.3191 34.4615 15.6471C34.4615 18.9925 32.7914 21.9402 30.2549 23.6707C35.9473 25.8418 40 31.4384 40 38V42H10V38C10 31.4384 14.0527 25.8418 19.7451 23.6707C17.2086 21.9402 15.5385 18.9925 15.5385 15.6471ZM25 21.0589C27.9314 21.0589 30.3077 18.6359 30.3077 15.6471C30.3077 12.6583 27.9314 10.2353 25 10.2353C22.0687 10.2353 19.6923 12.6583 19.6923 15.6471C19.6923 18.6359 22.0687 21.0589 25 21.0589ZM25 26.9413C19.0869 26.9413 14.2791 31.7658 14.1563 37.7648H35.8437C35.7209 31.7658 30.9131 26.9413 25 26.9413Z" fill="white"/>
            </svg>
        </div>
        <h2 class="align-center">Finish creating your account</h2>
        <div class="form-text-div dimmed align-center subtitle">Access the terminal and view our entire suite of tools and indicators</div>
        <form @submit.prevent class="container">
            <div class="button-div mt-10">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="continuePressed"
                >Continue</base-button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    methods: {
        continuePressed() {
            this.$emit("update-step", {
                step: "2"
            });
        }
    }
}
</script>