<template>
    <div class="settings-body" :style="computedStyle">
        <div class="settings-tabs">
            <ul>
                <li
                        v-for="tab in tabs"
                        :key="tab.code"
                        :class="{activetab: activeTab === tab.code}"><a href="javascript:void(0)"
                                                                        @click="changeTab(tab.code)">{{ tab.name }}</a>
                </li>
            </ul>
            <div class="tabs-body acc-settings" :class="activeTab + '-wrapper'">
                <div v-if="twoColumn" class="tabs-body-content" :class="tabsBodyContentClasses">
                    
                    <div>
                        <form class="filled-form">
                            <vue-form-generator ref="form"
                                    :schema="schemaLeft[activeTab]"
                                    :model="modelLeft[activeTab]"
                                    :options="formOptions"
                                    @model-updated="modelUpdated"></vue-form-generator>

                        </form>

                        <div id="card-error" style="color: red">
                            <span v-for="error in errorMessages" :key="error">{{ error }}<br/></span>
                        </div>

                        <div style="margin-top: 10px;" v-if="activeTab==='profile'">
                            <span>
                                 <base-button
                                         mode="white"
                                         :link="false"
                                         @click.native="openChangePasswordModal"
                                 >Change Password
                        </base-button>
                            </span>
                        </div>
                    </div>
                    
                    
                    <div>

                        <div class="filled-form"
                             v-if="activeTab === 'profile'"
                        >
                            <label style="text-align: center">
                            Change profile photo
                        </label>
                        <vue-dropzone
                                ref="picUpload"
                                id="dropzone-profile"
                                :options="dropzoneOptions"
                                @vdropzone-complete="updateUploadedFile"
                                @vdropzone-file-added="fileAdded"
                                @vdropzone-removed-file="removeAllFiles"
                        ></vue-dropzone>

                        </div>

                        <form
                                v-else-if="this.activeTab !== 'terminal'"
                                class="filled-form">

                            <vue-form-generator
                                    :schema="schemaRight[activeTab]"
                                    :model="modelRight[activeTab]"
                                    :options="formOptions"
                                    @model-updated="modelUpdated"></vue-form-generator>

                        </form>
                    </div>

                    
                </div>
                <div v-else-if="activeTab === 'billing'" class="tabs-body-content" :class="tabsBodyContentClasses">
                    <payment-settings :selectedInvoices="panel.selectedInvoices"></payment-settings>
                </div>
                <div v-else-if="activeTab === 'subscriptions'" class="tabs-body-content" :class="tabsBodyContentClasses" id="plan-scroll-wrapper">
                    <subscription-settings :selectedInvoices="panel.selectedInvoices"></subscription-settings>
                </div>
                <div v-else-if="activeTab === 'extra'" class="tabs-body-content" :class="tabsBodyContentClasses">
                    <billing-settings :selectedInvoices="panel.selectedInvoices"></billing-settings>
                </div>
            </div>
        </div>
        <div class="settings-bottom" v-if="!['billing', 'extra', 'subscriptions'].includes(activeTab)">

            <span class="settings-bottom--controls">
                <span>
                    <base-button
                            :disabled="uploading"
                            mode="blue"
                            :link="false"
                            @click.native="saveSettings"
                    >Save
                    </base-button>

                </span>

            </span>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
// import {usersTime} from '../../utility/datetime';
import PaymentSettings from "./payments/PaymentSettings";
import {createNamespacedHelpers} from "vuex";
const general = createNamespacedHelpers('general');
import _ from  "lodash";
import VueFormGenerator from "vue-form-generator";
import AccountService from "../../services/account/account.service";
import Swal from "sweetalert2";
import SubscriptionSettings from "./payments/SubscriptionSettings";
import BillingSettings from "./payments/BillingSettings";
import ChangePassword from "./ChangePassword";
import timezones from '../../assets/timezones.json';

export default {
    components: {
        BillingSettings,
        SubscriptionSettings,
        PaymentSettings,
        vueDropzone: vue2Dropzone
    },
    props: {
        panel: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    created() {


        const tzObj = timezones;
         const arr = [
            {id: "America/Los_Angeles", name: "America / Los Angeles", group: "Popular time zones"},
            {id: "America/New_York", name: "America / New York", group: "Popular time zones"},
            {id: "Europe/London", name: "Europe / London", group: "Popular time zones"},
            {id: "Europe/Berlin", name: "Europe / Berlin", group: "Popular time zones"},
            {id: "Australia/Sydney", name: "Australia / Sydney", group: "Popular time zones"},
         ];

        Object.entries(tzObj).forEach(([key, value]) => {
            arr.push({id: `${key}`, name: value, group: "Rest of the world (alphabetically by continent)"}); 
        });

        this.schemaLeft.terminal.fields[0].values = arr;



        this.updateUserDetails();
        if(this.panel?.tab) {
            this.changeTab(this.panel.tab);
        }
    },
    mounted: function() {
        this.initializeProfilePicture(this.modelLeft.profile);
    },
    computed: {
        ...general.mapState(['countries', 'user']),
        twoColumn() {
            return this.activeTab === 'profile' || this.activeTab === 'terminal';
        },
        selectedCountry: function () {
            return this.modelLeft.profile?.country_code;
        },
        computedStyle: function () {
            if(this.panel.zIndex) {
                return "z-index:" + this.panel.zIndex + " !important";
            } else {
                return "";
            }
        },
        tabsBodyContentClasses() {
            return `${this.twoColumn ? 'grid' : ''} ${this.activeTab}`;
        }
    },
    data() {
        return {
            activeTab: 'profile',
            countryDetailsById: {},
            tabs: [
                {
                    code: 'profile',
                    name: 'Profile'
                },
                {
                    code: 'terminal',
                    name: 'Terminal'
                },
                {
                    code: 'subscriptions',
                    name: 'Subscription Details'
                },
                {
                    code: 'extra',
                    name: 'Billing Details'
                }
            ],
            modelLeft: {
                profile: {
                    name: '',
                    email: '',
                    address1: '',
                    address2: '',
                    city: '',
                    region: '',
                    postal_code: '',
                    country_code: '',
                    sms_notification_country: '',
                    sms_notification_number: '',
                    email_opt_in: '',
                    phone_number: '',
                    avatar: ''
                },
                billing: {
                    subscription: 'legacy'
                },
                terminal: {
                    timezone: 'Europe/London',
                    // dateformat: 'd-m-y',
                    // timeformat: '24hr'
                }
                
            },
            modelRight: {
                terminal: {
                    // headlinealerts: false
                }

            },
            schemaLeft: {
                profile: {
                    groups: [
                        {
                            styleClasses: "profile-inputs",
                            // legend: 'Personal Details',
                            fields: [
                                {
                                    type: "input",
                                    inputType: "text",
                                    label: "Name",
                                    model: "name",
                                    readonly: false,
                                    featured: true,
                                    disabled: false,
                                    placeholder: "Name"
                                },
                                {
                                    type: "input",
                                    inputType: "email",
                                    label: "Email",
                                    model: "email",
                                    readonly: false,
                                    featured: true,
                                    required: true,
                                    disabled: false,
                                    placeholder: "Email",
                                    validator: VueFormGenerator.validators.required
                                },
                                {
                                    type: 'select',
                                    label: 'Country',
                                    model: 'country_code',
                                    values: [],
                                    required: true,
                                    selectOptions: {
                                        noneSelectedText: "Select a Country",
                                        hideNoneSelectedText: true,
                                    },
                                    validator: VueFormGenerator.validators.required
                                },
                                {
                                    type: 'select',
                                    label: 'Dial code',
                                    model: 'sms_notification_country',
                                    values: [],
                                    selectOptions: {
                                        noneSelectedText: "Select a SMS Country dialing code",
                                        hideNoneSelectedText: true,
                                    },
                                },
                                {
                                    type: "input",
                                    inputType: "text",
                                    label: "Phone",
                                    model: "phone_number",
                                    readonly: false,
                                    featured: true,
                                    disabled: false,
                                    placeholder: "Mobile",
                                }
                            ]
                        },
                        // {
                        //     legend: 'Address',
                        //     fields: [
                        //
                        //         {
                        //             type: "input",
                        //             inputType: "text",
                        //             label: "City",
                        //             model: "city",
                        //             readonly: false,
                        //             featured: true,
                        //             disabled: false,
                        //             placeholder: "City",
                        //             sses: "medium-input with-margin"
                        //         },
                        //         {
                        //             type: "input",
                        //             inputType: "text",
                        //             label: "Region",
                        //             model: "region",
                        //             readonly: false,
                        //             featured: true,
                        //             disabled: false,
                        //             styleClasses: "medium-input with-margin",
                        //             placeholder: "Region part of address"
                        //         },
                        //         {
                        //             type: "input",
                        //             inputType: "text",
                        //             label: "First line of address",
                        //             model: "address1",
                        //             readonly: false,
                        //             featured: true,
                        //             disabled: false,
                        //             placeholder: "First line of address",
                        //             styleClasses: "medium-input with-margin"
                        //         },
                        //         {
                        //             type: "input",
                        //             inputType: "text",
                        //             label: "Postal code",
                        //             model: "postal_code",
                        //             styleClasses: "medium-input with-margin",
                        //             readonly: false,
                        //             featured: true,
                        //             disabled: false,
                        //             placeholder: "Postal code"
                        //         }
                        //
                        //     ]
                        // }
                        // {
                        //     legend: 'Notifications',
                        //     fields: [
                        //         {
                        //             type: 'select',
                        //             label: 'Dial code',
                        //             model: 'sms_notification_country',
                        //             values: [],
                        //             selectOptions: {
                        //                 noneSelectedText: "Select a SMS Country dialing code",
                        //                 hideNoneSelectedText: true,
                        //             },
                        //             styleClasses: "medium-input with-margin",
                        //         },
                        //         {
                        //             type: "input",
                        //             inputType: "text",
                        //             label: "Notifications Phone number",
                        //             model: "sms_notification_number",
                        //             readonly: false,
                        //             featured: true,
                        //             max: 12,
                        //             disabled: false,
                        //             styleClasses: "medium-input with-margin",
                        //             placeholder: "SMS phone number",
                        //         },
                        //         {
                        //             type: "input",
                        //             inputType: "checkbox",
                        //             label: "Receive newsletters about the application",
                        //             model: "email_opt_in",
                        //             readonly: false,
                        //             featured: true,
                        //             styleClasses: "medium-input with-margin",
                        //             disabled: false,
                        //             placeholder: "Email opt in",
                        //         }
                        //     ]}
                        ]

                },
                billing: {
                    fields: [
                        {
                            type: 'select',
                            label: 'Subscription plan',
                            model: 'subscription',
                            values: [
                                {id: "free", name: "Free"},
                                {id: "legacy", name: "Legacy"},
                                {id: "pro", name: "Pro"}
                            ]
                        },
                    ]
                },
                terminal: {
                    fields: [
                        {
                            type: 'select',
                            label: 'Time zone',
                            model: 'timezone',
                            values: [
                                {id: "Europe/London", name: "Europe / London"},
                                {id: "America/New_York", name: "America / New York"},
                                {id: "Australia/Sydney", name: "Australia / Sydney"}
                            ],
                            selectOptions: {
                                noneSelectedText: "Select a time zone",
                                hideNoneSelectedText: true,
                            },
                        },
                        // {
                        //     type: 'select',
                        //     label: 'Date format',
                        //     model: 'dateformat',
                        //     values: [
                        //         {id: "d-m-y", name: usersTime(new Date(), true, false, false, {dateFormat: "d-m-y"})},
                        //         {id: "m-d-y", name: usersTime(new Date(), true, false, false, {dateFormat: "m-d-y"})},
                        //         {id: "M d", name: usersTime(new Date(), true, false, false, {dateFormat: "M d"})},
                        //         {id: "d M", name: usersTime(new Date(), true, false, false, {dateFormat: "d M"})},
                        //         {id: "js M", name: usersTime(new Date(), true, false, false, {dateFormat: "js M"})},
                        //         {id: "M js", name: usersTime(new Date(), true, false, false, {dateFormat: "M js"})},
                        //         {id: "D js M", name: usersTime(new Date(), true, false, false, {dateFormat: "D js M"})},
                        //     ],
                        //     styleClasses: "medium-input with-margin",
                        //     selectOptions: {
                        //         noneSelectedText: "Select a date format",
                        //         hideNoneSelectedText: true,
                        //     },
                        // },
                        // {
                        //     type: 'select',
                        //     label: 'Time format',
                        //     model: 'timeformat',
                        //     values: [
                        //         {id: "24hr", name: usersTime(new Date(), false, true, false, {timeFormat: "24hr"})},
                        //         {id: "ampm", name: usersTime(new Date(), false, true, false, {timeFormat: "ampm"})},

                        //     ],
                        //     styleClasses: "medium-input",
                        //     selectOptions: {
                        //         noneSelectedText: "Select a time format",
                        //         hideNoneSelectedText: true,
                        //     },
                        // }
                    ]
                }

            },
            schemaRight: {
                terminal: {
                    fields: [
                        {
                            "type": "switch",
                            "label": "Headline Alerts",
                            "model": "headlinealerts",
                            "multi": true,
                            "readonly": false,
                            "featured": false,
                            "disabled": false,
                            "textOn": "Active",
                            "textOff": "Inactive"
                        }
                    ]
                }
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            },
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                addRemoveLinks: true,
                maxFilesize: 5,
                maxFiles: 1
            },
            uploadedFile: null,
            removeUserFile: false,
            errorMessages: [],
            uploading: false
        }
    },

    watch: {
        selectedCountry: function () {
            this.modelLeft.profile.sms_notification_country = this.countryDetailsById[this.selectedCountry]?.[0];
        },
        user: {
            deep: true,
            handler: function () {
                this.updateUserDetails();
            }
        }
    },
    methods: {
        ...general.mapActions(['loadCurrentUserSettings']),
        changeTab(tab) {
            this.activeTab = tab;
        },
        modelUpdated() {
            console.log('model updated');
        },
        openChangePasswordModal: function () {
            this.showModal(ChangePassword);
        },
        validate: function () {
            return this.$refs.form.validate();
        },
        updateUserDetails: function () {
            const settingsObj = _.cloneDeep(this.user);
            this.schemaLeft.profile.groups[0].fields[2].values = this.countries.map(country => {
                return {
                    id: country.iso3166_3char,
                    name: country.country_name
                }
            });
            let countryDialCodes = [];
            this.countries.forEach(country => {
                let codes = country.dial?.split(",");
                if(codes) {
                    codes.forEach(code => countryDialCodes.push(code))
                    this.countryDetailsById[country.iso3166_3char] = codes;
                }
            });
            this.schemaLeft.profile.groups[0].fields[3].values = countryDialCodes;

            [this.modelLeft, this.modelRight].forEach(element => {
                for (const [key, value] of Object.entries(element)) {

                    for (const key1 of Object.keys(value)) {
                        if (settingsObj[key1] !== undefined) {

                            element[key][key1] = settingsObj[key1];
                        }

                    }
                }
            });
        },
        initializeProfilePicture: function (data) {
            if(data.avatar) {
                let imageParts = data.avatar.split(".");
                let imageType = "image/" + imageParts[imageParts.length-1];
                let file = { size: 200, name: "Icon", type: imageType };
                let url = data.avatar;
                this.$refs.picUpload.manuallyAddFile(file, url);
            }
        },
        saveSettings() {
            this.errorMessages = [];
            let isValid = this.validate();
            if(isValid) {
                let userObj = {};
                [this.modelLeft, this.modelRight].forEach(element => {
                    for (const value of Object.values(element)) {

                        userObj = {
                            ...userObj,
                            ...value
                        };

                    }
                });

                console.log(userObj, this.modelLeft);

                if(this.uploadedFile) {
                    userObj.avatar = this.uploadedFile;
                } else {
                    delete userObj.avatar;
                }
                userObj.avatar_delete = this.removeUserFile;
                AccountService.saveUserAccountSettings(userObj).then(result => {
                    if(result.data.success) {
                        Swal.fire({
                            title: 'Success!',
                            type: 'info',
                            text: 'The profile information was successfully updated!',
                            showCancelButton: true,
                            cancelButtonColor: '#1d9deb'
                        });
                        this.loadCurrentUserSettings().then(() => {});

                    }
                }).catch(error => {
                    Object.values(error?.response?.data?.data || {}).forEach(message => this.errorMessages.push(message[0]));
                })
            }

        },
        removeAllFiles() {
            const currentFile = this.$refs.picUpload.getAcceptedFiles();
            if (currentFile.length) {
                const toBeDeleted = currentFile[0];
                this.$refs.picUpload.removeFile(toBeDeleted);
            }

            this.removeUserFile = true;
            this.removeUserFile = true;
        },
        fileAdded() {
            this.removeAllFiles();
            this.uploading = true;
        },
        updateUploadedFile: function (response) {
            if(response.status === "success") {
                this.uploadedFile = response.dataURL;
            } else {
                this.uploadedFile = null;
            }
            this.uploading = false;
        }
    }
}
</script>

<style lang="scss">
// .filled-form {
// //   border: solid 1px grey !important;
//     background-color: rgba($color-03, 0.6);
//     padding: 20px;
// }
// @include settings;

// .medium-input {
//     width: 47%;
//   @include respond(sm) {
//     display: inline-block;
//   }

// }

// .medium-input.with-margin {

//   @include respond(sm) {
//     margin-right: 20px;
//   }
// }
// .narrow-input {
//     width: 22.4%;
//   @include respond(sm) {
//     display: inline-block;
//   }
// }


// #16-digit-card-number {
//   width: 155px;
// }

// #expiry, #cvv {
//   width: 55px;
// }

//     .errors.help-block {
//         margin-top: -18px;
//         margin-bottom: 25px;
//         font-size: 12px;
//     }

// #dropzone-profile {
//   width: 200px;
//   height: 200px;
//   border-radius: 200px;
//   background-color: $color-023;
//   padding: 0;
//   margin: auto;

//   border: 1px solid $color-067;

//   & .dz-default.dz-message {
//     color: $color-06;
//     width: 150px;
//     margin: auto;
//     margin-top: 50px;
//   }

//   & .dz-image {
//     width: 200px;
//     height: 200px;

//     & img {
//       width: 200px;
//       height: 200px;
//       object-fit: cover;
//       border-radius: 200px;
//     }
//   }

//   & .dz-preview.dz-processing.dz-image-preview {
//     margin: 0;
//     border-radius: 200px;
//   }

//   &.dropzone .dz-preview.dz-image-preview {
//     margin: 0;
//     border-radius: 200px;
//   }

//   &.vue-dropzone > .dz-preview .dz-remove {
//     margin-left: 0;
//     left: 50%;
//     transform: translateX(-50%);
//   }
// }

// .profile {

//     & .profile-inputs {
//         display: grid;
//         grid-template-columns: 1fr 1fr 1fr 1fr;
//         column-gap: 15px;

//         & > div:nth-child(1), 
//         & > div:nth-child(2) {
//             grid-column:  span 2;
//         }

//         & > div:nth-child(3) {
//             grid-column:  1 / 5;
//         }

//         & > div:nth-child(4) {
//             grid-column:  1 / 2;
//         }

//         & > div:nth-child(5) {
//             grid-column:  span 3;
//         }

        

//     }
// } 

    
</style>

<style lang="scss" scoped>
.settings-body {
    margin: 31px 15px 15px 15px;
}
.settings-tabs .tabs-body {
    height: calc(40vh + 20px + 30px + 15px + 20px);
    overflow-y: hidden;
    // position: relative;
    max-height: 390px;
//   @include respond(lg) {
//     height: 535px;
//   }

}

// .settings-tabs .tabs-body::before {
//     content: '';
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     position: absolute;
//     box-shadow: 
//         inset 0px 30px 8px -10px rgba($color-01, 0.25),
//         inset 0px -30px 8px -10px rgba($color-01, 0.15); 
//     pointer-events: none;
// }

.settings-tabs .tabs-body-content {
    overflow-y: scroll;
    height: calc(40vh + 20px + 30px + 15px + 20px);
    padding-right: 15px;
    max-height: 390px;
    overflow-x: hidden;

}

.settings-tabs .tabs-body.subscriptions-wrapper,
.settings-tabs .tabs-body.extra-wrapper {

    height: calc(49vh + 20px + 30px + 15px + 20px);
    max-height: 450px;

    & .tabs-body-content {
        height: calc(49vh + 20px + 30px + 15px + 20px);
        max-height: 450px;
    }
}

.settings-tabs .tabs-body-content.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    &.profile {
        grid-template-columns: 2fr 1fr;
    }

}

.top-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include respond(md) {
    flex-direction: row;
  }
}

.top-row > div {
  width: 100%;
}

.inside-row {
  margin: 5px 0;
  padding: 6px 0;

  @include respond(md) {
    text-align: right;
  }
}

// .inside-row:first-child {
//     margin-top: 0;
//     padding-top: 0;
// }
.plan-name {
  display: block;
  margin: 25px 0 5px;
  font-size: 30px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.plan-period {
  text-transform: lowercase;
  color: $color-06;
  font-size: 14px;
}

.total-payment,
.addon-name,
.addon-row {
  color: $color-06;
}

.addon-name,
.addon-row {
  line-height: 17px;
  font-size: 12px;
}

.total-payment {
  margin-top: 10px;
  line-height: 20px;
  font-size: 18px;
}

.total-payment-date,
.total-payment-amount,
.addon-price {
  color: $color-20;
  letter-spacing: 0.3px;
}

.total-payment-amount {
  font-weight: bold;
  letter-spacing: 1px;
}

.card-box {
  color: $color-06;
  margin-right: 20px;
}

.card-box-icon {
  font-size: 20px;
  margin-right: 5px;
}

.bottom-row {
  margin-top: 45px;
}

.payments {
  padding: 5px 0;
}

.payment-unit {
  margin: 5px 0;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba($color-20, 0.1);
  align-items: center;

  @include respond(sm) {
    justify-content: space-between;
  }
}

.addon {
  margin-top: 10px;
}

.addon-cancel {
  margin-left: auto;

  & a {
    color: $color-04;
    transition: color 0.3s ease;
  }

  & a:hover {
    color: $color-20;
  }

  & a:active,
  & a:visited {
    color: $color-06;
  }
}

.addon-row {
  display: flex;
  width: 200px;

}


</style>
