<template>
    <div>
        <div>
            <h2>Set Reminder</h2>
        </div>
        <form id="feedback-form" @submit.prevent>
            <base-input
            elementType="input"
            inputType="email"
            v-model="email"
            label="Confirm your contact email."
            forID="email"
            ></base-input>

            <div class="form-control" style="margin-top: 20px;">
                <base-button 
                @click.native="submitForm"
                mode="o-blue">Email me 15 minutes before the webinar starts</base-button>
            </div>
        </form>
    </div>
</template>

<script>
import { eventBus } from '../../../main';
import { talkToServer } from '../../../utility/helper-functions';
export default {
    props: ["panel"],
    data() {
        return {
            email: window.user.email ? window.user.email : ''
        }
    },
    methods: {
        submitForm() {
            talkToServer(`/widgets/live-streams/${this.panel.streamID}/reminder/subscribe`, "GET", null, null, true)
            .then(() => {
                // console.log("subscribe",response);
                this.$store.dispatch("webinars/addActiveReminder", this.panel.streamID);
                eventBus.$emit('close-modal');
            });
            
        }
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        padding: 0 25px;
        @include H2;
    }
    form {
        padding: 5px 25px 15px;
    }
</style>