import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            currencyData: [],
            currencyChannels: ["LiveDataUpdateDailyPerf"],
            lastUpdate: null,
            lastPush: null,
            pairs: {
                'LiveDataUpdateDailyPerf': {
                }
            },
            leaderboard: {
                'LiveDataUpdateDailyPerf': {
                }
            }
        }
    },
    mutations,
    actions,
    getters
}
