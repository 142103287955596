<template>
    <tr class="">
        <td :style="cellStyles" style="position: relative;">
            <country-flag v-if="sizes.fontsize > 12" :country="bank.country_code" size='normal' style="top: 0;"/>
            <country-flag v-else :country="bank.country_code" size='small' style="top: -8px;" />
        </td>
        <td :style="cellStyles">
            <button class="row-title-label" @click="openTableRow" :style="fontSize">
                <span class="row-title-label-name control-table-expandable from-lg">
                    {{ bank.central_bank }}
                </span>
                <span class="row-title-label-name control-table-expandable before-lg">
                    {{ bank.central_bank_code }}
                </span>
                <span class="row-title-label-plus control-table-expandable irpt before-md">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </span>
            </button>
        </td>
        <td 
        :style="cellStyles"
        :class="redOrGreen"
        class="from-xs" 
        >{{ action }}</td>
        <td 
        :style="cellStyles"
        class="from-sm">{{ formattedChangeBy }}</td>
        <td 
        :style="cellStyles"
        class="from-xs">{{ formattedProbability }}</td>
        <td 
        :style="cellStyles"
        class="from-xs"><span>{{ nextMeetingDay.dm }}</span> <span class="from-sm" style="margin-left: 4px;">{{ nextMeetingDay.y }}</span></td>
        <td 
        :style="cellStyles"
        class="from-md">{{ formattedRate }}</td>
            
    </tr>
</template>

<script>
import IrptDataMixin from '../../../../mixins/irptdatamixin';
import { eventBus } from '../../../../main';
import CountryFlag from 'vue-country-flag';

export default {
    props: ["bank", "lastUpdatedAt", "styleData"],
    components: {
        CountryFlag
    },
    mixins: [IrptDataMixin],
    // created() {
    //     console.log(this.sizes);
    // },
    // data() {
    //     return {
    //         isRowOpen: false,
    //     }
    // },
    computed: {
        sizes() {

            let vertPadding = 0;
            let fontsize = 0;
            // console.log(this.styleData.height);
            if(this.styleData.height > 500) {
                vertPadding = 10.5;
                fontsize = 15;
            }else{
                const rowsOffset = this.styleData.bankCount * 15;
                const heightBase = this.styleData.height - 160 - rowsOffset;
                vertPadding = heightBase / this.styleData.bankCount / 2;
                fontsize = 12;
                // console.log(this.height, heightBase);
            }
            return { vertPadding, fontsize }
            // return {paddingTop: padding + 'px', paddingBottom: padding + 'px'};
        },
        vertPadding() {
            return { paddingTop: this.sizes.vertPadding + 'px', paddingBottom: this.sizes.vertPadding + 'px' };
        },
        fontSize() {
            return { fontSize: this.sizes.fontsize + 'px' };
        },
        cellStyles() {
            return { ...this.vertPadding, ...this.fontSize }
        }
        // 
            
            
            // return {paddingTop: padding + 'px', paddingBottom: padding + 'px'};
    },
    methods: {
        openTableRow() {
            // this.isRowOpen = !this.isRowOpen;
            this.openMobilePopup();
        },
        openMobilePopup() {
            eventBus.$emit('open-modal', {
                instanceID: this.$root.instanceID,
                componentInModal: "interestrate-modal",
                classes: "terminal-modal simplified auto-height",
                panel: {
                    bank: this.bank,
                    lastUpdatedAt: this.lastUpdatedAt
                },
                mode: "wide",
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .row-title-label-plus.control-table-expandable.irpt {
        

        // @media screen and (min-width: 358px) {
        //     display: none;
        // }
    }
</style>