<template>
    <div class="video-preview-unit-wrapper">
        <div v-if="isComment">
        </div>
        <a 
        href="javascript:void(0);"
        v-else
        class="video-preview-unit video-unit" 
        :class="{ 'u-highlighted-video': isActive, 'is-locked': isLocked }"
        :data-id="video.id"
        v-on="!isLocked ? { click: renderVideo } : {}"
        >
            <div class="video-preview-unit__thumbnail">
                <span v-if="image"
                v-html="image">
                
                </span>
                <padlock-overlay
                v-if="isLocked"
                ></padlock-overlay>
                <!-- <div class="image-overlay"></div> -->
                <span class="progress-bar" :style="progressWidth"></span>
            </div>
            <div class="video-preview-unit__description">
                <div class="video-preview-unit__description--title disable-select">{{ processedVideo.title }}</div>

                <div class="video-preview-unit__description--details">
                    
                    <div class="video-preview-unit__description--details-time u-color-02">
                        <span class="output-time disable-select">{{ outputTime }}</span> <span v-if="!isCourse">-</span> <span class="output-date disable-select">{{ outputDate }}</span>
                    </div>

                    <div 
                    class="video-preview-unit__description--details"
                    v-if="isStream && showReminder"
                    >
                        <base-button 
                        v-if="isReminderActive"
                        @click.native="cancelReminder(video.id)"
                        mode="video-button green">Reminder Set
                        </base-button>

                        <base-button
                        v-else 
                        @click.native="setReminder(video.id)"
                        mode="video-button o-blue">Set Reminder
                        </base-button>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { usersTime } from '../../../../utility/datetime';
import { arrayIntersect, getServerEnv, getWebDomain } from '../../../../utility/helper-functions';
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import ReminderMixin from '../../../../mixins/reminderMixin';
import Constants from '../../../../utility/constants';
import PadlockOverlay from '../../../../assets/PadlockOverlay.vue';

const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");

export default {
    props: ["video", "noReminder"], 
    components: {
        PadlockOverlay,
    },
    mixins: [ReminderMixin],
    inject: ["$activeVideo"],
    created() {
        eventBus.$on("progress-update", obj =>  {
            if(!(obj.code === 'TCSTREAM' && this.isStream || obj.code === 'TCVIDEO' && this.isCourse || obj.code === 'VIDCOM' && this.isVidCom)) {
                return;
            }


            if(obj.instanceID === this.$root.instanceID &&
            this.video.id === obj.video_id) {
                this.processedVideo.progress = obj.progress;
            }
                
        });
        
        const item = this.video;
        let headline = this.video.subject
        let regExRule;
        let thumbnailUrl;
        
        if(headline && this.video.content)
            this.isVimeoHeadline = this.video.content.indexOf('iframe')!== -1 ? true : false;

        if(this.isVimeoHeadline) {
            regExRule = /\s(\w*data-thumbnail-play-url=+?)"(.+?)"/g;
            if((regExRule.exec(item.content))) {
                regExRule = /\s(\w*data-thumbnail-play-url=+?)"(.+?)"/g;
                thumbnailUrl = (regExRule.exec(item.content))[2];
            }

            if(thumbnailUrl?.indexOf("vimeo") === -1) {
                thumbnailUrl = getWebDomain() + thumbnailUrl;
            }

            if(thumbnailUrl)
                this.image = `<img src="${thumbnailUrl}" />`;
            
            // regExRule = /<iframe(.*?)iframe>/;
            // pureIframe = (regExRule.exec(item.content))[0];
            
            
            regExRule = /\s(\w*src=+?)"(.+?)"/g;
            
            this.processedVideo.src = (regExRule.exec(item.content))[2];
            this.processedVideo.title = this.video.subject;
            this.processedVideo.time = this.video.published_at;

            // vimeoID = this.processedVideo.src.replace(/[^0-9]/g,'');
            // console.log(thumbnailUrl);

        }

        if(headline && headline.indexOf('European Session Video Commentary:') > -1) {
            headline = headline.replace('European Session Video Commentary: ', '');
        }



        if(Object.prototype.hasOwnProperty.call(this.video, 'mux_id'))
            this.isStream = true;

        if(this.isStream) {
            this.processedVideo.src = this.video.playback_url; //"https://vimeo.com/585245235/e22f3bac5d"; //;
            this.processedVideo.title = this.video.name;
            this.processedVideo.time = this.video.starts_at;
            this.processedVideo.status = this.video.status;

            if(this.processedVideo.status === "scheduled" || this.processedVideo.status === "live")
                this.manageReminderStatus();

        }



        if(this.video.playback_url &&
        this.video.playback_url.indexOf("vimeo.com") > -1)
            this.isVimeoPlayback = true;

        if(this.isVimeoPlayback) {
            this.processedVideo.src = this.video.playback_url;
            this.processedVideo.title = this.video.name;
            this.processedVideo.time = this.video.starts_at;
            this.processedVideo.status = this.video.status;

            if(this.processedVideo.status === 'ready-for-playback')
                this.processedVideo.progress = this.watchHistory.TCSTREAM[this.video.id] ? this.watchHistory.TCSTREAM[this.video.id].progress : 0;
        }

        if(this.isStream || this.isVimeoPlayback) {
            thumbnailUrl = this.video.thumbnail_url;

            const domain = this.video.thumbnail_url?.indexOf("vimeo") > -1 ? '' : window.bootUp.entity.mapped_domain;

            if(thumbnailUrl)
                this.image = `<img src="${domain + thumbnailUrl}" />`;
        }

        if(Object.prototype.hasOwnProperty.call(this.video, 'associated_text')) {
            this.isCourse = true;

            this.image = `<img src="${this.video.thumbnail}" />`;
            this.processedVideo.title = this.video.video_name;
            this.processedVideo.time = this.video.created_at;
            this.processedVideo.src = 'https://player.vimeo.com/video/' + this.video.video_id;
            this.processedVideo.progress = this.watchHistory.TCVIDEO[this.video.id] ? this.watchHistory.TCVIDEO[this.video.id].progress : 0;
            this.processedVideo.length = this.video.video_length_seconds;
        }

        if(this.isVidCom) {
            this.processedVideo.progress = this.watchHistory.VIDCOM[this.video.id] ? this.watchHistory.VIDCOM[this.video.id].progress : 0;
        }

        //for end of day comments:
        if(!this.isStream && 
        !this.isVimeoHeadline &&
        !this.isVimeoPlayback &&
        !this.isCourse) {
            this.isComment = true;
            this.processedVideo.title = this.video.subject;
            this.processedVideo.time = this.video.published_at;
        }

        
    },
    computed: {
        ...general.mapGetters(['user', 'watchHistory']),
        ...payments.mapGetters(['activePlan', 'currentAddons']),
        timezone() {
            return this.user.timezone;
        },
        outputDate() {
            if(this.isCourse)
                return '';

            return usersTime(this.processedVideo.time, true, false, false, {dateFormat: "d M Y"}, false, this.timezone);
        },
        outputTime() {
            if(this.isCourse) {
                const length = this.processedVideo.length;
                const round = Math.round(length / 60);
                const min = round > 1 ? 'minutes' : 'minute';
                return  round + ' ' + min;
            }

            return usersTime(this.processedVideo.time, false, true, false, false, false, this.timezone);
        },
        activeVideo() {
            return this.$activeVideo();
        },
        isActive() {
            return this.activeVideo === this.video.id;
        },
        progressWidth() {
            return {width: this.processedVideo.progress + '%'};
        },
        isVidCom() {
            return !!this.video.source_id;
        },
        isLocked() {
            const env = getServerEnv();
            const restrictedCourseIDs = Object.keys(Constants.TC_FREE_VIDEOS[env]).map(el => +el);
            if(restrictedCourseIDs.includes(this.video.widget_implementation_id)) {

                const prodAccessIDs = [this.activePlan.product.id, ...this.currentAddons.map(el => el.product.parent_id ? el.product.parent_id : el.product.id)];
                const fullAccessProds = Constants.TC_FREE_VIDEOS[env][this.video.widget_implementation_id].full_access_prod_ids;

                if((arrayIntersect(prodAccessIDs, fullAccessProds)))
                    return false; //
                if(Constants.TC_FREE_VIDEOS[env][this.video.widget_implementation_id].free_videos.includes(this.video.video_id))
                    return false;

                return true;
            }

            return false;
        }
    },
    data() {
        return {
            image: '',
            processedVideo: {
                progress: 0,
                id: this.video.id,
                src: '',
                title: '',
                time: '',
                status: ''
            },
            isVimeoHeadline: null,
            isStream: null,
            isCourse: null,
            isVimeoPlayback: null,
            isComment: false,
            isReminderActive: false,
            showReminder: false,
        }
    },
    methods: {
        renderVideo() {
            if(this.processedVideo.src) {

                this.$emit("render-video", this.processedVideo);
            }
            
        },
        
        manageReminderStatus() {
            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "HH:mm:ss"
            }, true);
            const start = usersTime(this.processedVideo.time, true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "HH:mm:ss"
            }, true);

            if(start <= now) {
                this.showReminder = false;
                return;
            }
                
            this.showReminder = true;
            const active = this.activeReminders.findIndex(el => el == this.processedVideo.id) > -1;

            if(active)
                this.isReminderActive = true;
            else
                this.isReminderActive = false;
        }
    },
    watch: {
        video: {
            handler() {
                const item = this.video;
                let headline = this.video.subject
                let regExRule;
                let thumbnailUrl;
                
                if(this.video.content)
                    this.isVimeoHeadline = this.video.content.indexOf('iframe')!== -1 ? true : false;

                if(this.isVimeoHeadline) {
                    regExRule = /\s(\w*data-thumbnail-play-url=+?)"(.+?)"/g;
                    if((regExRule.exec(item.content))) {
                        regExRule = /\s(\w*data-thumbnail-play-url=+?)"(.+?)"/g;
                        thumbnailUrl = (regExRule.exec(item.content))[2];
                    }

                    if(thumbnailUrl?.indexOf("vimeo") === -1) {
                        thumbnailUrl = getWebDomain() + thumbnailUrl;
                    }

                    if(thumbnailUrl)
                        this.image = `<img src="${thumbnailUrl}" />`;
                    
                    // regExRule = /<iframe(.*?)iframe>/;
                    // pureIframe = (regExRule.exec(item.content))[0];
                    
                    
                    regExRule = /\s(\w*src=+?)"(.+?)"/g;
                    
                    this.processedVideo.src = (regExRule.exec(item.content))[2];
                    this.processedVideo.title = this.video.subject;
                    this.processedVideo.time = this.video.published_at;

                    // vimeoID = this.processedVideo.src.replace(/[^0-9]/g,'');

                    

                }

                if(headline && headline.indexOf('European Session Video Commentary:') > -1) {
                    headline = headline.replace('European Session Video Commentary: ', '');
                }



                if(this.video.playback_url &&
                this.video.playback_url.indexOf("mux.com") > -1)
                    this.isStream = true;

                if(this.isStream) {
                    this.processedVideo.src = this.video.playback_url; //"https://vimeo.com/585245235/e22f3bac5d"; //;
                    this.processedVideo.title = this.video.name;
                    this.processedVideo.time = this.video.starts_at;
                    this.processedVideo.status = this.video.status;

                    if(this.processedVideo.status === "scheduled" || this.processedVideo.status === "live")
                        this.manageReminderStatus();
                }



                if(this.video.playback_url &&
                this.video.playback_url.indexOf("vimeo.com") > -1)
                    this.isVimeoPlayback = true;

                if(this.isVimeoPlayback) {
                    this.processedVideo.src = this.video.playback_url;
                    this.processedVideo.title = this.video.name;
                    this.processedVideo.time = this.video.starts_at;
                }

                if(this.isStream || this.isVimeoPlayback) {
                    thumbnailUrl = this.video.thumbnail_url;

                    const domain = thumbnailUrl?.indexOf("vimeo") > -1 ? '' : window.bootUp.entity.mapped_domain;

                    if(thumbnailUrl)
                        this.image = `<img src="${domain + thumbnailUrl + '?token=' + localStorage.getItem('token')}" />`;
                }

                if(Object.prototype.hasOwnProperty.call(this.video, 'associated_text')) {
                    this.isCourse = true;

                    this.image = `<img src="${this.video.thumbnail}" />`;
                    this.processedVideo.title = this.video.video_name;
                    this.processedVideo.time = this.video.created_at;
                    this.processedVideo.src = 'https://player.vimeo.com/video/' + this.video.video_id;
                }

                //for end of day comments:
                if(!this.isStream && 
                !this.isVimeoHeadline &&
                !this.isVimeoPlayback &&
                !this.isCourse) {
                    this.isComment = true;
                    this.processedVideo.title = this.video.subject;
                    this.processedVideo.time = this.video.published_at;
                }
            },
            deep: true
        },
        
    }

}
</script>