export default {
    addBulkRecords(context, payload) {

        const arr = [];

        for (const value of Object.values(payload)) {
                arr.push(value);
        }

        const processingObject = {
            objStore: `lookupCategories`, 
            data: arr
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(response => {
            if(response) {
                context.dispatch("haveLookupCategories", true);
            }
            
        });
        
    },

    haveLookupCategories(context, payload) {
        context.commit("haveLookupCategories", payload);
    },
}