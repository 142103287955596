<template>
    <div class="plan-card" :key="plan.slug" @click="toggleAccordion">
        <slot></slot>
        <div class="plan-top" style="overflow-y: hidden;">
            <h3>{{ plan.name }}</h3>
            <p class="plan-price">
                <span class="dimmed-text sub" v-if="!isFree(price)">$</span>
                <VueCustomTooltip :label="priceVatInfo(price)" :multiline="true"><span>{{ price }}</span></VueCustomTooltip>
                <span style="font-size: small">
                    <em>
                     ({{ friendlyPromotionLength }} Access)
                    </em>
                 </span>
                <span style="font-size: small">
                    <em>
                     (offer expires {{ countdown }})
                    </em>
                 </span>
            </p>
            <div style="margin-bottom: 20px;">
                <base-button
                    mode="green"
                    :link="false"
                    @click.native="changePlanHandler(plan)"
                >Buy Now
                </base-button>
                <base-button
                        mode="blue"
                        :link="false"
                        @click.native="toggleAccordion"
                >View video
                </base-button>
                <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down">
                    <template v-if="plan.video_id">
                        <br/>
                        <br/>
                        <div class="responsive-16-9-frame">
                            <iframe class="terminal-embed-video" :src="'https://player.vimeo.com/video' + plan.video_id"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                    allowfullscreen="">
                            </iframe>
                        </div>
                    </template>

                    <br/>
                    <br/>
                    <div>
                        <div class="video-description">
                            <span v-html="plan.description"></span>
                        </div>
                    </div>
                </vue-slide-up-down>
            </div>
        </div>
    </div>
</template>

<script>

import {formatAnnouncementDate} from "../../../utility/datetime";
import _ from "lodash";
export default {
    name: "PromotionProductDetails",
    props: {
        plan: {
            type: Object,
            default: () => {
            }
        },
        changePlanHandler: {
            type: Function
        },
        price: String,
    },
    data: function () {
        return {
            isSlideDownOpen: false,
            countdown: '',
            intervalReference: null
        }
    },
    computed: {
        truncatedDescription: function () {
            return this.plan.description?.substr(0, 157) + "...";
        },
        friendlyPromotionEndDate: function () {
            return formatAnnouncementDate(this.plan.promotion_ends_at, true)
        },
        friendlyPromotionLength: function () {
            return this.plan.subscription_length + "  " + this.plan.subscription_length_type;
        }
    },
    methods: {
        toggleAccordion: function (event) {
            event.stopImmediatePropagation();
            this.isSlideDownOpen = !this.isSlideDownOpen;
        }
    },
    mounted() {
        let context = this;
        context.countdown = _.cloneDeep(context.friendlyPromotionEndDate);
        this.intervalReference = setInterval(() => {
            context.countdown = formatAnnouncementDate(this.plan.promotion_ends_at, true);
        }, 1000)
    },
    destroyed() {
        clearInterval(this.intervalReference);
    }
}
</script>


