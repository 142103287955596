export default {
    addBulkRecords(context, payload) {
        
        const arr = [];

        for (const [key, value] of Object.entries(payload)) {
                arr.push({
                    id: +key,
                    name: value
                });
        }

        const processingObject = {
            objStore: `lookupProperties`, 
            data: arr
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(response => {
            if(response) {
                context.dispatch("haveLookupProperties", true);
            }
            
        });
        
    },

    haveLookupProperties(context, payload) {
        context.commit("haveLookupProperties", payload);
    },
}