<template>
    <div>
        <div>
            <tab-menu 
            type="dashboards"
            class="tab-menu-section"
            :class="{isPopOut: isPopOut}"
            :draggable="true"
            :active="activeDashboard"
            :tabs="tabbedDashboards"
            @menu-item-clicked="changeDashboard"
            ></tab-menu>
            <platform-info v-if="poppedOutFromMain" version="popped-out"></platform-info>
            <dragndrop-grid v-else-if="activeDashboard" :active="activeDashboard" :pageType="'dashboard'" :panelLayoutID="panelLayoutID"></dragndrop-grid>
            <div v-else style="color: white; padding: 5px 5vw; text-align: center;">You have no access to any indicators, please log in on a desktop computer to upgrade or browse our Training Centre.</div>
        </div>
    </div>
</template>

<script>
import DragndropGrid from './ui/DragndropGrid';
import PlatformInfo from './ui/PlatformInfo.vue'
import TabMenu from './ui/TabMenu.vue'

import {createNamespacedHelpers} from 'vuex';
import { getParameterByName, isMobileForDashboards } from '../utility/helper-functions';
// import _ from "lodash";

const dashboards = createNamespacedHelpers('dashboard');

export default {
    props: ["panelLayoutID"],
    components: {
        DragndropGrid,
        PlatformInfo,
        TabMenu
    },
    created() {
        // console.log("dashboard created", this.activeDashboard);
        this.isPopOut = getParameterByName("popout");
    },
    data() {
        return {
            // modalIsOpen: false,
            layoutFetchDone: false,
            terminalUser: !window.bootUp.nonTerminalUser,
            timeout: null,
            isPopOut: false
        }
    },
    computed: {
        ...dashboards.mapGetters(['layouts','dashboards', 'nextDashboardId', 'isThereLayoutByInstanceID','activeDashboardByInstanceID', 'activeDashboardInfoByInstanceID', 'tabbedDashboards', 'getPoppedoutDashboardByID']),
        // isThereLayout() {
        //     return this.isThereLayoutByInstanceID(this.$root.instanceID);
        // },
        activeDashboard() {
            let active = this.activeDashboardByInstanceID(this.$root.instanceID, "dashboard");
            
            if(getParameterByName("dashboard"))
                active = +getParameterByName("dashboard");


            if(active === -1) {
                if(!isMobileForDashboards()) {
                    active = parseInt(Object.keys(this.layouts)[0]);
                    this.setActiveDashboardAndTab({id: active, instanceID: this.$root.instanceID});
                }else{
                    const filtered = (Object.values(this.layouts).filter(el => !el.vidpage));
                    active = Object.keys(filtered)?.[0];
                    this.setActiveDashboard({target: "dashboard", id: active, instanceID: this.$root.instanceID})
                }
                
            }

            return active;
        },
        poppedOutFromMain() {
            return this.layouts[this.activeDashboard]?.popout && !getParameterByName("popout");
        }
    },
    // beforeCreate() {
        
    // },
    // created() {
    //     console.log('panel param sent', this.activeDashboard);
    // },
    methods: {
        ...dashboards.mapActions(['setActiveDashboardAndTab', 'setActiveDashboard']),

        changeDashboard(obj) {
            const poppedOut = this.getPoppedoutDashboardByID(obj.id);
            if (poppedOut && poppedOut.window && !poppedOut.window.closed) {
                poppedOut.window.focus();
            }
            this.setActiveDashboardAndTab({ 
                instanceID: this.$root.instanceID,
                ...obj
            });
        }

    }
}
</script>

<style lang="scss" scoped>
.tab-menu-section {
    margin: 15px 30px 12px 30px;

    &.isPopOut {
        display: none;
    }
}
</style>

