<template>
    <div class="account-card">
        <h2>Billing Details</h2>
        <div style="margin-top: 25px;">
            <div class="account-card-section">
                <div>
                    <label>Current card</label>
                </div>
                <div style="margin-bottom: 5px;">
                    <div v-if="hasActiveCard" class="billing-details">
                        <template
                        >
                            
                                <div v-if="ccDetails[0]">
                                    <span>
                                        <font-awesome-icon size="2x" class="visa-icon" :icon="{ prefix: 'fab', iconName: 'cc-' + ccDetails[0].trim()}"/>
                                    </span>
                                </div>
                                
                                <div>{{ friendlyCardNumber }}<span class="cc-expiry"><span>Exp:</span> {{ cardExpirationDate }}</span></div>
                                
                                <div>
                                    <button class="edit-card" @click="editPaymentMethod">
                                        <font-awesome-icon :icon="['fa', 'pencil-alt']"/>
                                    </button>
                                    <button 
                                    v-if="!hasReversiblePremiumProd"
                                    class="delete-card" 
                                    @click="deleteCard">
                                        <font-awesome-icon :icon="['fas', 'trash-alt']"/>
                                    </button>
                                    
                                </div>
                                    
                                
                        
                            
                        </template>
                    </div>
                    <div v-else>
                        <base-button
                        mode="o-dark-grey"
                        :link="false"
                        @click.native="addPaymentMethod"
                        >Add card
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import PaymentCardModal from "../payments/PaymentCardModal";
import Swal from 'sweetalert2';

const payments = createNamespacedHelpers('payments');

export default {
    name: "BillingDetails",
    mixins: [SubscriptionMixin],
    computed: {
        ...payments.mapGetters(["userHasActiveSubscriptions", "nextPaymentInformation"]),
        hasReversiblePremiumProd() {
            return +this.activePlan.price && !this.activePlan.product.trial_of_product_id || this.currentAddons.find(el => +el.price > 0 && el.product.product_sub_type !== 'bundle');
        }
    },
    // created(){
    //     console.log(this.activePlan, this.currentAddons);
    // },
    data: function () {
        return {}
    },
    methods: {
        ...payments.mapActions(['removePaymentMethod']),
        addPaymentMethod: function () {
            this.showModal(PaymentCardModal, {
                edit: false,
                hideHeader: true
            });
        },
        editPaymentMethod: function () {
            this.showModal(PaymentCardModal, {
                edit: true,
                hideHeader: true
            });
        },
        deleteCard() {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You will need to add a new card before you purchase new products',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.removePaymentMethod().then(() => {
                        Swal.fire({
                            title: 'Success',
                            type: 'info',
                            text: 'Your payment method was successfully removed!',
                            confirmButtonColor: '##1d9deb',
                            confirmButtonText: 'OK'
                        });
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .billing-details {
        display: flex; 
        color: $input-color;

        & > div {
            margin: 0 5px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }

        & .edit-card,
        & .delete-card {
            background-color: transparent;
            color: $input-color-filter;
            border: none;
            cursor: pointer;
        }

    }
    .cc-expiry {

        & span {
            color: $input-color-filter;
        }
        @include respond(lg) {
            margin: 0 20px;
        }
    }
    .visa-icon {
        
    }
</style>
