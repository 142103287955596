<template>
    <div class="modal-wrapper">
        <h3>Welcome to the brand new Financial Source Terminal.</h3>
        <p>To help you get started, please click the button below for our detailed guide and learn how you can continue accessing your favourite trading and analysis tools:</p>
        <div style="margin-top: 40px;">
            <base-button
            mode="blue"
            :link="false"
            @click.native="openGuide"
            >Learn more
            </base-button>
        </div>
        <div style="margin-top: 10px; font-size: 10px;">
            <base-button
            mode="flat no-text-transform"
            @click.native="dismissPopup"
            >Don't show this again
            </base-button>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../main';
import { talkToServer } from '../../utility/helper-functions';

const general = createNamespacedHelpers('general');
export default {
    props: ["panel"],
    computed: {
        ...general.mapGetters(['user']),

        guideURL() {
            if(this.panel.panelObj.active === 'Legacy Plan')
                return 'https://financialsource.co/legacy-user-guide/';
            else if(this.panel.panelObj.active === 'Pro Plan')
                return 'https://financialsource.co/pro-user-guide/';

            return '';
        }
    },
    methods: {
        ...general.mapActions(['loadCurrentUserSettings']),

        openGuide() {
            window.open(this.guideURL);
        },
        async dismissPopup() {
            
            this.panel.panelObj["welcome-dismissed"][this.panel.panelObj.type] = true;
            console.log(this.panel.panelObj["welcome-dismissed"]);

            talkToServer('/setting/user/welcome-dismissed', "POST", null, this.panel.panelObj["welcome-dismissed"], true);

            eventBus.$emit('close-modal');

        }
    }
}

</script>

<style lang="scss" scoped>
    .modal-wrapper {
        padding: 15px;
        text-align: center;

        & h3, 
        & p {
            color: $color-06;
        }

        & h3 {
            margin-bottom: 30px;
            letter-spacing: 0.5px;
        }
    }
</style>
