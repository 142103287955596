import httpClient from "../../api/HttpClient";
import {createUrl} from "../../utility/helper-functions";

export default {
    loadCurrentUserSettings: function (withCache) {

        let cacheParams = '';
        if(withCache) {
            cacheParams = window.bootUp.cacheParams;
        }

        // console.log("loading");

        return httpClient.get(createUrl('/user/refresh'+cacheParams));
    },
    saveUserAccountSettings: function (userSettings) {
        return httpClient.put(createUrl('/user'), userSettings, {spinner: true});
    },
    changePassword: function (model) {
        return httpClient.put(createUrl('/user'), model, {spinner: true});
    },
    resetPassword: function (model) {
        return httpClient.post(createUrl('/password-reset'), model, {spinner: false});
    }
}
