<template>
    <div class="grid-layout-cell">
        <div class="content-card" 
        :class="{'poppable': !item.video_id}"
        v-on="!item.video_id ? { click: openFolder } : {}">
            <div class="content-card-thumbnail">
                <!-- <div class="responsive-16-9-frame"> -->

                    <video-player  
                    v-if="item.video_id"
                    :video="item" 
                    :layoutID="details.layoutID"
                    :code="details.widgetCode"
                    :impID="details.implementation_id"
                    :videoAutoPlay="false"
                    ></video-player>
                    <!-- <iframe
                    v-if="item.video_id" 
                    class="terminal-embed-video" :src="vimeoLink"
                    frameborder="0" 
                    allow="accelerometer; 
                    autoplay; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    fullscreen" 
                    allowfullscreen="" 
                    data-ready="true">
                    </iframe> -->

                    <img
                    v-else
                    :src="item.thumbnail" :alt="item.display_name" />
                    <span class="progress-bar" :style="progressWidth"></span>
                <!-- </div> -->
            </div>
            <div class="content-card-label">
                <div class="content-card-label-title video-title">
                    {{ itemTitle }}
                </div>
                <div class="content-card-label-details">
                    
                <!-- <div 
                v-if="item.video_id"
                class="content-card-label-details-time">
                    {{ outputDate }} {{ outputTime }}
                </div> -->

                </div>
            </div>
            <div 
            v-if="associatedText"
            class="content-card-text" 
            v-html="associatedText"></div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import { usersTime } from '../../../../utility/datetime';
import VideoPlayer from './VideoPlayer.vue';

const general = createNamespacedHelpers('general');

export default {
  components: { VideoPlayer },
    props: ["item", "details"],
    created() {
        eventBus.$on("progress-update", obj =>  {
           
            if(obj.instanceID === this.$root.instanceID &&
            this.item.id === obj.video_id) {
                this.progress = obj.progress;
            }
                
        });


        this.progress = this.watchHistory.TCVIDEO[this.item.id] ? this.watchHistory.TCVIDEO[this.item.id].progress : 0;
    },
    computed: {
        ...general.mapGetters(['watchHistory']),
        
        itemTitle() {
            if(this.item.video_id)
                return this.item.video_name;

            return this.item.display_name;
        },
        outputDate() {
            if(this.item.video_id)
                return usersTime(this.item.created_at, true, false, false, false, false, this.timezone);

            return '';
        },
        outputTime() {
            if(this.item.video_id)
                return usersTime(this.item.created_at, false, true, false, false, false, this.timezone);

            return '';
        },
        progressWidth() {
            return {width: this.progress + '%'};
        },
        associatedText() {
            if(this.item?.associated_text?.length > 15)
                return this.item.associated_text;

            return '';
        }
    },
    data() {
        return {
            progress: 0
        }
    },
    methods: {
        openFolder() {
            if(!this.video_id)
                this.$emit("open-folder", this.item.id);
        }
    }
}
</script>

<style lang="scss" scoped>
    .content-card {
        margin: 0 auto;
        width: 100%;

        display: grid;
        grid-template-columns: 1fr;

        
        grid-template-rows: 2fr 110px; //2.2fr 1.1fr


        
        

        background-color: $widget-faded-background;
        border-radius: 3px;
        box-shadow: 5px 5px 10px rgba($color-03, 0.15);
        overflow: hidden;

        transition: all 0.3s ease;

        &.poppable {

            & {
                cursor: pointer;
            }

            &:hover {
                transform: translateY(-5px);
                background-color: $widget-faded-background-2;
            }
        
            &:hover .content-card-thumbnail {
                filter: brightness(100%);
            }
        }
        
        

        &-thumbnail {
            grid-row: 1;
            // background-color: green;
            // overflow: hidden;
            transition: all 0.3s ease;
            filter: brightness(85%);

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.no-pointer {
                pointer-events: none;
            }
        }

        &-label {
            grid-row: 2;
            color: $input-color;
            pointer-events: none;


            @include respond(desktop) {
                padding: 5px 10px;
            }

            &-title {
                margin: 8px 0;
                padding: 0 10px;
                @include SubHead2;
                color: $input-color;
              
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &-details {

                display: grid;

                margin-top: 5px;

                &-time {
                    display: inline-block;
                    width: fit-content;
                    font-size: 12px;
                    color: $input-color-filter;
                    margin: 2px 0 2px 10px;
                    line-height: 8px;
                    padding: 6px;
                }

            }

        }
    }
</style>