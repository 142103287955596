<template>
    <div>
        <div class="glossary-panel">
            <div class="instruction-row">Find phrases by their initial or use the search bar. If you can't find your phrase, please send us a request to add it to our glossary.
                </div>
            <div class="glossary-panel-main modal-form-body">
                <loading-spinner v-if="!resultsReady" style="height:100px;"></loading-spinner>
                <div>
                    <div class="alphabet-row">
                        <span 
                        v-for="(letter, index) in renderedAlphabet"
                        :key="letter.letter"
                        class="alphabet-letter"
                        >
                        <span v-if="letter.link">
                            <button @click="pickLetter(letter.letter)">{{ letter.letter }}</button></span>
                            <span v-else>{{ letter.letter }}</span>
                            <span v-if="index < renderedAlphabet.length - 1" class="bullet-separator">•</span>
                        </span>
                        
                    </div>
                    <div class="glossary-feedback">
                        <form class="" @submit.prevent>
                            <base-input
                            :class="{invalid: !searchTerm.isValid}"
                            forID="searchTerm"
                            label="Look up a phrase:"
                            inputType="text" 
                            placeholder="begin typing..."
                            elementType="input"
                            max="25"
                            :invalid="!searchTerm.isValid"
                            :invalidMessage="errorMessage"
                            v-model="searchTerm.val"
                            @keyup="sortForRendering(searchTerm.val)"
                            @clear-validity="clearValidity"
                            >
                            </base-input>
                            <!-- <input maxlength="50" type="text" name="glossary-new-phrase-question" class="feedback-question" id="glossary-new-phrase-question"
                            placeholder="begin typing..."/> -->
                            <!-- <div class="char-count u-mt-1" id="glossary-new-phrase-question-char-count">
                                <span id="glossary-new-phrase-question-char-count-current">0</span>
                                <span id="glossary-new-phrase-question-char-count-maximum">/ 50</span>
                            </div> -->
                            <div class="glossary-feedback-question">
                            Can't find what you are looking for? <a href="javascript:void(0)" class="feedback-question-link" id="glossary-new-phrase-question-submit" @click="submitNewPhrase">Request a new glossary entry</a>
                            </div>
                        </form>
                    </div>
                    <div class="keyword-block">
                        <div v-for="keyword in filteredKeywords"
                        :key="keyword.id" 
                        class="keyword-block-phrase">
                            <button
                            @click="showDefinition(keyword.id)"
                            >{{ keyword.phrase }}</button>
                        </div>
                    </div>
                    <div 
                    v-if="currentDefinition"
                    class="definition-row">
                        <span 
                        class="definition-row-phrase">{{ currentDefinition.phrase }}</span>
                        <span 
                        v-html="currentDefinition.description"
                        class="definition-row-definition"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GlossaryService from '../../services/glossary/glossary.service';
import LoadingSpinner from '../ui/LoadingSpinner.vue';
import Swal from "sweetalert2";
import { talkToServer } from '../../utility/helper-functions';

export default {
    components: {
        LoadingSpinner
    },
    created() {

        this.fetchPhrases();

    },
    data() {
        return {
            resultsReady: true,
            serverForceFetchDone: false,
            alphabet: '',
            renderedAlphabet: [],
            searchTerm: {
                val: '',
                isValid: true
            },
            errorMessage: '',
            filteredKeywords: [],
            currentDefinition: null,
            phrases: []
        }
    }, 
    methods: {
        async fetchPhrases() {
            this.resultsReady = false;

            this.phrases = await GlossaryService.fetchPhrases();
            if(this.phrases.length) {
                this.resultsReady = true;
            }
            //in case phrases have not been loaded on the cache
            else if(!this.serverForceFetchDone) {
                this.fetchPhrasesFromServer();
            }else if(this.serverForceFetchDone && !this.phrases.length)
                this.resultsReady = true;


            this.serverForceFetchDone = true;

            this.renderAlphabet();                

        },
        async fetchPhrasesFromServer() {
            await GlossaryService.fetchPhrasesFromServer();
            this.fetchPhrases();
        },
        renderAlphabet() {
            this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

            [...this.alphabet].forEach(letter => {
                this.renderedAlphabet.push({
                    letter: letter,
                    link: false
                });

                this.phrases.some(phrase => {
                    const uppercase = phrase.phrase.toUpperCase();
                    if(uppercase.indexOf(letter) === 0)
                        this.renderedAlphabet[this.renderedAlphabet.length - 1].link = true;
                });
            });
        },

        pickLetter(ltrs) {
            this.searchTerm.val = '';
            this.sortForRendering(ltrs)

        },
        sortForRendering(ltrs) {
                this.searchTerm.val = ltrs.toLowerCase();
                const filteredKeywords = this.phrases.filter(element => {
                    const lowercase = element.phrase.toLowerCase();
                    if(this.searchTerm.val.length === 0)
                        this.currentDefinition = null;
                    else if(this.searchTerm.val.length === 1) { // for letter picks we just check first character matches
                        if(lowercase.indexOf(this.searchTerm.val) === 0 )
                        return element;
                    }else if(this.searchTerm.val.length > 1){
                        if(lowercase.indexOf(this.searchTerm.val) >= 0)
                        return element;
                    }
                    
                });

                //sort alphabetically
                filteredKeywords.sort(function(a, b) {
                    const wordA = a.phrase.toUpperCase();
                    const wordB = b.phrase.toUpperCase();
                    return (wordA < wordB) ? -1 : (wordA > wordB) ? 1 : 0;
                });

                
                this.filteredKeywords = filteredKeywords;
            
                    
        },
        showDefinition(id) {
            this.currentDefinition = this.phrases.find(element => element.id === id);
        },
        clearDefinition() {
            this.currentDefinition = null;
        },
        submitNewPhrase() {
            if(this.searchTerm.val.length < 2 || this.searchTerm.val.length > 25) {
                this.errorMessage = "Phrase must be 2 - 25 characters long";
                this.searchTerm.isValid = false;
                return;
            }

            Swal.fire({

                    title: 'Submit a new Glossary entry',
                    type: 'warning',
                    text: 'Are you sure the phrase doesn\'t exist?',
                    footer: "Please check for alternative spelling.",
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    cancelButtonText: 'Let me look again',
                    confirmButtonColor: 'green',
                    confirmButtonText: 'Submit new phrase'
            }).then(result => {

                if(result && result.value)
                    talkToServer("/widgets/glossary", "POST", null, { term: this.searchTerm.val }, true)
                    .then(() => {

                        Swal.fire({
                            title: 'Phrase submitted',
                            text: "Please allow us up to 48 hours to review your request and create a definition for " + this.searchTerm.val + ".",
                            type: 'info',
                            confirmButtonColor: '##1d9deb',
                            confirmButtonText: 'OK'
                            // timer: 1500

                        }).then(() => this.searchTerm.val = '');
                    }).catch(error => console.log(error));
                
            }).catch(error => console.log(error));

                
        },
        clearValidity(input) {
            this[input].isValid = true;
            this.errorMessage = '';
        },
    }
}
</script>

<style lang="scss" scoped>
    .glossary-panel {
        margin: 15px;
    }
    .glossary-panel-main {
        overflow-y: auto;
        height: calc(90vh - 220px);
        padding: 0 10px;
    }
    .instruction-row {
        color: $color-06;
    }
    .alphabet-row, .glossary-feedback, .keyword-block, .definition-row {
        // margin: 0 50px;
        margin-bottom: 35px;
    }
    .alphabet-row {
        text-align: center;
    }
    .alphabet-letter {
        display: inline-block;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 19px;
        color: $color-06;
    }
    button {
        background-color: transparent;
        border: none;
        font-size: inherit;
        font-weight: bold;
        cursor: pointer;
        color: $color-twitter;
    }
    .bullet-separator {
        margin: 0 12px;
    }
    .glossary-feedback label {
        font-size: 18px;
    }
    .glossary-feedback-question {
        text-align: center;
        color: $color-06;
        font-size: 12px;

        & a,
        & a:hover,
        & a:active,
        & a:visited {
            color: $color-twitter;
        }
    }
    .keyword-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
    }
    .keyword-block-phrase {
        margin-bottom: 20px;
    }
    .definition-row {
        border: 1px solid $color-067;
        padding: 15px;
        background-color: rgba($color-01, 0.01);
        text-align: center;
        color: $color-06;
    }
    .definition-row-phrase {
        display: inline-block;
        padding: 0 20px;
        margin: 10px 0 15px 0;
        font-size: 22px;
        font-weight: bold;
        border-bottom: 1px solid $color-067;
    }
    .definition-row-definition {
        font-size: 18px;
    }
</style>

<style lang="scss">
    #glossary-new-phrase-question::placeholder {
        color: $color-06;
    }
</style>