<template>
    <span
    v-html="image.html"
    @click="enlargeIMG(image.src)"
    ></span>
</template>

<script>
import { eventBus } from '../../../../main';
export default {
    props: ["image"],
    methods: {
        enlargeIMG(url) {
            eventBus.$emit('open-modal', {
                // title: "Image Viewer",
                classes: "terminal-modal simplified auto-height",
                componentInModal: "image-viewer",
                panel: {
                    url: url
                },
                mode: "wide"
            });
        }
    }
}
</script>