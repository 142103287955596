import axios from 'axios'
import errorHandler from "./HttpErrorInterceptor";
import HttpCancel from "./HttpCancel";
import { guidGenerator, removeSpinner} from "../utility/helper-functions";
import Optional from "optional-js";
import {store} from '../store/store';
class httpClient {

    constructor() {
        let service = axios.create({
            "Content-Type": "application/json"
        });

        service.interceptors.request.use(this.handleRequest);
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }

    addHttpHeaders(options) {
        let headers = {};
        if(!options?.noAuth) {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            headers = {
                "Content-Type": "application/json",
                'X-Machine-Hash': guidGenerator()
            };
            if(bearer) {
                headers['Authorization'] = bearer;
            }
        }
        return {
            ...options,
            headers: headers
        };
    }

    createHttpCancel(url) {
        let cancelToken = axios.CancelToken;
        return new HttpCancel(cancelToken.source(), url);
    }

    handleRequest(config) {
        return config;
    }

    handleSuccess(response) {
        if(!(response.data?.data?.invoice_id > 0)) {
            removeSpinner(store, response.config.url);
        }
        return response;
    }

    handleError = (error) => {
        const url = error.config? error.config.url: error.message;
        removeSpinner(store, url);
        store.dispatch("payments/setMakingPayment", false);
        return errorHandler(error);
    };

    redirectTo = (document, path) => {
        document.location = path;
    };

    get(path, options) {
        this.processOptions(path, options);
        return this.service.get(path, this.addHttpHeaders(options));
    }

    post(path, payload, options) {
        this.processOptions(path, options);
        return this.service.post(path, payload,  this.addHttpHeaders(options));
    }

    put(path, payload, options) {
        this.processOptions(path, options);
        return this.service.put(path, payload,  this.addHttpHeaders(options));
    }

    delete(path, options) {
        this.processOptions(path, options);
        return this.service.delete(path,  this.addHttpHeaders(options));
    }

    patch(path, options, payload) {
        this.processOptions(path, options);
        return this.service.patch(path, payload, this.addHttpHeaders(options));
    }

    request(path, payload, type) {
        return this.service.request({
            method: type,
            url: path,
            responseType: 'json',
            data: payload
        });
    }

    processOptions(path, options = {}) {
        Optional.ofNullable(options)
            .map(optionsList => optionsList.spinner)
            .filter(spinner => spinner === true)
            .ifPresent(() => {
                console.log(path);
                // addSpinner(store, path)
            });
    }

    isCancel(error) {
        return axios.isCancel(error);
    }
}

export default new httpClient;
