import _ from "lodash";
import {findNextNumber} from "../../utility/helper-functions";

export default {
    layouts(state) {
        return state.layouts.layouts;
    },
    activeDashboard(state) {
        return state.activeDashboard;
    },
    activeDashboardByInstanceID: (state) => (instanceID, target) => {
        return state.activeDashboard[instanceID]?.[target];
    },
    isThereLayoutByInstanceID: (state) => (instanceID) => {
        return isNaN(state.activeDashboard[instanceID]) ? true : state.activeDashboard[instanceID]  > 0;
    },
    getDashboardNameByID(state) {
        return (id) => state.layouts.layouts[id].name;
    },
    getLayoutsByDashboardID(state) {
        return (id) => {

            let dashboard = state.layouts.layouts[id] || {};
            const widgetArray = [];

            Object.keys(dashboard?.elements || {}).map(key => dashboard.elements[key]).forEach(element => {
                widgetArray.push(_.cloneDeep(element.layout));
            })

            return widgetArray;
        }
    },
    nextDashboardId(state) {
        return findNextNumber(state.layouts.layouts);
    },
    poppedoutDashboards(state) {
        return state.poppedoutDashboards;
    },
    activeDashboardInfoByInstanceID: (state) => (instanceID, target) => {
        return state.layouts?.layouts?.[state.activeDashboard[instanceID]?.[target]] || {}
    },
    getDashboardById(state) {
        return (id) => state.layouts.layouts[id];
    },
    getPoppedoutDashboardByID: (state) => (id) => {
        return state.poppedoutDashboards.find(element => element.id === id);
    },
    defaultDashboards(state, getters) {
        return getters.dashboards.filter(dashboard => dashboard.default);
    },
    customDashboards(state, getters) {
        return getters.dashboards.filter(dashboard => !dashboard.default && dashboard.custom)
    },
    systemDashboards(state, getters) {
        return getters.dashboards.filter(dashboard => !dashboard.default && !dashboard.custom)
    },
    tabbedDashboards(state, getters) {
        return _.sortBy(getters.dashboards.filter(dashboard => dashboard.tab), dashboard => dashboard.precedence)
    },
    dashboards(state) {
        let dashboards = [];
        Object.keys(state.layouts.layouts || {}).forEach(key => {
            let dashboard = state.layouts.layouts[key];
            if (dashboard.default) {
                dashboards.unshift(dashboard);
            } else {
                dashboards.push(dashboard);
            }
        })
        return dashboards;
    },
    analChatActiveOn(state) {
        return state.analChatActiveOn;
    },
    readonlyDashboardsByImplementationId: function (state) {
        return Object.values(state.layouts.layouts).filter(layout => layout.readonly === true).map(layout => layout.unique_id);
    }

}
