import Vue from 'vue';
import VueRouter from 'vue-router';
// import ForgotPassword from "../components/ui/ForgotPassword";
import AppMain from "../AppMain";
// import ResetPassword from "../components/ui/ResetPassword";
import PasswordReset from "../components/ui/onboard/PasswordReset.vue";
import LoginComponent from "../components/ui/onboard/LoginComponent.vue";
import StartHere from "../components/ui/onboard/StartHere.vue";
import NewPassword from "../components/ui/onboard/NewPassword.vue";
import Constants from '../utility/constants';
// import DashboardComponent from "../components/DashboardComponent.vue";
// import HomeComponent from "../components/HomeComponent.vue";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        // {path: '/forgot-password', component: ForgotPassword},
        // {
        //     path: '/password-reset', component: ResetPassword, props: (route) => ({
        //         token: route.query.nonce,
        //         email: route.query.email
        //     }),
        //     beforeEnter: (to, from, next) => {
        //         console.log(to.query?.email?.length)
        //         if (to.query?.email?.length > 0 && to.query?.nonce?.length > 0) {
        //             next();
        //         } else {
        //             next(false);
        //         }
        //     }
        // },
        {
            path: '/',
            component: AppMain,
            children: [
                {
                    name: 'login',
                    path: '/login',
                    component: LoginComponent,
                    props: true
                },
                {
                    name: 'start-here',
                    path: '/start-here',
                    component: StartHere,
                    props: true
                },
                {
                    name: 'reset',
                    path: '/reset',
                    component: PasswordReset,
                    props: true
                },
                {
                    path: '/password-reset',
                    component: NewPassword,
                    props: (route) => ({
                        token: route.query.nonce,
                        email: route.query.email
                    }),
                    beforeEnter: (to, from, next) => {
                        console.log(to.query?.email?.length)
                        if (to.query?.email?.length > 0 && to.query?.nonce?.length > 0) {
                            next();
                        } else {
                            next(false);
                        }
                    }
                },
                // {
                //     name: 'dashboard',
                //     path: '/dashboard',
                //     component: DashboardComponent,
                //     props:  true,
                // },
                // {
                //     name: 'account',
                //     path: '/account',
                //     component: AccountOverview,
                //     props:  true,
                // },
                // {
                //     path: '/home',
                //     component: HomeComponent,
                //     props: true
                // },
                {
                    name: 'root',
                    path: '/:catchAll(.*)',
                    component: AppMain,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        const autoLogin = window.bootUp.nonTerminalUser || window.bootUp.remoteAccess;
                        // console.log("autoLogin", autoLogin);
                        if(autoLogin) {
                            next();
                            return;
                        } 
                        if (localStorage.getItem("token")) {
                            next();
                        }
                        else {
                            if(+localStorage.getItem("promo") === Constants.MTA_FREE_ROUTE)
                                next("start-here");
                            else
                                next("login");
                        }
                    }
                }
            ],
            
            // beforeEnter: (to, from, next) => {
            //     const autoLogin = window.bootUp.nonTerminalUser || window.bootUp.remoteAccess;
            //     if(autoLogin) {
            //         next();
            //     } 
            //     else if (localStorage.getItem("token")) {
            //         next();
            //     }
            //     else {
            //         console.log("redirect");
            //         next("login");
            //     }
            // }
        },
        // {
        //     name: 'root',
        //     path: '/:catchAll(.*)',
        //     component: AppMain,
        //     props: true,
        //     beforeEnter: (to, from, next) => {
        //         if (localStorage.getItem("token")) {
        //             next();
        //         } else {
        //             next("login");
        //         }
        //     }
        // }
    ]
});

