<template>
    <div>
        <addon-details v-for="addon in currentVisiblePromotions"
        :cancel-handler="cancelProduct"
        :reactivate-handler="reactivateProduct"
        :key="addon.slug"
        :addon="addon"></addon-details>
        <span v-if="currentVisiblePromotions.length === 0">
          <span class="no-item-message">You have no non-subscription products.</span>
        </span>
    </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import AddonDetails from "../payments/AddonDetails";

export default {
    name: "CurrentPromosSection",
    components: {AddonDetails},
    mixins: [SubscriptionMixin],

}
</script>

