<template>
    <div class="widget-shop">
        <div class="modal-info-box">
            <span class="modal-info-box-icon">
                <font-awesome-icon :icon="['fas', 'info-circle']" size="2x"/>
            </span>
                <span>
                    <p class="modal-info-box-text">
                    Addons are the cheapest way to access specific widgets. <br/>Stay on our free plan and pay only for the
                widget(s) you want to use.
                    </p>
                </span>
                
        </div>
        <div class="settings-body" style="margin: 0">
            <div class="shop">
                <div class="shop-container">
                    <div class="shop-item " @click="purchaseAddonProduct(widget)"
                         :class="isWidgetIncludedInPlan(widget)? 'included': 'available'"
                         v-for="widget in currentPageWidgets" :key="uniqueKey(widget.id)">
                        <div class="shop-item--thumbnail">

                            <img :src="widget.image" :alt="widget.name" v-if="widget.image">
                            <font-awesome-icon v-else size="lg" :icon="['fas', 'shopping-cart']"/>
                        </div>
                        <div class="shop-item--description">
                            <div class="shop-item--description__header">
                                <span class="shop-item--description__header-title">
                                    <h3>{{ widget.details.name }}</h3>
                                </span>
                                <span v-if="newAddonsList.includes(widget.implementation_id)" class='badge not-round'
                                      style="position:relative;">New</span>

                            </div>
                            <div class="shop-item--description__text">
                                <p v-html="widget.details.description"></p>
                            </div>
                        </div>
                        <div v-if="availableWidgetsPriceInformation[widget.implementation_id]" class="shop-item-price">
                            <table style="width: 100%">
                                <tbody>
                                <tr>
                                    <td>
                                     <span v-if="displayWidgetPrice(widget)"
                                           class="price"
                                           style="font-size: small;float:left;">
                                         <VueCustomTooltip :label="priceVatInfo()" :multiline="true">
                                         <em>{{ availableWidgetsPriceInformation[widget.implementation_id].price
                                             }}</em>
                                     </VueCustomTooltip></span>
                                    </td>
                                    <td><span v-if="displayWidgetPrice(widget)"></span></td>
                                    <td><span v-if="displayWidgetPrice(widget) && availableWidgetsPriceInformation[widget.implementation_id].includedDetails">OR</span></td>
                                    <td style="float:right; font-size: small;">
                                        <span class="period">
                                        <em>{{ availableWidgetsPriceInformation[widget.implementation_id].includedDetails
                                            }}
                                    </em>
                                </span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
            <pagination v-model="page" :records="widgetsForRendering.length"
            :per-page="this.defaultItemsPerPage" :options="pagOptions"/>

        </div>

    </div>
</template>

<script>
import {randomizeBetween} from '../../../utility/helper-functions';
import {createNamespacedHelpers} from 'vuex';
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import _ from "lodash";
import Swal from "sweetalert2";
import WidgetPurchaseModal from "./WidgetPurchaseModal";
import MyPagination from '../../ui/shop/MyPagination.vue';

const payments = createNamespacedHelpers('payments');
const general = createNamespacedHelpers('general');

export default {
    name: "AddonShop",
    mixins: [SubscriptionMixin],

    computed: {
        ...general.mapGetters(['getLastShopVisitById']),
        ...payments.mapState(['currentProducts', 'availableProducts']),
        ...payments.mapGetters(['widgetProductDetails', 'availableWidgetsPriceInformation', 'sortedWidgetsByCreationDate', 'newAddons']),
        widgetsForRendering() {
            if (this.searchMode) {
                return this.sortedWidgetsByCreationDate.slice().filter(element => element.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1);
            } else {
                return this.sortedWidgetsByCreationDate.slice();
            }
        },
        startIndex: function () {
            return this.page * this.defaultItemsPerPage - this.defaultItemsPerPage;
        },
        endIndex: function () {
            return this.page * this.defaultItemsPerPage;
        },
        currentPageWidgets() {
            return this.widgetsForRendering.filter((element, index) => index >= this.startIndex && index < this.endIndex);
        },
        lastAddonVisitDate: function () {
            return this.getLastShopVisitById("ADDON");
        }
    },
    data() {
        return {
            searchMode: false,
            activeTab: "availableWidgets",
            defaultItemsPerPage: 4,
            searchTerm: '',
            page: 1,
            subscriptionProduct: null,
            newAddonsList: [],
            pagOptions: {
                template: MyPagination    
            }
        }
    },
    methods: {
        ...general.mapActions(['setLastShopVisitDate']),
        isWidgetIncludedInPlan: function (widget) {
            return this.availableWidgetsPriceInformation[widget.implementation_id] && this.availableWidgetsPriceInformation[widget.implementation_id].included;
        },
        changeTab(tab) {
            this.activeTab = tab;
        },

        uniqueKey(id) {
            return randomizeBetween(1, 100000) + '-' + id;
        },
        displayWidgetPrice: function (widget) {
            return this.availableWidgetsPriceInformation[widget.implementation_id]?.addonProducts.length > 0 && !this.availableWidgetsPriceInformation[widget.implementation_id]?.included;
        },
        purchaseAddonProduct: function (widget) {
            let product = widget?.planDetails?.addonProducts?.[0];
            if (this.isWidgetIncludedInPlan(widget)) {
                Swal.fire({
                    title: 'Widget Info!',
                    type: 'info',
                    text: 'This addon is already included in your plan!',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
                return;
            }

            if (product) {
                this.showModal(WidgetPurchaseModal, {widget: widget, addonProduct: product});
                // this.subscriptionProduct = product;
                // this.addSubscription();
            }
        }
    },
    created: function () {
        this.newAddonsList = _.cloneDeep(this.newAddons);
    },
    mounted() {
        this.$nextTick(() => {
            this.setLastShopVisitDate(['ADDON', new Date()]);
        })
    }

}
</script>
<style lang="scss">
.VuePagination nav {
    margin-top: 10px;
    display: flex;
}

.VuePagination nav p {
    margin: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.pagination {
    margin: 0;
    padding: 0;
}

.pager, .pagination ul {
    margin-left: 0;
    *zoom: 1
}

.pagination ul {
    padding: 0;
    display: inline-block;
    *display: inline;
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05)
}

.pagination li {
    display: inline
}

.pagination a {
    float: left;
    /* padding: 0 12px; */
    line-height: 20px;
    text-decoration: none;
    /* border: 1px solid #ddd; */
    border-left-width: 0
}

.pagination .active a /*, .pagination a:hover */ {
    /* background-color: #f5f5f5; */
    color: $color-20;
}

.pagination .active a {
    color: $color-20;
    cursor: default
}

.pagination .disabled a, .pagination .disabled a:hover, .pagination .disabled span {
    color: $color-06;
    background-color: transparent;
    cursor: default
}

.pagination li:first-child a, .pagination li:first-child span {
    border-left-width: 1px;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px
}

.pagination li:last-child a {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0
}

.pagination-centered {
    text-align: center
}

.pagination-right {
    text-align: right
}

.pager {
    margin-bottom: 18px;
    text-align: center
}

.pager:after, .pager:before {
    display: table;
    content: ""
}

.pager li {
    display: inline
}

.pager a {
    display: inline-block;
    padding: 5px 12px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px
}

.pager a:hover {
    text-decoration: none;
    background-color: #f5f5f5
}

.pager .next a {
    float: right
}

.pager .previous a {
    float: left
}

.pager .disabled a, .pager .disabled a:hover {
    color: #999;
    background-color: #fff;
    cursor: default
}

.pagination .prev.disabled span {
    float: left;
    padding: 0 12px;
    line-height: 20px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 1
}

.pagination .next.disabled span {
    float: left;
    padding: 0 12px;
    line-height: 20px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 0
}

.pagination li.active, .pagination li.disabled {
    float: left;
    /* padding: 0 12px; */
    /* line-height: 30px; */
    text-decoration: none;
    /* border: 1px solid #ddd; */
    border-left-width: 0
}

.pagination li.active {
    background: $color-04;
}

.pagination li:first-child {
    border-left-width: 1px;
}

.included {
    border-color: green !important;
}

.available {
    border-color: red !important;
}

.new {
    border-color: orange !important;
}
</style>

// <style lang="scss">
// .widget-shop {
//   position: relative;

//   & .shop-buttons {
//     position: absolute;
//     top: -5px;
//     right: 12px;

//     & button {
//       background-color: transparent;
//       border: none;
//       color: $color-01;
//       cursor: pointer;
//       text-transform: uppercase;
//       font-size: 13px;
//       color: $color-20;

//       &:hover {
//         text-decoration: underline;
//       }

//     }


//   }

//   .pagination li, .pagination li.active, .pagination li.disabled {
//     // padding: 0;
//     line-height: 0;
//     text-decoration: none;
//     border: none;
//     cursor: pointer;
//     color: white;
//   }

//   .pagination li.active, .pagination li.disabled {
//       cursor: unset;
//   }

//   .widget-shop-header {
//     color: $color-20;
//     margin: 30px 15px;

//   }

//   .widget-shop-header {
//     margin-top: 0;

//     & p {
//       color: $color-06;
//     }
//   }


//   .shop-container,
//   .filters {
//     @include col-lg-2;
//   }

//   .shop-container {
//     align-content: baseline;


//     // @include respond(lg) {
//     //   height: 451px;
//     // }

//   }

//   .shop .tabs {

//     @include respond(lg) {
//       height: 499px;
//     }
//   }

//   .shop-item {
//     // box-shadow: 0 0 10px rgba($color-00, 1);
//     border-radius: 2px;
//     // background-color: rgba($color-01, 0.20);
//     background-color: rgba($color-03, 0.6);
//     padding: 15px;
//     display: grid;
//     grid-template-columns: 38fr 62fr;
//     grid-template-rows: 5fr;
//     cursor: pointer;
//     height: 170px;

//     &:hover {
//       background-color: rgba($color-03, 0.85);
//     }

//     // & > div {

//     // }
//   }

//   .shop-item-price {
//     grid-column-start: 1;
//     grid-column-end: 3;
//   }

//   .shop-item--thumbnail {

//     & img {
//       width: 100%;
//     }
//   }

//   .shop-item--description {

//     margin-left: 15px;

//     &__header {
//       display: flex;
//       justify-content: space-between;

//       & h3 {
//         margin-top: 0;
//         font-size: 16px;
//         text-transform: capitalize;
//         letter-spacing: 0.5px;

//       }

//       &-price {

//         margin-left: 3px;

//         & .price {
//           color: $color-17;
//           font-weight: bold;
//           font-size: 22px;
//         }

//         & .period {
//           font-size: 9px;
//           color: $color-06;
//         }
//       }


//     }

//     & p {
//       margin: 0;
//       font-size: 12px;
//       color: $color-06;
//     }

//     &__text {
//       display: -webkit-box;
//       -webkit-line-clamp: 7;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//     }
//   }

// }

// .button-separator {
//   color: $color-06
// }

// @include settings;

// </style>
