<template>
    <div>
        <div class="plan-card-header">
            <h4 :class="planColor">{{ planName }}</h4>
            <div 
            v-if="planLabel"
            :class="planColor" 
            class="plan-card-header-label">{{ planLabel }}</div>
        </div>
        <div class="plan-card-main">
            <div v-if="commitment && !isMainPlanFree" class="plan-card-main-price">
                <span class="price-symbol" v-if="!isFree(productPrice)">$</span>
                <span class="price-value">{{ productPrice }}</span>
                <span class="price-period dimmed-text sub" v-if="!isFree(productPrice)">{{ subscription }} <span v-if="vatAdded">(Inc UK VAT)</span></span>
            </div>
            <div v-else-if="isMainPlanFree" style="height: 36.8px;">
                <span class="price-symbol" v-if="!isFree(calculateCommitmentPrice(plan))">$</span>
                <span class="price-value">{{ calculateCommitmentPrice(plan) }}</span>
                <span class="price-period dimmed-text sub" v-if="!isFree(calculateCommitmentPrice(plan))">{{ renderSubText(plan) }} <span v-if="vatAdded">(Inc UK VAT)</span></span>
            </div>
            <div v-else style="height: 36.8px;" class="plan-card-main-price">
                <span class="price-symbol"><span v-if="commitments.length > 1">From</span> $</span>
                <span class="price-value">{{ cheapest }}</span>
                <span class="price-period dimmed-text sub" v-if="!isFree(calculateCommitmentPrice(plan))">{{ subscription }}<span v-if="vatAdded">(Inc UK VAT)</span></span>
            </div>
            <div class="plan-card-main-description">
                {{ description }}
            </div>
        </div>


        <div v-if="isFreeMTA">
            <div v-if="widgetsToShow.videos.length">
                <div class="widget-handler"
                >
                    
                    <svg 
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                    </svg>
                    <a href="javascript:void(0);"
                    class="widget-name"
                    @click="handleSlideDown('isVideoCourseSlideDownOpen')"
                    >
                    <span
                    v-if="isFullVideoCourseSeries" 
                    class="">Full video course collection </span>
                    <span 
                    v-else
                    class="">{{ widgetsToShow.videos.length }} video course{{ widgetsToShow.videos.length > 1 ? "s" : "" }} </span>
                    <font-awesome-icon v-if="!isVideoCourseSlideDownOpen" :icon="['fa', 'chevron-down']"/>
                    <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                    </a>
                </div>
                <vue-slide-up-down :active="isVideoCourseSlideDownOpen" :duration="1" class="vue-slide-up-down"
                    >
                    <ul class="tc-list">
                        <li v-for="(label, i) in videocourseLabel"
                        :key="i"><svg 
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                        </svg>
                        {{label}}</li>
                    </ul>
                </vue-slide-up-down>
            </div>
            <div v-if="widgetsToShow.livestreams.length">
                <div class="widget-handler"
                >
                    
                    <svg 
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                    </svg>
                    <a href="javascript:void(0);"
                    class="widget-name"
                    @click="handleSlideDown('isLivestreamSlideDownOpen')"
                    >
                    <span
                        v-if="isFullLiveStreamSeries" 
                        class="">Full live stream series </span>
                        <span 
                        v-else
                        class="">{{ widgetsToShow.livestreams.length }} live stream series </span>

                    <font-awesome-icon v-if="!isLivestreamSlideDownOpen" :icon="['fa', 'chevron-down']"/>
                    <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                    </a>
                </div>
                <vue-slide-up-down :active="isLivestreamSlideDownOpen" :duration="1" class="vue-slide-up-down"
                    >
                    <ul class="tc-list">
                        <li v-for="(label, i) in livestreamLabel"
                        :key="i"><svg 
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                    </svg>
                    {{label}}</li>
                    </ul>
                    </vue-slide-up-down>
            </div>
            <div v-for="widget in widgetsToShow.widgets" :key="widget.implementation_id">
                <div class="widget-handler"
                >
                    
                        <svg 
                        v-if="isIncludedInPlan(widget) === 'included' || isIncludedInPlan(widget) === 'showcase'"
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                        </svg>
                    

                        <svg 
                        v-else-if="isIncludedInPlan(widget) == 'not-included'"
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.94074 8.00018L12.4588 11.5214C12.719 11.7819 12.7188 12.204 12.4584 12.4642C12.1979 12.7245 11.7758 12.7243 11.5156 12.4638L7.99723 8.94229L4.47073 12.4636C4.21019 12.7237 3.78808 12.7234 3.52792 12.4629C3.26776 12.2023 3.26808 11.7802 3.52862 11.52L7.05485 7.99905L3.53016 4.47117C3.26992 4.2107 3.27012 3.78859 3.53058 3.52836C3.79105 3.26813 4.21316 3.26832 4.47339 3.52879L7.99836 7.05695L11.5156 3.5449C11.7762 3.28474 12.1983 3.28506 12.4584 3.5456C12.7186 3.80614 12.7183 4.22825 12.4577 4.48841L8.94074 8.00018Z" fill="#727B9A"/>
                        </svg>

                        <VueCustomTooltip label="Not included in any plan. Can be purchased as an addon." v-else-if="isIncludedInPlan(widget) == 'not-in-any'"
                                            :multiline="true"
                                            position="is-bottom">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                        </VueCustomTooltip>
                        <div class="widget-name">{{ formatWidgetName(widget) }}</div>

                        <VueCustomTooltip :label="widgetInfoLabel(widget)" v-if="isIncludedInPlan(widget) == 'showcase'"
                                            :multiline="true"
                                            position="is-bottom">
                            <font-awesome-icon :icon="['fas', 'question-circle']" style="margin-left:4px;"/>
                        </VueCustomTooltip>
                        
                    
                </div>
                
            </div>
        </div>

        <div v-else>
            <div v-for="widget in widgetsToShow.widgets" :key="widget.implementation_id">
                <div class="widget-handler"
                >
                    
                        <svg 
                        v-if="isIncludedInPlan(widget) === 'included' || isIncludedInPlan(widget) === 'showcase'"
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                        </svg>
                    

                        <svg 
                        v-else-if="isIncludedInPlan(widget) == 'not-included'"
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.94074 8.00018L12.4588 11.5214C12.719 11.7819 12.7188 12.204 12.4584 12.4642C12.1979 12.7245 11.7758 12.7243 11.5156 12.4638L7.99723 8.94229L4.47073 12.4636C4.21019 12.7237 3.78808 12.7234 3.52792 12.4629C3.26776 12.2023 3.26808 11.7802 3.52862 11.52L7.05485 7.99905L3.53016 4.47117C3.26992 4.2107 3.27012 3.78859 3.53058 3.52836C3.79105 3.26813 4.21316 3.26832 4.47339 3.52879L7.99836 7.05695L11.5156 3.5449C11.7762 3.28474 12.1983 3.28506 12.4584 3.5456C12.7186 3.80614 12.7183 4.22825 12.4577 4.48841L8.94074 8.00018Z" fill="#727B9A"/>
                        </svg>

                        <VueCustomTooltip label="Not included in any plan. Can be purchased as an addon." v-else-if="isIncludedInPlan(widget) == 'not-in-any'"
                                            :multiline="true"
                                            position="is-bottom">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                        </VueCustomTooltip>
                        <div class="widget-name">{{ formatWidgetName(widget) }}</div>

                        <VueCustomTooltip :label="widgetInfoLabel(widget)" v-if="isIncludedInPlan(widget) == 'showcase'"
                                            :multiline="true"
                                            position="is-bottom">
                            <font-awesome-icon :icon="['fas', 'question-circle']" style="margin-left:4px;"/>
                        </VueCustomTooltip>
                        
                    
                </div>
                
            </div>
            <div v-if="widgetsToShow.livestreams.length">
                <div class="widget-handler"
                >
                    
                    <svg 
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                    </svg>
                    <a href="javascript:void(0);"
                    class="widget-name"
                    @click="handleSlideDown('isLivestreamSlideDownOpen')"
                    >
                    <span
                        v-if="isFullLiveStreamSeries" 
                        class="">Full live stream series </span>
                        <span 
                        v-else
                        class="">{{ widgetsToShow.livestreams.length }} live stream series </span>

                    <font-awesome-icon v-if="!isLivestreamSlideDownOpen" :icon="['fa', 'chevron-down']"/>
                    <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                    </a>
                </div>
                <vue-slide-up-down :active="isLivestreamSlideDownOpen" :duration="1" class="vue-slide-up-down"
                    >
                    <ul class="tc-list">
                        <li v-for="(label, i) in livestreamLabel"
                        :key="i"><svg 
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                    </svg>
                    {{label}}</li>
                    </ul>
                    </vue-slide-up-down>
            </div>
            <div v-if="widgetsToShow.videos.length">
                <div class="widget-handler"
                >
                    
                    <svg 
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                    </svg>
                    <a href="javascript:void(0);"
                    class="widget-name"
                    @click="handleSlideDown('isVideoCourseSlideDownOpen')"
                    >
                    <span
                    v-if="isFullVideoCourseSeries" 
                    class="">Full video course collection </span>
                    <span 
                    v-else
                    class="">{{ widgetsToShow.videos.length }} video course{{ widgetsToShow.videos.length > 1 ? "s" : "" }} </span>
                    <font-awesome-icon v-if="!isVideoCourseSlideDownOpen" :icon="['fa', 'chevron-down']"/>
                    <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                    </a>
                </div>
                <vue-slide-up-down :active="isVideoCourseSlideDownOpen" :duration="1" class="vue-slide-up-down"
                    >
                    <ul class="tc-list">
                        <li v-for="(label, i) in videocourseLabel"
                        :key="i"><svg 
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8027 6.64801C3.54105 6.38897 3.11894 6.39109 2.8599 6.65274C2.60086 6.91439 2.60297 7.33649 2.86462 7.59553L6.74819 11.4404C7.02236 11.7118 7.46901 11.6946 7.72142 11.4028L13.1712 5.10283C13.4121 4.82437 13.3816 4.40336 13.1031 4.16248C12.8247 3.9216 12.4037 3.95206 12.1628 4.23052L7.17953 9.99118L3.8027 6.64801Z" fill="#3DD598"/>
                        </svg>
                        {{label}}</li>
                    </ul>
                </vue-slide-up-down>
            </div>
        </div>


        <div class="plan-card-footer">
            <div 
            v-if="selected === plan.id && commitments.length > 1"
            class="form-group featured label-transition field-input">
                <label for="commitment" class="focused" :class="{'commitment-error': noCommitmentError}"><span>Commitment</span></label>
                <div class="field-wrap">
                    <div class="wrapper">
                        <select id="commitment" type="text" placeholder="Select a commitment *" required="required" class="commitment form-control delay-blur" 
                        :class="{'commitment-error': noCommitmentError}"
                        v-model="commitment"
                        @click="commitmentChanged">
                           
                            <option value="0" 
                            disabled="disabled" selected="selected" hidden="hidden" class="select-prompt">Select a commitment *</option>
                            <option v-for="commitment in commitments" 
                            :key="commitment.id" 
                            :value="commitment.id" 
                            class="select-prompt">{{ renderCommitment(commitment) }}</option>
                 
                            
                        </select>
                    </div>
                </div>
            </div>
            <div
            v-else
            style="height: 78px;"
            ></div>
        </div>

    </div>
    
</template>

<script>
import PlanDetailsHandler from "../../../../mixins/plan.details.handler";
import { formatPriceObject, getServerEnv, isFreeMTARoute, precise } from '../../../../utility/helper-functions';
import SharedMixins from '../../../../mixins/SharedMixins';
import SubscriptionMixin from '../../../../mixins/SubscriptionMixin';
import { toSystemDate } from '../../../../utility/datetime';
import { createNamespacedHelpers } from 'vuex';
import _ from "lodash";

import Constants from '../../../../utility/constants';
const payments = createNamespacedHelpers("payments");

export default {
    props: ["plan", "selected", "widgetsByProduct", "country", "noCommitmentError"],
    mixins: [PlanDetailsHandler, SharedMixins, SubscriptionMixin],
    created() {
        // console.log("rendering card", this.isMainPlanFree);
        // console.log("active", this.activePlan);
        // console.log("plan", this.plan);
        
        // console.log("mergedPlans", this.mergedPlans);
      
    },
    computed: {
        ...payments.mapGetters(['videoWidgetsForShop']),
        productPrice() {
            // console.log(this.plan.name, this.selectedCommitment);
            let price = formatPriceObject(this.selectedCommitment.pricing, false);
            
            const vat = this.$store.state.general.user.sales_tax_percent;
            // console.log("this.plan.pricing", vat, this.$store.state.general.user);
            //onboarding does not have country saved yet, needs to be passed in
            if(this.country) {
                if(this.isVat && !vat) {
                    return !isNaN(price) ? precise(price * 1.2) : price;
                }
                else if(!this.isVat && vat) {
                    return !isNaN(price) ? precise(price / 1.2) : price;
                }
    
                return price;
            }else{
                return price;
            }
                
                
        },
        description() {
            const regex = new RegExp("<[^>]*>?",'gm');
            let description = "";
            if(!this.selectedCommitment)
                description = this.plan.description;
            else 
                description = this.selectedCommitment.description;

            if(this.selectedCommitment?.trial_of_product_id)
                description += ' At the end of the trial you will be downgraded to ' + this.activePlan.product.name + '.'; 

            return description.replace(regex, '');
        },
        //style tags: color-yellow, color-green, label-... must be comma separated
        styleTags(){
            let text = this.plan.secondary_description;
            if(!text)
                return [];
            const regex = new RegExp("<[^>]*>?",'gm');
            text = text.replace(regex, '');
            return text.split(',');
        },
        planColor() {
            return this.styleTags.find(el => el.indexOf('color-') > -1) || 'plain';
        },
        planLabel() {
            const labelString = this.styleTags.find(el => el.indexOf('label-') > -1);
            if(!labelString)
                return '';

            return labelString.toLowerCase().replaceAll('-',' ').replace('label ', '');
        },
        isVat() {
            return this.country === "GBR";
        },
        commitments() {
            return this.plan.commitments;
        },
        livestreamLabel() {
            const streamArr = this.widgetsToShow.livestreams.map(el => el.name);
           
            return streamArr;
        },
        isFullLiveStreamSeries() {
            const visibleLivestreams = this.videoWidgetsForShop.filter(el => el.code === 'TCSTREAM' && el.training_centre_widget_style !== 'LEGACY').length;
            return visibleLivestreams === this.widgetsToShow.livestreams.length;
        },
        videocourseLabel() {
            const videoArr = this.widgetsToShow.videos.map(el => el.name);
            return videoArr;
        },
        isFullVideoCourseSeries() {
            const visibleVideoCourses = this.videoWidgetsForShop.filter(el => el.code === 'TCVIDEO' && el.training_centre_widget_style !== 'LEGACY').length;
            
            return visibleVideoCourses === this.widgetsToShow.videos.length;
        },
        // commitmentsToRender() {
        //     const prod = this.activePlan.product;


        //     if(this.plan.pricing.USD === 0 || !prod.is_visible_front_end || parseFloat(this.activePlan.price) === 0)
        //         return this.commitments;
                
        //     const trialsExcluded = this.commitments.filter(el => !el.trial_of_product_id);

        //     if(prod.trial_of_product_id) {
        //         return trialsExcluded;
        //     }

        //     const currentCommValue = prod.subscription_length * Constants.PLAN_CONSTANTS.length_values[prod.subscription_length_type];

        //     const upgradeables = [];

        //     trialsExcluded.forEach(el => {
        //         const upgradeCommValue = el.subscription_length * Constants.PLAN_CONSTANTS.length_values[el.subscription_length_type];
        //         if(currentCommValue < upgradeCommValue)
        //             upgradeables.push(el);
        //     });

        //     return upgradeables;

        // },
        selectedCommitment() {
            if(this.commitments.length === 1)
                return this.commitments[0];
            return this.commitments.find(comm => comm.id === this.commitment) || 0;
        },
        cheapest() {
            return this.calculateCommitmentPrice(this.commitments[0]);
        },
        isMainPlanFree() {
            const child = this.plan.parent_id;
            let planPrice = +this.plan.pricing.USD;

            if(child)
                planPrice = this.mergedPlans.find(el => el.id === child).pricing.USD;

            return planPrice === 0; 
        },
        widgetsToShow() {
            const arrW = [];
            const arrLs = [];
            const arrVi = [];
            const widgetsByProduct = _.cloneDeep(this.widgetsByProduct);
            widgetsByProduct.forEach(wid => {
                const found = this.plan.widgets.find(el => el.implementation_id === wid.implementation_id);
                
                if(found?.code === 'TCSTREAM') {
                    if(!arrLs.find(e => e.implementation_id === wid.implementation_id)) {
                        arrLs.push(wid);
                    }
                }
                else if(found?.code === 'TCVIDEO') {
                    if(!arrVi.find(e => e.implementation_id === wid.implementation_id)) {
                        const env = getServerEnv();
                      
                        if(found.implementation_id === Constants.MTA_COURSE[env] && this.plan.id !== Constants.MTA_PROMO[env]) {
                            wid.name = wid.name + ' (taster)';
                        
                        }
                                
                    
                        arrVi.push(wid);
                    }
                }
                else if(wid.code !== 'TCSTREAM' && wid.code !== 'TCVIDEO' && wid.planDetails.planProducts.filter(el => el.is_visible_front_end).length) {
                    if(!arrW.find(e => e.implementation_id === wid.implementation_id)) {
                        arrW.push(wid);
                    }
                }
            });
           
            return { 
                widgets: arrW, // _.orderBy(arrW, el => el.planDetails.ranking, "desc"), 
                livestreams: arrLs, 
                videos: arrVi
            };
        },
        isFreeMTA() {
            if(isFreeMTARoute(+localStorage.getItem("promo")))
                return true;

            return false;
        }
        
    },
    data() {
        return {
            commitment: 0,
            isLivestreamSlideDownOpen: false,
            isVideoCourseSlideDownOpen: false
        }
    },
    methods: {
        widgetInfoLabel: function (widget) {
            if(widget.planDetails?.planWidgetDetails?.showcase && widget.planDetails?.purchased?.length === 0) {
                return widget.planDetails?.includedDetails;
            }

            const plans = this.mergedPlans.filter(plan => plan.product_type === 'plan');
            let found;
            let showcaseEnds;
            plans.forEach(plan => {
                const premium = plan.widgets.find(el => el.showcase === false && el.implementation_id === widget.implementation_id);

                const showcase = plan.widgets.find(el => el.showcase && el.implementation_id === widget.implementation_id);
                if(showcase)
                    showcaseEnds = toSystemDate(showcase.showcase_ends_at, false, 'DD MMM YYYY');

                if(premium) {
                    found = plan.name;
                }
            
            });
            if(found)
                return "Normally on " + found + " showcased here for free until " + showcaseEnds + ".";
            
            return "";
        },
        isIncludedInPlan(widget) {
            const thisPlan = Object.values(this.availableProducts).find(el => el.id === this.plan.id);
            const thisWidget = thisPlan.widgets?.find(el => el.implementation_id === widget.implementation_id)
                 
        
            if(thisWidget && thisWidget.showcase)
                return "showcase";

            if(!widget.availablePlans.length)
                return "not-in-any"
            
            
            if(!thisWidget)
                return "not-included";

            
            
            else return "included";
            // if(curren)
            
        },
        formatWidgetName(widget) {
            return widget.name.replace('Terminal: ', '');
        },
        commitmentChanged() {
            // if(!this.commitment && this.commitments.length === 0)
            //     this.commitment = this.commitments[0].id;
            let commitment;
            if(!this.commitment) {
                commitment = this.commitments[0];
            } else {
                commitment = this.commitments.find(el => el.id === this.commitment);
            }

            
            setTimeout(() => this.$emit('commitment-changed', commitment), 100)
            
        },
        handleSlideDown(slidedown) {
            this[slidedown] = !this[slidedown]; 
            this.$emit("plan-changed", this.plan.id);
        }
    },
    watch: {
        selected(nVal) {
            if(nVal === this.plan.id && this.plan.commitments.length > 1)
                this.commitment = 0;
        }
    }
}
</script>

<style lang="scss" scoped>
    .plan-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 33px;
        line-height: 20px;
    }
    .plan-card-header-label {
        padding: 7px 15px;
        line-height: 17px;
        margin-right: -21px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        color: $input-background-color;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        font-weight: 700;
        font-size: 12px;

        &.color-green {
            background-color: $onboard-green;
        }
    }
    h4 {
        margin: 0;
        font-size: 18px;
        letter-spacing: 0.2px;

        &.color-yellow {
            color: $onboard-yellow;
        }
        &.color-green {
            color: $onboard-green;
        }
    }
    .plan-card-main,
    .plan-card-main-footer {
        font-weight: normal;
    }
    .plan-card-main {
        color: $input-color;
    }
    .price-symbol,
    .price-value {
        font-size: 24px;
    }
    .plan-card-main-price {
        display: flex;
        align-items: center;

    }
    .price-period {
        margin-left: 7.5px;
        font-family: 'Roboto'
    }
    .plan-card-main-description {
        margin-top: 14px;
        font-size: 14px;
        @include respond(lg) {
            min-height: 65px;
        }
    }
    .widget-handler {
        display: flex;
        align-items: center;
        margin: 6px 0;
    }
    .widget-name {
        margin-left: 5px;
        font-size: 14px;
        font-weight: normal;

    }
    a.widget-name {
        color: $input-color-filter;
        text-decoration: none;;
    }

    .tc-list {
        margin: 8px 0;
        padding-left: 20px;
        & li {
            font-size: 14px;
            list-style: none;
            font-weight: normal;

            display: grid;
            grid-template-columns: auto 1fr;
            margin-bottom: 5px;

            & svg {
                margin-top: 3px;
                margin-right: 3px;
            }
        }
    }

    .terminal-plan-selector label[for="commitment"] {
        background-color: $onboard-survey-card-background!important;
    }
    .terminal-plan-selector .label-transition input,
    .terminal-plan-selector .label-transition select {
        padding: 12px;
        font-family: Poppins;
        font-weight: 200;
        background-color: $onboard-survey-card-background!important;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px $onboard-survey-card-background inset !important;
        }
    }

    .label-transition label.focused.commitment-error {
        color: $core-lightred
    }

    .commitment-error {
        border-color: $core-lightred;
    }

    .plan-container .vue-custom-tooltip.is-bottom:after {
        width: 160px!important;
    }

</style>

