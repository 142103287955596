<template>
    <div>
        <div class="irptm-header">
            <div class="irptm-header-wrapper">
               
                <div>
                    <country-flag :country="bank.country_code" size='normal'/>
                </div>
              
                <div><h2>{{ bank.central_bank }}</h2></div>
            </div>
        </div>
        <div class="irptm-body">
            <div class="irptm-body-table">
                <div class="irptm-body-table-row col-sm-2">
                    <div class="irptm-body-table-row--unit">
                        <div class="irptm-body-table-row--unit__header">
                            Next Expected Move
                        </div>
                        <div 
                        :class="redOrGreen"
                        class="irptm-body-table-row--unit__body">
                            {{ action }}
                        </div>
                    </div>
                     <div class="irptm-body-table-row--unit">
                         <div class="irptm-body-table-row--unit__header">
                             Change By
                        </div>
                        <div class="irptm-body-table-row--unit__body">
                            {{ formattedChangeBy }}
                        </div>
                    </div>
                </div>
                <div class="irptm-body-table-row col-sm-2">
                    <div class="irptm-body-table-row--unit">
                        <div class="irptm-body-table-row--unit__header">
                            Probability
                        </div>
                        <div class="irptm-body-table-row--unit__body">
                            {{ formattedProbability }}
                        </div>
                    </div>
                     <div class="irptm-body-table-row--unit">
                         <div class="irptm-body-table-row--unit__header">
                             Next Meeting Date
                        </div>
                        <div class="irptm-body-table-row--unit__body">
                            <span>{{ nextMeetingDay.dm }}</span> <span>{{ nextMeetingDay.y }}</span>
                        </div>
                    </div>
                </div>
                <div class="irptm-body-table-row col-sm-2">
                    <div class="irptm-body-table-row--unit">
                        <div class="irptm-body-table-row--unit__header">
                            Current rate
                        </div>
                        <div class="irptm-body-table-row--unit__body">
                            {{ formattedRate }}
                        </div>
                    </div>
                </div>
                <div class="irptm-body-table-row">
                    <div class="irptm-body-table">
                         <div class="irptm-body-table-row--unit__header">
                             Trading Application
                        </div>
                        <div class="irptm-body-table-row--unit__body" v-html="comments">
                        </div>
                        <div class="last-updated-at">Last updated: <span>{{ panel.lastUpdatedAt }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import CountryFlag from 'vue-country-flag';
import { eventBus } from '../../../../main';

import IrptDataMixin from '../../../../mixins/irptdatamixin';

const intrateNS = createNamespacedHelpers('interestRates');

export default {
    props: ["panel"],
    components: {
        CountryFlag
    },
    mixins: [IrptDataMixin],
    created() {
        this.bank = _.cloneDeep(this.panel.bank);
    },
    computed: {
        ...intrateNS.mapGetters(['lastIntRateUpdate', 'lastIntRateDelete']),

        comments() {
            let comments = this.bank.comments;
            if(!comments) {
                return "";
            }
            
            /* eslint-disable */
            const figures = comments.match(/<figure(.*?)figure>+/g);
            if(figures?.length) {
                figures.forEach((fig, index) => {
                    const marker = '#####_' + index + '_#####';
                    comments = comments.replace(fig, marker);


                    const json =JSON.parse(fig.match(/data-trix-attachment=\"(.*?)\">/)[1].replaceAll('&quot;','"').match(/^(.*?)}/)[0]);

                    const href = json.href.replace("[#domain#]", window.bootUp.entity.mapped_domain);

                    comments = comments.replace(marker, `<img class="processed-figure-img" src="${href}" alt="${json.filename}" />`);
                });
                
            }
            
            
            /* eslint-enable */
            return comments;
            
        }
        
    },
    data() {
        return {
            bank: null
        }
    },
    watch: {
        lastIntRateUpdate(val) {
            console.log(val, this.bank);
            if(val[0].id === this.bank.id)  {
                this.bank = _.cloneDeep(val[0]);
            }

        },
        lastIntRateDelete(val) {
            if(this.bank.id === val)
                eventBus.$emit("close-modal");
        }
    }
}
</script>

<style lang="scss" scoped>
    .irptm-header {

        padding: 0 10px;
        margin: 15px 0;

        &-wrapper {
            display: flex;
            align-items: center;
        }
        & h2 {
            @include H2;
            margin: 0;
            // padding-bottom: 5px;
            margin-left: 20px;
        }
    }

    .irptm-body {
        padding: 0 21px;
        margin-bottom: 20px;
    }

    .irptm-body-table-row {
        margin-bottom: 25px;
    }

    .irptm-body-table-row--unit__header {
        @include H7;
    }

    .irptm-body-table-row--unit__body {
        margin-top: 5px;
        @include H6;
        font-weight: normal;
        color: $input-color-filter;
    }
</style>

