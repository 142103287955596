import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import countries from '../../assets/countries.json';

export default {
    namespaced: true,
    state() {
        return{
            majorCountries: ["AUS", "CAN", "CHE", "CHN", "EMU", "FRA", "GBR", "DEU", "JPN", "NZL", "USA"],
            minorCountries: [
                "AFG",
                "ALA",
                "ALB",
                "DZA",
                "ASM",
                "AND",
                "AGO",
                "AIA",
                "ATA",
                "ATG",
                "ARG",
                "ARM",
                "ABW",
                "AUT",
                "AZE",
                "BHS",
                "BHR",
                "BGD",
                "BRB",
                "BLR",
                "BEL",
                "BLZ",
                "BEN",
                "BMU",
                "BTN",
                "BOL",
                "BES",
                "BIH",
                "BWA",
                "BVT",
                "BRA",
                "IOT",
                "UMI",
                "VGB",
                "VIR",
                "BRN",
                "BGR",
                "BFA",
                "BDI",
                "KHM",
                "CMR",
                "CPV",
                "CYM",
                "CAF",
                "TCD",
                "CHL",
                "CXR",
                "CCK",
                "COL",
                "COM",
                "COG",
                "COD",
                "COK",
                "CRI",
                "HRV",
                "CUB",
                "CUW",
                "CYP",
                "CZE",
                "DNK",
                "DJI",
                "DMA",
                "DOM",
                "ECU",
                "EGY",
                "SLV",
                "GNQ",
                "ERI",
                "EST",
                "ETH",
                "FLK",
                "FRO",
                "FJI",
                "FIN",
                "GUF",
                "PYF",
                "ATF",
                "GAB",
                "GMB",
                "GEO",
                "GHA",
                "GIB",
                "GRC",
                "GRL",
                "GRD",
                "GLP",
                "GUM",
                "GTM",
                "GGY",
                "GIN",
                "GNB",
                "GUY",
                "HTI",
                "HMD",
                "VAT",
                "HND",
                "HKG",
                "HUN",
                "ISL",
                "IND",
                "IDN",
                "CIV",
                "IRN",
                "IRQ",
                "IRL",
                "IMN",
                "ISR",
                "ITA",
                "JAM",
                "JEY",
                "JOR",
                "KAZ",
                "KEN",
                "KIR",
                "KWT",
                "KGZ",
                "LAO",
                "LVA",
                "LBN",
                "LSO",
                "LBR",
                "LBY",
                "LIE",
                "LTU",
                "LUX",
                "MAC",
                "MKD",
                "MDG",
                "MWI",
                "MYS",
                "MDV",
                "MLI",
                "MLT",
                "MHL",
                "MTQ",
                "MRT",
                "MUS",
                "MYT",
                "MEX",
                "FSM",
                "MDA",
                "MCO",
                "MNG",
                "MNE",
                "MSR",
                "MAR",
                "MOZ",
                "MMR",
                "NAM",
                "NRU",
                "NPL",
                "NLD",
                "NCL",
                "NIC",
                "NER",
                "NGA",
                "NIU",
                "NFK",
                "PRK",
                "MNP",
                "NOR",
                "OMN",
                "PAK",
                "PLW",
                "PSE",
                "PAN",
                "PNG",
                "PRY",
                "PER",
                "PHL",
                "PCN",
                "POL",
                "PRT",
                "PRI",
                "QAT",
                "KOS",
                "REU",
                "ROU",
                "RUS",
                "RWA",
                "BLM",
                "SHN",
                "KNA",
                "LCA",
                "MAF",
                "SPM",
                "VCT",
                "WSM",
                "SMR",
                "STP",
                "SAU",
                "SEN",
                "SRB",
                "SYC",
                "SLE",
                "SGP",
                "SXM",
                "SVK",
                "SVN",
                "SLB",
                "SOM",
                "ZAF",
                "SGS",
                "KOR",
                "SSD",
                "ESP",
                "LKA",
                "SDN",
                "SUR",
                "SJM",
                "SWZ",
                "SWE",
                "SYR",
                "TWN",
                "TJK",
                "TZA",
                "THA",
                "TLS",
                "TGO",
                "TKL",
                "TON",
                "TTO",
                "TUN",
                "TUR",
                "TKM",
                "TCA",
                "TUV",
                "UGA",
                "UKR",
                "ARE",
                "URY",
                "UZB",
                "VUT",
                "VEN",
                "VNM",
                "WLF",
                "ESH",
                "YEM",
                "ZMB",
                "ZWE"
            ],
            allImpacts: [-1, 1, 2, 3],
            countryData: countries,

            lastEcoCalendarUpdate: null,
            lastEcoCalendarDelete: null,

            fetchDay: null
        }
    },
    mutations,
    actions,
    getters
}