import PaymentService from "@/services/payments/payment.service.js";
import _ from "lodash";
import EventBus from "../bus";

export default {
    loadAvailableProducts({commit}) {
        return new Promise((resolve => {
            PaymentService.listAllAvailableProducts().then(result => {
                commit("setAvailableProducts", result.data.data);
                resolve();
            })
        }));
    },

    setCurrentShop({dispatch}, [current, scheduled]) {
        let currentPlans = _.cloneDeep(current);
        dispatch("setCurrentProducts", currentPlans);

        let scheduledPlans = _.cloneDeep(scheduled);
        dispatch("setScheduledProducts", scheduledPlans);
        if(!window.bootUp.nonTerminalUser) {
            dispatch("dashboard/generateReadonlyDashboards", currentPlans, {root: true});
            // console.log('analyst chat from action');
            EventBus.$emit("ANALCHAT_CHECK");
        }

    },
    setCurrentProducts({commit}, payload) {
        commit('setCurrentProducts', payload);
    },
    setScheduledProducts({commit}, payload) {
        commit('setScheduledProducts', payload);
    },
    loadPaymentMethod({commit}) {
        return new Promise(resolve => {
            PaymentService.getPaymentMethodDetails().then(result => {
                commit("setPaymentMethod", _.cloneDeep(result?.data?.data));
                resolve();
            }).catch(() => {
                commit("setPaymentMethod", {});
                resolve();
            })
        });
    },
    loadPaymentHistory: async function ({commit}) {
        let paymentHistory = [];
        Promise.all([PaymentService.listInvoiceHistory(), PaymentService.listPaymentAttemptsHistory()])
            .then(([resultInvoices, resultPaymentAttempts]) => {
                PaymentService.processPaymentEntries(resultInvoices, resultPaymentAttempts, paymentHistory);
                commit("setPaymentHistory", paymentHistory);
            })
    },
    loadPaymentHistoryPromise: function ({commit}) {
        let paymentHistory = [];
        return new Promise(function (resolve) {
            Promise.all([PaymentService.listInvoiceHistory(), PaymentService.listPaymentAttemptsHistory()])
                .then(([resultInvoices, resultPaymentAttempts]) => {
                    PaymentService.processPaymentEntries(resultInvoices, resultPaymentAttempts, paymentHistory);
                    commit("setPaymentHistory", paymentHistory);
                    resolve();
                })
        });

    },
    removePaymentMethod({dispatch}) {
        return new Promise(resolve => {
            PaymentService.removePaymentMethod().then(() => dispatch("loadPaymentMethod").then(() => resolve()));
        })
    },
    purchaseAddon({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.purchaseAddon(payload).then(() => dispatch("general/loadCurrentUserSettings", { purchase: true }, {root:true}).then(() => resolve()));
        })
    },
    purchasePlan({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.purchasePlan(payload).then(() => {
                console.log("loadCurrentUserSettings")
                dispatch("general/loadCurrentUserSettings", { purchase: true }, {root:true}).then(() => resolve())
            });
        })
    },
    cancelAddon({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.cancelAddon(payload).then(() => dispatch("general/loadCurrentUserSettings", {}, {root:true}).then(() => resolve()));
        })
    },
    cancelPlan({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.cancelPlan(payload).then(() => dispatch("general/loadCurrentUserSettings", {}, {root:true}).then(() => resolve()));
        })
    },
    setWidgetProducts({commit}, payload) {
        commit("setWidgetProducts", _.cloneDeep(payload));
    },
    destroyUserStoreData({commit}) {
        commit('destroyUserStoreData');
    },
    setFailedPayment({commit}, payload) {
        commit("setFailedPayment", payload);
    },
    setMakingPayment({commit}, payload) {
        commit("setMakingPayment", payload);
    },
    setHistoricProductAccess({commit}, payload) {
        commit("setHistoricProductAccess", payload);
    }
}
