<template>
    <div>
        <div class="timeslot">{{ theDate }}</div>
        <news-item
        v-for="news in newsPerday"
        :key="news.id"
        :news="news"
        ></news-item>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { usersTime } from '../../../../utility/datetime';
import NewsItem from './NewsItem';

const general = createNamespacedHelpers("general");

export default {
    props: ["newsPerday", "day"],
    components: {
        NewsItem
    },
    computed: {
         ...general.mapGetters(['user']),
        timezone() {
            return this.user.timezone;
        },
        theDate() {
            return usersTime(this.day, true, false, false, false, false, "UTC");
        }
    },
    // created() {
    //     console.log(this.newsPerday);
    // }
}
</script>

