export default {
    addBulkRecords(context, payload) {
        
        const processingObject = {
            objStore: `interest_rates`, 
            data: payload
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(() => {});
        
    },

    lastIntRateUpdate(context, payload) {
        context.commit("lastIntRateUpdate", payload);
    },

    lastIntRateDelete(context, payload) {
        context.commit("lastIntRateDelete", payload);
    },


}