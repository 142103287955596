export default {
    // haveHeadlines(state, payload) {
    //     state.haveHeadlines = payload;
    // },

    lastHeadlineUpdate(state, payload) {
        state.lastHeadlineUpdate = payload;
    },
    setRareHeadlinesFetched(state, payload) {
        state.rareHeadlinesFetched = payload;
    }

}