export default {
    currentProducts: [],
    paymentHistory: [],
    scheduledProducts: [],
    paymentMethod: {},
    availableProducts: [],
    widgetProducts: {},
    failedPayment: {},
    makingPayment: false,
    historicProductAccess: []
}
