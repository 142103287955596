<template>
    <div class="embedded-component">
        <h2 class="align-center">Let's build your terminal</h2>
        <div class="form-text-div dimmed align-center subtitle">Just a few more steps to help us tailor the terminal to your trading needs.</div>
        <form @submit.prevent class="container">
            <div class="button-div mt-10">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="continuePressed"
                >Continue</base-button>
            </div>
        </form>
    </div>
</template>

<script>
// import { talkToServer } from '../../../utility/helper-functions';

export default {
    props: ["details"],
    // created() {
    //     talkToServer("/journey/free-entry", "GET", null, null, true);
    // },
    methods: {
        continuePressed() {
            console.log("step 10");
            this.$emit("update-step", {
                step: "10"
            });
        }
    }
}
</script>