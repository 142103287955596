<template>
    <div>
        <promotional-products></promotional-products>
    </div>
</template>

<script>
import PromotionalProducts from "./PromotionalProducts";
export default {
    name: "ViewProducts",
    components: {PromotionalProducts},
}
</script>

<style scoped>

</style>
