<template>
    <div :class="'card-' + cardType">
        <dashboard-card
        :cardType="cardType"
        :item="item"
        v-if="item.ad"
        ></dashboard-card>
        <dashboard-card
        :cardType="cardType"
        :item="item"
        v-else-if="!item.ad && (cardType === 'dashboards' || cardType === 'products')"
        ></dashboard-card>
        <widget-card
        v-else-if="cardType === 'widgets'"
        :cardType="cardType"
        :item="item"
        :noActions="noActions"
        :widgetDirectAddition="widgetDirectAddition"
        ></widget-card>
        <content-card
        v-else-if="cardType === 'videotiles'"
        :item="item"
        :details="details"
        >
        </content-card>
        <video-card
        v-else-if="tcType"
        :cardType="cardType"
        :item="item"
        :noActions="noActions"
        :widgetDirectAddition="widgetDirectAddition"
        ></video-card>
    </div>
    
</template>

<script>

import DashboardCard from './sectioncards/DashboardCard.vue';
import WidgetCard from './sectioncards/WidgetCard.vue';
import VideoCard from './sectioncards/VideoCard.vue';
import ContentCard from  '../widgets/components/VideoPlayers/ContentCard.vue'

export default {
    props: ["cardType", "item", "noActions", "widgetDirectAddition", "details"],
    components: {
        ContentCard,
        DashboardCard,
        WidgetCard,
        VideoCard
    },
    // created() {
    //     console.log(this.details);
    //     // console.log("logging", );
    // },
    computed: {
        tcType() {
            return (this.cardType === 'livestreams' || 
            this.cardType === 'withupcoming' || 
            this.cardType === 'noupcoming' || 
            this.cardType === 'vidlibs')
        }
    },
    methods: {
        
    }
    
}
</script>

<style lang="scss">
    .card-products {
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }
    }
    .section-card {

        padding: 20px;
        padding-bottom: 0;
        background-color: $onboard-survey-card-background;
        border-radius: 10px;
        position: relative;

        &.dashboards {
            height: 180px;
            padding-top: 0;
            padding-bottom: 20px;
            position: relative;

            & .section-card-body {
                height: 70px;
            }

            & .bottom-banner {
                display: none;
            }
        }

        &.products {
            height: 160px;
            width: calc(100vw - 40px);
            min-width: 250px;

            @include respond(xs) {
                width: 250px;
                min-width: unset;
            }


            & .section-card-body {

                // & img {
                    // height: 130px;
                // }
            }
        }

        & .showcase-ribbon {
            position: absolute;
            right: 0;
            top: 10px;
            padding: 3px 12px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: $onboard-green;
            color: $onboard-dialog-background;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            font-weight: 700;
            font-size: 12px;
        }

        &.widgets {

            padding: 15px 20px;
            
            display: grid;
            
            gap: 20px;

            @include respond (lg) {
                grid-template-columns: 110px 1fr;
                // height: 220px;

                & > div:nth-child(3) {
                    grid-column: 1 / 3;
                    
                }
            }

            @include respond(xl) {
                grid-template-columns: 110px 1fr 182px;
                height: 120px;

                & > div:nth-child(3) {
                    grid-column: auto;
                }
            }

            &.no-action {
                @include respond(xl) {
                    grid-template-columns: 110px 1fr;
                }
            }

            & .bottom-banner {
                display: none;
            }


        }

        &.livestreams,
        &.withupcoming,
        &.noupcoming,
        &.vidlibs {
            padding: 0;
            background-color: transparent;
            width: calc(100vw - 40px);
            margin-right: 10px;
            
            @include respond(sm) {
                margin-right: 15px;
                width: 275px;
            }
        }

        &.icon {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        & .bottom-banner {
            position: absolute;
            bottom: 0;
            background-color: $core-red;
            width: 100%;
            left: 0px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 1px 0;

            font-size: 10px;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;

        }

    }

    .tiles .videotiles,
    .tiles .livestreams,
    .tiles .withupcoming,
    .tiles .noupcoming,
    .tiles .vidlibs {
        width: auto;
        margin-right: 0;
    }

    .section-card-header,
    .section-card-footer {
        font-weight: 600;
        display: flex;
        justify-content: space-between;
    }

    .section-card-header-sup,
    .section-card-footer-sup {
        font-size: 10px;
        text-transform: uppercase;
        color: $input-color-filter;
        letter-spacing: 1px;
        font-weight: 300;
    }
    .section-card-header-sup {
        height: 14px;
    }

    .section-card-header-main,
    .section-card-footer-main {
        height: 20px;
        font-size: 14px;
        letter-spacing: 0.3px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .section-card-body {
        text-align: center; //

        & img {
            // width: 100%;
            object-fit: cover;
            margin-top: 10px;
            height: 77px;
        }

    }

    .section-card-top,
    .section-card-bottom {
        padding: 8px 0;
    }

    .showcase-star {
        color: $core-yellow;
    }

    .how-activation-works .section-card.widgets,
    .promo-includes .section-card.widgets {
        @include respond (sm) {
            grid-template-columns: 110px 1fr;
            // height: 220px;

            & > div:nth-child(3) {
                grid-column: 1 / 3;
                
            }
        }
    }
    
</style>