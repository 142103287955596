export default {
    subscribedToSquawk(state) {
        return state.subscribedToSquawk;
    },
    squawkAvailable(state) {
        return state.squawkAvailable;
    },
    switchedToFullScreen(state) {
        return state.switchedToFullScreen;
    }
}