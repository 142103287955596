<template>
  <chart-line
    style="border-radius: 15px;"
    :chart-data="chartBarData"
    :options="chartBarData.options"
    :styles="chartBarStyles"
    :dataIteration="dataIteration"
  />
</template>

<script>
import ChartLine from "./Chartline.vue";


export default {
  name: "App",
  props: ["dataPoints", "dataIteration", "css"],
  components: {
    ChartLine,
  },
  created() {
    this.chartBarStyles.height = this.css.height + "px";
    this.renderChart();
  },
  data: () => ({
    chartBarData: {
      labels: [
        // "2020-12-01",
        // "2020-12-02",
        // "2020-12-03",
        // "2020-12-04",
        // "2020-12-05",
      ],
      datasets: [
        // {
        //   label: "Container 1",
        //   borderColor: "#16CEB9",
        //   pointBackgroundColor: "black",
        //   borderWidth: 1,
        //   pointBorderColor: "black",
        //   backgroundColor: "#16CEB9",
        //   data: [],
        // },
        // {
        //   label: "Container 2",
        //   borderColor: "#81c784",
        //   pointBackgroundColor: "black",
        //   borderWidth: 1,
        //   pointBorderColor: "black",
        //   backgroundColor: "#81c784",
        //   data: [],
        // },
      ],
      options: {
        stacked: false,
        elements: {
            line: {
                tension: 0
            }
        },
        layout: {
          padding: {
            top: 15,
            right: 15,
            bottom: 15,
            left: 10,
          },
        },
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                // display: false ,
                color: "#292C38"
              },
            },
          ],
          yAxes: [
            {
              id: "yAxis",
              display: true,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                // display: false ,
                color: "#292C38"
              },
            },
          ],
        },
        annotation: {
          drawTime: "afterDatasetsDraw",
          annotations: [
            {
              id: "maxLine",
              type: "line",
              mode: "horizontal",
              scaleID: "yAxis",
              value: "23",
              borderColor: "#ff00ff",
              borderWidth: 5,
              label: {
                enabled: true,
                position: "center",
                content: "23",
              },
            },
          ],
        },
      },
    },
    chartBarStyles: {
      height: "0px",
      position: "relative",
      minWidth: 0,
      backgroundColor: "#1E1E1E"
    },
  }),
  methods: {
    renderChart() {
      if(this.dataPoints.labels?.length)
        this.chartBarData.labels = this.dataPoints.labels;
      if(this.dataPoints.datasets)
        this.chartBarData.datasets = this.dataPoints.datasets;
    }
  },
  watch: {
    dataIteration() {
      this.renderChart();
    }
  }
};
</script>

<style></style>
