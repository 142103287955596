/* eslint-disable */
import _ from 'lodash';
import {store} from '../../store/store';
let gtmTracking = false;
let dataLayer = [];
export default {
    
    initializeTracking(initialize) {
        if(!initialize) {
            return;
        }
        gtmTracking = true;
        window.dataLayer = window.dataLayer || [];
        dataLayer = window.dataLayer;
    
        
    },
    trackUser() {
        if(!gtmTracking)
            return;

        dataLayer.push({
            'user_id': 'USER_ID',
            'crm_id': 'USER_ID'
        });
    
    },
    sendEvent(eventName, obj) {
        if(!gtmTracking || !eventName)
            return;

        // console.log(`gtag('event', ${eventName}, ${JSON.stringify(obj)});`)
        
        if(_.isEmpty(obj))
            gtag('event', eventName);
        else
            gtag('event', eventName, obj);
    },
    sendTransaction(prod, pretag, nowrap=false) {
        if(!gtmTracking)
            return;
            
        const vat = (store.state.general.user.sales_tax_percent + 100) / 100;
        let obj = {};
        let slug;
        if(nowrap) {
            slug = prod.slug;
            obj = {
                name: prod.name,
                slug: prod.slug,
                id: prod.id,
                price: +prod.pricing.USD / 100 * vat,
                
            };
        }
        else {
            slug = prod.product.slug;
            obj = {
                name: prod.product.name,
                slug: prod.product.slug,
                id: prod.product.id,
                price: +prod.price / 100 * vat,
                
            };
            
        }
        slug = slug.replaceAll(/[\W_]/g,'_');
    
        this.sendEvent(pretag + "_" + slug, obj);
    
    }

}
