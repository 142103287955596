export default {
    setSubscribedToSquawk(state, payload) {
        state.subscribedToSquawk = payload;
    },
    setSquawkAvailable(state, payload) {
        state.squawkAvailable = payload;
    },
    setSwitchedToFullScreen(state, payload) {
        state.switchedToFullScreen = payload;
    }
}