<template>
    <div class="form-control" :class="formControlClass">

		<label v-if="forID" :for="forID" :class="labelClass">{{label}}</label>

		<input
		v-if="elementType === 'input' && (inputType === 'email' || inputType === 'password' || inputType === 'number' || inputType === 'text')"
        v-bind="$attrs"
        :type="inputType"
        v-on="$listeners"
        :value="value"
        :id="forID"
        :placeholder="placeholder"
        :class="inputClass"
        :max="max"
        @focus="clearValidity(forID)"
        @input="triggerInput"
        @keyup="countChars"
        />

        <textarea
		v-if="elementType === 'textarea'"
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        :placeholder="placeholder"
        :id="forID"
        :class="inputClass"
        @focus="clearValidity(forID)"
        @input="triggerInput"
        @keyup="countChars"
		></textarea>

		<select
		v-if="elementType === 'select'"
		:name="forID"
		:id="forID"
        v-bind="$attrs"
        v-on="$listeners"
        :class="inputClass"
        :value="value"
        @change="$emit('update', $event.target.value)"
		><slot /></select>

        <input
        v-if="elementType === 'input' && inputType === 'range'"
        :type="inputType"
        :class="inputClass"
        :id="forID"
        :name="forID"
        :value="value"
        :max="max"
        v-bind="$attrs"
        v-on="$listeners"
        @input="$emit('update', $event.target.value)"
        />

        <input
        v-if="elementType === 'input' && inputType === 'checkbox'"
        :type="inputType"
        :class="inputClass"
        :id="forID"
        :name="forID"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
        @input="$emit('update', $event.target.value)"
        />

        <div 
        v-if="max && inputType !== 'range'"
        class="char-count">
            <span class="char-count-current">{{ inputLength }}</span>
            <span class="char-count-maximum">/ {{ max }}</span>
        </div>

        <p v-if="invalid">{{ invalidMessage }}</p>
    </div>
</template>

<script>
	export default {
		inheritAttrs: false,
		props: ["value", "label", "inputClass", "invalid", "inputType", "elementType", "forID", "invalidMessage", "labelClass", "max", "formControlClass", "placeholder"],
		model: {
			prop: "value",
			event: "update"
		},
		methods: {
			clearValidity(id) {
				this.$emit("clear-validity", id);
			},
            triggerInput(event) {
                let input = event.target.value;

                if(this.maxChars && this.maxChars < input.length) {
                    input = input.substr(0, this.maxChars - 1);
                }
                    

                this.$emit('update', input);
            },
            countChars(event) {
                this.inputLength = event.target.value.length;
            }
		},
        data() {
            return {
                inputLength: 0,
                maxChars: this.max ? +this.max : null
            }
        }
	};
</script>

<style lang="scss">


    label {
        // font-weight: bold;
        display: block;
        margin-bottom: 5px;
        color: $input-color-filter;
        letter-spacing: 0.1px;
        font-weight: 300;
    }

    .video-question {
        font-size: 16px;
        color: $color-06 !important;
        
    }

    .large-text {
        & label {
            font-size: 25px;
        }
        & input {
            font-size: 22px;
            color: $color-20;
        }

    }

	input,
    textarea,
	select,
    .filled-form input,
    .filled-form select {
		width: 100%;
		height: 48px;
        font-family: Poppins;
		color: $input-color;
        border: none;
        border-radius: 10px;
        background-color: $onboard-survey-background;
        padding: 10px;
        letter-spacing: 0.3px;
        font-size: 24px;
        font-weight: 200;
	}
    input::placeholder {
        color: $input-color-filter;
    }
    textarea {
        height: 105px;
        resize: none;
        font-family: Poppins;
        letter-spacing: 0.5px;
        padding: 10px;
        font-size: 14px;
    }

	input:focus,
    select:focus,
    textarea:focus
    .filled-form:focus,
    .filled-form select:focus {

        //for light theme
		// box-shadow: 0 0 5px rgba(81, 203, 238, 1);
		// border: 1px solid rgba(81, 203, 238, 1);
        // box-shadow: 0 0 2px 2px #7f98e4;
		outline: none;
	}
    textarea:focus-visible {
        outline: 1px solid $input-focus-color;
    }
    .no-focus input:focus,
    .no-focus select:focus,
    .no-focus textarea:focus {
        box-shadow: none;
		border: none;
		outline: none;
    }

    input.flat {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid $color-06;
        font: inherit;
        height: 35px;
		color: $color-20;
    }

    input.flat:focus {
        outline: none;
		box-shadow:none;
		border:none;
		border-bottom: 1px solid #7f98e4;
    }

    //RANGE SLIDER

    // input[type=range] {
    //     -webkit-appearance: none;
    //     width: 100%;
    //     background: transparent;
    // }

    // input[type=range]::-webkit-slider-thumb {
    //     -webkit-appearance: none;
    // }

    // input[type=range]:focus {
    //     outline: none;
    // }

    // input[type=range]::-ms-track {
    //     width: 100%;
    //     cursor: pointer;

    //     background: transparent;
    //     border-color: transparent;
    //     color: transparent;
    // }

    .rangle-input {

        & input {
            height: unset;
            padding: 0;
        

            $height: 30px;
            $thumb-height: 5px;
            $track-height: 5px;

            // colours
            $upper-color: $input-border-color;
            $lower-color: $checkbox-active;
            $thumb-color: $checkbox-active;
            $thumb-hover-color: $checkbox-active;

            $upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
            $lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

            // Webkit cannot style progress so we fake it with a long shadow
            // on the thumb element
            @function webkit-slider-thumb-shadow($i: 1) {
                $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
                @if $i == 1 {
                    @for $k from 2 through 1000 {
                        $val: #{$val}, webkit-slider-thumb-shadow($k);
                    }
                }
                @return $val;
            }

            
                display: block;
                appearance: none;
                width: 100%;
                margin: 0;
                height: $height;
                overflow: hidden;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
        

        // Webkit
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: $height;
            background: $lower-background;
        }

        &::-webkit-slider-thumb {
            position: relative;
            appearance: none;
            height: $thumb-height;
            width: $thumb-height;
            background: $thumb-color;
            // border-radius: 100%;
            border: 0;
            top: 50%;
            margin-top: (-$thumb-height/2);
            box-shadow: webkit-slider-thumb-shadow();
            transition: background-color 150ms;
        }

        // Firefox
        &::-moz-range-track,
        &::-moz-range-progress {
        width: 100%;
        height: $height;
        background: $upper-background;
        }

        &::-moz-range-progress {
        background: $lower-background;
        }

        &::-moz-range-thumb {
        appearance: none;
        margin: 0;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        // border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
        }

        // Internet Exploder
        &::-ms-track {
        width: 100%;
        height: $height;
        border: 0;
        // color needed to hide track marks
        color: transparent;
        background: transparent;
        }

        &::-ms-fill-lower {
        background: $lower-background;
        }

        &::-ms-fill-upper {
        background: $upper-background;
        }

        &::-ms-thumb {
        appearance: none;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        // border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
        // IE Edge thinks it can support -webkit prefixes
        top: 0;
        margin: 0;
        box-shadow: none;
        }

        &:hover,
        &:focus {

            &::-webkit-slider-thumb {
            background-color: $thumb-hover-color;
            }
            &::-moz-range-thumb {
            background-color: $thumb-hover-color;
            }
            &::-ms-thumb {
            background-color: $thumb-hover-color;
            }
        }

        }

        &.muted input {
            &::-webkit-slider-thumb {
                background-color: $input-border-color;
            }
        }
        // & input {
        //     -webkit-appearance: none;
        //     height: .5rem;
        //     border-radius: 5px;
        //     background: $color-06;
        //     outline: none;
        //     cursor: pointer;
        //     width: 100%;
        //     padding: 0;

        //     &::-webkit-slider-thumb {
        //         -webkit-appearance: none;
        //         appearance: none;
        //         width: 15px;
        //         height: 15px;
        //         border-radius: 50%;
        //         background: $color-20;
        //         cursor: pointer;
        //     }

        // }
    }

    .invalid input,
    .invalid textarea {
        border: 1px solid red;
    }

    .invalid p {
        color: red;
    }
    .invalidMessage {
      border-color: red;
    }

    // .char-count {
    //     text-align: right;
    // }
    .char-count span {
        color: $input-color-filter;
        font-size: 10px;
    }
</style>
