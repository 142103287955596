import { createNamespacedHelpers } from 'vuex';
import HeadlineService from '../services/headlines/headline.service';
import { usersTime } from '../utility/datetime';
import { arrayIntersect, mergeArraysByProp, sortDataBy } from '../utility/helper-functions';
import _ from "lodash";

const headlinesNS = createNamespacedHelpers('headlines');
const general = createNamespacedHelpers("general");

export default {
    computed: {
        ...headlinesNS.mapGetters(['readyForHeadlineProcessing', 'lastHeadlineUpdate']),
        ...general.mapGetters(['user']),
        timezone() {
            return this.user.timezone;
        },
        searchValues() {
            return this.details.searchTerms;
        },
        headlineSettings() {
            return this.details.userSettings;
        }
        // sourcesArray() {
        //     return this.details.userSettings.sources;

        // },
        // categoriesArray() {
        //     return this.details.userSettings.categories;
        // }

    },
    data() {
        return {
            bulkHeadlines: [],
            sortedItems: {},
            headLinesPerFetch: 25,
            resultsReady: true,
            searchTerms: null,
            noMoreItems: false,
            highlightTimeOut: null,
            newHeadline: false,
            serverForceFetchDone: false,

        }
    },
    methods: {
        handleMergedSources() {
            if(this.details.userSettings.sources) {
                const sources = [...this.details.userSettings.sources];

                const index = sources.findIndex(el => el === 100000000);
                sources.splice(index, 1, 1, 2);
                this.sources = sources;
            }
        },
        async fetchInitialHeadlines() {
            this.resultsReady = false;
            // console.log(this.sources,this.categories);
            const obj = {
                sources: this.sources,
                categories: this.categories,
                props: this.currentSection
            };

            this.bulkHeadlines = await HeadlineService.fetchInitialHeadlines(obj);
            if(this.bulkHeadlines.length && this.bulkHeadlines.length > 10) {
                this.resultsReady = true;
            }
            //in case headlines have not been loaded on the cache
            else if(!this.serverForceFetchDone) {
                this.fetchHeadlinesFromServer();
            }else if(this.serverForceFetchDone && !this.bulkHeadlines.length)
                this.resultsReady = true;


            this.serverForceFetchDone = true;
        },
        async loadMore () {
            this.resultsReady = false;
            const limit = this.headLinesPerFetch;
            let offset = this.bulkHeadlines.length;
            let fromTo = "*/*";
            let keywords ="*";
            let searchMode = false;

            // console.log(this.searchTerms);

            if(this.searchTerms) {
                fromTo = this.searchTerms.from + "/" + this.searchTerms.to + "T23:59:59";
                keywords = this.searchTerms.keywords;
                offset = this.bulkHeadlines.length ? this.bulkHeadlines.length : 0;
                searchMode = true;
            }

            const response = await HeadlineService.loadMoreHeadlines(this.slug, this.sources, this.categories, this.currentSection, offset, limit, searchMode, fromTo, keywords);
            let bulkHeadlines = mergeArraysByProp(this.bulkHeadlines, response, "id");
            sortDataBy(bulkHeadlines, this.sortProp, "timeDESC");

            this.bulkHeadlines = bulkHeadlines;
            if(response.length < 4) {
                this.noMoreItems = true;
            }


            this.resultsReady = true;

        },
        async fetchHeadlinesFromServer() {
            await HeadlineService.fetchHeadlinesFromServer();
            this.fetchInitialHeadlines();
        },
        removeHighlight() {
            let bulkHeadlines = this.bulkHeadlines;
            bulkHeadlines.forEach(element => {
                if(element.new) {
                    element.new = false;
                }
            });

            this.bulkHeadlines = [];
            this.bulkHeadlines = bulkHeadlines;

        },
       
    },
    watch: {
        readyForHeadlineProcessing(val) {
            if(val) {
                this.fetchInitialHeadlines();
            }
        },
        bulkHeadlines() {
            this.sortedItems = {};

            this.bulkHeadlines.forEach(headline => {

                let thisDay = usersTime(headline[this.sortProp], true, true, false, {
                    dateFormat: "y-m-d"
                }, false, this.timezone);

                thisDay = thisDay.substring(0,10);

                if(!this.sortedItems[thisDay])
                    this.sortedItems[thisDay] = [];

                this.sortedItems[thisDay].push(headline);
            });


            this.sortedItems = _.cloneDeep(this.sortedItems);

        },
        lastHeadlineUpdate(val) {

            let bulkHeadlines = _.cloneDeep(this.bulkHeadlines);

            val.forEach(headline => {
                if(headline.deleted_at) {
                    const index = bulkHeadlines.findIndex(element => element.id === headline.id);

                    if(index > -1)
                        bulkHeadlines.splice(index,1);

                }else{
                    
                    if(!this.searchTerms &&
                    this.sources.includes(headline.source_id) &&
                    arrayIntersect(this.categories, headline.categories) &&
                    (headline.props.includes(this.currentSection) || this.currentSection === 0)) {

                        //not a great way to handle highlights for updates or fresh news, but working for now
                        clearTimeout(this.highlightTimeOut);
                        this.highlightTimeOut = setTimeout(() => {
                            this.removeHighlight();
                        },1800);

                        const ID = headline.id;
                        const index = bulkHeadlines.findIndex(element => element.id === ID);

                        if(index > -1) {
                            bulkHeadlines.splice(index, 1, headline);
                        }else{
                            bulkHeadlines.push(headline);
                            headline.new = true;
                            this.newHeadline = true;
                            // playSound();
                        }
                    }
                }
            });

            sortDataBy(bulkHeadlines, this.sortProp, "timeDESC");

            this.bulkHeadlines = bulkHeadlines;

            if(this.isVidcom)
                this.focusFirstVideoIfNeeded();

        },
        searchValues(val) {
                
                this.noMoreItems = false;
            this.bulkHeadlines = [];
            if(val) {
                this.searchTerms = val;
                this.loadMore();
                // this.initialFetchDone = false;
            }else{
                this.searchTerms = null;
                this.fetchInitialHeadlines();
                
                // this.initialFetchDone = true;
            }



        },
        headlineSettings: {
            handler(val) {
                if(this.searchTerms)
                    this.$emit("close-search");

                this.bulkHeadlines = [];

                this.categories = val.categories;
                // this.sources = val.sources;
                this.handleMergedSources();

                this.fetchInitialHeadlines();
            },
            deep: true
        },
        user: {
            deep: true,
            handler: function () {
                this.bulkHeadlines = _.cloneDeep(this.bulkHeadlines);
            }
        }
        // sourcesArray(val) {
        //     if(val) {
        //         this.categories = [];
        //         this.sources = val;
        //         this.fetchInitialHeadlines();
        //     }

        // },
        // categoriesArray(val) {
        //     if(val) {
        //         this.sources = [];
        //         this.categories = val;
        //         this.fetchInitialHeadlines();
        //     }

        // }
    }
}
