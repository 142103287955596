<template>
    <div class="slot-action-overlay">
        <div>
            <a href="javascript:void(0)"
            @click="addWidget"
            class="icon"
            ><font-awesome-icon :icon="['fas', iconCode]"/></a>
        </div>
        <div>
            <base-button 
            mode="green"
            @click.native="addWidget"
            >{{ buttonText }}</base-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["item"],
    // created() {
    //     console.log("item", this.item)
    // },
    computed: {
        replaceMode() {
            return !this.item.emptySlot;
        },
        iconCode() {
            if(this.replaceMode)
                return 'exchange-alt';
            
            return 'plus';
        },
        buttonText() {
            if(this.replaceMode)
                return "Replace";

            return "Add"
        }
    },
    methods: {
        addWidget() {
            this.$emit('slot-selected', this.item);
        }
    }
}
</script>

<style lang="scss" scoped>
    .slot-action-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border: 2px solid  $input-focus-color;
        border-radius: 10px;
        background-color: rgba($onboard-survey-card-background, 0.9);
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        color: $input-color;

        & > div {
            margin: 4px 0;

            & .icon {
                color: $onboard-survey-progress-bar-background;
                font-size: 36px;
            }
        }

        & a {
            color: inherit;
            font-size: 22px;
        }
    }
</style>